import { createSlice } from "@reduxjs/toolkit";

import { SensorTypeObject } from "../../types";

/***************************************************************************** */
export interface MeasurementTypesState {
  sensorTypes: Array<SensorTypeObject> | [];
}

const initialState: MeasurementTypesState = {
  sensorTypes: []
};

export const deviceTypesSlice = createSlice({
  name: "DeviceTypes",
  initialState,
  reducers: {}
});
/***************************************************************************** */

/***************************************************************************** */
export const {} = deviceTypesSlice.actions;

export default deviceTypesSlice.reducer;
