import moment, { utc } from "moment";
import { DeviceObject } from "../../types";

//FORMATS NUMBER VALUE TO GIVEN FORMAT
export const formatNumber = (
  value: number | undefined | null | any,
  digits?: number,
  unit?: string
): string => {
  let sReturn = "-";

  if (value !== undefined && value !== null) {
    sReturn = digits !== undefined ? value.toFixed(digits).toString() : value.toString();
  }

  sReturn = unit !== undefined ? sReturn + " " + unit : sReturn;

  return sReturn;
};

export const GetDateString = (dateIn: string | number | undefined | null, format: string): string => {
  if (dateIn === null || dateIn === undefined) return "-";
  var utcTime;
  if (typeof dateIn === 'number') {
    utcTime = moment.unix(dateIn/1000); // dateIn as milliseconds
  }
  else {
    utcTime = moment.utc(dateIn);
  }
  const localTime = moment(utcTime).local();
  const formatedDt = moment(localTime).format(format);
  return formatedDt;
};

export const CountDateDifference = (
  dateString: string | undefined | null
): { value: number; translationText: string } => {
  if (dateString === undefined || dateString === null) {
    return { value: 123754, translationText: "Error" };
  }

  //console.log("COUNTING DIFFERENCE");
  const dateStamp = Date.parse(dateString+"Z"); // without Z interpreted as local time

  const dateNow = Date.now();

  const timeDifference = dateNow - dateStamp;

  const day_Difference = Math.round(timeDifference / (1000 * 3600 * 24));
  const hour_Difference = Math.round(
    (timeDifference - day_Difference * (1000 * 3600 * 24)) / (1000 * 3600)
  );
  const minute_Difference = Math.round(
    (timeDifference - hour_Difference * (1000 * 3600)) / (1000 * 60)
  );

  let timeReturn = { value: -1, translationText: "count_difference.never" };

  if (day_Difference > 0) {
    //timeReturn = day_Difference.toString() + t("count_difference.days_ago");
    timeReturn = { value: day_Difference, translationText: "count_difference.days_ago" };
  } else if (hour_Difference > 0) {
    //timeReturn = hour_Difference.toString() + t("count_difference.hours_ago");
    timeReturn = { value: hour_Difference, translationText: "count_difference.hours_ago" };
  } else if (minute_Difference > 15) {
    //timeReturn = minute_Difference.toString() + t("count_difference.minutes_ago");
    timeReturn = { value: minute_Difference, translationText: "count_difference.minutes_ago" };
  } else if (minute_Difference > 0) {
    //timeReturn = t("count_difference.now");
    timeReturn = { value: -1, translationText: "count_difference.now" };
  }
  if (timeReturn.value > 10000) {
    return { value: -1, translationText: "count_difference.never"};
  }
  return timeReturn;
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const getAbsoluteHumidity = (temp: number, rh: number): number => {
  return parseFloat(((6.112 * Math.exp((17.67 * temp) / (temp + 243.5)) * rh * 2.1674) / (273.15 + temp)).toFixed(2));
};

export const getAbsoluteHumidityText = (
  temp: number | undefined,
  rh: number | undefined
): string => {
  return temp && rh ? getAbsoluteHumidity(temp, rh).toFixed(2) + " g/m3" : "- g/m3";
};
export const isActiveDevices = (devices: Array<DeviceObject>) => {
  const activeDevices = devices?.filter(device => {
    return device.linkStartDateTime && !device.linkEndDateTime ? true : false;
  });
  return activeDevices[0] ? true : false;
};

export const getNonCompensatedMC = (mc: number, temp: number): number => {
  const x = temp + 2.8;
  return parseFloat(((0.881 * (1.0056) ** x) * mc - 0.567 + 0.0260 * x - 0.000051 * x ** 2).toFixed(2));
};

export const getMcText = (mc: number | undefined, temp: number | undefined, text: string) : string => {
  return mc && temp ? getNonCompensatedMC(mc, temp).toFixed(2) + text : `- ${text}`;
};

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 * 
 * Used in measurementSlice and reportSlice to merge fetched
 * data.
 */
export const mergeDeep = (target:any, source:any) => {
  const isObject = (obj:any) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = sourceValue.concat(targetValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

/**
 * Traverses the object data recursively through and
 * when finds key timestamp, will create timestampNumber which
 * is same as timestamp, but converted from string format to number value
 * 
 * Used in measurementSlice and reportSlice
 */ 
export const performTimestampDuplicate = (data:any) => {
  const isObject = (obj:any) => obj && typeof obj === 'object';
  Object.keys(data).forEach(key => {
    if(isObject(data[key])) {
      performTimestampDuplicate(data[key]);
    } else {

      // Support for short key names for lower data and faster transfer
      if(key === "t") {
        if(data["timestampNumber"] === undefined) {
          data["timestampNumber"] = data[key];
        }
      }
      if(key === "v") {
        if(data["value"] === undefined) {
          data["value"] = data[key];
        }
      }

      if(key === "timestamp") {
        if(data["timestampNumber"] === undefined) {
          data["timestampNumber"] = new Date(data[key]).getTime();
        }
      }
    }
  })
}

export const sensorTextFunction = (deviceData: any, unit: string) => {
  const finalUnit = unit === "MoistureContent A"  ? " %MC (A)" : unit === "MoistureContent B" ? " %MC (B)" : unit;
  const finalText = formatNumber(deviceData, 2, finalUnit);
  return finalText;
} 
