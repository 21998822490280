import { useKeycloak } from "@react-keycloak/web";

type Props = {
  children: JSX.Element;
  altPage: JSX.Element;
};

const PrivateRoute = ({ children, altPage }: Props) => {
  const { keycloak } = useKeycloak();

  var isLoggedIn = keycloak.authenticated;

  if (isLoggedIn) {
  }

  return isLoggedIn ? children : altPage;
};

export default PrivateRoute;
