import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { commonAttributes } from "./../common/commonAttributes";
import { RootState } from "../../app/store";
import { DeviceSettingsDeviceObject, ProjectObject } from "../../types";

export interface CompanyState {
  userCompanyRoles: Array<UserCompanyObject>;
  companyProjects: Array<AllCompanyProjects>;
  showAllCompanyProjects: Array<CompanyProjectsState>;
  userCompanies: Array<UserCompanyObject>;
  companyDevices: Array<DeviceSettingsDeviceObject>;
  error: boolean;
  loading: boolean;
}

export type CompanyProjectsState = {
  companyId: number;
  showAllProjects: boolean;
}

export type AllCompanyProjects = {
  companyId: number;
  companyProjects: Array<ProjectObject>;
}

export type UserCompanyObject = {
  id: number;
  startDateTime: string;
  endDateTime: string;
  status: number;
  userId: string;
  userName: string;
  companyId: number;
  companyName: string;
  companyRoleId: number;
  companyRoleName: string;
};

const initialState: CompanyState = {
  userCompanyRoles: [],
  companyProjects: [],
  showAllCompanyProjects: [],
  userCompanies: [],
  companyDevices: [],
  error: false,
  loading: false
};

export const getCompanyProjects = createAsyncThunk(
  "SelectedCompany/getCompanyProjects", 
  async (companyId: number) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/Companies/${companyId}/Projects`);

    return response.data;
  }
);

export const getCompanyUserRights = createAsyncThunk(
  "SelectedCompany/getCompanyUserRights",
  async (projectId: string | undefined) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/Users/Companies`);

    return response.data;
  }
);

export const addCurrentUserInformation = createAsyncThunk(
  "SelectedCompany/addCurrentUserInformation",
  async () => {
    await axios.get(`${commonAttributes.apiUrl}/Users/Current/Add`);

    return {};
  }
);

export const getCompanyDevices = createAsyncThunk(
  "SelectedCompany/getCompanyDevices",
  async (companyId: number) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/Companies/${companyId}/Devices/ProjectsAndMeasurements`);

    return {data: response.data};
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    toggleShowAllCompanyProjects: (state, action: PayloadAction<number>) => {
      const companyId = action.payload;
      const index = state.showAllCompanyProjects.findIndex(
        company => company.companyId === companyId
      );

      if (index >= 0) {
        let company = state.showAllCompanyProjects[index];

        state.showAllCompanyProjects[index] = { ...company, showAllProjects: !state.showAllCompanyProjects[index].showAllProjects };
      }else {
        state.showAllCompanyProjects.push({companyId: companyId, showAllProjects: false});
      }
    },
    clearCompanySliceError: state => {
      state.error = false;
    },
    clearCompanyDevices: state => {
      state.companyDevices = [];
    }
  },
  extraReducers: {
    // **********************************************************************************
    [getCompanyUserRights.pending.type]: (state, action) => {},
    [getCompanyUserRights.fulfilled.type]: (state, action) => {
      state.userCompanyRoles = action.payload;
    },
    [getCompanyUserRights.rejected.type]: (state, action) => {
      console.log("FAILED to get user rights");
      state.userCompanyRoles = [];
    },
    // **********************************************************************************
    [addCurrentUserInformation.pending.type]: (state, action) => {},
    [addCurrentUserInformation.fulfilled.type]: (state, action) => {},
    [addCurrentUserInformation.rejected.type]: (state, action) => {
      console.log("addCurrentUserInformation rejected");
    },
    [getCompanyProjects.pending.type]: (state, action) => {},
    [getCompanyProjects.fulfilled.type]: (state, action: PayloadAction<ProjectObject[]>) => {
      const response = action.payload;
      if (response.length === 0) {
        return;
      }
      let companyId = response[0].ownerCompanyId;

      const index = state.companyProjects.findIndex(
        company => company.companyId === companyId
      )
      if (index >= 0) {
        let company = state.companyProjects[index];
        state.companyProjects[index] = {...company, companyProjects: state.companyProjects[index].companyProjects = action.payload};
      }else {
        state.companyProjects.push({companyId: companyId, companyProjects: action.payload});
      }
    },
    [getCompanyProjects.rejected.type]: (state, action) => {
      console.log("getCompanyProjects rejected");
    },
    [getCompanyDevices.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getCompanyDevices.fulfilled.type]: (state, action) => {
      state.companyDevices = action.payload.data;
      state.loading = false;
    },
    [getCompanyDevices.rejected.type]: (state, action) => {
      state.error = true;
      state.loading = false;
      console.log("Failed to get companyDevices");
    }
  }
});

export const getUserProjectManagerCompanies = (state: RootState) => {
  const selectedData: Array<UserCompanyObject> = state.company.userCompanyRoles.filter(
    x => x.companyRoleName === "ProjectManager"
  );

  return selectedData;
};
export const getUserProjectManagerAndAdminCompanies = (state: RootState) => {
  const selectedData: Array<UserCompanyObject> = state.company.userCompanyRoles.filter(
    x => x.companyRoleName === "ProjectManager" || x.companyRoleName === "Admin"
  );

  return selectedData;
};

export const getUserAdminAndFinancialCompanies = (state: RootState) => {
  const selectedData: Array<UserCompanyObject> = state.company.userCompanyRoles.filter(
    x => x.companyRoleName === "Admin" || x.companyRoleName === "FinancialManager"
  );

  return selectedData;
};

export const getUserAdminCompanies = (state: RootState) => {
  const selectedData: Array<UserCompanyObject> = state.company.userCompanyRoles.filter(
    x => x.companyRoleName === "Admin"
  );

  return selectedData;
};

export const getUserFinancialManagerCompanies = (state: RootState) => {
  const selectedData: Array<UserCompanyObject> = state.company.userCompanyRoles.filter(
    x => x.companyRoleName === "FinancialManager"
  );

  return selectedData;
};

export const { toggleShowAllCompanyProjects, clearCompanySliceError, clearCompanyDevices } = companySlice.actions;

export default companySlice.reducer;
