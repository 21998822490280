import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./KeyCloak";
import PrivateRoute from "./app/PrivateRoute";
import axios from "axios";
import WelcomePage from "./pages/WelcomePage";
import { addCurrentUserInformation } from "./features/company/companySlice";
import { setLoggedInUser, setLogoutUser } from "./features/authentication/authSlice";

//GUI Components
import PageHeader from "./features/UI/PageHeader";
import AppContent from "./AppContent";
import PageFooter from "./features/UI/PageFooter";
import ReportPage from "./pages/ReportPage";

import styles from "./App.module.css";

/* const authService = new AuthService(); */

function App() {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.authentication.user);

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        const redirectUri = process.env.NODE_ENV === "production"
        ? `https://${window.location.hostname}`
        : process.env.REACT_APP_REDIRECT_URL;
      
        const logoutUrl = `${keycloak.authServerUrl}/realms/Relia/protocol/openid-connect/logout?post_logout_redirect_uri=${redirectUri}/&id_token_hint=${user.idToken}`;
        window.location.replace(logoutUrl);
        dispatch(setLogoutUser());
        window.sessionStorage.setItem("sessiontimeoutalert", true.toString());
      }
      return Promise.reject(error);
    }
  );
  //Add a request interceptor to detect if token has expired or is expiring and try to renew the token
  axios.interceptors.request.use(
    async function (request) {
       const tokenIsExpiring = await keycloak.isTokenExpired(60);
       if (tokenIsExpiring) {
         try {
           await keycloak.updateToken(-1);
           axios.defaults.headers.common["Authorization"] = "Bearer " + keycloak.token
           return request;
         }catch (error) {
           return Promise.reject(error);
         }
       }
       return request;
     }
   );

  const onKeycloakEvent = (event: any) => {
    if (event === "onAuthSuccess") {
      // SET USER TO STORAGE
      dispatch(
        setLoggedInUser({
          userName: keycloak.tokenParsed.email,
          firstName: keycloak.tokenParsed.given_name,
          lastName: keycloak.tokenParsed.last_name,
          idToken: keycloak.idToken,
          accessToken: undefined,
          tokenExpiry: undefined,
          roles: undefined
        })
      );
      axios.defaults.headers.common["Authorization"] = "Bearer " + keycloak.token;
      dispatch(addCurrentUserInformation());
    }
  };

  return (
    <ReactKeycloakProvider
      LoadingComponent={<div className={styles["appLoading-Container"]}>Loading...</div>}
      authClient={keycloak}
      onEvent={onKeycloakEvent}
    >
      <Routes>
        <Route
          path="/*"
          element={
            <div className={styles["App-Container"]}>
              <PageHeader />
              <PrivateRoute altPage={<WelcomePage />}>
                <AppContent />
              </PrivateRoute>
              <PageFooter />
            </div>
          }
        />
        <Route
          path="/Report/:projectId"
          element={
            <PrivateRoute altPage={
            <div className={styles["App-Container"]}>
              <PageHeader /> 
                <WelcomePage />
              <PageFooter />
            </div>
            }>
              <ReportPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </ReactKeycloakProvider>
  );
}

export default App;
