import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DeviceSettingsMeasurementPointObject,
  DeviceSettingsProjectObject,
  PlanObject
} from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDevice,
  clearError,
  clearMeasurementPointsAndPlans,
  getDeviceMeasurementPoints,
  getDevicePlans,
  updateProjects
} from "./deviceSlice";
import { RootState } from "../../../../../app/store";
import PlanView from "../planview/PlanView";
import { useTranslation } from "react-i18next";
import { unlinkMeasurementPointDevice } from "../../../../measurements/measurementsSlice";
import { getCompanyDevices } from "../../../../company/companySlice";
import ConfirmDialog from "../../../../UI/ConfirmDialog";
import { useGetUserProjectsQuery } from "../../../projectApi";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
interface RowProps {
  project: DeviceSettingsProjectObject;
  handleLinkDialogClose: any;
}

const DeviceSettingsUnlinkTableRow = (props: RowProps) => {
  const { project, handleLinkDialogClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const { data: userProjects } = useGetUserProjectsQuery();

  const [hasPlannerRights, setHasPlannerRights] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [plan, setPlan] = useState<PlanObject | undefined>(undefined);
  const [measurementPoint, setMeasurementPoint] = useState<
    DeviceSettingsMeasurementPointObject | undefined
  >(undefined);

  const planData = useSelector((state: RootState) => state.device.plans);
  const measurementPoints = useSelector((state: RootState) => state.device.measurementPoints);
  const projects = useSelector((state: RootState) => state.device.device.projects);

  useEffect(() => {
    if (userProjects !== undefined) {
      const userAdminAndPlannerProjects = userProjects.filter(userProject => userProject.projectRoleName === "Admin" || userProject.projectRoleName === "Planner");
      const isPlanner = userAdminAndPlannerProjects.some(p => p.projectId === project.id);
      setHasPlannerRights(isPlanner);
    }
  }, [project.id, userProjects]);

  useEffect(() => {
    if (hasPlannerRights) {
      dispatch(getDevicePlans(project.planId));
      dispatch(getDeviceMeasurementPoints(project.measurementPointId));
    }
  }, [dispatch, project, hasPlannerRights]);

  useEffect(() => {
    if (!hasPlannerRights) {
      return;
    }
    if (plan === undefined && planData.length > 0) {
      const currentPlan = planData.find(plan => plan.projectId === project.id);
      setPlan(currentPlan);
    }
  }, [planData, plan, project.id, hasPlannerRights]);

  useEffect(() => {
    if (!hasPlannerRights) {
      return;
    }
    if (measurementPoints.length > 0 && plan !== undefined) {
      const currentPoint = measurementPoints.find(point => Number(point.planId) === plan.id);
      setMeasurementPoint(currentPoint);
    }
  }, [plan, measurementPoints, planData, hasPlannerRights]);

  const handleUnlink = () => {
    const measurementPointDeviceId = projects
      .filter(proj => proj.id === project.id)
      .find(x => x.measurementPointDeviceId)?.measurementPointDeviceId;
    if (measurementPointDeviceId !== undefined && measurementPoint !== undefined) {
      dispatch(
        unlinkMeasurementPointDevice({
          measurementPointID: measurementPoint.id.toString(),
          deviceId: measurementPointDeviceId.toString()
        })
      );
    }
    dispatch(updateProjects(project.id));
    dispatch(clearDevice());
    dispatch(clearError());
    dispatch(getCompanyDevices);
    handleLinkDialogClose();
  };

  return (
    <>
      {!hasPlannerRights && (
        <TableRow sx={{ borderBottom: "1px solid var(--colorGray)" }}>
          <>
          {!smallScreen &&
          <> 
          <TableCell sx={{ verticalAlign: "middle !important", padding: "10px 0px 5px 0px !important" }}>
            <Tooltip title={t("device_management.unauthorized_project") || ""}>
              <PriorityHighIcon sx={{ color: "red" }} fontSize={"small"} />
            </Tooltip>
          </TableCell>
          <TableCell sx={{ verticalAlign: "middle !important" }}>
            <Typography sx={{ fontWeight: "bold" }}>{project.name}</Typography>
          </TableCell>
          </>
          }
          {smallScreen &&
          <>
          <TableCell sx={{ verticalAlign: "middle !important", display: "inline-flex" }}>
            <Typography sx={{ fontWeight: "bold", verticalAlign: "middle !important" }}>{project.name}</Typography>
            <Tooltip title={t("device_management.unauthorized_project") || ""}>
              <PriorityHighIcon sx={{ color: "red" }} fontSize={"small"} />
            </Tooltip>
          </TableCell>
          </>
          }
          </>
        </TableRow>
      )}
      {hasPlannerRights && (
        <TableRow sx={smallScreen ? {display: "inline-block"} : {}}>
          <TableCell sx={{ verticalAlign: "middle !important" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell scope="cell" sx={{ verticalAlign: "middle !important" }}>
            <Typography sx={{ fontWeight: "bold" }}>{project.name}</Typography>
          </TableCell>
          {!smallScreen && (
            <TableCell sx={{ verticalAlign: "middle !important", padding: "10px !important" }}>
              <Button
                variant="contained"
                size="small"
                color="info"
                disabled={measurementPoint === undefined}
                onClick={() => {
                  setConfirmDialogOpen(true);
                }}
              >
                {t("device_management.unlink")}
              </Button>
            </TableCell>
          )}
          {smallScreen && (
            <TableCell sx={{ verticalAlign: "middle !important", padding: "10px !important" }}>
              <Button
                variant="contained"
                size="small"
                color="info"
                disabled={measurementPoint === undefined}
                onClick={() => {
                  setConfirmDialogOpen(true);
                }}
              >
                {t("device_management.unlink")}
              </Button>
            </TableCell>
          )}
          <TableCell align={!smallScreen ? "right" : "left"}></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      )}
      {hasPlannerRights && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto">
              <Box sx={{ margin: 0 }}>
                <Typography variant="body1" component="div">
                  {t("device_management.plan")}:
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: smallScreen ? "12px" : "16px" }}>
                  {plan?.name}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      {plan !== undefined && measurementPoint !== undefined && (
                        <TableCell
                          sx={{
                            width: !smallScreen ? "400px" : "200px",
                            height: "200px",
                            borderBottom: "none"
                          }}
                        >
                          <PlanView
                            editContent={false}
                            zoomLevel={0}
                            stageHidded={false}
                            projectPlanId={plan.id.toString()}
                            resizeCanvas={2}
                            contentEditable={false}
                            measurementPoints={[
                              {
                                id: measurementPoint.id.toString(),
                                name: measurementPoint.name,
                                annotation: "",
                                x: measurementPoint.positionX,
                                y: measurementPoint.positionY,
                                isLocated: true,
                                selected: true,
                                devices: []
                              }
                            ]}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ height: "50px" }}>
                      {measurementPoint !== undefined && (
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Typography>{t("device_management.measurement_points")}:</Typography>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "10px",
                              fontSize: smallScreen ? "12px" : "16px"
                            }}
                          >
                            {measurementPoint.name}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      <ConfirmDialog
        key={"device-settings-unlink-dialog"}
        open={confirmDialogOpen}
        value={""}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => {
          handleUnlink();
          dispatch(clearMeasurementPointsAndPlans());
        }}
        content={t("device_management.unlink_device_confirm")}
      />
    </>
  );
};

export default DeviceSettingsUnlinkTableRow;
