import {
  Box,
  Button,
  TextField,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import AddProjectForm from "../../../AddProjectForm";
import AddProjectPlanForm from "../AddProjectPlanForm";
import AddOrEditMeasurementDialog from "../measurementPointList/AddOrEditMeasurementDialog";
import AddDeviceToMeasurementDialog from "../measurementPointList/AddDeviceToMeasurementDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  addDeviceWithDetails,
  addMeasurementPoint
} from "../../../../measurements/measurementsSlice";
import { DeviceTypeObject } from "../../../../devices/deviceTypeAPI";
import { useTranslation } from "react-i18next";
import ChangeActiveLicenceDialog from "../settings/ChangeActiveLicenceDialog";
import { fetchActiveLicence, fetchLicenceTypes, fetchProjectPlans } from "../../../projectSlice";
import { useGetUserProjectsQuery } from "../../../projectApi";
import { getPlanMeasurementPoints } from "./deviceSlice";

interface Props {
  onClose: () => void;
}

const CreateProjectForm = (props: Props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: userProjectData, error, isLoading } = useGetUserProjectsQuery();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [addDeviceError, setAddDeviceError] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<number | "">("");
  const [selectedPlanId, setSelectedPlanId] = useState<number | "">("");
  const [selectedMeasurementPointId, setSelectedMeasurementPointId] = useState<number |"">("");
  const [editForm, setEditForm] = useState<boolean>(false);
  const [editPlan, setEditPlan] = useState<boolean>(false);
  const [editMeasurementPoint, setEditMeasurementPoint] = useState<boolean>(false);

  const createdProjectId = useSelector((state: RootState) => state.device.createdProjectId);
  const createdPlanId = useSelector((state: RootState) => state.selectedProject.createdPlanId);
  const createdMeasurementPoint = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.createdMeasurementPointId);
  const activeLicense = useSelector((state: RootState) => state.selectedProject.activeLicence);
  const projectPlans = useSelector((state: RootState) => state.selectedProject.plans);
  const planMeasurementPoints = useSelector((state: RootState) => state.device.planMeasurementPoints);

  const steps = [
    {
      label: t("device_management.select_project")
    },
    {
      label: t("device_management.create_project")
    },
    {
      label: t("device_management.select_licence")
    },
    {
      label: !editForm
        ? t("device_management.add_plan")
        : t("device_management.select_plan")
    },
    {
      label: !editForm
        ? t("device_management.add_measurementpoint")
        : t("device_management.select_point")
    },
    {
      label: t("device_management.add_device")
    }
  ];

  const handleNext = () => {
    if (activeStep === 5) {
      setActiveStep(0);
      onClose();
      return;
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleAddOrEditMeasurementPoint = (name: string, isEdit: boolean) => {
    dispatch(addMeasurementPoint({ measurementName: name, planid: createdPlanId.toString() }));
    dispatch(fetchActiveLicence(editForm && selectedProjectId !== "" ? selectedProjectId : createdProjectId));
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleAddDevice = (
    deviceType: DeviceTypeObject,
    plannedDepth: string,
    deviceDepths: Array<{ typeName: string; depth: string }>
  ) => {
    if (createdMeasurementPoint === undefined && editMeasurementPoint === false) {
      alert(t("measurement_list.error_failed_to_add_device"));
      return;
    }
    if (
      deviceType.depthDefinition !== 0 &&
      (plannedDepth.includes(",") || plannedDepth.includes(".") || Number(plannedDepth) < 1)
    ) {
      setAddDeviceError(true);
      return;
    }

    if (!editMeasurementPoint) {
      dispatch(
        addDeviceWithDetails({
          measurementPointId: createdMeasurementPoint.toString(),
          deviceTypeId: deviceType.id,
          depthDefinition: deviceType.depthDefinition,
          plannedDepth: plannedDepth,
          sensorDepths: deviceDepths
        })
      );
    } else {
      dispatch(
        addDeviceWithDetails({
          measurementPointId: selectedMeasurementPointId.toString(),
          deviceTypeId: deviceType.id,
          depthDefinition: deviceType.depthDefinition,
          plannedDepth: plannedDepth,
          sensorDepths: deviceDepths
        })
      );
    }
    setActiveStep(0);
    onClose();
  };

  const handleProjectSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(fetchProjectPlans(event.target.value.toString()));
    dispatch(fetchActiveLicence(Number(event.target.value)));
    dispatch(fetchLicenceTypes(Number(event.target.value)));
    setSelectedProjectId(Number(event.target.value));
    setEditForm(true);
    setActiveStep(2);
  };

  const handlePlanSelect = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(getPlanMeasurementPoints(Number(event.target.value)));
    setSelectedPlanId(Number(event.target.value));
    setEditPlan(true);
    setActiveStep(4);
  };

  const handleMeasurementPointSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedMeasurementPointId(Number(event.target.value));
    setEditMeasurementPoint(true);
    setActiveStep(5);
  };

  return (
    <Box sx={{ padding: "0px 5px 0px 5px" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Box>
                <Stack
                  direction={"row"}
                  sx={{ mt: "5px", mb: "10px", alignSelf: "flex-end" }}
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderColor: "red !important",
                      background: "var(--colorWhite) !important"
                    }}
                    onClick={() => onClose()}
                  >
                    {t("device_management.exit_form")}
                  </Button>
                </Stack>
                {activeStep === 0 &&
                  (userProjectData !== undefined ? (
                    <>
                      <TextField
                        select
                        value={selectedProjectId}
                        fullWidth
                        SelectProps={{
                          native: true
                        }}
                        label={t("device_management.select_project_to_edit")}
                        onChange={handleProjectSelect}
                        InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                        InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                      >
                        <option key="empty_option" value=""></option>
                        {userProjectData.map(project => (
                          <option
                            style={{ fontSize: "var(--inputFontSize)" }}
                            key={"project__" + project.projectName + project.projectId}
                            value={project.projectId}
                          >
                            {project.projectName}
                          </option>
                        ))}
                      </TextField>
                      <Button
                        variant="contained"
                        sx={{ mt: "5px" }}
                        onClick={() => setActiveStep(1)}
                      >
                        {t("device_management.create_new")}
                      </Button>
                    </>
                  ) : isLoading ? (
                    <div>LOADING...</div>
                  ) : error ? (
                    <div>ERROR</div>
                  ) : (
                    <div></div>
                  ))}
                {activeStep === 1 && (
                  <AddProjectForm deviceSettings={true} open={false} handleClose={handleNext} />
                )}
                {activeStep === 2 && (
                  activeLicense !== undefined &&
                  <> 
                    <ChangeActiveLicenceDialog
                    handleSave={handleNext}
                    handleClose={handleNext}
                    deviceSettings={true}
                    activeLicence={activeLicense}
                  />
                  <Button variant="contained" sx={{mt: "5px"}} onClick={handleNext}>
                    {t("device_management.skip")}
                  </Button>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    {editForm && (
                      <TextField
                        select
                        value={selectedPlanId}
                        disabled={projectPlans.length < 1}
                        fullWidth
                        label={projectPlans.length < 1 ? t("device_management.plans_not_found") : t("device_management.select_plan_to_edit")}
                        onChange={handlePlanSelect}
                        SelectProps={{
                          native: true
                        }}
                        InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                        InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                      >
                        <option key="empty_option" value=""></option>
                        {projectPlans.map(plan => (
                          <option
                            style={{ fontSize: "var(--inputFontSize)" }}
                            key={"project__" + plan.name + plan.id}
                            value={plan.id}
                          >
                            {plan.name}
                          </option>
                        ))}
                      </TextField>
                    )}
                    <AddProjectPlanForm
                      isEditing={false}
                      maxOrdinalNumber={1}
                      currentOrdinalNro={0}
                      open={true}
                      projectId={editForm && selectedProjectId !== "" ? selectedProjectId : createdProjectId}
                      plan={undefined}
                      projectPlans={!editForm ? [] : projectPlans.length > 0 ? projectPlans : []}
                      handleClose={handleNext}
                      handleSave={handleNext}
                      deviceSettings={true}
                    />
                  </>
                )}
                {activeStep === 4 && (
                  <>
                    {editPlan && (
                      <TextField
                        select
                        value={selectedMeasurementPointId}
                        disabled={planMeasurementPoints.length < 1}
                        fullWidth
                        SelectProps={{
                          native: true
                        }}
                        label={
                          planMeasurementPoints.length < 1
                            ? t("device_management.points_not_found")
                            : t("device_management.select_point_to_edit")
                        }
                        onChange={handleMeasurementPointSelect}
                        InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                        InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                      >
                        <option key="empty_option" value=""></option>
                        {planMeasurementPoints.map(mp => (
                          <option
                            style={{ fontSize: "var(--inputFontSize)" }}
                            key={"project__" + mp.name + mp.id}
                            value={mp.id}
                          >
                            {mp.name}
                          </option>
                        ))}
                      </TextField>
                    )}
                    <AddOrEditMeasurementDialog
                      oldValue={undefined}
                      projectId={editForm && selectedProjectId !== "" ? selectedProjectId : createdProjectId}
                      handleSave={handleAddOrEditMeasurementPoint}
                      handleClose={handleNext}
                      deviceSettings={true}
                    />
                  </>
                )}
                {activeStep === 5 && (
                  <AddDeviceToMeasurementDialog
                    handleSave={handleAddDevice}
                    handleClose={handleNext}
                    addDeviceError={addDeviceError}
                    deviceSettings={true}
                  />
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default CreateProjectForm;
