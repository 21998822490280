import React from "react";
import JSZip from "jszip";
import { useTranslation } from "react-i18next";
import { MeasurementHistoryObject, ReportObject, SensorUnitObj } from "../../types";
import { GetDateString } from "../utils/commonFunctions";
import { Button, CircularProgress } from "@mui/material";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";

type ReportCSVExportProps = {
  data: ReportObject | undefined;
};

const ReportCSVExport = ({ data }: ReportCSVExportProps) => {
  const { t } = useTranslation();

  const loading = useSelector((state: RootState) => state.report.loading);

  const userTimeZoneOffsetMinutes = new Date().getTimezoneOffset();
  const hours = Math.abs(Math.floor(userTimeZoneOffsetMinutes / 60));
  const minutes = Math.abs(userTimeZoneOffsetMinutes % 60);
  const sign = userTimeZoneOffsetMinutes >= 0 ? '-' : '+';
  
  const formattedTimeZone = `UTC ${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  const createHeader = (sensorUnits: SensorUnitObj[]): string => {
    return (
      `Time (${formattedTimeZone}),` +
      sensorUnits?.map(sensorUnit => {
        return `${sensorUnit.ambient ? "Ambient " : ""}${
          sensorUnit.typeName?.includes("Temperature")
            ? "T"
            : sensorUnit.unit === "%MC"
            ? `MC ${
                sensorUnit.typeName
                  ? sensorUnit.typeName.charAt(sensorUnit.typeName.length - 1)
                  : "-"
              }`
            : sensorUnit.unit === "%RH"
            ? "RH"
            : sensorUnit.unit
        } ${!sensorUnit.ambient ? "(" + sensorUnit.depth + "mm)" : ""}`;
      })
    );
  };

  const createRows = (
    sensorUnits: SensorUnitObj[],
    measurementHistory: MeasurementHistoryObject
  ) => {
    const csvRows = [];
    let sensorUnitIndex = 0;
    let rowIndex = 0;
    let csvRow = [];
    //assumes that in every measurement all sensors are given a value so that all the data arrays are same length
    //example (RelativeHumidity.data.length 275, RelativeTemperature.data.length 275)
    while (sensorUnitIndex < sensorUnits.length && measurementHistory) {
      const sensorType = sensorUnits[sensorUnitIndex]?.typeName;
      const measurement = measurementHistory[sensorType]?.data[rowIndex];

      if (sensorUnitIndex === 0) {
        //csvRow.push(GetDateString(measurement.timestamp as unknown as string, "DD-MM-YYYY HH:mm"));
        csvRow.push(GetDateString(measurement.timestampNumber as number, "DD-MM-YYYY HH:mm"));
      }
      if (measurement?.value !== undefined) {
      csvRow.push(measurement?.value);
      sensorUnitIndex++;
      } else {
        sensorUnitIndex++;
      }

      if (sensorUnitIndex === sensorUnits.length) {
        rowIndex++;
        csvRows.push(csvRow);
        csvRow = [];
        const totalRows = measurementHistory[sensorType]?.data?.[0]
          ? measurementHistory[sensorType].data.length
          : 0;

        if (rowIndex < totalRows) {
          sensorUnitIndex = 0;
        }
      }
    }
    return csvRows;
  };

  const createContent = (header: string, rows: (string | number)[][]): string => {
    return (
      header +
      "\r\n" +
      rows
        .reverse()
        .map(row => row.join(","))
        .join("\r\n")
    );
  };

  const getSortedSensorUnits = (sensorUnits: SensorUnitObj[]) => {
    const data = { sensorUnits };

    const getOrderNumber = (unit: string, ambient: boolean): number => {
      return unit === "%MC" && !ambient
        ? 5
        : unit === "%RH" && !ambient
        ? 4
        : unit === "°C" && !ambient
        ? 3
        : unit === "%RH" && ambient
        ? 2
        : unit === "°C" && ambient
        ? 1
        : 0;
    };
    if (sensorUnits) {
      data.sensorUnits = [...sensorUnits]?.sort((a, b) => {
        return getOrderNumber(b.unit, b.ambient) - getOrderNumber(a.unit, a.ambient);
      });
    }
    return data.sensorUnits;
  };

  const validateSameLengthArrays = (arrays: any) => {
    const firstArrayLength = arrays[0]?.length;
    return arrays?.find((array: any) => array?.length !== firstArrayLength) ? false : true;
  };

  const convertData = (data: ReportObject | undefined) => {
    const csvFoldersData = [];
    const existingFileNames: string[] = [];

    const plansInfo = data?.plans ? data?.plans : [];
    const plansData = data?.planData ? data?.planData : {};

    for (let planIndex = 0; planIndex < plansInfo.length; planIndex++) {
      const csvFilesData = [];
      const planData = plansData[plansInfo[planIndex].id];
      const measurementPoints = planData.measurementPoints;

      for (let mpIndex = 0; mpIndex < measurementPoints.length; mpIndex++) {
        const measurementPointDevices = measurementPoints[mpIndex]?.devices;
        const historyDevices = planData.historyData?.[measurementPoints[mpIndex].id]?.measurementPointDevices;

        for (
          let mpDeviceIndex = 0;
          mpDeviceIndex < measurementPointDevices.length;
          mpDeviceIndex++
        ) {
          const mpDevice = measurementPointDevices[mpDeviceIndex];
          const historyDevice = historyDevices?.[mpDevice.id];
          const deviceType = mpDevice?.deviceType ? mpDevice.deviceType : "no_device_type";
          const serial = mpDevice?.serial ? mpDevice.serial : "no_serial";
          const measurementPointName = measurementPoints[mpIndex]?.name
            ? measurementPoints[mpIndex].name
            : "no_measurementpoint_name";

          let csvFileName = `${measurementPointName} - ${deviceType} ${serial}`;
          let duplicates = 0;
          while (existingFileNames.includes(csvFileName))
          {
            csvFileName = `${measurementPointName} - ${deviceType} ${serial}_${++duplicates}`;
          }
          existingFileNames.push(csvFileName);

          const sensorUnits = getSortedSensorUnits(
            mpDevice?.sensorUnits?.filter(sensorUnit => {
              return sensorUnit.typeName !== "NetworkQuality" && sensorUnit.typeName !== "BatteryStatus";
            })
          );
          const measurementHistory = historyDevice?.measurementHistory;

          const isSameLengthArrays = validateSameLengthArrays(
            Object.values(measurementHistory ? measurementHistory : {})
              .filter(measurementHistory => {
                return measurementHistory.dataUnit !== "NQ" && measurementHistory.dataUnit !== "BS";
              })
              .map(measurementHistory => {
                return measurementHistory.data;
              })
          );

          if (isSameLengthArrays) {
            const csvHeader = createHeader(sensorUnits);
            const csvRows = createRows(sensorUnits, measurementHistory);
            const content = createContent(csvHeader, csvRows);

            csvFilesData.push({ fileName: csvFileName, content: content });
          } else
            csvFilesData.push({
              fileName: csvFileName,
              content: t("report.csvErrorText")
            });
        }
      }
      csvFoldersData.push({ folderName: plansInfo[planIndex].name, content: csvFilesData });
    }
    return csvFoldersData;
  };

  type convertedDataType = {
    folderName: string;
    content: {
      fileName: string;
      content: string;
    }[];
  };

  const downloadZip = (
    data: ReportObject | undefined,
    convertData: (data: ReportObject | undefined) => convertedDataType[]
  ) => {
    const csvFoldersData = convertData(data);
    const zip = new JSZip();

    csvFoldersData.forEach(folderData => {
      folderData.content.forEach(fileData => {
        zip.file(`${folderData.folderName}/${fileData.fileName}.csv`, fileData.content);
      });
    });

    zip.generateAsync({ type: "base64" }).then(content => {
      var link = document.createElement("a");
      link.href = "data:application/zip;base64," + content;
      link.download = `${data?.project?.name ? data.project.name : "no_project_name"}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Button
      disabled={loading ? true : false}
      variant="contained"
      size="small"
      sx={{
        height: "40px",
        fontFamily: "Fellix-Regular",
        mt: "10px",
        fontWeight: "bold",
        background: "lightgray", 
        color: "var(--colorBlack)",
        "&: hover": {
          background: "var(--colorOrange)"
        }
      }}
      onClick={() => {
        downloadZip(data, convertData);
      }}
    >
      {t("report.downloadCSV")}
      {loading && <CircularProgress size={30} sx={{ml: "20px", color: "var(--colorOrange)"}}/>}
    </Button>
  );
};

export default ReportCSVExport;
