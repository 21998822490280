import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import DeviceSettingsUnlinkTableRow from "./DeviceSettingsUnlinkTableRow";
import { DeviceSettingsProjectObject } from "../../../../../types";
import { t } from "i18next";

interface TableProps {
    projects: any;
    handleLinkDialogClose: any;
};

const DeviceSettingsUnlinkTable = (props: TableProps) => {
  
  const { projects, handleLinkDialogClose } = props;

  return (
    <TableContainer component={Paper} sx={{ margin: "10px", width: "90%", borderRadius: "12px 12px 0px 0px", overflowX: "hidden" }}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableRow>
            <TableCell>{t("device_management.device_projects")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project: DeviceSettingsProjectObject) => (
            <DeviceSettingsUnlinkTableRow
              key={project.id}
              project={project}
              handleLinkDialogClose={handleLinkDialogClose}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeviceSettingsUnlinkTable;

