import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Button, TextField } from "@mui/material";

import styles from "./ChangeActiveLicenceDialog.module.css";
import { LicenceObject } from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { changeLicence } from "../../../projectSlice";
import { RootState } from "../../../../../app/store";

interface FormInterface {
  handleSave: () => void;
  handleClose: () => void;
  deviceSettings?: boolean;
  activeLicence: LicenceObject | undefined;
}

export default function ChangeActiveLicenceDialog(props: FormInterface) {
  // COMMON

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // **********************************************************************************************************************
  // FORM DATA

  const licenceTypes = useSelector((state: RootState) => state.selectedProject.licenceTypes);

  const [activeLicenceTypeId, setactiveLicenceTypeId] = useState<number | "">("");
  const [activeLicence, setActiveLicence] = useState<LicenceObject | undefined>(undefined);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  //ERROR

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.activeLicence === undefined) {
      setErrorMessage(
        t("projectview_page.settings_page.licence.change_dialog.error_active_licence_not_valid")
      );
      return;
    }
    setactiveLicenceTypeId(props.activeLicence.licenceTypeId);
    setActiveLicence(props.activeLicence);
  }, [props.activeLicence, t]);

  const handleSave = () => {
    setSaveButtonDisabled(true);
    const selectedLicence = licenceTypes.find(type => type.id === activeLicenceTypeId);

    if (selectedLicence === undefined) {
      setErrorMessage(
        t("projectview_page.settings_page.licence.change_dialog.error_unknown_licence_type")
      );
      return;
    }else if (selectedLicence.id === props.activeLicence?.licenceTypeId) {
      setErrorMessage(t("projectview_page.settings_page.licence.change_dialog.error_licence_already_exists"));
      return;
    } else if (activeLicence === undefined) {
      setErrorMessage(
        t("projectview_page.settings_page.licence.change_dialog.error_no_actice_licence")
      );
      return;
    } else if (selectedLicence.maxLinkedDevices < activeLicence.maxLinkedDevices) {
      setErrorMessage(
        t("projectview_page.settings_page.licence.change_dialog.error_cannot_to_smaller")
      );
      return;
    }
    const licenceObj = {
      projectId: activeLicence.projectId,
      projectLicenceId: activeLicence.id,
      licenceTypeId: Number(activeLicenceTypeId)
    };

    dispatch(changeLicence(licenceObj));
    props.handleSave();
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleLicenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSaveButtonDisabled(false);
    setErrorMessage("");
    setactiveLicenceTypeId(Number(event.target.value));
  };

  return (
    !props.deviceSettings ?
    <Dialog open={true} onClose={handleClose}>
      <div className={styles["changelicence-form-container"]}>
        <div className={styles["changelicence-form-header"]}>
          {t("projectview_page.settings_page.licence.change_dialog.select_licence_type")}
        </div>
        {/**************************************************************************** */}

        <div>
          <TextField
            select
            value={activeLicenceTypeId}
            fullWidth
            //label={t("projectview_page.settings_page.licence.change_dialog.licence_type")}
            onChange={handleLicenceChange}
            SelectProps={{
              native: true
            }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          >
            <option key="empty_option" value=""></option>
            {licenceTypes.map(type => (
              <option
                style={{ fontSize: "var(--inputFontSize)" }}
                key={"licenceType__" + type.name + type.id}
                value={type.id}
              >
                {type.name}
              </option>
            ))}
          </TextField>
        </div>
        {/**************************************************************************** */}

        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" disabled={saveButtonDisabled} onClick={() => handleSave()}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={() => handleClose()}>
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
    :
    <div className={styles["changelicence-form-container-device-settings"]}>
        <div className={styles["changelicence-form-header"]}>
          {t("projectview_page.settings_page.licence.change_dialog.select_licence_type")}
        </div>
        {/**************************************************************************** */}

        <div>
          <TextField
            select
            value={activeLicenceTypeId}
            fullWidth
            //label={t("projectview_page.settings_page.licence.change_dialog.licence_type")}
            onChange={handleLicenceChange}
            SelectProps={{
              native: true
            }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          >
            <option key="empty_option" value=""></option>
            {licenceTypes.map(type => (
              <option
                style={{ fontSize: "var(--inputFontSize)" }}
                key={"licenceType__" + type.name + type.id}
                value={type.id}
              >
                {type.name}
              </option>
            ))}
          </TextField>
        </div>
        {/**************************************************************************** */}

        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" sx={{mt: "5px"}} disabled={saveButtonDisabled} onClick={() => handleSave()}>
          {t("common.save")}
        </Button>
      </div>
  );
}
