import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

//COMMON ATTRIBUTES
import { commonAttributes } from "./../common/commonAttributes";

//TYPES
import { ProjectObject, PlanObject, LicenceObject, LicenceTypeObject } from "../../types";

export type selectedProjectType = {
  project: ProjectObject;
  plans: Array<PlanObject>;
  userProjectRole: string | null;
  activeLicence: LicenceObject | undefined;
  usedLicenceDevices: number;
  createdPlanId: number;
  licenceTypes: Array<LicenceTypeObject>;
};

export const projectInitialState: selectedProjectType = {
  project: {
    id: -1,
    createdAt: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    ownerStatus: -1,
    status: -1,
    ownerCompanyId: -1,
    ownerCompanyName: "",
    newOwnerCompanyId: -1,
    newOwnerCompanyName: "",
    billingReference: ""
  },
  plans: Array<PlanObject>(),
  userProjectRole: null,
  activeLicence: undefined,
  usedLicenceDevices: 0,
  createdPlanId: -1,
  licenceTypes: []
};

export const planInitialState: PlanObject = {
  id: -1,
  name: "",
  filePath: "",
  projectId: -1,
  projectName: ""
};

export const getProjectUserRights = createAsyncThunk(
  "SelectedProject/getProjectUserRights",
  async (projectId: string | undefined) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/Users/Projects`);

    const currentProjectRole = response.data?.find(
      (x: any) => x.projectId.toString() === projectId
    )?.projectRoleName;

    return currentProjectRole || null;
  }
);

export const fetchSelectedProject = createAsyncThunk(
  "SelectedProject/fetchProjectInformation",
  async (projectId: string | undefined) => {
    if (projectId === undefined) return { data: undefined };

    const response = await axios.get(`${commonAttributes.apiUrl}/Users/Projects`);

    const currentProjectRole = response.data?.find(
      (x: any) => x.projectId.toString() === projectId
    )?.projectRoleName;

    const projectdata = await axios.get(`${commonAttributes.apiUrl}/Projects/${projectId}`);

    const plansData = await axios.get(`${commonAttributes.apiUrl}/Projects/${projectId}/plans`);

    let usedLicenceDevices = 0;
    let licenceData = undefined;

    // GET LICENCE
    if (currentProjectRole === "Admin" || currentProjectRole === "Planner") {
      // GET DEVICE
      licenceData = await axios.get(
        `${commonAttributes.apiUrl}/Projects/${projectId}/ActiveLicence`
      );
      licenceData = licenceData.data;

      // GET USED DEVICES
      let activeLinkedDeviceResponse = await axios.get(
        `${commonAttributes.apiUrl}/Projects/${projectId}/ActiveLinkedDevices`
      );

      usedLicenceDevices = activeLinkedDeviceResponse.data.length;
    }

    return {
      currentProjectRole: currentProjectRole,
      projectData: projectdata.data,
      plansData: plansData.data,
      licenceData: licenceData,
      usedLicenceDevices: usedLicenceDevices
    };
  }
);

export const fetchActiveLicence = createAsyncThunk(
  "SelectedProject/fetchActiveLicence",
  async (projectId: number) => {
    const licenceData = await axios.get(
      `${commonAttributes.apiUrl}/Projects/${projectId}/ActiveLicence`
    );

    return { licenceData: licenceData.data };
  }
);

export const fetchLinkedDevicesCount = createAsyncThunk(
  "SelectedProject/fetchLinkedDevicesCount",
  async (projectId: number) => {
    const activeLinkedDeviceResponse = await axios.get(
      `${commonAttributes.apiUrl}/Projects/${projectId}/ActiveLinkedDevices`
    );

    return { count: activeLinkedDeviceResponse.data.length };
  }
);

export const fetchProjectPlans = createAsyncThunk(
  "SelectedProject/fetchProjectPlans",
  async (projectId: string) => {
    const plansData = await axios.get(`${commonAttributes.apiUrl}/Projects/${projectId}/plans`);

    return {
      plansData: plansData.data
    };
  }
);

export const addProjectPlan = createAsyncThunk(
  "SelectedProject/addProjectPlan",
  async (newPlan: { projectId: number; planName: string; imageForm: any, fileType: any }) => {
    let planDTO = { name: newPlan.planName, filePath: "new.file", projectId: newPlan.projectId, fileType: newPlan.fileType };

    const data = await axios.post(`${commonAttributes.apiUrl}/Plans/Add`, planDTO);

    if (data.status === 201) {
      const projectId = data.data.id;
      await axios.post(
        `${commonAttributes.apiUrl}/Plans/${projectId}/Image/Add`,
        newPlan.imageForm
      );
    }

    return { planData: data.data };
  }
);

export const updateProjectPlan = createAsyncThunk(
  "SelectedProject/updateProjectPlan",
  async (updatedPlan: { plan: PlanObject; newName: string; imageForm: any }) => {
    let planDTO = {
      id: updatedPlan.plan.id,
      name: updatedPlan.newName,
      filePath: updatedPlan.plan.filePath,
      projectId: updatedPlan.plan.projectId
    };

    const data = await axios.post(
      `${commonAttributes.apiUrl}/Plans/${updatedPlan.plan.id}`,
      planDTO
    );

    /* if (data.status === 201 && updatedPlan.imageForm !== (undefined || "")) {
      const projectId = data.data.id;
      await axios.post(
        `${commonAttributes.apiUrl}/Plans/${projectId}/Image/Add`,
        updatedPlan.imageForm
      );
    } */

    return { plandata: data.data };
  }
);

export const addPlanImage = createAsyncThunk(
  "SelectedProject/addPlanImage",
  async (imageObj: { planId: number; imageForm: any }) => {
    const data = await axios.post(
      `${commonAttributes.apiUrl}/Plans/${imageObj.planId}/Image/Add`,
      imageObj.imageForm
    );

    return { imageData: data };
  }
);

export const deleteProjectPlan = createAsyncThunk(
  "SelectedProject/deleteProjectPlan",
  async (planId: number) => {
    await axios.delete(`${commonAttributes.apiUrl}/Plans/${planId}/DeleteWithReferences`);

    return { planId: planId };
  }
);

export const changeLicence = createAsyncThunk(
  "SelectedProject/changeLicence",
  async (licenceObj: {projectId: number, projectLicenceId: number, licenceTypeId: number}) => {
    const licenceData = {projectLicenceId: licenceObj.projectLicenceId, licenceTypeId: licenceObj.licenceTypeId}; 
    const data = await axios.post(`${commonAttributes.apiUrl}/Projects/${licenceObj.projectId}/ChangeLicence`, licenceData);

    return { data: data.data};
  }
);

export const fetchLicenceTypes = createAsyncThunk(
  "SelectedProject/fetchLicenceTypes",
  async (projectId: number) => {
    const data = await axios.get(`${commonAttributes.apiUrl}/Projects/${projectId}/LicenceTypes`);

    return { data: data.data};
  }
);

export const selectProjectSlice = createSlice({
  name: "SelectedProject",
  initialState: projectInitialState,
  reducers: {
    clearSelectedProject: (state, action: PayloadAction) => {
      //state = initialState;
      //console.log("CLEAR SELECTED PROJECT");

      state.project.id = -1;
      state.project.createdAt = "";
      state.project.name = "";
      state.project.address1 = "";
      state.project.address2 = "";
      state.project.city = "";
      state.project.postalCode = "";
      state.project.ownerStatus = -1;
      state.project.status = -1;
      state.project.ownerCompanyId = -1;
      state.project.ownerCompanyName = "";
      state.project.newOwnerCompanyId = -1;
      state.project.newOwnerCompanyName = "";

      state.plans = Array<PlanObject>();
    },
    deleteSelectedProject: (state, action: PayloadAction<{ planId: number }>) => {
      state.plans = [...state.plans.filter(x => x.id === action.payload.planId)];
    },
    updateUserProjectRole: (state, action: PayloadAction<string>) => {
      state.userProjectRole = action.payload;
    }
  },
  extraReducers: {
    [getProjectUserRights.pending.type]: (state, action) => {},
    [getProjectUserRights.fulfilled.type]: (state, action) => {
      state.userProjectRole = action.payload;
    },
    [getProjectUserRights.rejected.type]: (state, action) => {
      console.log("FAILED to get user rights");
      state.userProjectRole = null;
    },
    /* ----------------------------------------------------------------------------------------- */
    [fetchSelectedProject.pending.type]: (state, action) => {},
    [fetchSelectedProject.fulfilled.type]: (state, action) => {
      // PROJECT ROLE
      state.userProjectRole = action.payload.currentProjectRole;

      // PROJECT DATA
      let projectData = action.payload.projectData;

      state.project.id = projectData.id;
      state.project.createdAt = projectData.createdAt;
      state.project.name = projectData.name;
      state.project.address1 = projectData.address1;
      state.project.address2 = projectData.address2;
      state.project.city = projectData.city;
      state.project.postalCode = projectData.postalCode;
      state.project.ownerStatus = projectData.ownerStatus;
      state.project.status = projectData.status;
      state.project.ownerCompanyId = projectData.ownerCompanyId;
      state.project.ownerCompanyName = projectData.ownerCompanyName;
      state.project.newOwnerCompanyId = projectData.newOwnerCompanyId;
      state.project.newOwnerCompanyName = projectData.newOwnerCompanyName;

      // PLANS
      let newPlans = Array<PlanObject>();
      let plansData = action.payload.plansData;

      plansData.forEach((planData: any) => {
        newPlans.push({
          id: planData.id,
          name: planData.name,
          filePath: planData.filePath,
          projectId: planData.projectId,
          projectName: planData.projectName
        });
      });

      state.plans = newPlans;

      // LICENCE

      const licenceData = action.payload.licenceData;

      state.activeLicence = licenceData;

      // USED DEVICES

      state.usedLicenceDevices = Number(action.payload.usedLicenceDevices);
    },
    [fetchSelectedProject.rejected.type]: (state, action) => {
      console.log("FAILED to get fetchSelectedProject");
    },
    /* ----------------------------------------------------------------------------------------- */
    [fetchActiveLicence.pending.type]: (state, action) => {},
    [fetchActiveLicence.fulfilled.type]: (state, action) => {
      // LICENCE
      const licenceData = action.payload.licenceData;
      state.activeLicence = licenceData;
    },
    [fetchActiveLicence.rejected.type]: (state, action) => {
      console.log("FAILED to get fetchActiveLicence");
    },
    /* ----------------------------------------------------------------------------------------- */
    [fetchLinkedDevicesCount.pending.type]: (state, action) => {},
    [fetchLinkedDevicesCount.fulfilled.type]: (state, action) => {
      // USED DEVICES

      state.usedLicenceDevices = Number(action.payload.count);
    },
    [fetchLinkedDevicesCount.rejected.type]: (state, action) => {
      console.log("FAILED to get fetchActiveLicence");
    },
    /* ----------------------------------------------------------------------------------------- */
    [fetchProjectPlans.pending.type]: (state, action) => {},
    [fetchProjectPlans.fulfilled.type]: (state, action) => {
      let newPlans = Array<PlanObject>();
      let plansData = action.payload.plansData;

      //Object { id: 1, name: "Pohja 1", filePath: "/websrc/project_1_plan_1.png", projectId: 1, projectName: "Uusi alku" }
      plansData.forEach((planData: any) => {
        newPlans.push({
          id: planData.id,
          name: planData.name,
          filePath: planData.filePath,
          projectId: planData.projectId,
          projectName: planData.projectName
        });
      });

      state.plans = newPlans;
    },
    [fetchProjectPlans.rejected.type]: (state, action) => {
      console.log("FAILED to get fetchProjectPlans");
    },
    /* ----------------------------------------------------------------------------------------- */
    [addProjectPlan.pending.type]: (state, action) => {},
    [addProjectPlan.fulfilled.type]: (state, action) => {
      let newPlan: PlanObject = { id: -1, name: "", filePath: "", projectId: -1, projectName: "" };

      const fileType = 
        action.meta.arg.fileType === "gif" 
        ? "gif"
        : action.meta.arg.fileType === "jpg"
        ? "jpg"
        : action.meta.arg.fileType === "jpeg"
        ? "jpeg"
        : "png"
      let dataObj = action.payload.planData;
      newPlan.filePath = `/websrc/${dataObj.projectId}/project_${dataObj.projectId}_plan_${dataObj.id}.${fileType}`;
      newPlan.id = Number(dataObj.id);
      newPlan.name = dataObj.name;
      newPlan.projectId = dataObj.projectId;
      newPlan.projectName = dataObj.planName;

      state.plans.push(newPlan);
      state.createdPlanId = newPlan.id;
    },
    [addProjectPlan.rejected.type]: (state, action) => {
      console.log("FAILED to get addProjectPlan");
    },
    /* ----------------------------------------------------------------------------------------- */
    [updateProjectPlan.pending.type]: (state, action) => {},
    [updateProjectPlan.fulfilled.type]: (state, action) => {
      const planData = action.payload.plandata;

      // GET CURRENT INDEX
      let index = state.plans.findIndex(x => x.id === planData.id);

      state.plans[index] = planData;
    },
    [updateProjectPlan.rejected.type]: (state, action) => {
      console.log("FAILED to updateProjectPlan");
    },
    /* ----------------------------------------------------------------------------------------- */
    [addPlanImage.pending.type]: (state, action) => {},
    [addPlanImage.fulfilled.type]: (state, action) => {
      console.log("ADDING fulfilled");
    },
    [addPlanImage.rejected.type]: (state, action) => {
      console.log("FAILED to get addPlanImage");
    },
    /* ----------------------------------------------------------------------------------------- */
    [deleteProjectPlan.pending.type]: (state, action) => {},
    [deleteProjectPlan.fulfilled.type]: (state, action) => {
      let planId = Number(action.payload.planId);

      state.plans = [...state.plans.filter(x => x.id !== planId)];
    },
    [deleteProjectPlan.rejected.type]: (state, action) => {
      console.log("FAILED to get deleteProjectPlan");
    },
    [changeLicence.pending.type]: (state, action) => {},
    [changeLicence.fulfilled.type]: (state, action) => {
      const licenceDTO = action.payload.data;

      let newLicence: LicenceObject = licenceDTO;

      const type = state.licenceTypes.find(type => type.id === licenceDTO.licenceTypeId);

      if (type === undefined) {
        return;
      }
      newLicence.licenceTypeName = type.name;
      newLicence.maxLinkedDevices = type.maxLinkedDevices;
      newLicence.companyIsConfirmed = true;

      state.activeLicence = newLicence;
    },
    [changeLicence.rejected.type]: (state, action) => {
      console.log("Failed to change licence");
    },
    [fetchLicenceTypes.pending.type]: (state, action) => {},
    [fetchLicenceTypes.fulfilled.type]: (state, action) => {
      state.licenceTypes = action.payload.data;
    },
    [fetchLicenceTypes.rejected.type]: (state, action) => {
      console.log("Failed to get licence types");
    }
  }
});

export const { clearSelectedProject, deleteSelectedProject, updateUserProjectRole } = selectProjectSlice.actions;

export default selectProjectSlice.reducer;
