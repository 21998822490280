import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, Button, TextField, InputAdornment } from "@mui/material";

import styles from "./AddDeviceToMeasurementDialog.module.css";

import { useGetDeviceTypesQuery, DeviceTypeObject } from "./../../../../devices/deviceTypeAPI";
import { RootState } from "../../../../../app/store";
import { useSelector } from "react-redux";

interface FormInterface {
  handleSave: (
    deviceType: DeviceTypeObject,
    plannedDepth: string,
    deviceDepths: Array<{ typeName: string; depth: string }>
  ) => void;
  handleClose: () => void;
	addDeviceError: boolean;
  deviceSettings?: boolean;
}

type depthType = {
  typeName: string;
  depth: string;
};

export default function AddDeviceToMeasurementDialog(props: FormInterface) {
  const { t } = useTranslation();

  // **********************************************************************************************************************
  // webAPI QUERY

  const { data, error, isLoading } = useGetDeviceTypesQuery();

  const [queryState, setqueryState] = useState("");

  const deviceTypeId = useSelector((state: RootState) => state.device.device.deviceTypeId);

  useEffect(() => {
    if (isLoading) {
      setqueryState(t("measurement_list.add_new_device.Loading"));
    } else if (error) {
      setqueryState(t("measurement_list.add_new_device.queryError"));
    } else {
      setqueryState("");
      if (data !== null || data !== undefined) {
        setdeviceTypes(data);
      }
    }
  }, [isLoading, error, data, t]);

  // **********************************************************************************************************************

  //ERROR
  const [errorMessage, setErrorMessage] = useState("");

  const [deviceType, setDeviceType] = useState("");

  const [selectedDevice, setselectedDevice] = useState<DeviceTypeObject | undefined>(undefined);

  const [deviceDepths, setdeviceDepths] = useState<Array<depthType>>(Array<depthType>());

  const handleDeviceTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeviceType(event.target.value);

    setselectedDevice(deviceTypes?.find(device => device.name === event.target.value));
  };
  //SET SELECT DEVICE DEPTHS
  useEffect(() => {
    let newDepths: Array<depthType> = new Array<depthType>();
    selectedDevice?.sensorTypes.forEach(sensor => {
      let newDepthType: depthType = { typeName: sensor.name, depth: "" };

      if (
        selectedDevice?.depthDefinition === 2 &&
        (newDepthType.typeName.toLowerCase().indexOf("ambient") !== -1 ||
          newDepthType.typeName.toLowerCase().indexOf("network") !== -1 ||
          newDepthType.typeName.toLowerCase().indexOf("battery") !== -1)
      ) {
        newDepthType.depth = "123";
      }

      newDepths.push(newDepthType);
    });

    setdeviceDepths(newDepths);
  }, [deviceType, selectedDevice]);

  const handleDepthSelection = (
    event: ChangeEvent<HTMLInputElement>,
    typeName: string | undefined
  ) => {
    let arrDepths = deviceDepths;

    if (typeName !== undefined) {
      let newDepthIndex = deviceDepths.findIndex(dDepth => dDepth.typeName === typeName);

      if (newDepthIndex === -1) {
        setErrorMessage(t("measurement_list.add_new_device.error_depth_property"));
        return; //FALSE DEPTH PROPERTY
      } else {
        arrDepths[newDepthIndex].depth = event.target.value;
      }
    } else {
      arrDepths.forEach(element => {
        element.depth = event.target.value;
      });
    }

    setdeviceDepths(arrDepths);
  };

  const isValid = deviceType !== "";

  // **********************************************************************************************************************

  const handleSave = () => {
    if (isValid === false) {
      setErrorMessage(t("measurement_list.add_new_device.device_type_notvalid"));

      return;
    } else if (
      selectedDevice?.depthDefinition !== 0 &&
      deviceDepths.findIndex(obj => obj.depth === "") !== -1
    ) {
      setErrorMessage(t("measurement_list.add_new_device.device_depths_notvalid"));

      return;
    }

    let savingItem = selectedDevice;

    if (savingItem === undefined) {
      setErrorMessage("Error 52055674");
      return;
    }

    if (selectedDevice !== undefined)
      props.handleSave(savingItem, deviceDepths[0].depth, deviceDepths);
  };

  const handleClose = () => {
    props.handleClose();
  };
  // **********************************************************************************************************************

  const [deviceTypes, setdeviceTypes] = useState<Array<DeviceTypeObject> | undefined>(
    Array<DeviceTypeObject>()
  );

  useEffect(() => {
    if (props.deviceSettings && data) {
       const type = data.filter(type => Number(type.id) === deviceTypeId);
       setselectedDevice(type[0]);
       setDeviceType(type[0].name);
    }
  }, [deviceTypeId, data, props.deviceSettings]);

  // **********************************************************************************************************************
  const DepthElements = () => {
    if (selectedDevice?.depthDefinition === 0)
      //NO DEPTHS
      return <React.Fragment />;
    else if (selectedDevice?.depthDefinition === 1) {
      // DEVICE
      return (
        <TextField
          key={"devicedepth_" + selectedDevice?.id}
          required
          fullWidth
          size="small"
          type="number"
          sx={props.deviceSettings ? {mt: "10px"} : {}}
          inputProps={{min: "1", max: "1000"}}
					error={props.addDeviceError}
					helperText={t("measurement_list.add_new_device.contains_decimals")}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleDepthSelection(event, undefined)
          }
          label={t("measurement_list.add_new_device.depth")}
          InputProps={{
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            style: { fontSize: "var(--inputFontSize)" }
          }}
          InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        ></TextField>
      );
    } else if (selectedDevice?.depthDefinition === 2) {
      // SENSORS
      return (
        <React.Fragment>
          {selectedDevice.sensorTypes.map(sensorType =>
            (sensorType.ambient === false &&
              sensorType.name !== "NetworkQuality" &&
              sensorType.name !== "BatteryStatus") ? (
              <TextField
                key={"sensordepth_" + sensorType.name}
                required
                fullWidth
                size="small"
                type="number"
                sx={props.deviceSettings ? {mt: "10px"} : {}}
                inputProps={{min: "1", max: "1000"}}
								error={props.addDeviceError}
								helperText={t("measurement_list.add_new_device.contains_decimals")}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleDepthSelection(event, sensorType.name)
                }
                label={sensorType.name + " " + t("measurement_list.add_new_device.depth")}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                  style: { fontSize: "var(--inputFontSize)" }
                }}
                InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              ></TextField>
            ) : (
              <div
                key={"selectionEmptycell_" + sensorType.id + sensorType.name}
                style={{ display: "none" }}
              ></div>
            )
          )}
        </React.Fragment>
      );
    } else return <React.Fragment />;
  };
  // **********************************************************************************************************************

  return !props.deviceSettings ? (
    <Dialog open={true} onClose={props.handleClose}>
      <div className={styles["add-device-form-container"]}>
        <div className={styles["add-device-form-header"]}>
          {t("measurement_list.add_new_device.add_new_device")}
        </div>
        {/* *************************************************************************** */}
        {queryState !== "" ? (
          <div>{queryState}</div>
        ) : (
          <>
            {/* TYPE SELECTION */}
            <TextField
              required
              fullWidth
              size="small"
              label={t("measurement_list.add_new_device.type")}
              select
              value={deviceType}
              SelectProps={{
                native: true
              }}
              onChange={handleDeviceTypeChange}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            >
              <option key="empty_option" value=""></option>
              {deviceTypes?.map(deviceType => {
                return (
                  <option
                    key={"devicetypes_" + deviceType.id}
                    value={deviceType.name}
                    style={{ fontFamily: "Fellix-Regular", fontSize: "var(--inputFontSize)" }}
                  >
                    {deviceType.name}
                  </option>
                );
              })}
            </TextField>
            {/* DEPTH SELECTIONS */}
            <DepthElements />
          </>
        )}
        {/* *************************************************************************** */}
        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" onClick={() => handleSave()}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={() => handleClose()}>
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
  ) : (
    <>
      <div className={styles["add-device-form-header"]}>
        {t("measurement_list.add_new_device.add_new_device")}
      </div>
      {/* *************************************************************************** */}
      {queryState !== "" ? (
        <div>{queryState}</div>
      ) : (
        <>
          {/* TYPE SELECTION */}
          <TextField
            required
            fullWidth
            size="small"
            label={t("measurement_list.add_new_device.type")}
            select
            value={deviceType}
            SelectProps={{
              native: true
            }}
            onChange={handleDeviceTypeChange}
            sx={{mt: "10px"}}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          >
                <option
                  key={"devicetypes_" + deviceTypeId}
                  value={deviceType}
                  style={{ fontFamily: "Fellix-Regular", fontSize: "var(--inputFontSize)" }}
                >
                  {deviceType}
                </option>
          </TextField>
          {/* DEPTH SELECTIONS */}
          <DepthElements />
        </>
      )}
      {/* *************************************************************************** */}
      <Button
        variant="contained"
        color="error"
        style={errorMessage !== "" ? { display: "" } : { display: "none" }}
      >
        {errorMessage}
      </Button>
      <Button sx={{ mt: "10px" }} variant="contained" onClick={() => handleSave()}>
        {t("common.save")}
      </Button>
    </>
  );
}
