import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MeasurementPointObject } from "./../../../../../types";
import { useTranslation } from "react-i18next";
//STORE
import {
  updateMeasurementPointLocation,
  toggleMeasurementPointSelectedState,
  clearDeviceColors
} from "./../../../../measurements/measurementsSlice";
import { RootState } from "../../../../../app/store";

//KONVA
import { Stage, Layer } from "react-konva";

import styles from "./PlanView.module.css";

//IMAGE
//import no_image_avaible from "./../../../Resources/No_image_available.png";
//import move_icon from "./../../../Resources/move_icon.png";

//MATERIAL UI
import { IconButton, Divider, Tooltip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import FilterCenterFocusRoundedIcon from "@mui/icons-material/FilterCenterFocusRounded";
//import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

//COMPONENTS
import { PlanViewMeasurementPoints } from "./PlanViewMeasurementPoints";
import { PlanViewFloorImage } from "./PlanViewFloorImage";
import { commonAttributes } from "../../../../common/commonAttributes";

interface planViewProperties {
  editContent: boolean;
  zoomLevel: number;
  stageHidded: boolean;
  projectPlanId: string;
  resizeCanvas: number;
  contentEditable: boolean;
  measurementPoints: Array<MeasurementPointObject>;
  reportPage?: boolean;
}

interface ImageInterface {
  heigth: number;
  width: number;
  x: number;
  y: number;
}

export default function PlanView(props: planViewProperties) {
  //PAGE PROPERTIES
  const dispatch = useDispatch();

  const stageRef = useRef(null);
  const mainRef = useRef(null);

  const {t} = useTranslation();
  /***************************************************************************************************/

  const [measurementPointArray, setMeasurementPointArray] = useState(
    Array<MeasurementPointObject>()
  );
  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH
  );

  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );

  useEffect(() => {
    dispatch(clearDeviceColors());
  }, [props.projectPlanId, dispatch]);

  useEffect(() => {
    if (props.projectPlanId === "-1") {
      setMeasurementPointArray(Array<MeasurementPointObject>()); //CLEAR MEASUREMENT POINT FROM VIEW IF PLAN ID IS EMPTY
    } else {
      if (props.measurementPoints.find(x => x.isLocated === false) !== undefined) {
        setnewMeasurements(true);
      } else {
        setnewMeasurements(false);
      }

      setMeasurementPointArray(props.measurementPoints);
    }
  }, [props.measurementPoints, props.projectPlanId]);

  const [newMeasurements, setnewMeasurements] = useState(false);
  //***************************************************************************************************/
  //STAGE PROPERTIES
  const stagePropertiesObject = {
    zoomLevel: props.reportPage ? props.zoomLevel : 1,
    // stageZoomAddition: 0.0,
    clickZoomAddition: 0.1,
    stageX: commonAttributes.planStageInitX,
    stageY: commonAttributes.planStageInitY,
    scale: 1,
    shifttX: 0,
    shifttY: 0,
    height: 10,
    width: 10,
    image: { heigth: 0, width: 0, x: 0, y: 0 }
  };
  const [stageProperties, setStageProps] = useState(stagePropertiesObject);
  const [editContent, setEditState] = useState(false);
  //const [handZoomLevel, setHandZoomLevel] = useState(0.0);
  const [stageAutoDraw, setStageAutoDraw] = useState(false);

  const [stageHeigth, setstageHeight] = useState(50);
  const [stageWidth, setstageWidth] = useState(50);

  const [viewSensorTexts, setviewSensorTexts] = useState(true);
  const [movePoints, setmovePoints] = useState(false);

  const [planId, setPlanId] = useState("-1");
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FOLLOWS EDIT STATE

  useEffect(() => {
    if (editContent === false) {
      setmovePoints(false);
      return;
    }

    if (newMeasurements && movePoints === false) {
      setmovePoints(true);
    }
  }, [editContent, newMeasurements, movePoints]);

  useEffect(() => {
    setEditState(props.editContent);
  }, [props.editContent]);

  useEffect(() => {
    setStageAutoDraw(props.stageHidded === false);
  }, [props.stageHidded]);

  useEffect(() => {
    //console.log("resize outside from canvas [" + props.resizeCanvas + "]");
    resizeHandler(100, true);
  }, [props.resizeCanvas]);

  useEffect(() => {
    if (props.contentEditable === false) setviewSensorTexts(false);
  }, [props.contentEditable]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const timer = useRef<any>(null);

  // RESIZE FUNCTIONALITY
  const resizeHandler = (time: number, force: boolean) => {
    if (timer.current !== null) clearTimeout(timer.current);

    if (props.stageHidded) return;

    if (force) {
      resizeCanvas();
    }

    timer.current = setTimeout(() => {
      //console.log("NOW (" + new Date().getSeconds() + ")");
      //console.log("WINDOW SIZE (" + height + ")(" + width + ")");
      resizeCanvas();
    }, time);
  };

  const resizeCanvas = () => {
    if (props.stageHidded) return;

    let width = 50; //window.innerWidth;
    let height = 50; //window.innerHeight;

    if (mainRef !== null && mainRef.current !== null) {
      const current = mainRef.current as any;

      //console.log(current);
      width = current.clientWidth !== undefined ? current.clientWidth : width;
      height = current.clientHeight !== undefined ? current.clientHeight : height;
    }

    if (stageRef !== null && stageRef.current !== null) {
      setstageHeight(height < 50 ? 50 : height);
      setstageWidth(width < 50 ? 50 : width);
    }
  };

  const windowResize = () => {
    resizeHandler(250, false);
  };

  // Listening for the window resize event
  useEffect(() => {
    window.onresize = windowResize;
    resizeHandler(250, true);
    // You can also use:
    // window.addEventListener('resize', resizeHanlder);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //MANUAL ZOOM BUTTON CLICKS
  const handleZoom = (e: any, zoomLevel: number) => {
    //ZOOMING REDONE
    let newScale = Math.round((stageProperties.scale + zoomLevel) * 100) / 100;
    let stageX = 0;
    let stageY = 0;

    //console.log(newScale);

    //CHECK IF RESET
    if (zoomLevel === 0) {
      // fOR TESTING newScale = solveImageRatio({
      //   heigth: stageProperties.image.heigth,
      //   width: stageProperties.image.width,
      //   x: stageProperties.image.x,
      //   y: stageProperties.image.y
      // });

      // ORIGINAL SIZE
      /*
      newScale = 1;
      stageX = commonAttributes.planStageInitX * newScale;
      stageY = commonAttributes.planStageInitY * newScale;
      */

      // FIT TO SCREEN
      //newScale = initialPositionRef.current.scale;
      const fitvalues = solveScaleToFit();
      newScale = fitvalues.scale;
      stageX = fitvalues.stageX;
      stageY = fitvalues.stageY;

      //RESET POSITION
      //setStageProps({ ...stageProperties, scale: newScale, stageX: 0, stageY: 0 });
    } else {
      //CHECK THAT SCALE IS NOT TOO SMALL
      if (newScale <= 0.1) newScale = 0.1;

      stageX = stageProperties.stageX;
      stageY = stageProperties.stageY;
    }

    setStageProps({
      ...stageProperties,
      scale: newScale,
      stageX: stageX,
      stageY: stageY
    });
  };

  //FOR MOUSE SCROLL WHEEN ACTION
  const handleCanvasWheel = (e: any) => {
    if (props.contentEditable === false) return;

    e.evt.preventDefault();
    const newScale = e.evt.deltaY < 0 ? 0.05 : -0.05;

    handleZoom(null, newScale);
  };

  const solveScaleToFit = () => {
    let values = {
      scale: 1,
      stageX: 0,
      stageY: 0,
    };
    if (stageRef !== null && stageRef.current !== null) {
      const current = stageRef.current as any;
      const layers = current.getLayers();
      if (layers !== null) {
        const imageLayer = layers[0];
        const pointsLayer = layers[1];
        const imageLayerRect = imageLayer.getClientRect({relativeTo: current,});
        const pointsLayerRect = pointsLayer.getClientRect({relativeTo: current,});
        const points = pointsLayer.getChildren();
        if (points !== null) {
          let pointTopRect = {...pointsLayerRect};

          pointTopRect.y += pointTopRect.height;
          for (let point of points) {
            const pointRect = point.getClientRect({relativeTo: pointsLayer,});
            pointTopRect = (pointTopRect.y < pointRect.y ? pointTopRect : pointRect);
          }
  
          let minX = (imageLayerRect.x < pointsLayerRect.x ? imageLayerRect.x : pointsLayerRect.x);
          let minY = (imageLayerRect.y < pointsLayerRect.y ? imageLayerRect.y : pointsLayerRect.y);
          let maxX = ((imageLayerRect.x + imageLayerRect.width) > (pointsLayerRect.x + pointsLayerRect.width) ?
            (imageLayerRect.x + imageLayerRect.width) : (pointsLayerRect.x + pointsLayerRect.width));
          let maxY = ((imageLayerRect.y + imageLayerRect.height) > (pointsLayerRect.y + pointsLayerRect.height) ?
            (imageLayerRect.y + imageLayerRect.height) : (pointsLayerRect.y + pointsLayerRect.height));
          let maxWidth = maxX - minX;
          let maxHeight = maxY - minY;
          if (maxWidth === 0 || maxHeight === 0) return values;

          let imageStageRatioWidth = Math.round(((stageWidth - 10) / maxWidth) * 100) / 100;
          let imageStageRatioHeight = Math.round(((stageHeigth - 10) / maxHeight) * 100) / 100;
          values.scale = imageStageRatioWidth <= imageStageRatioHeight ? imageStageRatioWidth : imageStageRatioHeight;
          let textScale = (1 - commonAttributes.planTextScaleFactor) / values.scale + commonAttributes.planTextScaleFactor;
          let marginTop = (pointTopRect.y + pointTopRect.height) * values.scale - pointTopRect.height / textScale - imageLayerRect.y;
          values.stageX = -1 * minX * values.scale + 5;
          values.stageY = (marginTop < imageLayerRect.y ? -marginTop : imageLayerRect.y) + 5;
          values.stageX = isNaN(values.stageX) ? 0 : values.stageX;
          values.stageY = isNaN(values.stageY) ? 0 : values.stageY;
        }
      };
    };
    return values;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CANVAS DRAG END
  const handleCanvasDragEnd = (e: any) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();

    setStageProps({ ...stageProperties, stageX: stage.attrs.x, stageY: stage.attrs.y });
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // IMAGE RETURN

  //const initialPositionRef = useRef(stagePropertiesObject);

  const handleTimedImageChange = (time: number, image: ImageInterface) => {
    if (timer.current !== null) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleImageChange(image);
    }, time);
  };

  // preparation, because needs two renderings before scale to fit hits the target
  const prepareImageChange = (image: ImageInterface) => {
    if (image.heigth !== undefined && image.width !== undefined) {
      const fitScale = solveScaleToFit();
      setStageProps({
        ...stageProperties,
        scale: fitScale.scale,
        stageX: fitScale.stageX,
        stageY: fitScale.stageY,
        image: { heigth: image.heigth, width: image.width, x: image.x, y: image.y }
      });
    }
  };

  const handleImageChange = (image: ImageInterface) => {
    if (image.heigth !== undefined && image.width !== undefined) {
      //const newCanvasScale = solveImageRatio(image);
      const fitScale = solveScaleToFit();
      /*
      initialPositionRef.current = {
        ...stageProperties,
        //scale: newCanvasScale,
        //stageX: commonAttributes.planStageInitX * newCanvasScale,
        //stageY: commonAttributes.planStageInitY * newCanvasScale,
        scale: fitScale.scale,
        stageX: fitScale.stageX,
        stageY: fitScale.stageY,
        image: { heigth: image.heigth, width: image.width, x: image.x, y: image.y }
      };
      */
      setStageProps({
        ...stageProperties,
        //scale: newCanvasScale,
        //stageX: commonAttributes.planStageInitX * newCanvasScale,
        //stageY: commonAttributes.planStageInitY * newCanvasScale,
        scale: fitScale.scale,
        stageX: fitScale.stageX,
        stageY: fitScale.stageY,
        image: { heigth: image.heigth, width: image.width, x: image.x, y: image.y }
      });

      // show only final placement
      if (stageRef !== null && stageRef.current !== null) {
        const current = stageRef.current as any;
        current.show();
        current.to({
          opacity: 1,
          duration: 0.05
        });
      }
  
      //handleZoom(null, 0);
    }
  };

  // for actions before project plan change affects to rendering
  useEffect(() => {
    // hide intermediate stages
    if (stageRef !== null && stageRef.current !== null) {
      const current = stageRef.current as any;
      current.hide();
      current.opacity(0);
    }
    
    setStageProps({
      ...stagePropertiesObject
    });
    
    setPlanId(props.projectPlanId);
  }, [props.projectPlanId])

  const measurementPointsAmount = useRef(0);
  const activePlanId = useRef("-1");
  //reset floorplanimage when new measurementpoint is added to current plan
  useEffect(() => {
    if (activePlanId.current === props.projectPlanId)
    {
      if (measurementPointsAmount.current < props.measurementPoints.length) {
        //setStageProps(initialPositionRef.current);
        // set zoom with delay, so that new point get added on layer before calculations
        if (timer.current !== null) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          handleZoom(null, 0);
        }, 50);
      }
    }
    measurementPointsAmount.current = props.measurementPoints.length;
    activePlanId.current = props.projectPlanId;
  }, [measurementPointArray]);

  const solveImageRatio = (image: ImageInterface): number => {
    //CHECK NEW SCALE FROM IMAGE SCALING
    //ONLY FOR WIDTH WISE FOR NOW
    if (image === undefined) return 1;
    let imageFullWidth = image.width + 2 * commonAttributes.planStageInitX; //TAKE INIT STAGE X AS MARGIN
    let imageFullHeight = image.heigth + 2 * commonAttributes.planStageInitY; //TAKE INIT STAGE Y AS MARGIN
    let imageStageRatioWidth = Math.round((stageWidth / imageFullWidth) * 100) / 100;
    let imageStageRatioHeight = Math.round((stageHeigth / imageFullHeight) * 100) / 100;

    if (imageStageRatioWidth <= 0.2) imageStageRatioWidth = 0.2;
    if (imageStageRatioHeight <= 0.2) imageStageRatioHeight = 0.2;

    let newScale =
      imageStageRatioWidth <= imageStageRatioHeight ? imageStageRatioWidth : imageStageRatioHeight;

    return newScale; //NEW SCALE OF CANVAS FROM IMAGE
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handlePointsMove = (e: any) => {
    setmovePoints(!movePoints);
  };

  // HANDLE SHOW DEVICE TEXTS
  const handleShowDeviceText = (e: any) => {
    setviewSensorTexts(!viewSensorTexts);
  };

  // SAVE CANVAS TO IMAGE
  const saveCanvasImage = (e: any) => {
    if (stageRef !== null && stageRef.current !== null) {
      const current = stageRef.current as any;

      if (typeof current.toDataURL === "function") {
        //console.log(current.toDataURL());
        downloadURI(current.toDataURL(), "PlanPicture_" + props.projectPlanId + ".png");
      }
    }
  };

  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //***************************************************************************************************/
  // SET MEASUREMENT POINTS
  const measurementDragEnd = (e: any, id: string) => {
    if (!editContent) return; //NO EDIT

    if (e !== null) {
      const newX = Math.round(e.target.x());
      const newY = Math.round(e.target.y());

      dispatch(
        updateMeasurementPointLocation({ measurentPointId: id, positionX: newX, positionY: newY })
      );
    }
  };

  const setSelectedMeasurementPoint = (e: any, id: string) => {
    if (e !== null) {
      dispatch(
        toggleMeasurementPointSelectedState({ measurementId: id, planId: props.projectPlanId })
      );
    }
  };

  //************************************************************************************************** */

  //***************************************************************************************************/

  return (
    <div className={styles["planview-main-container"]} id="mainContainer">
      <div
        className={styles["planview-controls-container"]}
        style={{
          display: props.contentEditable ? "" : "none"
        }}
      >
        <Tooltip title={t("tooltip.reset_image") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => handleZoom(event, 0)}
        >
          <FilterCenterFocusRoundedIcon
            sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
            className={styles["planview-static-icon"]}
          />
        </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip.zoom_in") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => handleZoom(event, stageProperties.clickZoomAddition)}
        >
          <ZoomInIcon
            sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
            className={styles["planview-static-icon"]}
          />
        </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip.zoom_out") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => handleZoom(event, -1 * stageProperties.clickZoomAddition)}
        >
          <ZoomOutIcon
            sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
            className={styles["planview-static-icon"]}
          />
        </IconButton>
        </Tooltip>
        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          light={false}
          sx={{ borderColor: "var(--colorDivider)" }}
        />
        <Tooltip title={t("tooltip.lock") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => handlePointsMove(event)}
          sx={{ display: editContent ? "" : "none" }}
        >
          {movePoints ? (
            <LockOpenOutlinedIcon
              sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
              className={
                newMeasurements ? styles["planview-animated-icon"] : styles["planview-static-icon"]
              }
            />
          ) : (
            <LockRoundedIcon
              sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
              className={styles["planview-static-icon"]}
            />
          )}
        </IconButton>
        </Tooltip>
        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          light={false}
          sx={{ borderColor: "var(--colorDivider)" }}
        />
        <Tooltip title={t("tooltip.toggle_floor_image_measurements") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => handleShowDeviceText(event)}
        >
          <FormatStrikethroughIcon
            sx={{
              color: viewSensorTexts ? "var(--colorOrange)" : "red",
              height: "var(--iconSize)",
              width: "var(--iconSize)"
            }}
            className={styles["planview-static-icon"]}
          />
        </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip.download") || ""} placement="left">
        <IconButton
          size="large"
          className={styles["planview-control-buttons"]}
          onClick={event => saveCanvasImage(event)}
        >
          <SaveAltIcon
            sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
            className={styles["planview-static-icon"]}
          />
        </IconButton>
        </Tooltip>
        {/* <IconButton size="large" sx={{ fontSize: "8px" }}>
          Zoom:
          <br />
          {stageProperties.scale}
        </IconButton>
        <IconButton size="large" sx={{ fontSize: "8px" }}>
          X:
          <br />
          {stageProperties.stageX}
        </IconButton>
        <IconButton size="large" sx={{ fontSize: "8px" }}>
          Y:
          <br />
          {stageProperties.stageY}
        </IconButton> */}
      </div>

      <div className={styles["planview-draw-container"]} ref={mainRef}>
        <Stage
          ref={stageRef}
          style={{ background: "white" }}
          height={stageHeigth}
          width={stageWidth}
          onWheel={handleCanvasWheel}
          // scaleX={stageProperties.scale}
          // scaleY={stageProperties.scale}
          x={stageProperties.stageX}
          y={stageProperties.stageY}
          onDragEnd={handleCanvasDragEnd}
          autoDrawEnabled={stageAutoDraw}
          draggable={props.contentEditable}
          scaleX={stageProperties.scale}
          scaleY={stageProperties.scale}
        >
          {/* ******************** */}
          <Layer key="imageLayer">
            {props.projectPlanId !== "-1" && (
              <PlanViewFloorImage
                projectPlanId={planId}
                prepareImageChange={imageProps => prepareImageChange(imageProps)}
                handleImageChange={imageProps => handleTimedImageChange(50, imageProps)}
              />
            )}
          </Layer>
          <Layer key="measurementPointsLayer">
            {/* ******************** */}
            <PlanViewMeasurementPoints
              editContent={editContent}
              movePoints={movePoints}
              measurementArray={measurementPointArray}
              measurementDragEnd={measurementDragEnd}
              // measurementSetShowData={measurementSetShowData}
              measurementSetSelected={setSelectedMeasurementPoint} //SET ONLY HIGHLIGHTED
              viewDeviceSensorTexts={viewSensorTexts}
              showRHorAH={showRHorAH}
              temperatureCompensation={temperatureCompensation}
              textScale={(1 - commonAttributes.planTextScaleFactor) / stageProperties.scale + commonAttributes.planTextScaleFactor}
            />
          </Layer>
          {/* ******************** */}
        </Stage>
      </div>
    </div>
  );
}
