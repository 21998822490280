import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import styles from "./DeviceSettings.module.css";
import { useTranslation } from "react-i18next";
import {
  Button,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { clearAvailableMeasurementPoints, clearError, clearHistoryData, getDevice, getDeviceHistoryData } from "./deviceSlice";
import { useGetDeviceTypesQuery } from "../../../../devices/deviceTypeAPI";
import { GetDateString } from "../../../../utils/commonFunctions";
import DeviceSettingsLinkOrUnlinkDialog from "./DeviceSettingsLinkOrUnlinkDialog";
import { DeviceSettingsLastMeasurementsObject, DeviceSettingsProjectObject } from "../../../../../types";
import DeviceSettingsTagsDialog from "./DeviceSettingsTagsDialog";
import { getCompanyUserRights, getUserProjectManagerAndAdminCompanies } from "../../../../company/companySlice";
import DrawerComponent from "../../../../UI/DrawerComponent";
import CreateProjectForm from "./CreateProjectForm";
import ProjectViewHistory from "../history/ProjectViewHistory";
import { StatusIcon } from "../../../../UI/StatusIcons";
interface DeviceObject {
  serial: string;
  deviceType: string | undefined;
  projects: Array<DeviceSettingsProjectObject>;
  lastSeen: string | undefined;
  battery: number | undefined;
  network: number | undefined;
  latestMeasurements: Array<DeviceSettingsLastMeasurementsObject>;
}

const DeviceSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const deviceSerial = params.deviceserial;

  const { data: deviceTypes } = useGetDeviceTypesQuery();

  const deviceData = useSelector((state: RootState) => state.device);
  const loading = useSelector((state: RootState) => state.device.loading);
  const error = useSelector((state: RootState) => state.device.error);
  const userCompanies = useSelector(getUserProjectManagerAndAdminCompanies);
  const historyLoading = useSelector((state: RootState) => state.device.historyLoading);

  const [device, setDevice] = useState<undefined | DeviceObject>(undefined);
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState<boolean>(false);
  const [linkDialogOpen, setLinkDialogOpen] = useState<boolean>(false);
  const [tagDialogOpen, setTagDialogOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [addOrRemoveDialog, setAddOrRemoveDialog] = useState<"add" | "remove">("add");
  const [hasCompanyAdminRights, setHasCompanyAdminRights] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const userNavigatedFromCompanyDevices: string | null =
    window.localStorage.getItem("clickedcompany");

  useEffect(() => {
    if (deviceSerial === undefined || null) {
      return;
    } else {
      dispatch(clearError());
      dispatch(getCompanyUserRights());
      dispatch(getDevice(deviceSerial.toString()));
    }
    return () => {
      dispatch(clearAvailableMeasurementPoints());
      dispatch(clearHistoryData());
      window.localStorage.removeItem("clickedcompany");
    };
  }, [dispatch, deviceSerial]);

  useEffect(() => {
    if (userCompanies.length > 0) {
      const isAdmin = userCompanies.filter(company => company.companyId === deviceData.device.companyId && company.companyRoleName === "Admin").length > 0;
      setHasCompanyAdminRights(isAdmin);
    }
  }, [userCompanies, deviceData.device.companyId]);

  useEffect(() => {
    if (deviceData === undefined || deviceData.device.deviceId === (-1 || undefined)) {
      return;
    } else {
      setDevice({
        serial: deviceData.device.serial,
        deviceType: deviceTypes?.find(x => Number(x.id) === deviceData.device.deviceTypeId)?.name,
        projects: deviceData.device.projects.map(project => project),
        lastSeen:
          deviceData.device.latestMeasurements.length !== 0
            ? deviceData.device.latestMeasurements
                .map(meas => GetDateString(meas.dateTime.toString(), "DD-MM-YYYY HH:mm"))
                .reduce((a, b) => (a > b ? a : b))
            : "-",
        battery: deviceData.device.latestMeasurements.find(
          x => x.sensorTypeName === "BatteryStatus"
        )?.value,
        network: deviceData.device.latestMeasurements.find(
          x => x.sensorTypeName === "NetworkQuality"
        )?.value,
        latestMeasurements: deviceData.device.latestMeasurements.filter(
          x => x.sensorTypeName !== "BatteryStatus" && x.sensorTypeName !== "NetworkQuality")
      });
    }
  }, [dispatch, deviceData, deviceTypes]);

  const handleDialogClose = () => {
    setLinkDialogOpen(false);
    setUnlinkDialogOpen(false);
  };

  const handleTagDialogClose = () => {
    setTagDialogOpen(false);
  };

  const getHistoryData = () => {
      dispatch(getDeviceHistoryData(deviceData.device.deviceId));
  };

  const getSensorTypeShortText = (sensorTypeName: string) => {
    switch (sensorTypeName) {
      case "RelativeHumidity":
        return "%RH:";
      case "Temperature":
        return "°C:";
      case "RelativeHumidityAmbient":
        return `%RH ${t("device_management.ambient")}:`;
      case "TemperatureAmbient":
        return `°C ${t("device_management.ambient")}:`;
      case "MoistureContent A":
        return "%MC A:"; 
      case "MoistureContent B":
        return "%MC B:";
      case "DifferentialPressure":
        return "DP";
      case "PM 2_5":
        return "PM 2.5:"; 
      case "PM 10":
        return "PM 10:"; 
      case "CO2":
        return "CO2:"; 
      case "TVOC":
        return "tVOC:"; 
      };
  };
  

  const firstCellStyles = {
    fontWeight: "bold",
    fontSize: !smallScreen ? "16px !important" : "12px !important",
    height: !smallScreen ? "75px" : "55px",
    width: "25%",
    verticalAlign: "middle !important"
  };
  const secondCellStyles = {
    fontSize: !smallScreen ? "16px !important" : "12px !important",
    height: !smallScreen ? "75px" : "55px",
    width: !smallScreen ? "50%" : "25%",
    verticalAlign: "middle !important",
  };

  return (
    <>
      <>
      <Typography component={"h1"} sx={{ fontSize: "var(--largeHeaderFontSize)" }}>
            {t("device_management.device_settings")}
          </Typography>
          {userNavigatedFromCompanyDevices && (
              <Link
                component={RouterLink}
                to={`/devicemanagement/company/${userNavigatedFromCompanyDevices}`}
                sx={{ ml: "auto" }}
              >
                {t("device_management.return_to_devices")}
              </Link>
          )}
        <div className={showHistory ? styles["device-settings-container"] : styles["device-settings-container-center"]}>
          <div className={styles["device-settings-table-header"]}>
          {smallScreen && 
            <Button variant="contained" onClick={() => setDrawerOpen(true)}>
              {t("device_management.manage_projects")}
            </Button>
          }
          </div>
          {loading ? (
            <div>LOADING...</div>
          ) : error ? (
            <div>ERROR</div>
          ) : device !== undefined ? (
            <>
            <div className={styles["device-settings-table-container"]}>
              <TableContainer
                component={Paper}
                sx={{
                  padding: !smallScreen ? "25px 0px 25px 25px" : "15px",
                  borderRadius: "12px",
                  //borderColor: "var(--colorOrange)"
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>{t("device_management.serial")}:</TableCell>
                      <TableCell sx={secondCellStyles}>{device.serial}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>
                        {t("device_management.device_type")}:
                      </TableCell>
                      <TableCell sx={secondCellStyles}>{device.deviceType}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>{t("device_management.tags")}:</TableCell>
                      <TableCell sx={secondCellStyles}>
                        {deviceData.device.deviceTags.map(tag => 
                          <div style={{ whiteSpace: "pre-line" }} key={tag.id}>
                            {tag.name}
                          </div>
                        )}
                        </TableCell>
                      <TableCell sx={{ verticalAlign: "middle !important" }}>
                        <Stack>
                          <Stack direction={!smallScreen ? "row" : "column"} spacing={1}>
                            <Button 
                              variant="contained" 
                              size="small"
                              disabled={!hasCompanyAdminRights}
                              onClick={() => {
                                setAddOrRemoveDialog("add");
                                setTagDialogOpen(true);
                              }}
                              >
                                {t("device_management.add")}
                            </Button>
                            <Button 
                              variant="contained"
                              size="small" 
                              color="info" 
                              disabled={deviceData.device.deviceTags.length < 1 || !hasCompanyAdminRights}
                              onClick={() => { 
                                setAddOrRemoveDialog("remove");
                                setTagDialogOpen(true);
                              }}
                            >
                                {t("device_management.remove")}
                            </Button>
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>
                        {t("device_management.project_links")}:
                      </TableCell>
                      <TableCell sx={secondCellStyles}>
                        {device.projects.map(project => (
                          <div style={{ whiteSpace: "pre-line" }} key={project.id}>
                            <Link
                              key={project.id}
                              component={RouterLink}
                              to={`/projects/${project.id}/load`}
                            >
                              {project.name}
                            </Link>
                          </div>
                        ))}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "middle !important" }}>
                        <Stack direction="column" spacing={1}>
                          <Stack direction={!smallScreen ? "row" : "column"} spacing={1}>
                            <Button 
                              variant="contained" 
                              size="small"
                              onClick={() => setLinkDialogOpen(true)}
                            >
                              {t("device_management.link")}
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              color="info"
                              disabled={device.projects.length < 1}
                              onClick={() => setUnlinkDialogOpen(true)}
                            >
                              {t("device_management.unlink")}
                            </Button>
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>
                        {t("device_management.last_seen")}:
                      </TableCell>
                      <TableCell sx={secondCellStyles}>{device.lastSeen}</TableCell>
                      <TableCell sx={{ verticalAlign: "middle !important", textAlign: "center !important" }}>
                      {!smallScreen &&
                        <Button
                          variant="contained"
                          size="small"
                          disabled={!hasCompanyAdminRights}
                          onClick={() => {
                            setShowHistory((prev) => !prev);
                            if (deviceData.historyData.length < 1 && !showHistory) {
                              getHistoryData();
                            }
                          }}
                        >
                          {showHistory ? t("device_management.hide_history") : t("device_management.show_history")}
                        </Button>
                      }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={firstCellStyles}>{t("device_management.battery")}:</TableCell>
                      {device.battery ?
                      <TableCell sx={secondCellStyles}>
                        <Stack direction="row" spacing={1}>
                          <p>{device.battery}</p>
                          <StatusIcon type="battery" status={device.battery}/>
                        </Stack>
                      </TableCell>
                      :
                      <TableCell sx={secondCellStyles}>-</TableCell>
                      }
                      {/* {smallScreen && 
                      <>
                      <TableCell sx={firstCellStyles}>{t("device_management.network")}:</TableCell>
                      {device.network ?
                      <TableCell sx={secondCellStyles}>
                        <Stack direction="row" spacing={1}>
                          <p>{device.network}</p> 
                          <StatusIcon type="network" status={device.network} />
                        </Stack>
                      </TableCell>
                      :
                      <TableCell sx={secondCellStyles}>-</TableCell>
                      }
                      </>
                      } */}
                    </TableRow>
                  
                    <TableRow>
                      <TableCell sx={firstCellStyles}>{t("device_management.network")}:</TableCell>
                      {device.network ?
                      <TableCell sx={secondCellStyles}>
                        <Stack direction="row" spacing={1}>
                          <p>{device.network}</p> 
                          <StatusIcon type="network" status={device.network} />
                        </Stack>
                      </TableCell>
                      :
                      <TableCell sx={secondCellStyles}>-</TableCell>
                      }
                    </TableRow>
                    {device.latestMeasurements.map(meas => 
                    <TableRow key={`device-settings-latest-measurements-${meas.sensorTypeName}-${meas.dateTime}`}>
                      <TableCell sx={firstCellStyles}>
                        {getSensorTypeShortText(meas.sensorTypeName)}
                      </TableCell>
                      <TableCell sx={secondCellStyles}>
                        {meas.value}
                      </TableCell>
                    </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {historyLoading && showHistory && <div className={styles["device-settings-history-loading-container"]}>LOADING...</div>}
            {!smallScreen && showHistory && !historyLoading &&
            <div className={styles["device-settings-history-container"]}> 
              <ProjectViewHistory 
                planViewShown={false}
                transitions={{buttons: false, measurements:false, planview: false, history: false}}
                deviceSettings={true}
              />
            </div>
            }
            </>
          ) : (
            <div></div>
          )}
        </div>
      </>
      <>
        <DrawerComponent 
          open={drawerOpen} 
          onClose={() => setDrawerOpen(false)} 
          position={"bottom"} 
          content={
          <CreateProjectForm onClose={() => setDrawerOpen(false)}/>
          }
        />
        <DeviceSettingsLinkOrUnlinkDialog
          open={unlinkDialogOpen}
          linkOrUnlink={"unlink"}
          projects={device?.projects}
          handleDialogClose={handleDialogClose}
        />
        <DeviceSettingsLinkOrUnlinkDialog
          open={linkDialogOpen}
          linkOrUnlink={"link"}
          projects={device?.projects}
          handleDialogClose={handleDialogClose}
        />
        <DeviceSettingsTagsDialog 
          open={tagDialogOpen}
          addOrRemove={addOrRemoveDialog}
          handleClose={handleTagDialogClose}
        />
      </>
    </>
  );
};

export default DeviceSettings;
