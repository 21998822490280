import React, { useEffect, useMemo, useRef, useState } from "react";
import uPlot from "uplot";
import "uplot/dist/uPlot.min.css";
import "./History.css";
import {
  SensorHistoryObject,
  HistoryDataUnitsObject
} from "../../../../../types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../app/store";
import { getAbsoluteHumidity, getNonCompensatedMC} from "../../../../utils/commonFunctions";
import { wheelZoomPlugin } from "./wheelZoomPlugin";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import tooltipPlugin from "./TooltipPlugin";
import { updateDeviceColors } from "../../../../measurements/measurementsSlice";

const HistoryChart = (props: {
  signalDatas: SensorHistoryObject[];
  tickFormat: string;
  tooltipFormat: string;
  fromDate: string;
  toDate: string;
  dataProp: string;
  altDataProps?: Array<keyof HistoryDataUnitsObject>;
  title: string;
  tickSizeDivisor: number;
  handleTimeRangeChange: (from: string, to: string) => void;
  showAmbient: boolean;
  editEnabled: boolean;
  chartsAmount?: number;
  planViewShown: boolean;
  extraSpaceRight: boolean;
  showLegend?: boolean;
  reportPage?: boolean;
  transitions: {
    buttons: boolean;
    measurements: boolean;
    planview: boolean;
    history: boolean;
  };
  minDateRef?: any;
  refChartArea?: any;
  deviceSettings?: boolean;
}) => {
  const {
    signalDatas,
    fromDate,
    toDate,
    dataProp,
    altDataProps,
    title,
    showAmbient,
    chartsAmount,
    transitions,
    minDateRef,
    refChartArea,
    reportPage
  } = props;

  const { t } = useTranslation();
  const [values, setValues] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);

  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH
  );
  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );

  const deviceColors = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.deviceColors);
  const showDeviceSeries = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.showSeriesDevices);
  const hoveredDevice = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.hoveredDevice);

  const dispatch = useDispatch();

  const chart: any = useRef();
  const chartSync: any = useRef();
  
  const smallScreen = useMediaQuery("(max-width: 999px)");

  const colors = [
    '#e6194B', 
    '#3cb44b', 
    "#12CBC4",
    '#4363d8', 
    '#f58231', 
    '#911eb4',  
    '#9A6324',
    "#4F4557",   
    "#EE5A24",
    "#1B1464",
    "#F79F1F",
    "#245953",
    "#A3CB38",
    "#D980FA",
    "#EA2027",
    "#5758BB",
    "#FFA559",
    "#485460",
    "#FF6000",
    "#408E91",
    "#E49393",
    "#1289A7",
    "#B3C99C",
    "#006266",
    '#800000', 
    "#FFC312",
    "#ED4C67",
    '#ffe119', 
    '#42d4f4',
    '#f032e6', 
    "#009FBD",
    '#bfef45', 
    '#fabed4', 
    '#469990', 
    '#dcbeff',
    '#fffac8',
    '#aaffc3',
    '#808000', 
    '#ffd8b1', 
    '#000075', 
    '#a9a9a9',
    "#C4E538",
    "#D14D72",
    "#FDA7DF",
  ];
  let uniqueColor = 0;

  //NOT NEEDED AFTER TIMSESTAMP CHANGES?
 /*  function utcToLocal(date: Date) {
    let date2;
    date2 = new Date(+date - date.getTimezoneOffset() * 6e4);
    return date2;
  }; */
  const timeTickSpacing = 60;
  const timeIncrements = [60, 60*30, 3600, 3600*4, 3600*12, 3600*24, 3600*24*7, 3600*24*28, 3600*24*365]
  const timeFormatting = [
  // tick incr          default           year                     month              day                    hour     min      sec      mode
    [3600 * 24 * 365,   "{M}/{YYYY}",     null,                    null,              null,                  null,    null,    null,    1],
    [3600 * 24 * 28,    "{M}",            "\n{YYYY}",              null,              null,                  null,    null,    null,    1],
    [3600 * 24 * 7,     "{D}",            null,                    "\n{M}/{YYYY}",    null,                  null,    null,    null,    1],
    [3600 * 24,         "{D}",            null,                    "\n{M}/{YYYY}",    null,                  null,    null,    null,    1],
    [3600 * 12,         "{H}:{mm}",       "\n{D}/{M}/{YYYY}",      null,              "\n{D}/{M}/{YYYY}",    null,    null,    null,    1],
    [3600,              "{H}",            "\n{D}/{M}/{YYYY}",      null,              "\n{D}/{M}/{YYYY}",    null,    null,    null,    1],
    [60,                "{H}:{mm}",       "\n{D}/{M}/{YYYY}",      null,              "\n{D}/{M}/{YYYY}",    null,    null,    null,    1],
  ];
  //---------------------------------------------------------------------------------------------------

  type IndexObject = {
    deviceIndex: number;
    sensorKey: string;
    measurementIndex: number;
    isCompleted: boolean;
  };
  const showSensor = (
    measurementName: string,
    dataProp: string,
  ): boolean => {
    if (measurementName === dataProp || (measurementName === `${dataProp}Ambient` && showAmbient)) {
      return true;
    } else if (measurementName.includes("MoistureContent") && dataProp === "MoistureContent") {
      return true;
    } else {
      return false;
    }
  };
  type Serie = {
    label: string;
    value?: string;
    dash?: number[];
    scale?: string;
    stroke?: string;
    spanGaps?: boolean;
    show: boolean;
    width: number;
    alpha: number;
    points?: {
      show: boolean;
    };
    legend?: any;
  };

  const getSerie = useMemo(() => (device: SensorHistoryObject, key: string, currentDevice: string): Serie => {
    const sensor = device.measurementHistory[key];
    const sensorDepth = sensor.depth !== (null || undefined) ? `(${sensor.depth}mm)` : "(- mm)";
    const hovered = hoveredDevice !== "";
    const sensorUnit = 
    (sensor.dataUnit === "%RH" && showRHorAH) 
    ? 
    "g/m3" 
    : sensor.name === "MoistureContent A" 
    ? "%MC (A)" 
    : sensor.name === "MoistureContent B" 
    ? "%MC (B)" 
    : sensor.dataUnit;
    const deviceColor = deviceColors.filter(device => device.deviceSerial === currentDevice).find(x => x.color)?.color;
    const showDevice = showDeviceSeries.filter(device => device.deviceSerial === currentDevice)?.find(x => x.show);

    const mainviewLabel = `${device.measurementPointName} - ${device.deviceTypeName} - ${currentDevice} - ${sensorUnit} - ${sensor.ambient === true ? `${t("projectview_page.history.ambient_short")} ` : ""}`;
    const mcLabel = sensorUnit === "%MC (A)" ? "(A —)" : sensorUnit === "%MC (B)" ? "(B - -)" : "";

    return {
      label: !reportPage ? mainviewLabel : `${device.measurementPointName} ${device.deviceTypeName} ${currentDevice} ${mcLabel}`,
      dash: sensor.ambient ? [5, 5] : sensor.name === "MoistureContent B" ? [5,5] : [0, 0],
      scale: "values",
      stroke: deviceColor !== undefined ? deviceColor.toString() : colors[uniqueColor],
      show: showDevice !== undefined ? showDevice.show : reportPage ? true : props.deviceSettings ? true : false,
      spanGaps: true,
      alpha: smallScreen ? 1 : hovered && hoveredDevice !== currentDevice ? 0.2 : 1,
      width: 1.75,
      points: {
        show: false
      }
    };
  }, [signalDatas, showRHorAH, showAmbient, temperatureCompensation, deviceColors, hoveredDevice, smallScreen, props.deviceSettings, t, reportPage]);

  //make all chartdata arrays same length with null values
  const getSeriesAndValues = useMemo(() => (
    signalDatas: SensorHistoryObject[]
  ): { values: (number | null)[][]; series: Serie[] } => {
    const devices: SensorHistoryObject[] = signalDatas;
    const indexObjects: IndexObject[] = [];
    const dates: number[] = [];
    const values: (number | null)[][] = [];
    const series: Serie[] = [{ label: t("projectview_page.history.date_label"), value: "{D}-{M}-{YYYY} {HH}:{mm}", show: true, width: 1.75, alpha: 1 }];
    let uncompletedMeasurementHistories = 0;

    for (let i = 0; i < devices.length; i++) {
      const sensors = Object.values(devices[i].measurementHistory);
      const currentDevice = devices[i].serial;
      uniqueColor++;

      if (!deviceColors.some(device => device.deviceSerial === currentDevice)) {
        dispatch(updateDeviceColors({ deviceSerial: currentDevice }));
      }

      sensors.forEach((sensor, i2) => {
        const show = showSensor(
          sensor.name as string,
          dataProp as string,
        );
        if (show) {
          uncompletedMeasurementHistories++;
          values.push([]);
          series.push(getSerie(devices[i], Object.keys(devices[i].measurementHistory)[i2], currentDevice));
          indexObjects.push({
            deviceIndex: i,
            sensorKey: Object.keys(devices[i].measurementHistory)[i2],
            measurementIndex: 0,
            isCompleted: false
          });
        }
      });
    }

    while (uncompletedMeasurementHistories > 0) {
      let earliestTime = Number.MAX_VALUE;
      for (let i = 0; i < indexObjects.length; i++) {
        const measurementHistoryObject = 
          devices[indexObjects[i].deviceIndex].measurementHistory[indexObjects[i].sensorKey];
        const show = showSensor(
          measurementHistoryObject.name as string,
          dataProp as string,
        );
        if (show) {
          const measurement =
            measurementHistoryObject.data[indexObjects[i].measurementIndex];
          if (measurement) {
            const measTime = measurement.timestampNumber !== undefined ? measurement.timestampNumber : measurement.t;

            if (measTime < earliestTime) {
              earliestTime = measTime;
            }
          }
        }
      }
      let dateAdded = false;
      for (let i = 0; i < indexObjects.length; i++) {
        const historyObject = devices[indexObjects[i].deviceIndex].measurementHistory;

        const show = showSensor(
          historyObject[indexObjects[i].sensorKey]
            .name as string,
          dataProp as string,
        );
        if (show) {
          const measurement =
            historyObject[indexObjects[i].sensorKey].data[indexObjects[i].measurementIndex];

          if (!measurement && !indexObjects[i].isCompleted) {
            indexObjects[i].isCompleted = true;
            uncompletedMeasurementHistories--;
          }
          if (
            measurement &&
            (measurement.timestampNumber === earliestTime || measurement.t === earliestTime) &&
            !indexObjects[i].isCompleted
          ) {

          //get temperature for absolute humidity calculation
         const temperatureMeasurement = 
         indexObjects[i].sensorKey === "RelativeHumidity"
         ? 
         historyObject["Temperature"]?.data[indexObjects[i].measurementIndex]
         :
         indexObjects[i].sensorKey === "RelativeHumidityAmbient"
         ? 
         historyObject["TemperatureAmbient"]?.data[indexObjects[i].measurementIndex]
         : 
         indexObjects[i].sensorKey?.includes("MoistureContent")
         ?
         historyObject["TemperatureAmbient"]?.data[indexObjects[i].measurementIndex]
         : !props.deviceSettings ? {value: 0} : {v: 0}

            if (showRHorAH && indexObjects[i].sensorKey.includes("RelativeHumidity")) {
              if (temperatureMeasurement.value !== undefined) {
              values[i].push(getAbsoluteHumidity(temperatureMeasurement.value, measurement.value));
              }else {
                values[i].push(getAbsoluteHumidity(temperatureMeasurement.v, measurement.v));
              }
            } else if (temperatureCompensation && indexObjects[i]?.sensorKey?.includes("MoistureContent")) {
              if (temperatureMeasurement.value !== undefined) {
              values[i].push(getNonCompensatedMC(measurement?.value, temperatureMeasurement?.value));
              }else {
                values[i].push(getNonCompensatedMC(measurement?.v, temperatureMeasurement?.v));
              }
            } else {
              if (measurement.value !== undefined) {
              values[i].push(measurement.value);
              }else {
                values[i].push(measurement.v);
              }
            }

            indexObjects[i].measurementIndex += 1;
          } else {
            values[i].push(null);
          }
          if (!dateAdded) {
            dates.push(earliestTime / 1000);
            dateAdded = true;
          }
        }
      }
    }
    //adds same data starting date for temperature chart as moisture chart
    if (!minDateRef.current || minDateRef.current > dates[0]) {
      minDateRef.current = dates[0];
    }
    if (minDateRef.current !== dates[0]) {
      dates.splice(0, 0, minDateRef.current);
      values.forEach((array, i) => {
        values[i].splice(0, 0, null);
      });
    }
    dates.push(new Date().getTime() / 1000);
    values.forEach((array, i) => {
      values[i].push(null);
    });

    values.splice(0, 0, dates);
    return {
      values: values,
      series: series
    };
  }, [minDateRef, dataProp, altDataProps, showRHorAH, temperatureCompensation, dispatch, deviceColors, getSerie, props.deviceSettings, t]);

  useEffect(() => {
    const valuesAndSeries = getSeriesAndValues(signalDatas);
    setSeries(valuesAndSeries.series);
    setValues(valuesAndSeries.values);
  }, [signalDatas, showAmbient, showRHorAH, temperatureCompensation]);

  const getSize = (): any => {
      const height = refChartArea.current.parentElement.clientHeight;
      const width = refChartArea.current.parentElement.clientWidth;
     
    if (!smallScreen && !reportPage){
      return {
      width: !width ? 0 : chartsAmount && chartsAmount < 4 ? width - 50 : width / 2 - 50,
      height: !height ? 0 
      : chartsAmount === 1 
      ? height - 30 
      : chartsAmount === 2 
      ? height / 2 - 30 
      : chartsAmount === 4 
      ? height / 2 -30 
      : height / 3 - 30
    }
    } else if (reportPage) {
      return {
        width: !width ? 0 : width - 20,
        height: !height ? 0 : series.length > 40 ? height / 3 : height / 2 + 50
      };
    } else if (props.deviceSettings) {
      return {
      width: !width ? 0 : chartsAmount && chartsAmount < 4 ? width - 60 : width / 2 - 60,
      height: !height ? 0 
      : chartsAmount === 1 
      ? height - 20 
      : chartsAmount === 2 
      ? height / 2 - 20 
      : chartsAmount === 4 
      ? height / 2 -20 
      : height / 3 - 20
      }
    } else {
      return {
        width: !width ? 0 : width - 50,
        height: !height ? 0 : height / 2
      };
    }
  };

  const plotRef = useRef();

  //initialize chart 
  useEffect(() => {
    chart.current = new uPlot(options as any, [] as any, plotRef.current);
    chartSync.current = uPlot.sync("chartSync");
    chartSync.current.sub(chart.current);
  }, []);

  //setScale on fromDate, toDate change
  //setting to localstorage here causes zoom sync to break and different scales for charts when chart is deselected and selected from the left icons, (add if stamements???)
  useEffect(() => {
    if (chart.current) {
      chart.current.setScale("x", {
        min: new Date(fromDate).getTime() / 1000,
        max: new Date(toDate).getTime() / 1000
      });
    }
  }, [fromDate, toDate]);

  //change size on transition and series change, and on window resize
  useEffect(() => {
    if (refChartArea.current === null) {
      return;
    }
    chart.current.setSize({ width: getSize().width, height: getSize().height });
  }, [transitions, series, chartsAmount]);

  const handleResize = () => {
    chart.current.setSize({ width: getSize().width, height: getSize().height });
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //add data and series to chart
  useEffect(() => {
    const seriesLength = chart.current.series.length;
    for (let i = seriesLength - 1; i >= 0; i--) {
      chart.current.delSeries(i);
    }
    series.forEach((serie: any, i: any) => {
      chart.current.addSeries(serie, i);
    });
    chart.current.setData(values, true);
    chart.current.setScale("x", {
      min: localStorage.getItem("chartZoomFrom"),
      max: localStorage.getItem("chartZoomTo")
    });
    chart.current.redraw();
    chart.current.axes[1].label = dataProp.includes("RelativeHumidity") && showRHorAH
    ? "g/m3"
    : dataProp.includes("MoistureContent" || "MC" || "%MC")
    ? "%MC"
    : dataProp === "Temperature"
    ? "°C"
    : dataProp === "DifferentialPressure"
    ? "PA"
    : dataProp === "CO2"
    ? "CO2"
    : dataProp === "PM 10"
    ? "PM10"
    : dataProp === "PM 2_5"
    ? "PM2.5"
    : dataProp === "TVOC"
    ? "tVOC"
    : "%RH";
    if (reportPage) {
      const legends = document.getElementsByClassName("u-legend u-inline u-live");
      while(legends[0]) {
        legends[0].classList.remove("u-live");
      }
    }
  }, [values, series, reportPage]);

    

  const options = {
    title: title,
    legend: {show: props.showLegend ? props.showLegend : false},
    width: 0,
    height: 0,
    plugins: [
      wheelZoomPlugin({
        factor: 0.75,
        minX: new Date("01-01-2022").getTime() / 1000,
        maxX: new Date().getTime() / 1000
      }),
      !reportPage && tooltipPlugin(title)
    ],
    tzDate: (ts: number) => new Date(ts * 1e3),
    series: [],
    scales: {
      x: {
        time: true,
        min: localStorage.getItem("chartZoomFrom")
          ? Number(localStorage.getItem("chartZoomFrom"))
          : new Date(fromDate).getTime() / 1000,
        max: localStorage.getItem("chartZoomTo")
          ? Number(localStorage.getItem("chartZoomTo"))
          : new Date(toDate).getTime() / 1000,
        //onScale update
        range(_u: any, dataMin: number, dataMax: number) {
          if (!dataMin) {
            if (localStorage.getItem("chartZoomFrom") && localStorage.getItem("chartZoomTo")) {
              return [
                Number(localStorage.getItem("chartZoomFrom")),
                Number(localStorage.getItem("chartZoomTo"))
              ];
            }
            return [new Date(fromDate).getTime() / 1000, new Date(toDate).getTime() / 1000];
          }
          return [dataMin, dataMax];
        }
      }
    },
    cursor: {
      dataIdx: (self: any, seriesIdx: any, hoveredIdx: any, cursorXVal: any) => {
        let seriesData = self.data[seriesIdx];

        if (seriesData[hoveredIdx] == null) {
          let nonNullLft = null,
            nonNullRgt = null,
            i;

          i = hoveredIdx;
          while (nonNullLft == null && i-- > 0) {
            if (seriesData[i] != null) nonNullLft = i;
          }

          i = hoveredIdx;
          while (nonNullRgt == null && i++ < seriesData.length) {
            if (seriesData[i] != null) nonNullRgt = i;
          }

          let xVals = self.data[0];

          let curPos = self.valToPos(cursorXVal, "x");
          let rgtPos = nonNullRgt == null ? Infinity : self.valToPos(xVals[nonNullRgt], "x");
          let lftPos = nonNullLft == null ? -Infinity : self.valToPos(xVals[nonNullLft], "x");

          let lftDelta = curPos - lftPos;
          let rgtDelta = rgtPos - curPos;

          if (lftDelta <= rgtDelta) {
            if (lftDelta <= 10) hoveredIdx = nonNullLft;
          } else {
            if (rgtDelta <= 10) hoveredIdx = nonNullRgt;
          }
        }
        return hoveredIdx;
      },
      //removes doubleclick reset
      /* bind: {
        dblclick: () => null
      },*/
      focus: { prox: 50 },
      sync: {
        key: "chartSync",
        setSeries: false
      },
      x: true,
      y: false,
      //removes drag zoom
      //drag: { x: false, y: false, setScale: false }
    },
    axes: [
      {
        /*
        value: (_self: any, ticks: number[]) => {
          return ticks.map((rawValue: number) => {
            return new Date(rawValue);
          });
        },
        */
        space: timeTickSpacing,
        incrs: timeIncrements,
        values: timeFormatting,
        labelFont: "bold 12px Fellix-Regular",
        font: "10px Fellix-Regular"
      },
      {
        scale: "values",
        values: (_self: any, ticks: number[]) => ticks.map((rawValue: number) => rawValue),
        side: 3,
        label:
          dataProp.includes("RelativeHumidity") && showRHorAH
            ? "g/m3"
            : dataProp.includes("MoistureContent" || "MC" || "%MC")
            ? "%MC"
            : dataProp === "Temperature"
            ? "°C"
            : dataProp === "DifferentialPressure"
            ? "PA"
            : dataProp === "CO2"
            ? "CO2"
            : dataProp === "PM 10"
            ? "PM10"
            : dataProp === "PM 2_5"
            ? "PM2.5"
            : dataProp === "TVOC"
            ? "tVOC"
            : "%RH",
        labelFont: "bold 12px Fellix-Regular",
        font: "10px Fellix-Regular"
      },
      /* {
        scale: "altValues",
        values: (_self: any, ticks: number[]) => ticks.map((rawValue: number) => rawValue),
        side: 1,
        label: dataProp === "Temperature" ? "Pa" : "%MC",
        labelFont: "bold 12px Fellix-Regular",
        font: "10px Fellix-Regular"
      } */
    ]
  };

  return (
    <div
      onTransitionEnd={() => {
        chart.current.setSize({ width: getSize().width, height: getSize().height });
      }}
    >
      <div ref={plotRef as any} />
    </div>
  );
};

export default HistoryChart;