import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationEn from "./locales/en/translationEn.json";
import translationFi from "./locales/fi/translationFi.json";

export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    fi: { translation: translationFi }
  },
  lng: "fi",
  fallbackLng: "en",
  interpolation: { escapeValue: false }
});
