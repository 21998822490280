import { useState } from "react";
import styles from "./ProjectViewInfo.module.css";

import { useGetProjectQuery } from "../../../projectApi"; //"../../project/projectApi";
import { useParams } from "react-router-dom";
import ProjectViewInfoEdit from "./ProjectViewInfoEdit";

export default function ProjectViewInfo() {
  const [editMode, setEditMode] = useState(false);
  const { projectId } = useParams();

  const { data, error, isLoading } = useGetProjectQuery(projectId || "error");

  return (
    <div className={styles["project-info-container"]}>
      {!isLoading &&
        !error &&
        data &&
        (editMode ? (
          <ProjectViewInfoEdit
            key="edit-form"
            editMode={true}
            projectInfo={data}
            handleEditModeToggle={() => setEditMode(!editMode)}
          ></ProjectViewInfoEdit>
        ) : (
          <ProjectViewInfoEdit
            key="view-form"
            editMode={false}
            projectInfo={data}
            handleEditModeToggle={() => setEditMode(!editMode)}
          ></ProjectViewInfoEdit>
        ))}
    </div>
  );
}
