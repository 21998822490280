import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Button,
  Typography,
  FormHelperText
} from "@mui/material";
import { AlarmProfile } from "../../../../../types";
import {
  useAddProjectAlarmProfileMutation,
  useEditProjectAlarmProfileMutation,
  useDeleteProjectAlarmProfileMutation,
  useGetSensorTypesQuery
} from "../../../projectApi";
import styles from "./AddAlarmProfileForm.module.css";

const AddProjectForm = (props: {
  open: boolean;
  handleClose: () => void;
  projectId: number;
  editMode: boolean;
  editableProfile?: AlarmProfile | null;
}) => {
  const {
    data: sensorTypesData,
    error: errorSensorTypes,
    isLoading: isLoadingSensorTypes
  } = useGetSensorTypesQuery();

  const triggerClauses = [
    { id: "greaterThan", symbol: ">" },
    { id: "lessThan", symbol: "<" }
  ];

  const [errorMessage, setErrorMessage] = useState("");
  const [addAlarmProfile, { isLoading: isLoadingPost }] = useAddProjectAlarmProfileMutation();
  const [editAlarmProfile, { isLoading: isLoadingEdit }] = useEditProjectAlarmProfileMutation();
  const [deleteAlarmProfile, { isLoading: isLoadingDelete }] =
    useDeleteProjectAlarmProfileMutation();

  const { t } = useTranslation();

  const { editMode, editableProfile } = props;

  const [sensorType, setSensorType] = useState(
    editableProfile ? editableProfile.sensorTypeName : ""
  );
  const [triggerClause, setTriggerClause] = useState(
    editableProfile ? (editableProfile.greaterThanValue ? "greaterThan" : "lessThan") : ""
  );
  const [name, setName] = useState(editableProfile ? editableProfile.name : "");
  const [value, setValue] = useState(
    editableProfile
      ? editableProfile.greaterThanValue !== null
        ? editableProfile.greaterThanValue.toString()
        : editableProfile.lessThanValue?.toString()
      : ""
  );

  // Validation
  const [sensorTypeTouched, setSensorTypeTouched] = useState(editMode ? true : false);
  const [triggerClauseTouched, setTriggerClauseTouched] = useState(editMode ? true : false);
  const [nameTouched, setNameTouched] = useState(editMode ? true : false);
  const [valueTouched, setValueTouched] = useState(editMode ? true : false);

  var sensorTypeValid = sensorType !== "";
  var sensorTypeInvalid = !sensorTypeValid && sensorTypeTouched;
  var triggerClauseValid = triggerClause !== "";
  var triggerClauseInvalid = !triggerClauseValid && triggerClauseTouched;
  var nameValid = name?.trim() !== "";
  var nameInvalid = !nameValid && nameTouched;
  var valueValid = value != null && value?.trim() !== "" && !Number.isNaN(Number(value));
  var valueInvalid = !valueValid && valueTouched;

  const formInvalid = !sensorTypeValid || !nameValid || !valueValid || !triggerClauseValid;

  const handleSensorTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSensorType(event.target.value);
  };
  const handleTriggerClauseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTriggerClause(event.target.value);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSensorTypeBlur = () => {
    setSensorTypeTouched(true);
  };
  const handleTriggerClauseBlur = () => {
    setTriggerClauseTouched(true);
  };
  const handleNameBlur = () => {
    setNameTouched(true);
  };
  const handleValueBlur = () => {
    setValueTouched(true);
  };

  const handleSend = () => {
    setSensorTypeTouched(true);
    setNameTouched(true);
    setValueTouched(true);
    setTriggerClauseTouched(true);

    if (formInvalid) return;

    var alarmProfile;

    if (triggerClause === "greaterThan") {
      alarmProfile = {
        ProjectId: props.projectId,
        Name: name,
        SensorTypeName: sensorType,
        GreaterThanValue: Number(value)
      };
    } else if (triggerClause === "lessThan") {
      alarmProfile = {
        ProjectId: props.projectId,
        Name: name,
        SensorTypeName: sensorType,
        LessThanValue: Number(value)
      };
    } else return;

    if (editMode && editableProfile) {
      editAlarmProfile({ alarmProfile: alarmProfile, id: editableProfile?.id })
        .then((res: any) => {
          if (res.error) {
            if (res.error.status === 409) {
              setErrorMessage(t("projectview_page.alarms.alarm_profile_name_conflict_message"));
            }
          } else {
            props.handleClose();
          }
        })
        .catch(error => console.log(error));
    } else {
      addAlarmProfile(alarmProfile)
        .then((res: any) => {
          if (res.error) {
            if (res.error.status === 409) {
              setErrorMessage(t("projectview_page.alarms.alarm_profile_name_conflict_message"));
            }
          } else {
            props.handleClose();
          }
        })
        .catch(error => console.log(error));
    }
  };

  const handleDelete = () => {
    if (editableProfile && editableProfile.id) {
      deleteAlarmProfile(editableProfile.id)
        .then(res => {
          props.handleClose();
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <div className={styles["add-project-form-container"]}>
        <Typography sx={{fontWeight: "bold", fontSize: "var(--headerFontSize)"}} mt={2} mb={2} ml={2}>
          {editMode
            ? t("projectview_page.alarms.edit_alarm_profile")
            : t("projectview_page.alarms.add_alarm_profile")}
        </Typography>
        <FormHelperText error sx={{ mb: 2, ml: 2 }}>
          {errorMessage}
        </FormHelperText>
        <FormControl variant="standard" sx={{ m: 1, mb: 1 }}>
          <TextField
            required
            fullWidth
            id="alarm-profile-name-text-field"
            label={t("projectview_page.alarms.profile_name_label")}
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            error={nameInvalid}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          />
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, mb: 3 }}>
          <TextField
            variant="standard"
            fullWidth
            select
            required
            id="add-alarm-profile-sensortype"
            value={sensorType}
            onChange={handleSensorTypeChange}
            label={t("projectview_page.alarms.sensor_type_label")}
            onBlur={handleSensorTypeBlur}
            error={sensorTypeInvalid}
            disabled={editMode}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          >
            {sensorTypesData &&
              sensorTypesData.map(sensorType => {
                return (
                  <MenuItem
                    sx={{ fontSize: "var(--inputFontSize)" }}
                    key={sensorType.id}
                    value={sensorType.name}
                  >
                    {t(`projectview_page.alarms.${sensorType.name}`)} ({sensorType.dataUnit})
                  </MenuItem>
                );
              })}
              {!sensorTypesData && <MenuItem />}
          </TextField>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, mb: 3 }}>
          <TextField
            variant="standard"
            fullWidth
            required
            select
            label={t("projectview_page.alarms.trigger_clause_label")}
            id="aadd-alarm-profile-triggerclause"
            value={triggerClause}
            onChange={handleTriggerClauseChange}
            onBlur={handleTriggerClauseBlur}
            error={triggerClauseInvalid}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          >
            {triggerClauses.map(triggerClause => {
              return (
                <MenuItem
                  sx={{ fontSize: "var(--inputFontSize)" }}
                  key={triggerClause.id}
                  value={triggerClause.id}
                >
                  {t(`projectview_page.alarms.${triggerClause.id}`)} {triggerClause.symbol}
                </MenuItem>
              );
            })}
            {!triggerClauses && <MenuItem />}
          </TextField>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, mb: 3 }}>
          <TextField
            required
            id="alarm-profile-value-text-field"
            label={t("projectview_page.alarms.value_label")}
            value={value}
            onChange={handleValueChange}
            onBlur={handleValueBlur}
            error={valueInvalid}
            helperText={
              sensorType === "BatteryStatus" 
            ? t("projectview_page.alarms.battery_status_helper_text") 
            : sensorType === "NetworkQuality" 
            ? t("projectview_page.alarms.network_quality_helper_text")
            : null
            }
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          ></TextField>
        </FormControl>
        <FormControl variant="standard">
          <div className={styles["test"]}>
            {editMode && (
              <Button
                variant="contained"
                onClick={() => handleDelete()}
                color="error"
                sx={{ ml: 2, color: "black", cursor: "pointer !important" }}
                disabled={isLoadingPost || isLoadingSensorTypes || isLoadingEdit}
              >
                {t("projectview_page.alarms.alarmprofile_delete_button")}
              </Button>
            )}
            <Grid container className={styles["add-alarm-profile-buttons-container"]}>
              <Button
                variant="outlined"
                onClick={() => props.handleClose()}
                sx={{ mr: 2, mb: 1 }}
                disabled={isLoadingPost || isLoadingSensorTypes || isLoadingEdit}
              >
                {t("projectview_page.alarms.add_alarmprofile_cancel_button")}
              </Button>
              <Button
                variant="contained"
                onClick={handleSend}
                sx={{ mr: 2 }}
                disabled={isLoadingPost || isLoadingSensorTypes || isLoadingEdit}
              >
                {t("projectview_page.alarms.add_alarmprofile_save_button")}
              </Button>
            </Grid>
          </div>
        </FormControl>
      </div>
    </Dialog>
  );
};

export default AddProjectForm;
