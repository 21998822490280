import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
interface statusProps {
  type: string,
  status: number | undefined,
  fontSize?: "inherit" | "medium" | "small"
};

export const StatusIcon = ({ type, status, fontSize }: statusProps) => {
  const { t } = useTranslation();

  return type === "battery" ? (
    status === 4 ? (
      <Tooltip title={t("tooltip.battery_status4") || ""}>
        <BatteryFullIcon fontSize={fontSize ? fontSize : "small"} color="success" />
      </Tooltip>
    ) : status === 3 ? (
      <Tooltip title={t("tooltip.battery_status3") || ""}>
        <Battery5BarIcon fontSize={fontSize ? fontSize : "small"} color="success" />
      </Tooltip>
    ) : status === 2 ? (
      <Tooltip title={t("tooltip.battery_status2") || ""}>
        <Battery3BarIcon fontSize={fontSize ? fontSize : "small"} color="warning" />
      </Tooltip>
    ) : status === 1 ? (
      <Tooltip title={t("tooltip.battery_status1") || ""}>
        <Battery1BarIcon fontSize={fontSize ? fontSize : "small"} color="error" />
      </Tooltip>
    ) : status === 0 ? (
      <Tooltip title={t("tooltip.battery_status0") || ""}>
        <Battery0BarIcon fontSize={fontSize ? fontSize : "small"} color="error" />
      </Tooltip>
    ) : (
      <p>-</p>
    )
  ) : status === 4 ? (
    <Tooltip title={t("tooltip.network_status4") || ""}>
      <SignalCellular4BarIcon fontSize={fontSize ? fontSize : "small"} color="success" />
    </Tooltip>
  ) : status === 3 ? (
    <Tooltip title={t("tooltip.network_status3") || ""}>
      <SignalCellular3BarIcon fontSize={fontSize ? fontSize : "small"} color="success" />
    </Tooltip>
  ) : status === 2 ? (
    <Tooltip title={t("tooltip.network_status2") || ""}>
      <SignalCellular2BarIcon fontSize={fontSize ? fontSize : "small"} color="warning" />
    </Tooltip>
  ) : status === 1 ? (
    <Tooltip title={t("tooltip.network_status1") || ""}>
      <SignalCellular1BarIcon fontSize={fontSize ? fontSize : "small"} color="error" />
    </Tooltip>
  ) : status === 0 ? (
    <Tooltip title={t("tooltip.network_status0") || ""}>
      <SignalCellular0BarIcon fontSize={fontSize ? fontSize : "small"} color="error" />
    </Tooltip>
  ) : (
    <p>-</p>
  );
};


