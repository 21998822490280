import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  // define REACT_APP_KEYCLOAK_URL in docker-compose for production/staging, in .env for local development
  url: (process.env.NODE_ENV === 'production' && window.location.hostname === process.env.REACT_APP_PRODUCTION_HOSTNAME
    ? process.env.REACT_APP_KEYCLOAK_PRODUCTION_URL
    : process.env.REACT_APP_KEYCLOAK_DEVELOPMENT_URL),
  realm: "Relia",
  clientId: "relia2-client"
});

export default keycloak;
