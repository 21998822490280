import React, { useState, useEffect } from "react";

import { Button, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";

import styles from "./ProjectViewTransferProject.module.css";

import { useSendTransferRequestMutation, useCancelTransferRequestMutation, useGetTransferRequestQuery } from "../../../projectApi";

import { useTranslation } from "react-i18next";

import ConfirmDialog from "../../../../UI/ConfirmDialog";

interface IProjecTransfer {
  projectId: number;
}

export default function ProjectViewTransferProject(props: IProjecTransfer) {
  // COMMON
  const { t } = useTranslation();

  const [newOwnerEmail, setNewOwnerEmail] = useState("");

  const [transferDevices, settransferDevices] = useState(true);

  const emailIsValid = validateEmail(newOwnerEmail);

  const [showTransferConfirmDialog, setShowTransferConfirmDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const [sendTransferRequest, { isLoading: isLoadingSend }] = useSendTransferRequestMutation();
  const [cancelTransferRequest, { isLoading: isLoadingCancel }] = useCancelTransferRequestMutation();
  const {data: transferRequest, error, isLoading: isLoadingGet} = useGetTransferRequestQuery(props.projectId);

  const [sentText, setsentText] = useState("");

  useEffect(() => {
    //settransferDevices(true);
    if (transferRequest) {
      setNewOwnerEmail(transferRequest.userName);
      settransferDevices(transferRequest.transferDevices);
    }
  }, [props.projectId, isLoadingGet]);

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const sendRequest = () => {
    if (emailIsValid === false) return;
/*
      setsentText("");
      let emailAddress = newOwnerEmail;
*/
      sendTransferRequest({ projectId: props.projectId, userName: newOwnerEmail, transferDevices })
        .then((res: any) => {
        })
        .catch(error => {
          console.log("Error / Virhe");
          console.log(error);
        });
  };

  const cancelRequest = () => {
      cancelTransferRequest({ projectId: props.projectId, userName: newOwnerEmail, transferDevices })
        .then((res: any) => {
        })
        .catch(error => {
          console.log("Error / Virhe");
          console.log(error);
        });
  }

  return (
    <>
    <div className={styles["projectsettings-transfer-container"]}>
      <div className={styles["projectsettings-content-container"]}>
        <Stack direction="column" spacing={2}>
          <TextField
            disabled={transferRequest !== null}
            label={transferRequest !== null
              ? t("projectview_page.settings_page.transferProject.sent")
              : t("projectview_page.settings_page.transferProject.new_owner_email")
            }
            value={newOwnerEmail}
            contentEditable={false}
            sx={{ mt: 2 }}
            error={!emailIsValid}
            onChange={event => setNewOwnerEmail(event.target.value)}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={transferRequest !== null}
                checked={transferDevices}
                onChange={() => {
                  settransferDevices(!transferDevices);
                }}
                sx={{
                  width: "var(--checkBoxSize)",
                  height: "var(--checkBoxSize)",
                  borderRadius: 2,
                  "&.Mui-checked": {
                    color: "orange"
                  },
                  "&.Mui-disabled": {
                    color: "gray"
                  },
                }}
              />
            }
            label={t("projectview_page.settings_page.transferProject.transfer_also_sensors")}
          />
        </Stack>
      </div>
      <div className={styles["projectsettings-controls-container"]}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              transferRequest !== null
                ? setShowCancelDialog(true)
                : setShowTransferConfirmDialog(true)
              }}
            disabled={isLoadingGet || !emailIsValid}
          >
            {transferRequest !== null
              ? t("projectview_page.settings_page.transferProject.cancel_request")
              : t("projectview_page.settings_page.transferProject.send_request")
            }
          </Button>
        </Stack>
      </div>
    </div>
    <ConfirmDialog 
      key="set-transfer-project-dialog"
      open={showTransferConfirmDialog}
      content={t("dialog.set_transfer")}
      setOpen={setShowTransferConfirmDialog}
      value={undefined}
      onConfirm={sendRequest}
    />
    <ConfirmDialog 
      key="cancel-transfer-project-dialog"
      open={showCancelDialog}
      content={t("dialog.cancel_transfer")}
      setOpen={setShowCancelDialog}
      value={undefined}
      onConfirm={cancelRequest}
    />
    </>
  );
}
