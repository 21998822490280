//components
import { DeviceObject, MeasurementPointObject } from "../../types";
import { useTranslation } from "react-i18next";

import { formatNumber, GetDateString, getAbsoluteHumidityText, getMcText, sensorTextFunction } from "../utils/commonFunctions";

import styles from "./ReportLastValues.module.css";
import React from "react";
interface Properties {
  measurementPoints: Array<MeasurementPointObject>;
}

export default function ReportLastValues(props: Properties) {
  const { t } = useTranslation();

  //*******************************************************************************************************************
  const DepthDefinitions = (props: { device: DeviceObject }) => {
    if (props.device.depthDefinition === 0) {
      return <p>-</p>;
    } else if (props.device.depthDefinition === 1) {
      // DEVICE
      return <React.Fragment>{props.device.sensorUnits[0] !== undefined
        ? props.device.sensorUnits[0].depth + " mm"
        : "-"}</React.Fragment>;
    } else if (props.device.depthDefinition === 2) {
      // SENSORS
      return (
        <React.Fragment>
          {props.device.sensorUnits.map((sensor, index) => {
            return sensor.ambient === false &&
              sensor.typeName !== "NetworkQuality" &&
              sensor.typeName !== "BatteryStatus" ? (
              <p key={"report-depth-definition" + sensor.typeName + "_" + index}>
                {sensor.depth + " mm"}
              </p>
            ) : (
              <b key={"report-depth-definition" + sensor.typeName + "_" + index}></b>
            );
          })}
        </React.Fragment>
      );
    } else {
      return <b>none</b>;
    }
  };
  //*******************************************************************************************************************
  //COMPONENT
  const Reportlines = (props: { device: DeviceObject }) => {
    const deviceLatestData = props.device.latestData;
    const temperature = deviceLatestData?.Temperature?.value;
    const temperatureAmbient = deviceLatestData?.TemperatureAmbient?.value;
    const relativeHumidity = deviceLatestData?.RelativeHumidity?.value;
    const relativeHumidityAmbient = deviceLatestData?.RelativeHumidityAmbient?.value;
    const moistureContentA = deviceLatestData?.["MoistureContent A"]?.value;
    const moistureContentB = deviceLatestData?.["MoistureContent B"]?.value;

    return (
      <div className={styles["report-table-row"]}>
        <div className={styles["report-table-cell"]}>{props.device.deviceType}</div>
        <div className={styles["report-table-cell"]}>{props.device.serial}</div>
        <div className={styles["report-table-cell"]}>
          <DepthDefinitions device={props.device} />
        </div>
        <div className={styles["report-table-cell"]}>
          {GetDateString(props.device.lastUpdated, "DD-MM-YYYY HH:mm")}
        </div>
        <div className={styles["report-table-cell"]} data-sensor-values>
          {props.device.sensorUnits.map((sensorUnit, index) => {
            if (
              sensorUnit.ambient === true &&
              sensorUnit.typeName !== "CO2" &&
              sensorUnit.typeName !== "TVOC" &&
              sensorUnit.typeName !== "PM 10" &&
              sensorUnit.typeName !== "PM 2_5"
            ) {
              return (
                <div key={"report_sensor_" + sensorUnit.typeName + "_" + index}>
                  {formatNumber(deviceLatestData[sensorUnit.typeName]?.value, 2, sensorUnit.unit)}
                  <br />
                  {sensorUnit.unit === "%RH"
                    ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
                    : null}
                </div>
              );
            } else
              return (
                <React.Fragment
                  key={"report_sensor_" + sensorUnit.typeName + "_" + index}
                ></React.Fragment>
              );
          })}
        </div>
        <div className={styles["report-table-cell"]} data-sensor-values>
          {props.device.sensorUnits.map((sensorUnit, index) => {
            if (
              sensorUnit.ambient === true &&
              sensorUnit.typeName !== "TemperatureAmbient" &&
              sensorUnit.typeName !== "RelativeHumidityAmbient"
            ) {
              return (
                <div key={"report_sensor_" + sensorUnit.typeName + "_" + index}>
                  {formatNumber(deviceLatestData[sensorUnit.typeName]?.value, 2, sensorUnit.unit) +
                    ` (${sensorUnit.typeName === "PM 2_5" ? "PM2.5" : sensorUnit.typeName === "PM 10" ? "PM10" : sensorUnit.typeName})`}
                  <br />
                  {sensorUnit.unit === "%RH"
                    ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
                    : null}
                </div>
              );
            } else
              return (
                <React.Fragment
                  key={"report_sensor_" + sensorUnit.typeName + "_" + index}
                ></React.Fragment>
              );
          })}
        </div>
        <div className={styles["report-table-cell"]}>
          {props.device.sensorUnits.map((sensorUnit, index) => {
            if (
              sensorUnit.ambient === false &&
              sensorUnit.typeName !== "NetworkQuality" &&
              sensorUnit.typeName !== "BatteryStatus"
            ) {
              return (
                <div key={"report_sensor_" + sensorUnit.typeName + "_" + index}>
                  {sensorTextFunction(
                    deviceLatestData[sensorUnit.typeName]?.value,
                    sensorUnit.typeName === "MoistureContent A"
                      ? "MoistureContent A"
                      : sensorUnit.typeName === "MoistureContent B"
                      ? "MoistureContent B"
                      : sensorUnit.unit
                  )}
                  <br />
                  {sensorUnit.unit === "%RH"
                    ? getAbsoluteHumidityText(temperature, relativeHumidity)
                    : sensorUnit.typeName === "MoistureContent A"
                    ? getMcText(moistureContentA, temperatureAmbient, " %MC (A) @20°C")
                    : sensorUnit.typeName === "MoistureContent B"
                    ? getMcText(moistureContentB, temperatureAmbient, " %MC (B) @20°C")
                    : null}
                </div>
              );
            } else
              return (
                <React.Fragment
                  key={"report_sensor_" + sensorUnit.typeName + "_" + index}
                ></React.Fragment>
              );
          })}
        </div>
      </div>
    );
  };

  /******************************************************************************************************************* */
  return (
    <div className={styles["report-table-container"]}>
      <div className={styles["report-table-row"]} data-isheader>
        <div className={styles["report-table-cell"]}>{t("device_list.device_name")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.serial")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.depth")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.last_updated")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.ambient_values")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.ambient_values")}</div>
        <div className={styles["report-table-cell"]}>{t("device_list.values")}</div>
      </div>
      {props.measurementPoints.map(measurement => {
        return (
          <div key={"report_meas_" + measurement.id} className={styles["report-table-measurement"]}>
            <div className={styles["report-table-row-header"]}> {measurement.name} {measurement.devices.length === 0 && t("device_list.no_devices")} </div>
            {measurement.devices.map(device => {
              return <Reportlines key={"report_device_" + device.id} device={device} />;
            })}
          </div>
        );
      })}
    </div>
  );
}
