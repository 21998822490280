import uPlot from "uplot";

function tooltipPlugin(title: string) {

  let tooltip: HTMLElement;

  if (document.getElementById(`u-tooltip`) === (undefined || null)) {
    tooltip = document.createElement("div");
    tooltip.id = `u-tooltip`;
    tooltip.className = "u-tooltip";
  }else {
    tooltip = document.getElementById(`u-tooltip`)!;
  }
  /* let tooltip = document.createElement("div");
  tooltip.className = `u-tooltip-${title}`; */
  tooltip.style.position = "absolute";
  tooltip.style.border = "2px solid black";
  tooltip.style.padding = "6px";
  tooltip.style.zIndex = "100";
  tooltip.style.background = "white";
  tooltip.style.color = "rgba(0,0,0,0.87)";
  tooltip.style.display = "none";
  tooltip.style.fontSize = "12px";
  tooltip.style.fontWeight = "bold";
  tooltip.style.whiteSpace = "pre-line";
  tooltip.style.lineHeight = "1.2";
  tooltip.style.borderRadius = "7px";
  tooltip.style.fontFamily = "Fellix-Regular";

  let seriesIdx: any = null;
  let dataIdx: any = null;

  const fmtDate = uPlot.fmtDate("{D}/{M}/{YYYY} {HH}:{mm} ");

  let over: any;

  let tooltipVisible = false;

  function showTooltip() {
    if (!tooltipVisible) {
      tooltip.style.display = "block";
      over.style.cursor = "pointer";
      tooltipVisible = true;
    }
  }

  function hideTooltip() {
    if (tooltipVisible) {
      tooltip.style.display = "none";
      tooltip.style.borderColor = "black";
      over.style.cursor = null;
      tooltipVisible = false;
      tooltip.textContent = "";
    }
  }

  function setTooltip(u: any) {
    showTooltip();

    let strokeColor;
    let tooltipLabel;
    let tooltipValue;
    let tooltipTime;

    const cursor = u.cursor;
    if (u.series[seriesIdx] && u.series[seriesIdx]._stroke !== undefined) {
      strokeColor = u.series[seriesIdx]._stroke;
    } else {
      tooltip.style.display = "none";
    }
    if (u.series[seriesIdx] && u.series[seriesIdx].label !== undefined) {
      tooltipLabel = u.series[seriesIdx].label;
    } else {
      tooltip.style.display = "none";
    }
    if (u.data[seriesIdx] !== undefined && u.data[seriesIdx][dataIdx] !== undefined && u.data[seriesIdx][dataIdx] !== null) {
      tooltipValue = u.data[seriesIdx][dataIdx];
      tooltipTime = fmtDate(new Date(u.data[0][dataIdx] * 1e3));
    }else if (u.data[seriesIdx] !== undefined) {
      let xVals = u.data[0];
      let yVals = u.data[seriesIdx];
      let nonNullIdx;
      
      if (yVals[dataIdx] !== undefined) {
        let nonNullLft = null;
        let nonNullRgt = null;
        let i;
       
        i = u.cursor.idx;
        while (nonNullLft == null && i-- > 0) {
          if (yVals[i] != null)
            nonNullLft = i;
        }
      
        i = u.cursor.idx;
        while (nonNullRgt == null && i++ < yVals.length) {
          if (yVals[i] != null)
            nonNullRgt = i;
        }
        const rgtVal = nonNullRgt == null ?  Infinity : xVals[nonNullRgt];
				const lftVal = nonNullLft == null ? -Infinity : xVals[nonNullLft];
        
				const lftDelta = u.data[seriesIdx][dataIdx] - lftVal;
			  const rgtDelta = rgtVal - u.data[seriesIdx][dataIdx];
        
				nonNullIdx = lftDelta <= rgtDelta ? nonNullLft : nonNullRgt;
      }
        tooltipValue = u.data[seriesIdx][nonNullIdx];
        tooltipTime = fmtDate(new Date(u.data[0][nonNullIdx] * 1e3));
        
    } else {
      tooltip.style.display = "none";
    }

    tooltip.style.borderColor = strokeColor;

    const chartRect = over.getBoundingClientRect();

    let shiftY = 10;
    let shiftX = chartRect.width - cursor.left < 150 ? -150 : 10;
    
    tooltip.style.top = cursor.top + chartRect.top + shiftY + "px";
    tooltip.style.left = cursor.left + chartRect.left + shiftX + "px";

    const lastHyphenIndex = tooltipLabel.lastIndexOf("-");
    const secondToLastHyphenIndex = tooltipLabel.lastIndexOf("-", lastHyphenIndex - 1);
    
    const tooltipUnit = tooltipLabel.substring(secondToLastHyphenIndex + 1, lastHyphenIndex).trim();
    const tooltipAmbient = tooltipLabel.substring(lastHyphenIndex + 1).trim();
    tooltipLabel = tooltipLabel.substring(0, secondToLastHyphenIndex).trim();

    tooltip.textContent = `${tooltipTime}\n${tooltipLabel}\n${tooltipValue}  ${tooltipUnit} ${tooltipAmbient}`;
  }

  return {
    hooks: {
      ready: 
        (u: any) => {
          over = u.over;

          over.addEventListener("dblclick", (e: any) => {
            if (e.detail === 2) {
              const from = u.scales.x.min;
              const to = u.scales.x.max;
              window.localStorage.setItem("chartZoomFrom", from);
              window.localStorage.setItem("chartZoomTo", to);
            }else {
              return;
            }
          });

          over.addEventListener("mouseenter", (e: any) => {
            document.body.appendChild(tooltip);
          });

          over.addEventListener("mouseleave", (e: any) => {
            document.getElementById("u-tooltip")?.remove();
          });

          over.addEventListener("mousedown", (e: any) => {
            if (e.detail === 2) {
              return;
            }else {
              let fromClick = u.posToVal(u.cursor.left, "x");
              over.addEventListener("mouseup", (e: any) => {
                  let toClick = u.posToVal(u.cursor.left, "x");
                  if (toClick - fromClick < 10) {
                    return;
                  }else {
                    window.localStorage.setItem("chartZoomTo", toClick)
                    window.localStorage.setItem("chartZoomFrom", fromClick);
                  }
                });
          }
          });
        },
      setCursor: [
        (u: any) => {
          let c = u.cursor;
          if (dataIdx !== c.idx) {
            dataIdx = c.idx;

            if (seriesIdx !== null) {
              setTooltip(u);
            }
          }
        }
      ],
      setSeries: [
        (u: any, sidx: any) => {
          if (seriesIdx !== sidx) {
            seriesIdx = sidx;

            if (sidx === null) {
              hideTooltip();
            }
            else if (dataIdx !== null){ 
              setTooltip(u);
          }
          }
        }
      ]
    }
  };
}

export default tooltipPlugin;
