import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container, MenuItem, Stack, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridSelectionModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import {
  useGetProjectAlarmProfilesQuery,
  useGetProjectMeasurementPointDevicesQuery,
  useAddProjectMeasurementPointSensorAlarmMutation,
  useDeleteProjectMeasurementPointSensorAlarmMutation
} from "../../../projectApi"; //"../../project/projectApi";
import { AlarmProfile } from "../../../../../types"; //"../../../types";
import AddAlarmProfileForm from "../alarms/AddAlarmProfileForm";
import styles from "./ProjectViewAlarms.module.css";
import MobileMenu from "../../../../UI/MobileMenu";

export default function ProjectViewAlarms() {
  const { projectId } = useParams();

  const { data, error, isLoading } = useGetProjectAlarmProfilesQuery(projectId || "error");
  const {
    data: deviceData,
    error: deviceDataError,
    isLoading: isLoadingDevices
  } = useGetProjectMeasurementPointDevicesQuery(projectId || "error");
  const [deleteSensorAlarm, { isLoading: isLoadingSensorAlarmDelete }] =
    useDeleteProjectMeasurementPointSensorAlarmMutation();
  const [addSensorAlarm, { isLoading: isLoadingSensorAlarmPost }] =
    useAddProjectMeasurementPointSensorAlarmMutation();

  const [selectedProfile, setSelectedProfile] = useState<null | AlarmProfile>(null);
  const [deviceSelectionModel, setDeviceSelectionModel] = useState<Array<number>>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { t } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const columns: GridColDef[] = [
    {
      field: "measurementPointName",
      headerName: t("projectview_page.alarms.device_list_measurement_point_header"),
      minWidth: 50,
      flex: 1
    },
    {
      field: "deviceTypeName",
      headerName: t("projectview_page.alarms.device_list_device_type_header"),
      minWidth: 50,
      flex: 1
    },
    {
      field: "serial",
      headerName: t("projectview_page.alarms.device_list_linked_device_header"),
      minWidth: 50,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => `${params.row.serial || " - "}`
    }
  ];

  useEffect(() => {
    setDeviceSelectionModel(
      selectedProfile && selectedProfile.alertedMeasurementPointSensors
        ? selectedProfile.alertedMeasurementPointSensors.map(x => x.measurementPointDeviceId)
        : []
    );
  }, [selectedProfile]);

  useEffect(() => {
    setSelectedProfile(data?.find(x => x.id === selectedProfile?.id) || null);
  }, [data, selectedProfile?.id]);

  const setSelectionChange = (x: GridSelectionModel) => {
    const idArray = x as Array<number>;

    // Deselect
    if (idArray.length < deviceSelectionModel.length) {
      const missingMeasurementPointDevice = deviceSelectionModel.filter(x => !idArray.includes(x));

      // Allow only one addition at a time
      if (missingMeasurementPointDevice.length !== 1) {
        return;
      }

      const deletedMeasurementPointSensorAlarm =
        selectedProfile?.alertedMeasurementPointSensors.find(
          x => x.measurementPointDeviceId === missingMeasurementPointDevice[0]
        );

      if (!deletedMeasurementPointSensorAlarm) {
        return;
      } else {
        deleteSensorAlarm(deletedMeasurementPointSensorAlarm.id);
      }
    }
    // Select
    else {
      const addedMeasurementPointDevice = idArray.filter(x => !deviceSelectionModel.includes(x));

      // Allow only one addition at a time
      if (addedMeasurementPointDevice.length !== 1) {
        return;
      }

      if (selectedProfile && projectId) {
        addSensorAlarm({
          MeasurementPointDeviceId: addedMeasurementPointDevice[0],
          ProjectId: parseInt(projectId),
          AlarmProfileId: selectedProfile.id,
          SensorTypeName: selectedProfile.sensorTypeName
        });
      }
    }
  };

  return (
    <>
      {/*Mobile view********************************************************************/}

      {smallScreen ? (
        <>
          <Container sx={{ 
            marginBottom: "1%", 
            marginTop: "2%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center"
            }}
          >
            <MobileMenu
              id="alarms-mobile-menu"
              icon="arrow"
              menuText={selectedProfile ? selectedProfile.name : t("projectview_page.alarms.select_profile_short_text")}
            >
              {!isLoading && !error && data && (
                <Stack spacing={0}>
                  <MenuItem>
                    <Button
                      fullWidth
                      sx={{ bgcolor: "var(--colorOrange)" }}
                      variant="contained"
                      onClick={() => setDialogOpen(true)}
                    >
                      {"+"} {t("projectview_page.alarms.add_alarm_profile")}
                    </Button>
                  </MenuItem>
                  {data.map((row: any) => (
                    <MenuItem key={row.id}>
                      <Button
                        fullWidth
                        size="small"
                        onClick={() => {
                          setSelectedProfile(row);
                        }}
                        className={styles["project-alarms-alarmprofiles-row"]}
                        variant={selectedProfile?.id === row.id ? "contained" : "outlined"}
                      >
                        {row.name}
                      </Button>
                    </MenuItem>
                  ))}
                </Stack>
              )}
            </MobileMenu>
            <Button
              className={styles["project-alarms-edit-button"]}
              variant="contained"
              disabled={
                isLoading ||
                isLoadingDevices ||
                selectedProfile === null ||
                selectedProfile === undefined
              }
              onClick={() => setEditDialogOpen(true)}
            >
              {t("projectview_page.alarms.edit_button")}
            </Button>
          </Container>
          {!isLoadingDevices && !deviceDataError && deviceData && (
            selectedProfile !== null ?
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "deviceTypeName", sort: "asc" }],
                },
              }}
              sx={{
                fontSize: "var(--basicFontSize)",
                backgroundColor: "white",
                borderRadius: "12px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                margin: "1%",
                "& .Mui-checked svg": {
                  width: "var(--checkBoxSize)",
                  height: "var(--checkBoxSize)",
                  backgroundColor: "transparent",
                  borderRadius: 2,
                  color: "orange"
                },
                "& .MuiCheckbox-root svg": {
                  width: "var(--checkBoxSize)",
                  height: "var(--checkBoxSize)",
                  backgroundColor: "transparent",
                  borderRadius: 2
                },
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "none"
                }
              }}
              rows={deviceData}
              columns={columns}
              selectionModel={deviceSelectionModel}
              autoPageSize
              checkboxSelection
              onSelectionModelChange={setSelectionChange}
              isRowSelectable={(params: GridRowParams) =>
                selectedProfile !== null &&
                selectedProfile !== undefined &&
                !isLoadingDevices &&
                !isLoadingSensorAlarmDelete &&
                !isLoadingSensorAlarmPost
              }
              localeText = {{
                toolbarDensity: t("measurement_list.data_grid_toolbar.toolbar_density"),
                toolbarDensityLabel: t("measurement_list.data_grid_toolbar.toolbar_density_label"),
                toolbarDensityCompact: t("measurement_list.data_grid_toolbar.toolbar_density_compact"),
                toolbarDensityStandard: t("measurement_list.data_grid_toolbar.toolbar_density_standard"),
                toolbarDensityComfortable: t("measurement_list.data_grid_toolbar.toolbar_density_comfortable"),
                toolbarColumns: t("measurement_list.data_grid_toolbar.toolbar_columns"),
                toolbarFilters: t("measurement_list.data_grid_toolbar.toolbar_filters"),
                toolbarExport: t("measurement_list.data_grid_toolbar.toolbar_export"),
                toolbarExportLabel: t("measurement_list.data_grid_toolbar.toolbar_export_label"),
                toolbarExportCSV: t("measurement_list.data_grid_toolbar.toolbar_export_csv"),
                toolbarExportPrint: t("measurement_list.data_grid_toolbar.toolbar_export_print"),
                columnsPanelTextFieldLabel: t("measurement_list.data_grid_toolbar.columns_panel_text_field_label"),
                columnsPanelTextFieldPlaceholder: t("measurement_list.data_grid_toolbar.columns_panel_text_field_placeholder"),
                columnsPanelShowAllButton: t("measurement_list.data_grid_toolbar.columns_panel_show_all_button"),
                columnsPanelHideAllButton: t("measurement_list.data_grid_toolbar.columns_panel_hide_all_button"),
                filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
                filterPanelOperators: t("measurement_list.data_grid_toolbar.filter_panel_operators"),
                filterPanelInputLabel: t("measurement_list.data_grid_toolbar.filter_panel_Input_label"),
                filterPanelInputPlaceholder: t("measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"),
                filterOperatorContains: t("measurement_list.data_grid_toolbar.filter_operator_contains"),
                filterOperatorEquals: t("measurement_list.data_grid_toolbar.filter_operator_equals"),
                filterOperatorStartsWith: t("measurement_list.data_grid_toolbar.filter_operator_starts_with"),
                filterOperatorEndsWith: t("measurement_list.data_grid_toolbar.filter_operator_ends_with"),
                filterOperatorIsEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_empty"),
                filterOperatorIsNotEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_not_empty"),
                filterOperatorIsAnyOf: t("measurement_list.data_grid_toolbar.filter_operator_is_any_of"),
                filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
                filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
                filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
                columnMenuShowColumns: t("measurement_list.data_grid_column_menu.show_columns"),
                columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
                columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
                columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
                columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
                columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc")
              }}
            />
            :
            <div></div>
          )}
          {dialogOpen && projectId && (
            <AddAlarmProfileForm
              projectId={+projectId}
              open={dialogOpen}
              handleClose={() => setDialogOpen(false)}
              editMode={false}
            />
          )}
          {editDialogOpen && projectId && (
            <AddAlarmProfileForm
              projectId={+projectId}
              open={editDialogOpen}
              handleClose={() => setEditDialogOpen(false)}
              editMode={true}
              editableProfile={selectedProfile}
            />
          )}
        </>
      ) : (
   /*Mobile view end ****************************************************************/

        <div className={styles["project-alarms-container"]}>
          <div className={styles["project-alarms-container-view"]}>
            {/*  <div>
          <Typography variant="h5" component="h1" mt={2} mb={2} ml={2}>
            {t("projectview_page.alarms.alarm_profiles")}
          </Typography>
        </div> */}
            <div>
              {/* <Button
            className={styles["project-details-cancel-button"]}
            variant="contained"
            sx={{ mr: 2, mb: 2, flex: 0 }}
            disabled={isLoading || isLoadingDevices}
            startIcon={<PlusIcon />}
          >
            {t("projectview_page.alarms.add_alarm_profile")}
          </Button> */}
            </div>
            <div className={styles["project-alarms-content"]}>
              {!isLoading && !error && data && (
                <div className={styles["project-alarms-alarmprofiles"]}>
                  <TableContainer
                    sx={{
                      "&.MuiPaper-root": {
                        border: "1px solid var(--colorDivider)",
                        borderRadius: "12px",
                        boxShadow: "none",
                        textAlign: "left"
                      }
                    }}
                    component={Paper}
                  >
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow hover onClick={() => setDialogOpen(true)} sx={{ border: 0 }}>
                          <TableCell
                            component="th"
                            scope="row"
                            className={styles["project-alarms-alarmprofiles-row"]}
                            sx={{
                              fontSize: "var(--basicFontSize)"
                            }}
                          >
                            + {t("projectview_page.alarms.add_alarm_profile")}
                          </TableCell>
                        </TableRow>
                        {data.map((row: any) => (
                          <TableRow
                            hover
                            key={row.id}
                            onClick={() => {
                              setSelectedProfile(row);
                            }}
                            className={styles["project-alarms-alarmprofiles-row"]}
                            selected={selectedProfile != null && selectedProfile.id === row.id}
                            classes={{
                              selected: styles["project-alarms-alarmprofiles-selectedrow"]
                            }}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell
                              sx={{
                                fontSize: "var(--basicFontSize)"
                              }}
                              component="th"
                              scope="row"
                            >
                              {row.name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              <div className={styles["project-alarms-devices"]}>
                <div className={styles["project-alarms-selectedprofile"]}>
                  <div className={styles["project-alarms-selectedprofile-text"]}>
                    {selectedProfile != null
                      ? `${selectedProfile.name}: ${t(
                          `projectview_page.alarms.${selectedProfile.sensorTypeName}`
                        )} ${
                          selectedProfile.greaterThanValue
                            ? " > " + selectedProfile.greaterThanValue
                            : " < " + selectedProfile.lessThanValue
                        }  ${selectedProfile.unit}`
                      : t("projectview_page.alarms.select_profile_text")}
                  </div>
                  <Button
                    className={styles["project-alarms-edit-button"]}
                    variant="contained"
                    disabled={
                      isLoading ||
                      isLoadingDevices ||
                      selectedProfile === null ||
                      selectedProfile === undefined
                    }
                    onClick={() => setEditDialogOpen(true)}
                  >
                    {t("projectview_page.alarms.edit_button")}
                  </Button>
                </div>
                {!isLoadingDevices && !deviceDataError && deviceData && (
                  selectedProfile !== null ?
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "deviceTypeName", sort: "asc" }],
                      },
                    }}
                    sx={{
                      fontSize: "var(--basicFontSize)",
                      backgroundColor: "white",
                      borderRadius: "12px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      "& .Mui-checked svg": {
                        width: "var(--checkBoxSize)",
                        height: "var(--checkBoxSize)",
                        backgroundColor: "transparent",
                        borderRadius: 2,
                        color: "orange"
                      },
                      "& .MuiCheckbox-root svg": {
                        width: "var(--checkBoxSize)",
                        height: "var(--checkBoxSize)",
                        backgroundColor: "transparent",
                        borderRadius: 2
                      },
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "none"
                      }
                    }}
                    rows={deviceData}
                    columns={columns}
                    selectionModel={deviceSelectionModel}
                    autoPageSize
                    checkboxSelection
                    onSelectionModelChange={setSelectionChange}
                    isRowSelectable={(params: GridRowParams) =>
                      selectedProfile !== null &&
                      selectedProfile !== undefined &&
                      !isLoadingDevices &&
                      !isLoadingSensorAlarmDelete &&
                      !isLoadingSensorAlarmPost
                    }
                    localeText = {{
                      toolbarDensity: t("measurement_list.data_grid_toolbar.toolbar_density"),
                      toolbarDensityLabel: t("measurement_list.data_grid_toolbar.toolbar_density_label"),
                      toolbarDensityCompact: t("measurement_list.data_grid_toolbar.toolbar_density_compact"),
                      toolbarDensityStandard: t("measurement_list.data_grid_toolbar.toolbar_density_standard"),
                      toolbarDensityComfortable: t("measurement_list.data_grid_toolbar.toolbar_density_comfortable"),
                      toolbarColumns: t("measurement_list.data_grid_toolbar.toolbar_columns"),
                      toolbarFilters: t("measurement_list.data_grid_toolbar.toolbar_filters"),
                      toolbarExport: t("measurement_list.data_grid_toolbar.toolbar_export"),
                      toolbarExportLabel: t("measurement_list.data_grid_toolbar.toolbar_export_label"),
                      toolbarExportCSV: t("measurement_list.data_grid_toolbar.toolbar_export_csv"),
                      toolbarExportPrint: t("measurement_list.data_grid_toolbar.toolbar_export_print"),
                      columnsPanelTextFieldLabel: t("measurement_list.data_grid_toolbar.columns_panel_text_field_label"),
                      columnsPanelTextFieldPlaceholder: t("measurement_list.data_grid_toolbar.columns_panel_text_field_placeholder"),
                      columnsPanelShowAllButton: t("measurement_list.data_grid_toolbar.columns_panel_show_all_button"),
                      columnsPanelHideAllButton: t("measurement_list.data_grid_toolbar.columns_panel_hide_all_button"),
                      filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
                      filterPanelOperators: t("measurement_list.data_grid_toolbar.filter_panel_operators"),
                      filterPanelInputLabel: t("measurement_list.data_grid_toolbar.filter_panel_Input_label"),
                      filterPanelInputPlaceholder: t("measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"),
                      filterOperatorContains: t("measurement_list.data_grid_toolbar.filter_operator_contains"),
                      filterOperatorEquals: t("measurement_list.data_grid_toolbar.filter_operator_equals"),
                      filterOperatorStartsWith: t("measurement_list.data_grid_toolbar.filter_operator_starts_with"),
                      filterOperatorEndsWith: t("measurement_list.data_grid_toolbar.filter_operator_ends_with"),
                      filterOperatorIsEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_empty"),
                      filterOperatorIsNotEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_not_empty"),
                      filterOperatorIsAnyOf: t("measurement_list.data_grid_toolbar.filter_operator_is_any_of"),
                      filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
                      filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
                      filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
                      columnMenuShowColumns: t("measurement_list.data_grid_column_menu.show_columns"),
                      columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
                      columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
                      columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
                      columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
                      columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc")
                    }}
                  />
                  :
                  <div></div>
                )}
              </div>
            </div>
          </div>

          {dialogOpen && projectId && (
            <AddAlarmProfileForm
              projectId={+projectId}
              open={dialogOpen}
              handleClose={() => setDialogOpen(false)}
              editMode={false}
            />
          )}
          {editDialogOpen && projectId && (
            <AddAlarmProfileForm
              projectId={+projectId}
              open={editDialogOpen}
              handleClose={() => setEditDialogOpen(false)}
              editMode={true}
              editableProfile={selectedProfile}
            />
          )}
        </div>
      )}
    </>
  );
}
