import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProjectListRow.module.css";
import { ProjectObject, UserCompanyObject, UserProject } from "../../types";

//MaterialUI
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  TableBody,
  Box,
  Collapse,
  Table,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SettingsIcon from "@mui/icons-material/Settings";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RouterIcon from '@mui/icons-material/Router';

//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyProjects,
  getUserAdminCompanies,
  getUserFinancialManagerCompanies,
  getUserProjectManagerCompanies,
  toggleShowAllCompanyProjects
} from "../company/companySlice";
import { useGetCompanyBillingQuery, useGetCompanyQuery } from "../company/companyAPI";
import { useGetUserProjectsQuery } from "./projectApi";
import { RootState } from "../../app/store";

//Components
import { SingleSwitch } from "../UI/CustomSwitch";
import ProjectListRow from "./ProjectListRow";
import AllCompanyProjectsListRow from "./AllCompanyProjectsListRow";

interface companyRowInterface {
  companyId: number;
  companyName: string;
  open: boolean;
  noContent: boolean;
  onClick: () => void;
}

const CompanyListRow = (props: companyRowInterface) => {
  const navigateToLink = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const { data: projectData, error, isLoading } = useGetUserProjectsQuery();
  const { data } = useGetCompanyQuery(Number(props.companyId));

  const userAdminCompanies = useSelector(getUserAdminCompanies);
  const userFinancialManagerCompanies = useSelector(getUserFinancialManagerCompanies);
  const userProjectManagerCompanies = useSelector(getUserProjectManagerCompanies);

  const [isAdmin, setisAdmin] = useState(false);
  const [isProjectManager, setisProjectManager] = useState(false);
  const [isFinancialManager, setisFinancialManager] = useState(false);

  const [finalProjectData, setFinalProjectData] = useState<UserProject[]>([]);
  const [finalCompanyProjectsData, setFinalCompanyProjectsData] = useState<ProjectObject[]>([]);
  const [companyInfoRow, setcompanyInfoRow] = useState<string>("");

  const { data: billingData } = useGetCompanyBillingQuery(Number(props.companyId));

  const showAllCompanyProjects = useSelector((state: RootState) =>
    state.company.showAllCompanyProjects.find(x => x.companyId === props.companyId)
  );

  const allCompanyProjects = useSelector((state: RootState) =>
    state.company.companyProjects.find(x => x.companyId === props.companyId)
  );

  function checkAddressParameter(parameter: string | null, divider: string): string {
    if (parameter === null) return "";

    return parameter + divider;
  }

  //Get state for showing all projects (admins)
  useEffect(() => {
    if (!isAdmin && props.noContent) {
      return;
    }
    if (props.companyId === -1 || undefined) {
      return;
    }
    dispatch(toggleShowAllCompanyProjects(props.companyId));
    if (isAdmin) {
    dispatch(getCompanyProjects(props.companyId));
    }
    return () => {
      dispatch(toggleShowAllCompanyProjects(props.companyId));
    };
  }, [props.companyId, dispatch, props.noContent, isAdmin, isProjectManager]);

  useEffect(() => {
    //GET COMPANY DATA
    let companyInfo = "";
    if (data !== undefined) {
      companyInfo += "(" + data.businessId + ") - ";
      companyInfo += checkAddressParameter(data.address1, ", ");
      companyInfo += checkAddressParameter(data.address2, ", ");
      companyInfo += checkAddressParameter(data.postalCode, ", ");
      companyInfo += checkAddressParameter(data.city, ", ");
      companyInfo += checkAddressParameter(data.country, "");
    }
    setcompanyInfoRow(companyInfo);
  }, [data]);

  //Get user admin, projectManager and financialManager companies
  useEffect(() => {
    const foundAdminCompanies: UserCompanyObject[] = userAdminCompanies.filter(x => x.companyId === props.companyId);
    const foundFinancialCompanies: UserCompanyObject[] = userFinancialManagerCompanies.filter(x => x.companyId === props.companyId);
    const foundProjectManagerCompanies: UserCompanyObject[] = userProjectManagerCompanies.filter(x => x.companyId === props.companyId);
    setisAdmin(foundAdminCompanies.length > 0);
    setisFinancialManager(foundFinancialCompanies.length > 0);
    setisProjectManager(foundProjectManagerCompanies.length > 0);
  }, [userAdminCompanies, userFinancialManagerCompanies, userProjectManagerCompanies, props.companyId]);

  //Check which projects to show, users projects or all company projects
  useEffect(() => {
    if (!isAdmin && props.noContent) {
      return;
    }
    if (showAllCompanyProjects?.showAllProjects && allCompanyProjects !== undefined) {
      const alphabeticalCompanyProjects = [...allCompanyProjects.companyProjects].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
      setFinalCompanyProjectsData(alphabeticalCompanyProjects);
    } else if (projectData) {
      const companyProjects = projectData.filter(project => project.companyId === props.companyId);
      const alphabeticalProjects = [...companyProjects].sort((a, b) => (a.projectName.toLowerCase() > b.projectName.toLowerCase()) ? 1 : -1);
      setFinalProjectData(alphabeticalProjects);
    }else {
      return;
    }
  }, [projectData, showAllCompanyProjects, props.companyId, allCompanyProjects, props.noContent, isAdmin]);

  const RowArrow = () => {
    if (props.noContent && finalCompanyProjectsData.length === 0) {
      return (
        <RemoveIcon
          sx={{
            height: "var(--iconSize)",
            width: "var(--iconSize)"
          }}
        />
      );
    } else if (open) {
      return (
        <KeyboardArrowUpIcon
          sx={{
            height: "var(--iconSize)",
            width: "var(--iconSize)"
          }}
        />
      );
    } else {
      return (
        <KeyboardArrowDownIcon
          sx={{
            height: "var(--iconSize)",
            width: "var(--iconSize)"
          }}
        />
      );
    }
  };

  const navigateToCompany = () => {
    navigateToLink("/companies/" + props.companyId);
  };

  return (
    <>
      {error && <div>ERROR</div>}
      {isLoading && <div>Loading...</div>}
      <Table aria-label="collapsible table" key={"table-collapse"}>
      <TableBody>
        <TableRow>
          <TableCell
            component="th"
            scope="row"
            className={styles["projects-table-company-cell"]}
            onClick={() => setOpen(prev => !prev)}
          >
            <IconButton 
              aria-label="expand row" 
              size="small"
              disabled={finalCompanyProjectsData.length === 0 && finalProjectData.length === 0}
            >
              <RowArrow />
            </IconButton>
            <Typography
              sx={{ fontSize: "var(--navButtonFontSize)" }}
              variant="body1"
              component="span"
              style={{ fontWeight: "bold" }}
            >
              {props.companyName + "  "}
            </Typography>
            <Typography
              sx={{ fontSize: "var(--navButtonFontSize)" }}
              variant="body1"
              component="span"
              style={{ marginLeft: "5px" }}
            >
              {companyInfoRow}
            </Typography>
          </TableCell>
          <TableCell sx={{ verticalAlign: "middle !important" }}>
            {isAdmin && (
              <SingleSwitch
                checked={!!showAllCompanyProjects?.showAllProjects}
                onChange={() => dispatch(toggleShowAllCompanyProjects(props.companyId))}
              />
            )}
          </TableCell>
          {/* HIDE DEVICE MANAGEMENT BUTTON FOR NOW */}
          {/*
          <TableCell className={styles["projects-table-devices-cell"]} size="small" align="right">
            {!isAdmin && !isProjectManager ? (
              <IconButton
              size="small"
              disabled
              >
                <RouterIcon 
                  sx={{
                    //height: "var(--iconSize)",
                    //width: "var(--iconSize)",
                    marginRight: "2px",
                    color: "var(--colorLightText)"
                  }}
                />
              </IconButton>
            )
            :
            <Tooltip title={t("tooltip.device_management") || ""}>
              <IconButton 
                size="small"
                onClick={() => {
                  navigateToLink("/devicemanagement/company/" + props.companyId)
                }}
              >
                  <RouterIcon
                    sx={{
                     //height: "var(--iconSize)",
                      //width: "var(--iconSize)",
                      marginRight: "2px",
                      color: "var(--colorOrange)"
                    }}
                  />
              </IconButton>
            </Tooltip>
            }
          </TableCell> */}
          <TableCell className={styles["projects-table-edit-cell"]} size="small" align="right">
            {!isAdmin && !isFinancialManager ? (
              <IconButton size="small" disabled>
                <SettingsIcon
                  sx={{
                    height: "var(--iconSize)",
                    width: "var(--iconSize)",
                    marginRight: "5px",
                    color:
                      isAdmin || isFinancialManager
                        ? "var(--colorOrange)"
                        : "var(--colorLightText)",
                    display: props.companyId === -1 ? "none" : ""
                  }}
                />
              </IconButton>
            ) : (
              <Tooltip title={t("tooltip.settings") || ""}>
                <IconButton size="small" onClick={() => navigateToCompany()} disabled={false}>
                  <SettingsIcon
                    sx={{
                      height: "var(--iconSize)",
                      width: "var(--iconSize)",
                      marginRight: "5px",
                      color:
                        isAdmin || isFinancialManager
                          ? "var(--colorOrange)"
                          : "var(--colorLightText)",
                      display: props.companyId === -1 ? "none" : ""
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {billingData?.emailInvoicingAddress === null && billingData.finvoiceAddress === null && 
            <Tooltip title={t("tooltip.missing_billing_info") || ""}>
              <PriorityHighIcon sx={{verticalAlign: "middle", color: "red"}} fontSize={"small"}/>
             </Tooltip>
            }  
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table>
                  {finalProjectData &&
                    !showAllCompanyProjects?.showAllProjects &&
                    finalProjectData.map(project => (
                      <ProjectListRow row={project} key={"projectlistrow" + project.projectId} />
                    ))}
                  {finalCompanyProjectsData &&
                    showAllCompanyProjects?.showAllProjects &&
                    finalCompanyProjectsData.map(project => (
                      <AllCompanyProjectsListRow
                        row={project}
                        key={"allcompanyproijectslistrow" + project.id}
                      />
                    ))}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
      </Table>
    </>
  );
};

export default CompanyListRow;
