import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DeviceSettingsAvailablePointDevicesProjectObject,
  MeasurementPointObject
} from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMeasurementPointsAndPlans,
  getDevice,
  linkMeasurementPointDeviceFromDeviceSettings
} from "./deviceSlice";
import PlanView from "../planview/PlanView";
import { useTranslation } from "react-i18next";
import React from "react";
import { InitialMeasurementPoint } from "../../../../measurements/measurementInitialData";
import { RootState } from "../../../../../app/store";
import { useGetDeviceTypesQuery } from "../../../../devices/deviceTypeAPI";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ConfirmDialog from "../../../../UI/ConfirmDialog";
interface RowProps {
  project: DeviceSettingsAvailablePointDevicesProjectObject;
  handleLinkDialogClose: any;
}

const DeviceSettingsLinkTableRow = (props: RowProps) => {
  const { project, handleLinkDialogClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const device = useSelector((state: RootState) => state.device.device);
  const { data: deviceTypes } = useGetDeviceTypesQuery();

  const [open, setOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [mpId, setMpId] = useState<number>(-1);
  const [mpdId, setMpdId] = useState<number>(-1);
  const [depth, setDepth] = useState<string>("");
  const [planViewMeasurementPoints, setPlanViewMeasurementPoints] = useState<Array<MeasurementPointObject>>([]);

  useEffect(() => {
    let newPointsArray: Array<MeasurementPointObject> = [];
    project.plans.forEach(plan =>
      plan.measurementPoints.forEach(mp => {
        let newMeasurementPoint: MeasurementPointObject = InitialMeasurementPoint();

        newMeasurementPoint.id = plan.id.toString();
        newMeasurementPoint.name = mp.name;
        newMeasurementPoint.x = mp.positionX;
        newMeasurementPoint.y = mp.positionY;
        newMeasurementPoint.selected = false;
        newMeasurementPoint.isLocated = mp.positionX === 0 && mp.positionY === 0 ? false : true;
        newMeasurementPoint.annotation = "";

        newPointsArray.push(newMeasurementPoint);
      })
    );
    setPlanViewMeasurementPoints(newPointsArray);
  }, [project.plans]);

  const handleLink = async (
    measurementPointId: number,
    measurementPointDeviceId: number,
    plannedDepth: string
  ) => {
    if (deviceTypes === undefined) {
      return;
    }
    const deviceType = deviceTypes.find(
      deviceType => Number(deviceType.id) === device.deviceTypeId
    );

    const depths: Array<{ typeName: string; depth: string }> = [];
    deviceType?.sensorTypes.map(sensor =>
      depths.push({ typeName: sensor.name, depth: plannedDepth })
    );

    try {
      await dispatch(
        linkMeasurementPointDeviceFromDeviceSettings({
          measurementPointId: measurementPointId.toString(),
          deviceId: measurementPointDeviceId.toString(),
          deviceSerial: device.serial,
          depthDefinition: deviceType!.depthDefinition,
          depth: plannedDepth,
          depths: depths
        })
      );
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(getDevice(device.serial));
    }
    handleLinkDialogClose();
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="cell" sx={{verticalAlign: "middle !important"}}>
          <Typography sx={{ fontWeight: "bold" }}>{project.name}</Typography>
        </TableCell>
        <TableCell align={!smallScreen ? "right" : "left"}></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            {project.plans.map(plan => (
              <Box key={"device-settings-link-table-box-component " + plan.id} sx={{ margin: 0 }}>
                <Typography variant="body1" component="div">
                  {t("device_management.plan")}:
                </Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: smallScreen ? "12px" : "16px" }}>
                  {plan?.name}
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: !smallScreen ? "400px" : "200px",
                          height: "200px",
                          borderBottom: "none"
                        }}
                      >
                        <PlanView
                          editContent={false}
                          zoomLevel={0}
                          stageHidded={false}
                          projectPlanId={plan.id.toString()}
                          resizeCanvas={2}
                          contentEditable={false}
                          measurementPoints={planViewMeasurementPoints.filter(
                            mp => mp.id === plan.id.toString()
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ height: "50px" }}>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography>{t("device_management.measurement_points")}:</Typography>
                      </TableCell>
                    </TableRow>
                    {plan.measurementPoints.map(mp => (
                      <React.Fragment key={"device-settings-link-table-fragment " + mp.id + plan.id}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                marginBottom: "8px",
                                fontSize: smallScreen ? "12px" : "16px"
                              }}
                            >
                              {mp.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {!smallScreen &&
                          mp.measurementPointDevices.map(mpd => (
                            <TableRow
                              key={"device-settings-link-table-meas-point-device-row " + mpd.id}
                            >
                              <TableCell>
                                <Typography sx={{ marginBottom: "8px", fontSize: "16px" }}>
                                  {t("device_management.planned_depth")}: {mpd.plannedDepth} mm
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {project.maxLinkedDevices === 0 ? (
                                  <Tooltip title={t("measurement_list.licence_warning") || ""}>
                                    <PriorityHighIcon
                                      sx={{ verticalAlign: "middle", color: "red" }}
                                      fontSize={"small"}
                                    />
                                  </Tooltip>
                                ) : project.activeLinkedDevices >= project.maxLinkedDevices ? (
                                  <Tooltip title={t("device_management.licence_full") || ""}>
                                    <PriorityHighIcon
                                      sx={{ verticalAlign: "middle", color: "red" }}
                                      fontSize={"small"}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  variant="contained"
                                  size="small"
                                  disabled={
                                    project.maxLinkedDevices === 0 ||
                                    project.activeLinkedDevices >= project.maxLinkedDevices
                                  }
                                  key={
                                    "device-settings-link-table-meas-point-link-button " + mpd.id
                                  }
                                  sx={{ marginBottom: "8px" }}
                                  //disabled={mp === undefined}
                                  onClick={() => {
                                    setMpId(mp.id);
                                    setMpdId(mpd.id);
                                    setDepth(mpd.plannedDepth.toString());
                                    setConfirmDialogOpen(true);
                                  }}
                                >
                                  {t("device_management.link")}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        {smallScreen &&
                          mp.measurementPointDevices.map(mpd => (
                            <React.Fragment key={
                              "device-settings-link-table-meas-point-device-row-mobile " + mpd.id
                            }>
                            <TableRow>
                              <TableCell>
                                <Typography sx={{ marginBottom: "8px", fontSize: "12px" }}>
                                  {t("device_management.planned_depth")}: {mpd.plannedDepth} mm
                                </Typography>
                              </TableCell>
                              </TableRow>
                              <TableRow>
                              <TableCell>
                                <Button
                                  key={
                                    "device-settings-link-table-meas-point-link-button-mobile " +
                                    mpd.id
                                  }
                                  disabled={
                                    project.maxLinkedDevices === 0 ||
                                    project.activeLinkedDevices >= project.maxLinkedDevices
                                  }
                                  variant="contained"
                                  size="small"
                                  sx={{ marginBottom: "10px" }}
                                  //disabled={mp=== undefined}
                                  onClick={() => {
                                    setMpId(mp.id);
                                    setMpdId(mpd.id);
                                    setDepth(mpd.plannedDepth.toString());
                                    setConfirmDialogOpen(true);
                                  }}
                                >
                                  {t("device_management.link")}
                                </Button>
                              </TableCell>
                            </TableRow>
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmDialog 
        key={"device-settings-link-dialog"}
        open={confirmDialogOpen}
        value={""}
        setOpen={setConfirmDialogOpen}
        onConfirm={() => {
          handleLink(mpId, mpdId, depth);
          dispatch(clearMeasurementPointsAndPlans());
        }}
        content={t("device_management.link_confirm")}
        />
    </>
  );
};

export default DeviceSettingsLinkTableRow;
