import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TableContainer, Table, Paper, TableBody } from "@mui/material";

//Redux
import { clearSelectedProject } from "./projectSlice";
import { useGetTransferedProjectsQuery, useGetUserProjectsQuery } from "./projectApi";
import { useGetUserCompaniesQuery } from "../company/companyAPI";
import { UserProject, UserCompanyObject } from "../../types";

//Components
import styles from "./ProjectList.module.css";
import CompanyListRow from "./CompanyListRow";
import TransferListTable from "./ProjectListTransferProjectTable";

const CollapsibleTable = () => {
  const dispatch = useDispatch();

  const { data: userProjectData, error, isLoading } = useGetUserProjectsQuery();
  const { data: transferProjectsData, error: error2, isLoading: isLoading2 } = useGetTransferedProjectsQuery();

  const [finalData, setFinalData] = useState<UserProject[]>();
  const [emptyCompanies, setEmptyCompanies] = useState<UserCompanyObject[]>([]);

  const userCompanies = useGetUserCompaniesQuery();

  useEffect(() => {
    dispatch(clearSelectedProject());
  }, [userProjectData, dispatch]);

  //Find users companies from users projects
  useEffect(() => {
    const finalCompanyData: UserProject[] = [];
    userProjectData?.forEach(company => {
      if (!finalCompanyData.map(company => company.companyId).includes(company.companyId)) {
        finalCompanyData.push(company);
      }
    });
    setFinalData(finalCompanyData);
  }, [userProjectData]);

  //Find users companies that don't have any projects
  useEffect(() => {
    const sortedEmptyCompanies: UserCompanyObject[] = [];
    if (userCompanies.data !== undefined) {
      userCompanies.data.forEach(company => {
      if (!finalData?.map(company => company.companyId).includes(company.companyId)) {
        sortedEmptyCompanies.push(company);
      }
    })
    setEmptyCompanies(sortedEmptyCompanies);
    }
  }, [userCompanies, finalData]);

  return (
    <div className={styles["projects-table-container"]}>
      <TableContainer component={Paper}>
        {isLoading && <div>Loading...</div>}
        {error && <div>ERROR</div>}
          {finalData &&
            finalData.map(company => (
              <CompanyListRow
                key={"companyrow_" + company.companyName + company.id}
                companyId={company.companyId}
                companyName={company.companyName}
                open={false}
                noContent={false}
                onClick={() => {}}
              />
            ))}
          {emptyCompanies &&
            emptyCompanies.map(company => (
              <CompanyListRow
                key={"companyrow_" + company.companyName + company.id}
                companyId={company.companyId}
                companyName={company.companyName}
                open={false}
                noContent={true}
                onClick={() => {}}
              />
            ))}
        {isLoading2 && <div>Loading...</div>}
        {error2 && <div>ERROR</div>}
        {transferProjectsData && (
          <div style={{ display: transferProjectsData.projectTransfers.length === 0 ? "none" : "" }}>
            <Table aria-label="collapsible table">
              <TableBody>
                <TransferListTable transferObject={transferProjectsData} />
              </TableBody>
            </Table>
          </div>
        )}
      </TableContainer>
    </div>
  );
}

export default CollapsibleTable;