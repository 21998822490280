//KONVA
import { Group, Arrow } from "react-konva";
import { isActiveDevices } from "../../../../utils/commonFunctions";
import { DeviceObject } from "./../../../../../types";

interface ArrowProperties {
  editContent: boolean;
  selected: boolean;
  devices: Array<DeviceObject>;
  arrowClick: (event: any) => void;
  onTap: (event: any) => void;
}

export default function PlanViewMeasurementPointArrow(props: ArrowProperties) {
  /**************************************************************************** */
  //STATES, NO NEED

  /**************************************************************************** */
  const onMouseAction = (e: any) => {
    //DO CURSOR
    const stage = e.target.getStage();
    if (stage) {
      if (e.type === "mouseenter") {
        stage.container().style.cursor = props.editContent ? "move" : "pointer";
      } else {
        stage.container().style.cursor = "default";
      }
    }
  };

  const isActiveDevicesStyle = isActiveDevices(props.devices)

  return (
    <Group
      onTap={props.onTap}
      onMouseEnter={onMouseAction}
      onMouseLeave={onMouseAction}
      onClick={(event: any) => {
        if (!props.editContent) props.arrowClick(event);
      }}
    >
      <Arrow
        x={0}
        y={-20}
        stroke={isActiveDevicesStyle ? "#eb941c" : "#000"}
        fill={isActiveDevicesStyle ? "#eb941c" : "#000"}
        points={[0, 0, 0, 20]}
        tension={0}
        pointerWidth={8}
        pointerLength={10}
        strokeWidth={1}
        lineCap="butt"
        lineJoin="round"
        shadowColor="#000"
        shadowBlur={3}
        shadowOffset={{ x: 1, y: 1 }}
        shadowOpacity={0.4}
      />
      {/*
      <Line
        x={0}
        y={-20}
        stroke={isActiveDevicesStyle ? "#eb941c" : "#000"}
        points={[0, 0, 0, 0, 0, 10]}
        tension={0}
        strokeWidth={1}
        lineCap="butt"
        lineJoin="round"
        shadowColor="#000"
        shadowBlur={3}
        shadowOffset={{ x: 1, y: 1 }}
        shadowOpacity={0.4}
      />
      */}
      {/*
      <Wedge
        x={0}
        y={0}
        stroke={isActiveDevicesStyle ? "#eb941c" : "#000"}
        fill={isActiveDevicesStyle ? "#eb941c" : "#000"}
        strokeWidth={1}
        radius={10}
        rotation={-115}
        angle={50}
        shadowColor="#000"
        shadowBlur={3}
        shadowOffset={{ x: 1, y: 1 }}
        shadowOpacity={0.4}
      ></Wedge>
    */}
      {/* <Circle x={-14.5} y={10} fill="red" radius={4} />
      <Circle x={-14.5} y={10} radius={8} stroke="#eb941c" strokeWidth={2} /> */}
    </Group>
  );
}
