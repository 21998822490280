import ProjectList from "../features/project/ProjectList";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProjectForm from "../features/project/AddProjectForm";
import styles from "./Projects.module.css";
import { getCompanyUserRights } from "../features/company/companySlice";
import { getUserProjectManagerAndAdminCompanies } from "../features/company/companySlice";

export default function Projects() {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const userCompanies = useSelector(getUserProjectManagerAndAdminCompanies);

  useEffect(() => {
    dispatch(getCompanyUserRights());
  }, [dispatch]);

  return (
    <div className={styles["projects-page-container"]}>
      <div className={styles["projects-buttons-container"]}>
        <Button
          className={styles["projects-page-add-project-button"]}
          variant="contained"
          onClick={() => setDialogOpen(true)}
          disabled={!(userCompanies.length > 0)}
        >
          {t("projects_page.add_project_button")}
        </Button>
      </div>
      <Typography sx={{ fontSize: "var(--largeHeaderFontSize)" }} component="h1" mt={2} mb={2}>
        {t("projects_page.projectlist_header")}
      </Typography>
      <ProjectList />
      {dialogOpen && <AddProjectForm open={dialogOpen} handleClose={() => setDialogOpen(false)} />}
    </div>
  );
}
