import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button, useMediaQuery, MenuItem } from "@mui/material";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { setLogoutUser } from "../authentication/authSlice";

import styles from "./PageHeader.module.css";

import { ProjectObject } from "../../types";

//COMPONENTS
import LanguageSelection from "./LanguageSelection";
import MobileMenu from "./MobileMenu";
import logoPNG from "./../../Resources/wiiste_logo.png";
import InstructionsLinkButton from "./InstructionsLinkButton";

export default function PageHeader(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //const loggedIn = useSelector((state: RootState) => state.authentication.loggedIn);
  const user = useSelector((state: RootState) => state.authentication.user);
  //const loggedIn = useSelector((state: RootState) => state.authentication.loggedIn);

  const { keycloak } = useKeycloak();

  const params = useLocation();
   
  const selectedProject = useSelector((state: RootState) => state.selectedProject);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const logout = () => {
    var logoutOptions = {redirectUri : (process.env.NODE_ENV === 'production'
    ? 'https://' + window.location.hostname
    : process.env.REACT_APP_REDIRECT_URL)};
    keycloak.logout(logoutOptions);
    dispatch(setLogoutUser());
  };

  const ProjectTexts = (props: { project: ProjectObject }) => {
    const project = props.project;

    const projectLine = project.name;
    const addressLine =
      project.address1 +
      (project.address2 !== undefined && project.address2 !== null && project.address2 !== ""
        ? ", " + project.address2
        : "") +
      ", " +
      project.postalCode +
      " " +
      project.city;

    return (
      <div className={styles["header-text-project"]}>
        <p>{projectLine}</p>
        <p>{addressLine}</p>
      </div>
    );
  };

  const buttonStyle = {
    color: "black",
    fontFamily: "Fellix-Regular",
    fontWeight: "bold",
    fontSize: "var(--navButtonFontSize)",
    margin: 0
  };

  return (
    <div className={styles["app-header"]}>
      <div className={styles["logo-container"]}>
        <Link to="/">
          <img src={logoPNG} alt="Wiiste logo" width="100%" height="100%" />
        </Link>
      </div>
      <div className={styles["header-text-container"]}>
        {selectedProject.project.name !== undefined && selectedProject.project.name !== "" ? (
          <ProjectTexts project={selectedProject.project} />
        ) : (
          <></>
        )}
      </div>
      <InstructionsLinkButton />
      <div className={styles["header-language-container"]}>
        <LanguageSelection saveLanguage={true} display={true} />
      </div>

      {/*Mobile view************************************************************************** */}

      {smallScreen ? (
        <>
          <div className={styles["header-user-login"]}>
            <label username-exists={user?.userName ? "true" : "false"}>
              {user && user.userName ? user.userName : ""}
            </label>
          </div>
          <MobileMenu icon="menu" menuText={null} id="header-mobile-menu">
            {params.pathname !== "/projects" && 
            <MenuItem>
              <Button component={Link} to="/" sx={buttonStyle}>
                {t("header.projects")}
              </Button>
            </MenuItem>
            }
            <MenuItem>
              {keycloak.authenticated ? (
                <Button
                  className={styles["header-logout-button"]}
                  onClick={() => logout()}
                  sx={buttonStyle}
                >
                  {t("header.logout_button")}
                </Button>
              ) : (
                <Button
                  className={styles["header-login-button"]}
                  onClick={() => keycloak.login({locale: t("keycloak_login.language")})}
                  sx={buttonStyle}
                >
                  {t("header.login_button")}
                </Button>
              )}
            </MenuItem>
          </MobileMenu>

          {/*Mobile view end************************************************************************** */}
        </>
      ) : (
        <>
          <div className={styles["home-container"]}>
            {params.pathname !== "/projects" && 
            <Button component={Link} to="/" sx={buttonStyle}>
              {t("header.projects")}
            </Button>
            }
          </div>
          <div className={styles["header-user-login"]}>
            <label username-exists={user?.userName ? "true" : "false"}>
              {user && user.userName ? user.userName : ""}
            </label>
            {keycloak.authenticated ? (
              <Button
                className={styles["header-logout-button"]}
                onClick={() => logout()}
                sx={buttonStyle}
              >
                {t("header.logout_button")}
              </Button>
            ) : (
              <Button
                className={styles["header-login-button"]}
                onClick={() => keycloak.login({locale: t("keycloak_login.language")})}
                sx={buttonStyle}
              >
                {t("header.login_button")}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
