import { useState, useEffect } from "react";

//KONVA
import { Group, Text, Rect } from "react-konva";

//TYPES
import { DeviceObject, SensorUnitObj } from "./../../../../../types";

//common functions
import { formatNumber, getAbsoluteHumidityText, isActiveDevices, getMcText, sensorTextFunction } from "./../../../../utils/commonFunctions";

interface SensorsTextProperties {
  devices: Array<DeviceObject>;
  editContent: boolean;
  selected: boolean;
  headertext: string;
  onDataClick: (event: any) => void;
  viewSensorTexts: boolean;
  showRHorAH: boolean;
  temperatureCompensation: boolean;
  onTap: (event: any) => void;
}

interface TextInterface {
  short: string;
  long: string;
}

export default function PlanViewSensorTexts(props: SensorsTextProperties) {
  //STATES
  const [boxHeight, setboxHeight] = useState(20);
  const [boxWidth, setboxWidth] = useState(150);
  const [boxYPos, setBoxYPos] = useState(0);
  const [boxXPos, setBoxXPos] = useState(0);

  const [deviceTexts, setdeviceTexts] = useState<Array<TextInterface>>(new Array<TextInterface>());

  //SET TEXTS
  useEffect(() => {
    let newTexts = Array<TextInterface>();

    if (props.viewSensorTexts === false) {
      setdeviceTexts(newTexts);
      return;
    }

    props.devices.forEach(device => {
      let longText = "";
      const temperature = device.latestData?.Temperature?.value
      const temperatureAmbient = device.latestData?.TemperatureAmbient?.value;
      const relativeHumidity = device.latestData?.RelativeHumidity?.value
      const relativeHumidityAmbient = device.latestData?.RelativeHumidityAmbient?.value;
      const moistureContentA = device.latestData?.["MoistureContent A"]?.value
      const moistureContentB = device.latestData?.["MoistureContent B"]?.value

      const sortedSensorUnits = [...device.sensorUnits].sort((a, b) =>
        a.unit === "Pa" ? -2 : a.unit ==="[0...4]" ? 2 : a.ambient ? 1 : b.ambient ? -1 : 0
      );

      if (device.depthDefinition === 2) {
        //ambient measurements last
        longText = device.deviceType;

        sortedSensorUnits.forEach((sensorData, index) => {
          if (sensorData.unit !== "[0...4]") {
            longText +=
            `${index !== 0 ? ",  " : ""}` +
            getDepthText(sensorData) +
            (props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === true
            ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
            : props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === false
            ? getAbsoluteHumidityText(temperature, relativeHumidity)
            : props.temperatureCompensation && sensorData.typeName === "MoistureContent A"
            ? getMcText(moistureContentA, temperatureAmbient, " %MC (A) @20°C")
            : props.temperatureCompensation && sensorData.typeName === "MoistureContent B"
            ? getMcText(moistureContentB, temperatureAmbient, " %MC (B) @20°C")
            : sensorTextFunction(device.latestData[sensorData.typeName]?.value, 
              sensorData.typeName === "MoistureContent A"
            ? "%MC (A)"
            : sensorData.typeName === "MoistureContent B"
            ? "%MC (B)"
            : sensorData.unit)
            );
          }
        });
      } else if (device.depthDefinition === 1) {
        longText = device.deviceType + getDepthText(device.sensorUnits[0]) + " ";

        sortedSensorUnits.forEach((sensorData, index) => {
          if (sensorData.unit !== "[0...4]") {
            longText +=
            `${index !== 0 ? ",  " : ""}` +
            getDepthText(sensorData, true) +
            (props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === true
            ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
            : props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === false
            ? getAbsoluteHumidityText(temperature, relativeHumidity)
            : props.temperatureCompensation && sensorData.typeName === "MoistureContent A"
            ? getMcText(moistureContentA, temperatureAmbient, " %MC (A) @20°C")
            : props.temperatureCompensation && sensorData.typeName === "MoistureContent B"
            ? getMcText(moistureContentB, temperatureAmbient, " %MC (B) @20°C")
            : sensorTextFunction(device.latestData[sensorData.typeName]?.value, 
              sensorData.typeName === "MoistureContent A"
            ? "%MC (A)"
            : sensorData.typeName === "MoistureContent B"
            ? "%MC (B)"
            : sensorData.unit)
            )
          }
        });
      } else if (device.depthDefinition === 0) {
        if (device.deviceType === "SP1-WAN") {
          longText = device.deviceType + " @ *:  ";
          sortedSensorUnits.forEach((sensorData, index) => {
            if (sensorData.unit !== "[0...4]") {
              longText +=
              `${index !== 0 ? ",  " : ""}` +
              getDepthText(sensorData, true) +
              (props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === true
              ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
              : props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === false
              ? getAbsoluteHumidityText(temperature, relativeHumidity)
              : props.temperatureCompensation && sensorData.typeName === "MoistureContent A"
              ? getMcText(moistureContentA, temperatureAmbient, " %MC (A) @20°C")
              : props.temperatureCompensation && sensorData.typeName === "MoistureContent B"
              ? getMcText(moistureContentB, temperatureAmbient, " %MC (B) @20°C")
              : sensorTextFunction(device.latestData[sensorData.typeName]?.value,
                sensorData.typeName === "MoistureContent A"
              ? "%MC (A)"
              : sensorData.typeName === "MoistureContent B"
              ? "%MC (B)"
              : sensorData.unit)
                );
            }
          });
        } else {
          longText = device.deviceType + getDepthText(device.sensorUnits[0]) + " ";
          sortedSensorUnits.forEach((sensorData, index) => {
            if (sensorData.unit !== "[0...4]") {
              longText +=
              `${index !== 0 ? ",  " : ""}` +
              (props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === true
              ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
              : props.showRHorAH && sensorData.unit === "%RH" && sensorData.ambient === false
              ? getAbsoluteHumidityText(temperature, relativeHumidity)
              : props.temperatureCompensation && sensorData.typeName === "MoistureContent A"
              ? getMcText(moistureContentA, temperatureAmbient, " %MC (A) @20°C")
              : props.temperatureCompensation && sensorData.typeName === "MoistureContent B"
              ? getMcText(moistureContentB, temperatureAmbient, " %MC (B) @20°C")
              : sensorTextFunction(device.latestData[sensorData.typeName]?.value, 
                sensorData.typeName === "MoistureContent A"
              ? "%MC (A)"
              : sensorData.typeName === "MoistureContent B"
              ? "%MC (B)"
              : sensorData.unit)
                );
            }
          });  
        }
      } else {
        longText = "";
      }

      let shortText = device.deviceType;
      /*
      let shortText = ""
      sortedSensorUnits.forEach((sensorData, index) => {
        if (sensorData.unit !== "[0...4]") {
          shortText +=
          `${index !== 0 ? ", " : ""}` +
          (props.showRHorAH && sensorData.unit === "%RH"
          ? getAbsoluteHumidityText(temperature, relativeHumidity)
          : formatNumber(device.latestData[sensorData?.typeName]?.value, 2, sensorData?.unit));
        }
      });
      */
      newTexts.push({ short: shortText, long: longText });
    });

    //console.log(newTexts);

    setdeviceTexts(newTexts);
  }, [props.devices, props.viewSensorTexts, props.showRHorAH, props.temperatureCompensation]);

  //******************************************************************************************************* */
  //RESIZES SENSOR TEXT SIZE TO ADJUST IN PARENT
  useEffect(() => {
    const sensorAmount = deviceTexts.length;
    let boxheight = 20 + sensorAmount * 17;

    //DETERMINE BOX WIDTH
    let boxWidth = 120;
    if (props.selected) {
      boxWidth = 270;
    } else {
      boxWidth = 120;
    }

    //console.log("SETTLING TH_INGS");
    let fontWidth = 8;
    boxWidth =
      props.headertext.length * fontWidth > boxWidth
        ? props.headertext.length * fontWidth
        : boxWidth;

    //CHECK SENSOR DATA LENGTH
    let sensorFontWidth = 6.3;
    let sensorTextWidth = 0;
    if (props.selected) {
      let longestText = 0;

      deviceTexts.forEach(text => {
        if (longestText < text.long.length) {
          longestText = text.long.length;
        }
      });

      sensorTextWidth = longestText * sensorFontWidth;
    }

    boxWidth = boxWidth > sensorTextWidth ? boxWidth : sensorTextWidth;

    //SET ALL
    setBoxYPos(boxheight + 20);
    setBoxXPos(0);
    setboxHeight(boxheight);
    setboxWidth(boxWidth);
  }, [props.selected, props.headertext, deviceTexts]);

  //******************************************************************************************************* */
  const getDepthText = (sensor: SensorUnitObj | null | undefined, onlyAmbient = false) => {
    if (sensor === undefined || sensor === null) return "";
    if (sensor.ambient) {
      return " # ";
    } else if (onlyAmbient) {
      return " ";
    } else {
      return " @ " + sensor.depth + " mm: ";
    }
  };

  //******************************************************************************************************* */
  const onMouseAction = (e: any) => {
    //DO CURSOR
    const stage = e.target.getStage();
    if (stage) {
      if (e.type === "mouseenter") {
        stage.container().style.cursor = props.editContent ? "move" : "pointer";
      } else {
        stage.container().style.cursor = "default";
      }
    }
  };

  const isActiveDevicesStyle = isActiveDevices(props.devices)

  return (
    <Group
      y={boxYPos * -1}
      x={boxXPos * -1}
      onClick={(event: any) => {
        if (!props.editContent) props.onDataClick(event);
      }}
      onMouseEnter={onMouseAction}
      onMouseLeave={onMouseAction}
      onTap={props.onTap}
    >
      {/* ############################################################# */}
      <Rect
        height={boxHeight}
        width={boxWidth}
        stroke={isActiveDevicesStyle ? "#eb941c" : "#000"}
        fill={props.selected && isActiveDevicesStyle ? "#ffdc91" : props.selected ? "#e6e6e6" : "white"}
        shadowColor="#000"
        shadowBlur={3}
        shadowOffset={{ x: 1, y: 1 }}
        shadowOpacity={0.4}
        cornerRadius={[5, 5, 5, 0]}
        strokeWidth={1}
      ></Rect>
      {/* ############################################################# */}
      <Text
        width={boxWidth - 10}
        x={5}
        height={20}
        fontSize={14}
        fontStyle="bold"
        fontFamily={"Fellix-Regular"}
        text={props.headertext}
        verticalAlign="middle"
        align="left"
        fill={isActiveDevicesStyle ? "#000" : "#8c95a4"}
      />
      {/* ############################################################# */}
      {deviceTexts.map((text, index) => {
        //console.log("TEXT = " + text.short + "[" + index + "]");
        return (
          <Text
            key={"sensortext_" + index}
            y={20 + index * 15}
            x={5}
            width={boxWidth - 10} //SMALL PADDING
            height={15}
            fontFamily={"Fellix-Regular"}
            fontSize={12}
            verticalAlign="middle"
            align="left"
            text={props.selected ? text.long : text.short}
            fill={isActiveDevicesStyle ? "#000" : "#8c95a4"}
          />
        );
      })}
      {/* ############################################################# */}
    </Group>
  );
}
