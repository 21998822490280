import { useState, useEffect } from "react";
import styles from "./WelcomePage.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import AlertComponent from "../features/UI/AlertComponent";
import axios from "axios";

export default function WelcomePage() {
  const { t } = useTranslation();
  const {keycloak} = useKeycloak();
 
  const [showAlert, setShowAlert] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const timestamp = new Date().getTime();
  const infoUri =  `https://${window.location.hostname}/files/Relia_info_box_${t("common.info_box_locale")}.html?${timestamp}`;

  useEffect(() => {
    const queuedAlert = window.sessionStorage.getItem("sessiontimeoutalert");
    if (queuedAlert) {
      setShowAlert(true);
    }
    return () => {
      window.sessionStorage.removeItem("sessiontimeoutalert");
    }
  }, []);

  useEffect(() => {
    axios.get(infoUri)
      .then(() => setShowInfo(true))
      .catch(e => setShowInfo(false))
  }, [infoUri]);

  return (
    <div className={styles["welcome-page-Container"]}>
      {showAlert && <AlertComponent severity="error" msg={t("alerts.session_timeout")}/>}
      <p className={styles["welcome-page-header"]}>{t("welcome_page.welcome_header")}</p>
      <p className={styles["welcome-page-message"]}>{t("welcome_page.before_login_message")}</p>
      <Link sx={{mt: "5%"}} variant="body1" component="button" onClick={() => keycloak.login({locale: t("keycloak_login.language")})}>
        {t("header.login_button")}
      </Link>
        <div> 
         {showInfo &&
          <iframe
           className={styles["infobox"]} 
           title="infoBox"
           src={infoUri} 
           style={{border: "none", height: "400px", width: "600px"}} 
          />
         }
      </div>
    </div>
  );
}
