import { Link, IconButton, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from "react-i18next";

const InstructionsLinkButton = () => {

    const { t } = useTranslation();

    const baseURI = `https://${window.location.hostname}`

    return (
        <Link href={`${baseURI}/files/Relia_user_guide_${t("common.instructions_link_locale")}.pdf`} target="_blank" rel="noopener noreferrer">
          <Tooltip title={t("tooltip.manual") || ""}>
            <IconButton sx={{color: "var(--colorOrange)"}} >
                <HelpIcon />
            </IconButton>
          </Tooltip>
        </Link>
    )
}

export default InstructionsLinkButton;