export const commonAttributes = {
  //apiUrl: "https://hauki.wiiste.com/api",
  //apiUrl: "http://localhost:5000/api",
  apiUrl: "/api",
  planStageInitX: 30,
  planStageInitY: 30,
  newPointX: -20,
  newPointY: 25,
  newPointDivX: 0,
  newPointDivY: 20,
  planTextScaleFactor: 0.15
};
