import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../app/store";

import moment from "moment";

import "./ReportPage.css";

import logo from "../Resources/wiiste_logo.png";

import { CircularProgress, Button, FormGroup, FormControlLabel, Typography, Tooltip, IconButton, Menu } from "@mui/material";
import { CustomSwitch } from "../features/UI/CustomSwitch";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import {
  MeasurementPointObject,
  SensorHistoryObject,
  HistoryDataObject,
  MeasurementPointHistoryObject,
  TimeRangeOptionsType
} from "../types";

import {
  fecthProjectBaseReport,
  fetchPlanHistoryData,
  ReportState
} from "../features/report/reportSlice";

import { toggleShowRHorAHSelection, toggleTemperatureCompensation } from "../features/measurements/measurementsSlice";

// COMPONENTS
import PlanView from "../features/project/projectview/components/planview/PlanView";
import ReportLastValues from "../features/report/ReportLastValues";

import LanguageSelection from "../features/UI/LanguageSelection";

import HistoryChart from "../features/project/projectview/components/history/HistoryChart";
import ReportCSVExport from "../features/report/ReportCSVExport";
import { Checkbox } from "@mui/material";
import styles from "./ReportPage.module.css";

export default function ReportPage() {
  const { projectId } = useParams();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const minDateRef = useRef(0);

  const storeReport = useSelector((state: RootState) => state.report);

  const [reportState, setreportState] = useState<ReportState | undefined>(undefined);

  const loading = useSelector((state: RootState) => state.report.loading);

  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH
  );

  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );

  // HISTORY  INFORMATION
  const now = moment();
  const [timeOption, setTimeOption] = useState<keyof TimeRangeOptionsType>("6months");
  const [timeRange, setTimeRange] = useState({
    from: moment(now).subtract(6, "months").toISOString(),
    to: now.toISOString()
  });
  const [calendarSelectionsMenuAnchorEl, setCalendarSelectionsMenuAnchorEl] =
    useState<Element | null>(null);

  const [showRhChart, setShowRhChart] = useState(true);
  const [showMcChart, setShowMcChart] = useState(false);
  const [showTempChart, setShowTempChart] = useState(true);
  const [showDpChart, setShowDpChart] = useState(false);
  const [showPressureChart, setShowPressureChart] = useState(false);
  const [showCo2Chart, setShowCo2Chart] = useState(false);
  const [showTvocChart, setShowTvocChart] = useState(false);
  const [showPM2_5Chart, setShowPM2_5Chart] = useState(false);
  const [showPM10Chart, setShowPM10Chart] = useState(false);
  const [hasMcData, setHasMcData] = useState(false);
  const [hasDpData, setHasDpData] = useState(false);
  const [hasPressureData, setHasPressureData] = useState(false);
  const [hasCo2Data, setHasCo2Data] = useState(false);
  const [hasTvocData, setHasTvocData] = useState(false);
  const [hasPM2_5Data, setHasPM2_5Data] = useState(false);
  const [hasPM10Data, setHasPM10Data] = useState(false);

  const timeRangeOptions: TimeRangeOptionsType = useMemo(() => {
    return {
    "24h": { unit: "hours", number: 24 },
    week: {
      unit: "days",
      number: 7
    },
    "2weeks": {
      unit: "days",
      number: 14
    },
    month: {
      unit: "days",
      number: 30
    },
    "6months": {
      unit: "months",
      number: 6
    },
    year: {
      unit: "months",
      number: 12
    },
    alltime: {
      unit: "months",
      number: 36
    }
  }}, []);

  const timeOptionLabels = [
    { id: "24h", label: t("projectview_page.history.24h_option") },
    { id: "week", label: t("projectview_page.history.week_option") },
    { id: "2weeks", label: t("projectview_page.history.2weeks_option") },
    { id: "month", label: t("projectview_page.history.month_option") },
    { id: "6months", label: t("projectview_page.history.6months_option") },
    { id: "year", label: t("projectview_page.history.year_option") },
    { id: "alltime", label: t("projectview_page.history.alltime")}
  ];

  const onTimeOptionChange = useCallback( (label: string) => {
    setTimeOption(label);
    window.localStorage.setItem("timeoption", label);
    const timeRangeOption = timeRangeOptions[label];
    setTimeRangeOption(timeRangeOption);
  }, [timeRangeOptions]);

  useEffect(() => {
    const savedTimeOption = window.localStorage.getItem("timeoption");

    if (savedTimeOption) {
      onTimeOptionChange(savedTimeOption);
    }
  }, [onTimeOptionChange]);

  const setTimeRangeOption = (timeRangeOption: {
    number: number;
    unit: moment.unitOfTime.DurationConstructor;
  }) => {
    if (timeRangeOption && timeRangeOption.number && timeRangeOption.unit) {
      const now = moment();
      const from = moment(now).subtract(timeRangeOption.number, timeRangeOption.unit);

      setTimeRange({
        from: from.toISOString(),
        to: now.toISOString()
      });
    }
  };

  // ---------------------------------------------------
  // GET STATE FROM STORE
  useEffect(() => {
    setreportState(storeReport);
  }, [storeReport]);

  // ---------------------------------------------------
  // NEW PLANS
  useEffect(() => {
    //GOOD LETS FETCH HISTORY DATA
    storeReport.reportObject.plans.forEach(plan => {
      dispatch(fetchPlanHistoryData(plan.id));
    });
  }, [storeReport.reportObject.plans, dispatch]);

  function setPageTime() {
    let sheet = document.styleSheets[0];

    //let newDate = new Date();

    let varText = t("report.report_created") + ": " + now.toString();

    sheet.insertRule(":root{--var-time:'" + varText + "'");
  }

  //GET FIRST IMPRESSION
  useEffect(() => {
    dispatch(fecthProjectBaseReport(Number(projectId)));
    setPageTime();
  }, [projectId, dispatch]);

  //GO THROUGH REPORT OBJECT TO SEE IF GIVEN MEASUREMENT EXISTS ON PROJECT
  const checkForKey = useMemo(() => (obj: any, key: string) => {
    if (!obj) {
      return false;
    }
    if (obj.hasOwnProperty(key)) {
      return true;
    }
    for (let nestedKey in obj) {
      if (typeof obj[nestedKey] === "object") {
        if (checkForKey(obj[nestedKey], key)) {
          return true;
        }
      }
    }
    return false;
  }, []);

  const checkMc = "MoistureContent A";
  const checkDp = "DifferentialPressure";
  const checkPressure = "";
  const checkCo2 = "CO2";
  const checkTvoc = "TVOC";
  const checkPM2_5 = "PM 2_5";
  const checkPM10 = "PM 10";

  //CHECK IF OPTIONAL MEASUREMENTS EXIST(OTHER THAN RH AND TEMP)
  useEffect(() => {
    if (reportState === undefined) {
      return;
    };
    const mcExists = checkForKey(reportState, checkMc);
    const dpExists = checkForKey(reportState, checkDp);
    const pressureExists = checkForKey(reportState, checkPressure);
    const co2Exists = checkForKey(reportState, checkCo2);
    const tvocExists = checkForKey(reportState, checkTvoc);
    const pm2_5Exists = checkForKey(reportState, checkPM2_5);
    const pm10Exists = checkForKey(reportState, checkPM10);

    if (mcExists) { 
      setHasMcData(true); 
      setShowMcChart(true); 
    }
    if (dpExists) { 
      setHasDpData(true); 
      setShowDpChart(true);
    }
    if (pressureExists) { 
      setHasPressureData(true); 
      setShowPressureChart(true);
    }
    if (co2Exists) { 
      setHasCo2Data(true); 
      setShowCo2Chart(true);
    }
    if (tvocExists) { 
      setHasTvocData(true); 
      setShowTvocChart(true);
    }
    if (pm2_5Exists) { 
      setHasPM2_5Data(true); 
      setShowPM2_5Chart(true);
    }
    if (pm10Exists) { 
      setHasPM10Data(true); 
      setShowPM10Chart(true);
    }
  }, [checkForKey, reportState]);

  // references for history chart draw area
  const refChartArea1 = useRef<HTMLDivElement>(null);

  const getDateInputValue = (date: string) => {
    const dateToReturn = `${new Date(date).getFullYear()}-${
      (new Date(date).getMonth() + 1).toString().length !== 1
        ? new Date(date).getMonth() + 1
        : `0${new Date(date).getMonth() + 1}`
    }-${
      new Date(date).getDate().toString().length !== 1
        ? new Date(date).getDate()
        : `0${new Date(date).getDate()}`
    }`;
    return dateToReturn;
  };


  return (
    <>
      <div className="report-tools-container" /* style={{ top: scrollY }} */>
        <div data-header>{t("report.tool_header")}</div>
        <div data-content>
          <div style={{ height: "5px" }}></div>
          <LanguageSelection saveLanguage={false} display={true} />
          <CustomSwitch
            checked1={!showRHorAH}
            onChange1={() => {
              dispatch(toggleShowRHorAHSelection());
            }}
            checked2={!temperatureCompensation}
            onChange2={() => {
              dispatch(toggleTemperatureCompensation());
            }}
          />
          <h5 style={{ marginTop: "10px" }}>{t("report.active_graphs")}</h5>
          <FormGroup sx={{ mt: "5px", mb: "10px", fontSize: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showRhChart}
                  onChange={() => setShowRhChart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": {
                      color: "var(--colorOrange)"
                    }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.rh")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showMcChart}
                  disabled={!hasMcData}
                  onChange={() => {
                    setShowMcChart((prev) => !prev);
                  }}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.moisture_content")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTempChart}
                  onChange={() => {
                    setShowTempChart((prev) => !prev);
                  }}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.temp")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDpChart}
                  disabled={!hasDpData}
                  onChange={() => setShowDpChart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.differential_pressure")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPressureChart}
                  disabled={!hasPressureData}
                  onChange={() => setShowPressureChart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.pressure")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCo2Chart}
                  disabled={!hasCo2Data}
                  onChange={() => setShowCo2Chart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.co2")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showTvocChart}
                  disabled={!hasTvocData}
                  onChange={() => setShowTvocChart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.tvoc")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPM2_5Chart}
                  disabled={!hasPM2_5Data}
                  onChange={() => setShowPM2_5Chart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.pm2_5")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showPM10Chart}
                  disabled={!hasPM10Data}
                  onChange={() => setShowPM10Chart((prev) => !prev)}
                  sx={{
                    height: "12px",
                    color: "var(--colorOrange)",
                    "&.Mui-checked": { color: "var(--colorOrange)" }
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: "Fellix-Regular", fontSize: "12px", lineHeight: "2" }}>
                  {t("report.pm10")}
                </Typography> 
              }
            />
          </FormGroup>
          <ReportCSVExport data={reportState?.reportObject} />
          <label>{t("report.time_range")}</label>

          <select
            name="timeselections"
            id="time-range-selection"
            value={timeOption}
            onChange={e => onTimeOptionChange(e.target.value)}
          >
            {timeOptionLabels.map(timeOption => {
              return (
                <option key={timeOption.id} value={timeOption.id}>
                  {timeOption.label}
                </option>
              );
            })}
          </select>
          <Tooltip title={t("tooltip.calendar") || ""} placement="left">
            <IconButton
              sx={{borderRadius: "1px", bgcolor: "var(--colorOrange)"}}
              onClick={e => {
                setCalendarSelectionsMenuAnchorEl(e.currentTarget);
              }}
            >
              <div
                style={
                  calendarSelectionsMenuAnchorEl
                    ? {
                        color: "#eb941c",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                }
              >
                <CalendarMonthIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)", mt: "5px" }} />
              </div>
            </IconButton>
          </Tooltip>
          <Menu
            open={!!calendarSelectionsMenuAnchorEl}
            anchorEl={calendarSelectionsMenuAnchorEl}
            anchorOrigin={{ vertical: "center", horizontal: "right" }}
            transformOrigin={{ vertical: "center", horizontal: "left" }}
            onClose={() => {
              setCalendarSelectionsMenuAnchorEl(null);
            }}
          >
            <div style={{ padding: "10px" }}>
              <input
                className={`${styles["dateInput"]}`}
                type={"date"}
                value={getDateInputValue(timeRange.from)}
                onChange={event => {
                    setTimeRange({ from: new Date(event.target.value).toISOString(), to: timeRange.to });
                    window.localStorage.setItem("chartZoomFrom", moment(event.target.value).unix().toString());
                  }
                }
              />
            </div>
            <div style={{ padding: "10px" }}>
              <input
                type={"date"}
                value={getDateInputValue(timeRange.to)}
                onChange={event => {
                    setTimeRange({ from: timeRange.from, to: new Date(event.target.value).toISOString() });
                    window.localStorage.setItem("chartZoomTo", moment(event.target.value).unix().toString());
                  }
                }
              />
            </div>
          </Menu>
          <Button
            disabled={loading ? true : false}
            variant="contained"
            size="small"
            sx={{
              height: "40px",
              fontFamily: "Fellix-Regular",
              fontWeight: "bold",
              mt: "10px",
              background: "lightgray",
              color: "var(--colorBlack)",
              "&: hover": {
                background: "var(--colorOrange)"
              }
            }}
            onClick={() => window.print()}
          >
            {t("report.print")}
            {loading && (
              <CircularProgress size={30} sx={{ ml: "20px", color: "var(--colorOrange)" }} />
            )}
          </Button>
        </div>
      </div>
      <div className="page">
        <div data-content>
          {/****************************************************************************************************** */}
          <div className="report-header">
            <div style={{ width: "40%" }}>
              <img src={logo} style={{ height: "100%" }} alt="" />
            </div>
            <div style={{ flexGrow: "1" }}>
              <h3>RELIA</h3>
              <br />
              <label>{t("report.humidity_report")}</label>
            </div>
          </div>
          {/****************************************************************************************************** */}
          <div className="report-section">
            <h3>{t("report.project")}:</h3>
            <p>{reportState?.reportObject.project.name}</p>
            <p>{reportState?.reportObject.project.address1}</p>
            <p>{reportState?.reportObject.project.address2}</p>
            <p>
              {reportState?.reportObject.project.postalCode}
              {", "}
              {reportState?.reportObject.project.city}
            </p>
          </div>
          {/****************************************************************************************************** */}
          <div className="report-section">
            <h3>{t("report.owner_company")}:</h3>
            <p>{reportState?.reportObject.project.ownerCompanyName}</p>
          </div>
          {/****************************************************************************************************** */}
          <div className="report-section report-disclaimer">
            <p>{t("report.disclaimer1")}</p>
            <p>{t("report.disclaimer2")}</p>
            <p>--</p>
            <p style={{ fontWeight: "bold" }}>{t("report.disclaimer3")}</p>
            <p>{t("report.disclaimer4")}</p>
            <p>{t("report.disclaimer5")}</p>
            <p>{t("report.disclaimer6")}</p>
            <p>{t("report.disclaimer7")}</p>
          </div>
          {/****************************************************************************************************** */}
        </div>
      </div>
      {/****************************************************************************************************** */}
      {reportState?.reportObject.plans.map(plan => {
        /////////////////////////////////////////////////////////////////////////
        // MEASUREMENT POINTS
        const planMcExists = checkForKey(reportState.reportObject.planData[plan.id], checkMc);
        const planDpExists = checkForKey(reportState.reportObject.planData[plan.id], checkDp);
        // const planPressureExists = checkForKey(reportState.reportObject.planData[plan.id], checkPressure);
        const planCo2Exists = checkForKey(reportState.reportObject.planData[plan.id], checkCo2);
        const planTvocExists = checkForKey(reportState.reportObject.planData[plan.id], checkTvoc);
        const planPM2_5Exists = checkForKey(reportState.reportObject.planData[plan.id], checkPM2_5);
        const planPM10Exists = checkForKey(reportState.reportObject.planData[plan.id], checkPM10);
        const measurementPoints: Array<MeasurementPointObject> =
          reportState.reportObject.planData[plan.id].measurementPoints;

        type measurementsPageProp = {
          pageNro: number;
          measurementPoints: Array<MeasurementPointObject>;
        };

        // GO THROUGH DEVICES AND POINTS TO SEE IF THEY FIT ON ONE PAGE, IF NOT, THEN ASSIGN SOME TO FIRST PAGE WITH PLAN IMAGE,
        // AND PUSH OTHERS TO NEXT PAGE OR PAGES
        let measurementPages: Array<measurementsPageProp> = new Array<measurementsPageProp>();

        let pageItemCount = 0;
        //let firstPageItemCount = 0;
        let pages = 2;
        let assignedItems: Array<String> = [];
        let newPage: measurementsPageProp = {
          pageNro: 2,
          measurementPoints: Array<MeasurementPointObject>()
        };

        //let firstPage: Array<MeasurementPointObject> = [];

        // TOTAL DEVICES IN A PLAN TO DETERMINE IF THEY ALL FIT IN ONE PAGE WITH THE PLAN IMAGE
        /* const totalPlanDevices = measurementPoints
          .map(point => (point.devices.length > 0 ? point.devices.length : 1))
          .reduce((sum, num) => sum + num, 0);

        // ONLY PUSH POINT DATA TO FIRST PAGE IF IT WILL FIT
        if (totalPlanDevices <= 6) {
          //firstPage = measurementPoints;
          measurementPages = Array<measurementsPageProp>();
        } else { */
          measurementPoints.forEach(point => {
            const pointItemCount = point.devices.length === 0 ? 0.5 : point.devices.length + 0.5;
            /* if (firstPageItemCount + pointItemCount <= 8) {
              firstPage.push(point);
              assignedItems.push(point.id);
              firstPageItemCount += pointItemCount;

              // IF POINT DATA DOES NOT FIT TO FIRST PAGE, PUSH TO NEXT PAGE
            } else { */
              if (!assignedItems.map(point => point).includes(point.id)) {
                if (pointItemCount > 14) {
                  let pointPage1 = { ...point, devices: [...point.devices].splice(0, 15) };
                  let pointPage2 = { ...point, devices: [...point.devices].splice(15) };
                  const page1ItemCount = pointPage1.devices.length;
                  const page2ItemCount = pointPage2.devices.length;
                  if (newPage.measurementPoints.length + page1ItemCount > 15) {
                    measurementPages.push(newPage);
                    pages += 1;
                    pageItemCount = 0;
                    newPage = { pageNro: pages, measurementPoints: [] };
                  }
                  newPage.measurementPoints.push(pointPage1);
                  measurementPages.push(newPage);
                  pages += 1;
                  pageItemCount = 0;
                  newPage = { pageNro: pages, measurementPoints: [] };
                  newPage.measurementPoints.push(pointPage2);
                  pageItemCount += page2ItemCount;
                  assignedItems.push(point.id);
                } else if (pageItemCount + pointItemCount <= 14) {
                  pageItemCount += pointItemCount;
                  newPage.measurementPoints.push(point);
                  assignedItems.push(point.id);
                  // IF POINT DATA DOES NOT FIT ON CURRENT PAGE, PUSH TO NEXT PAGE
                } else {
                  measurementPages.push(newPage);
                  pages += 1;
                  pageItemCount = 0;
                  newPage = { pageNro: pages, measurementPoints: [] };
                  pageItemCount += pointItemCount;
                  newPage.measurementPoints.push(point);
                  assignedItems.push(point.id);
                }
              } else {
                //NO NEED TO RETURN ANYTHING
              }
          });

        // ANYTHING THAT IS LEFT OVER WILL BE PUSHED TO A NEW PAGE
        if (newPage.measurementPoints.length > 0) {
          measurementPages.push(newPage);
        }

        //GET TOTAL NUMBER OF PAGES FOR THIS PLANS DEVICE DATA
        const totalPages =
          measurementPages.length !== 0 ? measurementPages[measurementPages.length - 1].pageNro : 1;

        /* ************************************************************** */

        //HISTORY DATA FOR GRAPHS
        const historyPoints: HistoryDataObject =
          reportState.reportObject.planData[plan.id].historyData;

        const storemeasurementPoints: MeasurementPointObject[] =
          reportState.reportObject.planData[plan.id].measurementPoints;

        const selectedPoints = measurementPoints.map(point => {
          return point.id.toString();
        });
        // dATA

        const selectedData: HistoryDataObject = Object.keys(historyPoints)
          .filter(key => selectedPoints.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: historyPoints[key] as MeasurementPointHistoryObject
            };
          }, {});

        const selectedHistoryData = Object.values(selectedData).map(measurementPoint =>
          Object.values(measurementPoint.measurementPointDevices)
        );

        const historyData: Array<SensorHistoryObject> = selectedHistoryData.flat(
          1
        ) as Array<SensorHistoryObject>;

        return (
          <div key={"plan_report_" + plan.id}>
            <div className="page">
              <div data-content>
                <h2>
                  {t("report.plan") +
                    ": " +
                    plan.name +
                    " -- " +
                    t("report.page") +
                    " 1/" +
                    totalPages}
                </h2>
                <div className="report-floorplan-section">
                  <div data-image>
                    <PlanView
                      editContent={false}
                      zoomLevel={0}
                      stageHidded={false}
                      projectPlanId={plan.id.toString()}
                      resizeCanvas={0}
                      contentEditable={false}
                      measurementPoints={measurementPoints}
                      reportPage={true}
                    />
                  </div>
                  {/***************************************************************/}
                </div>
              </div>
            </div>
            {/***************************************************************/}
            {measurementPages.length !== 0 &&
              measurementPages.map((pageProp, index) => {
                return (
                  <div
                    className="page"
                    key={"measurement_page_" + plan.id + "_" + pageProp.pageNro}
                  >
                    <div data-content>
                      <h2>
                        {t("report.plan") +
                          ": " +
                          plan.name +
                          " -- " +
                          t("report.page") +
                          " " +
                          pageProp.pageNro +
                          "/" +
                          totalPages}
                      </h2>
                      {/***************************************************************/}
                      <div className="report-floorplan-section">
                        <div data-table>
                          <ReportLastValues measurementPoints={pageProp.measurementPoints} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/***************************************************************/}
            {showRhChart && 
              <div className="page">
                <div data-content>
                  <h2>{t("report.history") + ": " + plan.name + " / " + t("report.rh")}</h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-rh"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="RelativeHumidity"
                        title={t("projectview_page.history.humidity_chart_title")}
                        altDataProps={["MoistureContent A", "MoistureContent B"]}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showMcChart && hasMcData && planMcExists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") + ": " + plan.name + " / " + t("report.moisture_content")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-mc"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="MoistureContent"
                        title={"Moisture Content"}
                        //altDataProps={["MoistureContent A", "MoistureContent B"]}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showTempChart && 
              <div className="page">
                <div data-content>
                  <h2>{t("report.history") + ": " + plan.name + " / " + t("report.temp")}</h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                    <HistoryChart
                        key={"project-view-history-report-main-temp"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="Temperature"
                        title={"Temp"}
                        //altDataProps={["MoistureContent A", "MoistureContent B"]}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showDpChart && hasDpData && planDpExists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") +
                      ": " +
                      plan.name +
                      " / " +
                      t("report.differential_pressure")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-differential-pressure"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="DifferentialPressure"
                        title={"PA"}
                        //altDataProps={["MoistureContent A", "MoistureContent B"]}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showCo2Chart && hasCo2Data && planCo2Exists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") +
                      ": " +
                      plan.name +
                      " / " +
                      t("report.co2")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-co2"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="CO2"
                        title={"CO2"}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showPM2_5Chart && hasPM2_5Data && planPM2_5Exists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") +
                      ": " +
                      plan.name +
                      " / " +
                      t("report.pm2_5")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-pm2_5"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="PM 2_5"
                        title={"PM2.5"}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showPM10Chart && hasPM10Data && planPM10Exists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") +
                      ": " +
                      plan.name +
                      " / " +
                      t("report.pm10")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-pm10"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="PM 10"
                        title={"pm10"}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
            {showTvocChart && hasTvocData && planTvocExists &&
              <div className="page">
                <div data-content>
                  <h2>
                    {t("report.history") +
                      ": " +
                      plan.name +
                      " / " +
                      t("report.tvoc")}
                  </h2>
                  <div className="report-history-section">
                    {/***************************************************************/}
                    <div ref={refChartArea1}>
                      <HistoryChart
                        key={"project-view-history-report-main-tvoc"}
                        signalDatas={historyData}
                        tickFormat="D-M-Y"
                        tooltipFormat="D-M-Y HH:mm"
                        fromDate={timeRange.from}
                        toDate={timeRange.to}
                        handleTimeRangeChange={() => {}}
                        dataProp="TVOC"
                        title={"tvoc"}
                        showAmbient={true}
                        tickSizeDivisor={10}
                        editEnabled={false}
                        chartsAmount={1}
                        planViewShown={true}
                        extraSpaceRight={false}
                        transitions={{
                          buttons: false,
                          measurements: false,
                          planview: false,
                          history: false
                        }}
                        minDateRef={minDateRef}
                        refChartArea={refChartArea1}
                        showLegend={true}
                        reportPage={true}
                      />
                    </div>
                    {/***************************************************************/}
                  </div>
                </div>
              </div>
            }
          </div>
        );
      })}
    </>
  );
}
