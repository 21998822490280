import { Button, Dialog, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./DeviceSettingsLinkOrUnlinkDialog.module.css";
import { DeviceSettingsProjectObject } from "../../../../../types";
import { useDispatch } from "react-redux";
import DeviceSettingsUnlinkTable from "./DeviceSettingsUnlinkTable";
import { clearError, clearMeasurementPointsAndPlans } from "./deviceSlice";
import DeviceSettingsLinkTable from "./DeviceSettingsLinkTable";
interface DialogProps {
  handleDialogClose: () => void;
  open: boolean;
  projects: Array<DeviceSettingsProjectObject> | undefined;
  linkOrUnlink: "link" | "unlink";
}

const DeviceSettingsLinkDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, projects, linkOrUnlink, handleDialogClose } = props;

  return (
    <Dialog open={open}>
      <div className={styles["link-device-form-container"]}>
        {linkOrUnlink === "unlink" &&<DeviceSettingsUnlinkTable projects={projects} handleLinkDialogClose={handleDialogClose} />}
        {linkOrUnlink === "link" && <DeviceSettingsLinkTable handleLinkDialogClose={handleDialogClose} />}
        <div className={styles["link-device-dialog-buttons"]}>
          <Stack direction={"column"} spacing={1}>
            <Button
              variant="contained"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(clearMeasurementPointsAndPlans());
                dispatch(clearError());
                handleDialogClose();
              }}
            >
              {t("common.cancel")}
            </Button>
          </Stack>
        </div>
      </div>
    </Dialog>
  );
};

export default DeviceSettingsLinkDialog;
