import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Button, TextField } from "@mui/material";

import styles from "./AddNewMeasurementDialog.module.css";

import axios from "axios";

import { commonAttributes } from "./../../../../common/commonAttributes";

interface FormInterface {
  oldValue: string | undefined;
  projectId: number;
  handleSave: (name: string, isEdit: boolean) => void;
  handleClose: () => void;
  deviceSettings?: boolean;
}

export default function AddOrEditMeasurementDialog(props: FormInterface) {
  const { t } = useTranslation();
  // **********************************************************************************************************************
  const [measurementName, setmeasurementName] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");

  const isEdit = measurementName !== undefined && measurementName !== "";

  const nameValid = measurementName !== "";

  useEffect(() => {
    if (props.oldValue !== undefined) setmeasurementName(props.oldValue);
  }, [props.oldValue]);

  // **********************************************************************************************************************

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setmeasurementName(event.target.value);
  };

  const handleSave = () => {
    if (nameValid === false) {
      setErrorMessage(t("measurement_list.add_new_measurement.error"));

      return;
    }

    // CHECKING THAT MEASUREMENT POINT NAME DONT EXISTS

    const urlPath =
      commonAttributes.apiUrl +
      `/Projects/${props.projectId}/ValidateMeasurementPointName?name=${measurementName}`;

    axios
      .get(urlPath)
      .then(function (response) {
        if (response === null || response === undefined) throw new Error("False name");

        props.handleSave(measurementName, isEdit);
      })
      .catch(error => {
        setErrorMessage(t("measurement_list.add_new_measurement.error_name_exists"));

        return;
      });
  };

  const handleCancel = () => {
    props.handleClose();
  };
  // **********************************************************************************************************************

  return !props.deviceSettings ? (
    <Dialog open={true} onClose={props.handleClose}>
      <div className={styles["measurementpoint-form-container"]}>
        <div className={styles["measurementpoint-form-header"]}>
          {isEdit
            ? t("measurement_list.add_new_measurement.edit_measurement")
            : t("measurement_list.add_new_measurement.add_new_measurement")}
        </div>

        <TextField
          required
          fullWidth
          label={t("measurement_list.add_new_measurement.measurement_name")}
          size="small"
          onChange={handleNameChange}
          value={measurementName}
          error={nameValid === false}
          InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        />

        {/* *************************************************************************** */}
        <Button
          variant="contained"
          color="error"
          style={{ display: errorMessage !== "" ? "" : "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" onClick={() => handleSave()}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={() => handleCancel()}>
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
  ) : (
    <>
      <div className={styles["measurementpoint-form-header"]}>
        {isEdit
          ? t("measurement_list.add_new_measurement.edit_measurement")
          : t("measurement_list.add_new_measurement.add_new_measurement")}
      </div>

      <TextField
        required
        fullWidth
        label={t("measurement_list.add_new_measurement.measurement_name")}
        size="small"
        onChange={handleNameChange}
        value={measurementName}
        error={nameValid === false}
        sx={{mt: "10px"}}
        InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
      />

      {/* *************************************************************************** */}
      <Button
        variant="contained"
        color="error"
        style={{ display: errorMessage !== "" ? "" : "none" }}
      >
        {errorMessage}
      </Button>
      <Button sx={{ mt: "10px" }} variant="contained" onClick={() => handleSave()}>
        {t("common.save")}
      </Button>
    </>
  );
}
