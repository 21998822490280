import React, { useState } from "react";
import {IconButton, Tooltip} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import CancelScheduleSendRoundedIcon from "@mui/icons-material/CancelScheduleSendRounded";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { useTranslation } from "react-i18next";

import { DeviceObject } from "../../../../../types";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  removeSensorFromMeasurementPoint,
  selectedHistoryDataSelector,
  toggleAnyDialogOpen,
  toggleShowSeriesDevice,
  setHoveredDevice,
  clearHoveredDevice,
  unlinkMeasurementPointDevice,
  ShowSeriesDevicesObject,
  fetchPlanMeasurementsDevices,
  fetchPlanMeasurementsLatestData,
} from "../../../../measurements/measurementsSlice";
import { RootState } from "../../../../../app/store";
import styles from "./MeasurementPointSensorList.module.css";

//components
import { StatusIcon } from "../../../../UI/StatusIcons";
import ConfirmDialog from "../../../../UI/ConfirmDialog";

//common functions
import {
  formatNumber,
  CountDateDifference,
  GetDateString,
  getAbsoluteHumidityText,
  getMcText,
  sensorTextFunction
} from "./../../../../utils/commonFunctions";

//DIALOGS
import LinkDevice from "./LinkDeviceToDeviceDialog";

interface SensorProperties {
  measurementPointId: string;
  editContent: boolean;
  deviceData: Array<DeviceObject>;
  fullView: boolean;
  planId: number;
}

/*'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''' */
export function MeasurementPointSensorsList(props: SensorProperties) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  //STATES

  const [linkDeviceDialogOpen, setlinkDeviceDialogOpen] = useState(false);
  const [editedDevice, seteditedDevice] = useState<DeviceObject | undefined>(undefined);

  const smallScreen = useMediaQuery("(max-width: 999px)");
  const bigScreen = useMediaQuery("(min-width: 1200px)");

  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState<boolean>(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const [dialogValue, setDialogValue] = useState<string>("");
  const [deviceToUnlinkOrRemove, setDeviceToUnlinkOrRemove] = useState<string>("");

  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);

  const historyData = useSelector(selectedHistoryDataSelector);

  const showDeviceSeries = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.showSeriesDevices);

  const deviceColors = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.deviceColors
  );

  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH
  );

  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );

  const onDeviceLinkClick = (deviceObj: DeviceObject) => {
    setlinkDeviceDialogOpen(true);
    seteditedDevice(deviceObj);
  };

  const onRemoveClick = (deviceId: string) => {
      dispatch(
        removeSensorFromMeasurementPoint({
          measurementPointID: props.measurementPointId,
          deviceId: deviceId
        })
      );
    };

  const onLinkEndClick = async (deviceId: string) => {
    try {
      await dispatch(
          unlinkMeasurementPointDevice({
            measurementPointID: props.measurementPointId,
            deviceId: deviceId
          })
        );
    }
    catch (err) {
      console.log("Error unlinking device");
    }
    finally {
      dispatch(fetchPlanMeasurementsDevices(props.planId));
      dispatch(fetchPlanMeasurementsLatestData(props.planId));
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  const getDifferenceText = (lastUpdated: string): string => {
    let sReturn = "";

    const { value, translationText } = CountDateDifference(lastUpdated);

    if (value === -1) {
      sReturn = t(translationText);
    } else {
      sReturn = value + " " + t(translationText);
    }

    return sReturn;
  };

  const handleMouseEnter = (showDevice: ShowSeriesDevicesObject | undefined ,serial: string) => {
    if (smallScreen) {
      return;
    }
    showDevice !== undefined && showDevice.show && dispatch(setHoveredDevice(serial));
    return;
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const TableHeader = () => {
    if (!smallScreen && props.fullView) {
      return (
        <TableHead className={styles["measurement-sensor-table-header"]}>
          <TableRow className={styles["measurement-sensor-table-row"]}>
            <TableCell sx={{ minWidth: "100px", width: "100px", maxWidth: "300px" }}>
              {t("device_list.type")}
            </TableCell>
            <TableCell sx={{ width: "120px", mr: "50px" }}>{t("device_list.serial")}</TableCell>
            {bigScreen ?
            <>
            <TableCell sx={ {width: "100px"}}>{t("projectview_page.alarms.BatteryStatus")}</TableCell>
            <TableCell sx={ {width: "100px", mr: "50px"}}>{t("projectview_page.alarms.NetworkQuality")}</TableCell>
            </>
            : null
            }
            <TableCell sx={{ minWidth: "150px", width: "200px", maxWidth: "400px" }}>
              {t("device_list.linked_date")}
            </TableCell>
            <TableCell sx={{ minWidth: "50px", width: "200px" }}>
              {t("device_list.last_active")}
            </TableCell>

            <TableCell sx={{ minWidth: "100px", width: "150px" }}>
              {t("device_list.ambient_values")}
            </TableCell>

            <TableCell sx={{ minWidth: "100px", width: "150px" }}>
              {t("device_list.values")}
            </TableCell>
            <TableCell sx={{ width: "150px" }}>{t("device_list.depth")}</TableCell>

            {props.editContent ? <TableCell sx={{ width: "50px" }}></TableCell> : <></>}
          </TableRow>
        </TableHead>
      );
    } else {
      return (
        <TableHead className={styles["measurement-sensor-table-header"]}>
          <TableRow className={styles["measurement-sensor-table-row"]}>
            <TableCell sx={{ minWidth: "50px" }}></TableCell>
            <TableCell sx={{ minWidth: "50px" }}></TableCell>
            <TableCell sx={{ minWidth: "70px", maxWidth: "80px" }}>
              {props.editContent ? t("device_list.serial") : t("device_list.ambient_values")}
            </TableCell>
              {!props.editContent && <TableCell sx={{ minWidth: "70px", maxWidth: "100px" }}>
                {props.editContent ? "" : t("device_list.values")}
              </TableCell>}
            <TableCell sx={{minWidth: "50px", maxWidth: "100px" }}>{t("device_list.depth")}</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  };

  // ***********************************************************************************************************************************************************************************************
  // DEPTH FIELDS
  const DepthFields = (props: { device: DeviceObject }) => {
    if (props.device.depthDefinition === 0) {
      return <React.Fragment>
      <div
        key={"sensordata_depth_" + props.device.id + "_" + props.device.serial}
        className={styles["measurement-sensor-depth"]}
      >
      {"-"}
      </div>
    </React.Fragment>
    } else if (props.device.depthDefinition === 1) {
      // DEVICE
      return (
        <div
          key={"sensordata_depth" + props.device.id}
          className={styles["measurement-sensor-depth"]}
        >
          {props.device.sensorUnits[0] !== undefined
            ? props.device.sensorUnits[0].depth + " mm"
            : "-"}
        </div>
      );
    } else if (props.device.depthDefinition === 2) {
      // SENSORS
      return (
        <React.Fragment>
          {props.device.sensorUnits?.map((sensorUnit, index) =>
            sensorUnit.ambient === false &&
            sensorUnit.typeName !== "NetworkQuality" &&
            sensorUnit.typeName !== "BatteryStatus" ? (
              <div
                key={"sensordata_depth_" + props.device.id + "_" + index}
                className={styles["measurement-sensor-depth"]}
              >
                {sensorUnit.depth + " mm"}
              </div>
            ) : (
              <div key={"depth_none_" + props.device.id + index} style={{ display: "none" }}></div>
            )
          )}
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  // ***********************************************************************************************************************************************************************************************


  // ***********************************************************************************************************************************************************************************************

  ///////////////////////////////////////////////////////////////////////////////////////
  //SENSORS TABLE
  const TableContent = () => {
    // ***********************************************************************************************************************************************************************************************
    // FULL VIEW
    // ***********************************************************************************************************************************************************************************************
    if (!smallScreen && props.fullView) {
      return (
        <TableBody
          className={styles["measurement-sensor-table"]}
          key={"fullview-table" + props.measurementPointId}
        >
          {props.deviceData.map((device, index) => {
            // DEVICE linkEndTime
            const linkingEnded =
              device.linkEndDateTime !== null &&
              device.linkEndDateTime !== undefined &&
              device.linkEndDateTime !== "";

            const isLinked =
              device.linkStartDateTime !== null &&
              device.linkStartDateTime !== undefined &&
              device.linkStartDateTime !== "";

            const disableUnlinkButton = !(isLinked === true && linkingEnded === false);
            const disableRemoveButton = !(isLinked === false || linkingEnded === true);
            const temperature = device.latestData?.Temperature?.value
            const temperatureAmbient = device.latestData?.TemperatureAmbient?.value
            const relativeHumidity = device.latestData?.RelativeHumidity?.value
            const relativeHumidityAmbient = device.latestData?.RelativeHumidityAmbient?.value
            const battery = device.latestData?.BatteryStatus?.value
            const network = device.latestData?.NetworkQuality?.value
            const moistureContentA = device.latestData?.["MoistureContent A"]?.value
            const moistureContentB = device.latestData?.["MoistureContent B"]?.value

            return (
              <TableRow
                key={"sensor_row_" + device.id}
                className={styles["measurement-sensor-table-row"]}
                data-linking-ended={linkingEnded}
              >
                <TableCell>
                  <label className={styles["measurement-sensor-value"]}>{device.deviceType}</label>
                </TableCell>

                <TableCell>
                  {props.editContent ? (
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      value={device.serial}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDeviceLinkClick(device);
                        dispatch(toggleAnyDialogOpen(true));
                      }}
                      disabled={
                        (device.serial !== null &&
                        device.serial !== undefined &&
                        device.serial !== "-")
                        ||
                        (activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0)
                      }
                      sx={
                        device.serial !== null &&
                        device.serial !== undefined &&
                        device.serial !== "-"
                          ? { border: "0", color: "black !important" }
                          : {}
                      }
                    >
                      {device.serial === null ||
                      device.serial === undefined ||
                      device.serial === "-"
                        ? t("measurement_list.link_device")
                        : device.serial}
                    </Button>
                  ) : (
                    <label>
                      {device.serial === null || device.serial === undefined ? "-" : device.serial}
                    </label>
                  )}
                </TableCell>
                {bigScreen ?
                <>
                <TableCell>
                  <StatusIcon type="battery" status={battery} />
                </TableCell>
                
                <TableCell>
                    <StatusIcon type="network" status={network} />
                </TableCell>
                </>
                : null
                }
                <TableCell>
                  {isLinked ? (
                    <div className={styles["measurement-sensor-linkdate"]}>
                      <div>
                        <b>{t("device_list.start_time")}</b>
                        {":  " + GetDateString(device.linkStartDateTime, "DD-MM-YYYY HH:mm")}
                      </div>
                      <div>
                        <b>{t("device_list.end_time")}</b>
                        {":  " + GetDateString(device.linkEndDateTime, "DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={"not_linked_" + device.id + "_" + index}
                      className={styles["measurement-sensor-value"]}
                      data-notlinked={true}
                    >
                      {t("measurement_list.device_not_linked")}
                    </div>
                  )}
                </TableCell>

                <TableCell>
                <label>{getDifferenceText(device.lastUpdated)}</label>
                </TableCell>
                {/* **************************************************************************************************************************************************** */}
                {/* //ambient_values */}
                <TableCell>
                 { [...device.sensorUnits]
                 .sort((a, b) => (a.typeName > b.typeName) ? 1 : -1)
                 .map((sensorUnit, index) => {
                    return sensorUnit.ambient ? (
                      <div
                        key={"sensordata_ambient_" + index}
                        className={styles["measurement-sensor-value"]}
                        data-isambient={sensorUnit.ambient}
                        data-linking-ended={linkingEnded}
                      >
                        {showRHorAH && sensorUnit.unit === "%RH"
                          ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
                          : formatNumber(
                              device.latestData[sensorUnit.typeName]?.value,
                              2,
                              sensorUnit.unit
                            )}
                      </div>
                    ) : (
                      <div
                        key={"ambient_none_" + device.id + index}
                        style={{ display: "none" }}
                      ></div>
                    );
                  })}
                </TableCell>

                {/* //NOT ambient_values */}
                <TableCell>
                  { [...device.sensorUnits]
                 .sort((a, b) => (a.typeName > b.typeName) ? 1 : -1)
                 .map((sensorUnit, index) => 
                    sensorUnit.ambient === false &&
                    sensorUnit.typeName !== "NetworkQuality" &&
                    sensorUnit.typeName !== "BatteryStatus" ? (
                      <div
                        key={"sensordata_values_" + index}
                        className={styles["measurement-sensor-value"]}
                        data-isambient={sensorUnit.ambient}
                        data-linking-ended={linkingEnded}
                      >
                        {showRHorAH && sensorUnit.unit === "%RH"
                              ? getAbsoluteHumidityText(temperature, relativeHumidity)
                              : temperatureCompensation && sensorUnit.typeName === "MoistureContent A" 
                              ? getMcText(moistureContentA, temperatureAmbient, "%MC (A) @20°C" )
                              : temperatureCompensation && sensorUnit.typeName === "MoistureContent B"
                              ? getMcText(moistureContentB, temperatureAmbient, "%MC (B) @20°C")
                              : sensorTextFunction(device.latestData[sensorUnit.typeName]?.value, 
                                sensorUnit.typeName === "MoistureContent A"
                              ? "%MC (A)"
                              : sensorUnit.typeName === "MoistureContent B"
                              ? "%MC (B)"
                              : sensorUnit.unit)
                              }
                      </div>
                    ) : (
                      <div
                        key={"values_none_" + device.id + index}
                        style={{ display: "none" }}
                      ></div>
                    )
                    )}
                </TableCell>
                {/* **************************************************************************************************************************************************** */}
                {/* DEPTH */}
                <TableCell>
                  <DepthFields device={device} />
                </TableCell>
                {/* **************************************************************************************************************************************************** */}
                {/* DELETE SENSOR */}
                {props.editContent ? (
                  <TableCell className={styles["measurement-sensor-delete-cell"]}>
                  {disableUnlinkButton && 
                  <button
                  disabled={disableUnlinkButton}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDialogValue(event.currentTarget.value);
                    setDeviceToUnlinkOrRemove(device.id);
                    setUnlinkDialogOpen(true);
                    dispatch(toggleAnyDialogOpen(true));
                  }}
                  style={{ marginRight: "6px", cursor: "pointer" }}
                >
                  <CancelScheduleSendRoundedIcon
                    sx={{
                      color: disableUnlinkButton ? "lightgray" : "red",
                      height: "var(--iconSize)",
                      width: "var(--iconSize)"
                    }}
                  />
                </button>
                  }
                  {!disableUnlinkButton && 
                  <Tooltip title={t("tooltip.unlink") || ""}>
                    <button
                      disabled={disableUnlinkButton}
                      value={"unlink"}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDialogValue(event.currentTarget.value);
                        setDeviceToUnlinkOrRemove(device.id);
                        setUnlinkDialogOpen(true);
                        dispatch(toggleAnyDialogOpen(true));
                      }}
                      style={{ marginRight: "6px", cursor: "pointer" }}
                    >
                      <CancelScheduleSendRoundedIcon
                        sx={{
                          color: disableUnlinkButton ? "lightgray" : "red",
                          height: "var(--iconSize)",
                          width: "var(--iconSize)"
                        }}
                      />
                    </button>
                    </Tooltip>
                    }
                    {disableRemoveButton &&
                    <button
                    disabled={disableRemoveButton}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDialogValue(event.currentTarget.value);
                      setDeviceToUnlinkOrRemove(device.id);
                      setRemoveDialogOpen(true);
                      dispatch(toggleAnyDialogOpen(true));
                    }}
                    style={{ marginRight: "2px", cursor: "pointer" }}
                  >
                    <DeleteOutlineRoundedIcon
                      sx={{
                        color: disableRemoveButton ? "lightgray" : "red",
                        height: "var(--iconSize)",
                        width: "var(--iconSize)"
                      }}
                    />
                  </button>
                    }
                    {!disableRemoveButton &&
                    <Tooltip title={t("tooltip.delete") || ""}>
                    <button
                      disabled={disableRemoveButton}
                      value={"remove"}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDialogValue(event.currentTarget.value);
                        setDeviceToUnlinkOrRemove(device.id);
                        setRemoveDialogOpen(true);
                        dispatch(toggleAnyDialogOpen(true));
                      }}
                      style={{ marginRight: "2px", cursor: "pointer" }}
                    >
                      <DeleteOutlineRoundedIcon
                        sx={{
                          color: disableRemoveButton ? "lightgray" : "red",
                          height: "var(--iconSize)",
                          width: "var(--iconSize)"
                        }}
                      />
                    </button>
                    </Tooltip>
                    }
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      );
    }
    // ***********************************************************************************************************************************************************************************************
    // SMALL VIEW
    // ***********************************************************************************************************************************************************************************************
    else {
      return (
        <TableBody key={"smallview-table_" + props.measurementPointId}>
          {props.deviceData.map((device, index) => {
            // SENSOR IS LINKED
            const sensorIsLinked =
              device.serial !== null &&
              device.serial !== undefined &&
              device.serial !== "" &&
              device.serial !== "-";

             const currentSerial = device.serial;
            // DEVICE linkEndTime
            const linkingEnded =
              device.linkEndDateTime !== null &&
              device.linkEndDateTime !== undefined &&
              device.linkEndDateTime !== "";

              const disableUnlinkButton = !(sensorIsLinked === true && linkingEnded === false);
              const disableRemoveButton = !(sensorIsLinked === false || linkingEnded === true);
              const temperature = device.latestData?.Temperature?.value
              const temperatureAmbient = device.latestData?.TemperatureAmbient?.value
              const relativeHumidity = device.latestData?.RelativeHumidity?.value
              const relativeHumidityAmbient = device.latestData?.RelativeHumidityAmbient?.value
              const battery = device.latestData?.BatteryStatus?.value
              const network = device.latestData?.NetworkQuality?.value
              const moistureContentA = device.latestData?.["MoistureContent A"]?.value
              const moistureContentB = device.latestData?.["MoistureContent B"]?.value

              const color = deviceColors.filter(device => device.deviceSerial === currentSerial)?.find(x => x.color);

              const deviceHasHistoryData = historyData.map(x => x.serial).filter(x => x === currentSerial).length > 0;

              const showDevice = showDeviceSeries.filter(device => device.deviceSerial === currentSerial)?.find(x => x.show);

            return (
              <TableRow
                hover={!props.editContent}
                key={"sensor_row_" + device.id}
                className={styles["measurement-sensor-table-row"]}
                data-linking-ended={linkingEnded}
                onMouseEnter={!props.editContent && !smallScreen && sensorIsLinked && deviceHasHistoryData && showDevice !== undefined && showDevice.show ? () => handleMouseEnter(showDevice, device.serial) : () => dispatch(clearHoveredDevice())}
                onMouseLeave={!props.editContent && !smallScreen && sensorIsLinked && deviceHasHistoryData && showDevice !== undefined && !showDevice.show ? () => dispatch(clearHoveredDevice()) : () => dispatch(clearHoveredDevice())}
               
              >
                <TableCell
                  className={styles["measurement-sensor-lastactive-cell"]}
                  key={"lastactive-cell_" + device.id}
                  width={smallScreen ? "50px" : "65px"}
                >
                  <div style={{ fontWeight: "bold" }}>
                    {device.deviceType}
                    {sensorIsLinked && (battery === 1 || 0) ? 
                    <span style={{marginLeft: "2px"}}>
                      <StatusIcon type="battery" fontSize="inherit" status={battery} />
                    </span> 
                    : null
                    }
                    {sensorIsLinked && (network === 1 || 0) ? 
                    <span style={{marginLeft: "5px"}}>
                      <StatusIcon type="network" fontSize="inherit" status={network} />
                    </span> 
                    : null
                    }
                  </div>
                  <div style={{ color: "var(--colorLightText)" }}>
                    {getDifferenceText(device.lastUpdated)}
                  </div>
                </TableCell>
                {!props.editContent &&
                <TableCell 
                  key={"measurementpointsensorlist-chart-select" + device.id}
                  width={"50px"}
                  sx={{textAlign: smallScreen ? "end" : "none"}}
                  >
                <IconButton 
                     // onMouseEnter={sensorIsLinked && deviceHasHistoryData && showDevice !== undefined && showDevice.show ? () => handleMouseEnter(showDevice, device.serial) : () => dispatch(clearHoveredDevice())}
                     // onMouseLeave={sensorIsLinked && deviceHasHistoryData && showDevice !== undefined && !showDevice.show ? () => dispatch(clearHoveredDevice()) : () => dispatch(clearHoveredDevice())}
                      size="small" 
                      disabled={!sensorIsLinked || !deviceHasHistoryData}
                      onClick={(e) => {
                        dispatch(toggleShowSeriesDevice({deviceSerial: device.serial}));
                        if (!smallScreen) {
                          dispatch(clearHoveredDevice());
                          dispatch(setHoveredDevice(device.serial));
                        }
                        e.stopPropagation();
                      }}
                      sx={{
                        ml: !props.fullView ? "15px" : "10px", 
                        padding: "0px",
                        verticalAlign: "baseline",
                        color: color ? color.color.toString() : "gray"
                      }}
                      >
                      {sensorIsLinked && deviceHasHistoryData && (showDevice !== undefined && showDevice.show) 
                      ? 
                      <span><RadioButtonCheckedIcon fontSize="small"/></span>
                      :
                      <span><RadioButtonUncheckedIcon fontSize="small"/></span>
                      }
                    </IconButton>
                </TableCell>
                }
                
                {/* """""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""" */}
                {sensorIsLinked && !props.editContent ? (
                  <>
                    <TableCell key={"ambient-values-cell_" + device.id}>
                      { [...device.sensorUnits]
                        .sort((a, b) => (a.typeName > b.typeName) ? 1 : -1)
                        .map((sensorUnit, index) =>
                        sensorUnit.ambient ? (
                          <div
                            key={"sensordata_" + device.id + "_" + index}
                            className={styles["measurement-sensor-value"]}
                            data-isambient={sensorUnit.ambient}
                            data-linking-ended={linkingEnded}
                          >
                        {showRHorAH && sensorUnit.unit === "%RH"
                          ? getAbsoluteHumidityText(temperatureAmbient, relativeHumidityAmbient)
                          : formatNumber(
                            device.latestData[sensorUnit.typeName]?.value,
                            2,
                            sensorUnit.unit
                        )}
                          </div>
                        ) : (
                          <div
                            key={"ambient_none_" + device.id + index}
                            style={{ display: "none" }}
                          ></div>
                        )
                      )}
                    </TableCell>

                    {/* //NOT ambient_values */}
                    <TableCell key={"sensor-values-cell_" + device.id}>
                      { [...device.sensorUnits]
                      .sort((a, b) => (a.typeName > b.typeName) ? 1 : -1)
                      .map((sensorUnit, index) => 
                        sensorUnit.ambient === false &&
                        sensorUnit.typeName !== "NetworkQuality" &&
                        sensorUnit.typeName !== "BatteryStatus" ? (
                          <div
                            key={"sensordata_" + device.id + "_" + index}
                            className={styles["measurement-sensor-value"]}
                            data-isambient={sensorUnit.ambient}
                            data-linking-ended={linkingEnded}
                          >
                            {showRHorAH && sensorUnit.unit === "%RH"
                              ? getAbsoluteHumidityText(temperature, relativeHumidity)
                              : temperatureCompensation && sensorUnit.typeName === "MoistureContent A" 
                              ? getMcText(moistureContentA, temperatureAmbient, "%MC (A) @20°C" )
                              : temperatureCompensation && sensorUnit.typeName === "MoistureContent B"
                              ? getMcText(moistureContentB, temperatureAmbient, "%MC (B) @20°C")
                              : sensorTextFunction(device.latestData[sensorUnit.typeName]?.value, 
                                sensorUnit.typeName === "MoistureContent A"
                              ? "%MC (A)"
                              : sensorUnit.typeName === "MoistureContent B"
                              ? "%MC (B)"
                              : sensorUnit.unit)
                              }
                          </div>
                        ) : (
                          <div
                            key={"values_none_" + device.id + index}
                            style={{ display: "none" }}
                          ></div>
                        )
                      )}
                    </TableCell>
                  </>
                ) : (
                  <TableCell key={"sensor_link_cell" + device.id} colSpan={2}>
                    {props.editContent ? (
                      <Button
                        variant="contained"
                        size="small"
                        value={device.serial}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          onDeviceLinkClick(device);
                          dispatch(toggleAnyDialogOpen(true));
                        }}
                        disabled={
                          (device.serial !== null &&
                          device.serial !== undefined &&
                          device.serial !== "-")
                          ||
                          (activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0)
                        }
                        sx={
                          device.serial !== null &&
                          device.serial !== undefined &&
                          device.serial !== "-"
                            ? { border: "0", color: "black !important", ml: "38%"}
                            : { ml: "38%" }
                        }
                      >
                        {device.serial === null ||
                        device.serial === undefined ||
                        device.serial === "-"
                          ? t("measurement_list.link_device")
                          : device.serial}
                      </Button>
                    ) : (
                      <div
                        key={"not_linked_" + device.id + "_" + index}
                        className={styles["measurement-sensor-value"]}
                        data-notlinked={true}
                      >
                        {t("measurement_list.device_not_linked")}
                      </div>
                    )}
                  </TableCell>
                )}
                {/* //ambient_values */}

                {/* """""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""""" */}
                {/* DEPTH */}
                <TableCell key={"depth-cell_" + device.id}>
                  <DepthFields device={device} />
                </TableCell>
                {props.editContent && (
                  <TableCell className={styles["measurement-sensor-delete-cell"]}>
                  {disableUnlinkButton && 
                  <button
                  disabled={disableUnlinkButton}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDialogValue(event.currentTarget.value);
                    setDeviceToUnlinkOrRemove(device.id);
                    setUnlinkDialogOpen(true);
                    dispatch(toggleAnyDialogOpen(true));
                  }}
                  style={{ marginRight: "6px", cursor: "pointer" }}
                >
                  <CancelScheduleSendRoundedIcon
                    sx={{
                      color: disableUnlinkButton ? "lightgray" : "red",
                      height: "var(--iconSize)",
                      width: "var(--iconSize)"
                    }}
                  />
                </button>
                  }
                  {!disableUnlinkButton && 
                  <Tooltip title={t("tooltip.unlink") || ""}>
                    <button
                      disabled={disableUnlinkButton}
                      value={"unlink"}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDialogValue(event.currentTarget.value);
                        setDeviceToUnlinkOrRemove(device.id);
                        setUnlinkDialogOpen(true);
                        dispatch(toggleAnyDialogOpen(true));
                      }}
                      style={{ marginRight: "6px", cursor: "pointer" }}
                    >
                      <CancelScheduleSendRoundedIcon
                        sx={{
                          color: disableUnlinkButton ? "lightgray" : "red",
                          height: "var(--iconSize)",
                          width: "var(--iconSize)"
                        }}
                      />
                    </button>
                    </Tooltip>
                    }
                    {disableRemoveButton &&
                    <button
                    disabled={disableRemoveButton}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDialogValue(event.currentTarget.value);
                      setDeviceToUnlinkOrRemove(device.id);
                      setRemoveDialogOpen(true);
                      dispatch(toggleAnyDialogOpen(true));
                    }}
                    style={{ marginRight: "2px", cursor: "pointer" }}
                  >
                    <DeleteOutlineRoundedIcon
                      sx={{
                        color: disableRemoveButton ? "lightgray" : "red",
                        height: "var(--iconSize)",
                        width: "var(--iconSize)"
                      }}
                    />
                  </button>
                    }
                    {!disableRemoveButton &&
                    <Tooltip title={t("tooltip.delete") || ""}>
                    <button
                      disabled={disableRemoveButton}
                      value={"remove"}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDialogValue(event.currentTarget.value);
                        setDeviceToUnlinkOrRemove(device.id);
                        setRemoveDialogOpen(true);
                        dispatch(toggleAnyDialogOpen(true));
                      }}
                      style={{ marginRight: "2px", cursor: "pointer" }}
                    >
                      <DeleteOutlineRoundedIcon
                        sx={{
                          color: disableRemoveButton ? "lightgray" : "red",
                          height: "var(--iconSize)",
                          width: "var(--iconSize)"
                        }}
                      />
                    </button>
                    </Tooltip>
                    }
                  </TableCell>
                  )}
              </TableRow>
            );
          })}
        </TableBody>
      );
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  //FINAL RETURN
  return (
    <>
      <>
      <div style={{ width: "100%", borderRadius: "0" }}>
        <TableContainer className={styles["measurement-sensor-table"]}>
          <Table aria-label="Sensors" size="small">
            {/* ********************************* */}
            <TableHeader />
            {/* ********************************* */}
            <TableContent />
            {/* ********************************* */}
          </Table>
        </TableContainer>
      </div>
      </>
      <>
        {linkDeviceDialogOpen && (
          <LinkDevice
            handleClose={() => {
              setlinkDeviceDialogOpen(false);
              dispatch(toggleAnyDialogOpen(false));
            }}
            handleSave={() => {
              setlinkDeviceDialogOpen(false);
              dispatch(toggleAnyDialogOpen(false));
            }}
            measurementPointId={props.measurementPointId}
            planId={props.planId}
            editedDevice={editedDevice}
          />
        )}
        <ConfirmDialog 
        key={unlinkDialogOpen ? "unlink-dialog" : removeDialogOpen ? "remove-dialog" : "disabled-dialog"}
        open={unlinkDialogOpen || removeDialogOpen}
        value={dialogValue}
        setOpen={unlinkDialogOpen ? setUnlinkDialogOpen : setRemoveDialogOpen}
        onConfirm={() => {
          dialogValue === "unlink" ? onLinkEndClick(deviceToUnlinkOrRemove) : onRemoveClick(deviceToUnlinkOrRemove);
          dispatch(toggleAnyDialogOpen(false));
        }}
        content={unlinkDialogOpen ? t("dialog.unlink_device") : removeDialogOpen ? t("dialog.remove_device") : ""}
        />
      </>
    </>
  );
  ///////////////////////////////////////////////////////////////////////////////////////
}
