import React, { useState, ChangeEvent } from "react";
import { TableCell, Typography, TableRow, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import MoveToInboxRoundedIcon from "@mui/icons-material/MoveToInboxRounded";

import { ProjectTransferReturn } from "./projectApi";
import { UserCompanyObject } from "../company/companySlice";

import { commonAttributes } from "../common/commonAttributes";

import styles from "./ProjectListRow.module.css";

import axios from "axios";

import { useTranslation } from "react-i18next";

import ConfirmDialog from "../UI/ConfirmDialog";

interface ITransferProject {
  transferProject: ProjectTransferReturn;
  companyUsers: UserCompanyObject[];
}

export default function ProjectListTransferProjectRow(props: ITransferProject) {
  // COMMON

  const { t } = useTranslation();

  const [selectedCompany, setSelectedCompany] = useState("");

  const [errorMessage, seterrorMessage] = useState("");

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleSelectedCompany = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(event.target.value);
  };

  const handleTransfer = () => {
    if (selectedCompany === "-1") {
      seterrorMessage(t("projects_page.transferprojectrow.select_company_before"));
    }

    const selectedCompanyObj: UserCompanyObject | undefined = props.companyUsers.find(
      x => x.companyId.toString() === selectedCompany
    );

    if (selectedCompanyObj === undefined) {
      seterrorMessage(t("projects_page.transferprojectrow.error_in_company"));
      return;
    }else {
      const urlPath =
        commonAttributes.apiUrl + `/Projects/${props.transferProject.projectId}/Transfers/Confirm`;
      //console.log("TRANSFER TO COMPANY (" + selectedCompany + ") [" + urlPath + "]");

      axios
        .post(urlPath, {
          projectId: props.transferProject.projectId,
          newOwnerCompanyId: selectedCompany
        })
        .then(reponse => {
          console.log("OK!");
          window.location.reload();
        })
        .catch(error => {
          seterrorMessage(t("projects_page.transferprojectrow.transfer_error") + error);
        });
    }
  };

  return (
    <>
    <>
      <TableRow
        key={"transferrow_" + props.transferProject.projectName + props.transferProject.projectId}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={styles["projects-table-address-cell"]}
        >
          <Typography variant="body1" component="span">
            {props.transferProject.projectName}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          className={styles["projects-table-address-cell"]}
        >
          <TextField
            fullWidth
            size="small"
            label={t("projects_page.transferprojectrow.select_company_to_transfer")}
            select
            SelectProps={{
              native: true
            }}
            onChange={handleSelectedCompany}
          >
            <option value="-1">-</option>
            {props.companyUsers.map(company => {
              return (
                <option
                  key={"selectedTransferCompany_" + company.id}
                  value={company.companyId}
                  style={{ fontFamily: "Fellix-Regular !important" }}
                >
                  {company.companyName}
                </option>
              );
            })}
          </TextField>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          className={styles["projects-table-address-cell"]}
        >
          {t("projects_page.transferprojectrow.transfer")}
        </TableCell>
        <TableCell className={styles["projects-table-edit-cell"]} size="small" align="right">
          <IconButton size="small" onClick={(e) => {
            e.preventDefault();
            setShowConfirmDialog(true);
          }}>
            <MoveToInboxRoundedIcon
              sx={{
                color: "var(--colorOrange)",
                height: "var(--iconSize)",
                width: "var(--iconSize)"
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        style={{ display: errorMessage === "" ? "none" : "" }}
        key={"ERRORROW_" + props.transferProject.projectName + props.transferProject.projectId}
      >
        <TableCell>
          <Typography variant="body1" component="span" style={{ color: "red" }}>
            {errorMessage}
          </Typography>
        </TableCell>
      </TableRow>
    </>
    <>
    <ConfirmDialog 
      key="transfer-project-dialog"
      open={showConfirmDialog}
      content={t("dialog.confirm_transfer")}
      setOpen={setShowConfirmDialog}
      value={undefined}
      onConfirm={handleTransfer}
    />
    </>
    </>
  );
}
