// IMPORTS
import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useParams, Navigate } from "react-router-dom";
import { Button, useMediaQuery, Box, Grid, Stack, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

// COMPONENTS
import CompanyViewInfo from "./settings/CompanyViewInfo";
import CompanyViewUsers from "./settings/CompanyViewUsers";
import MobileMenu from "../UI/MobileMenu";

// CSS
import styles from "./CompanySettings.module.css";

// SLICE
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyUserRights,
  getUserAdminCompanies,
  getUserFinancialManagerCompanies
} from "./companySlice";

export default function CompanySettings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyUserRights());
  }, [dispatch]);

  const { t } = useTranslation();
  const urlParams = useParams();
  const navigateToLink = useNavigate();

  const [currentTab, setcurrentTab] = useState(0);

  const userAdminCompanies = useSelector(getUserAdminCompanies);
  const userFinancialManagerCompanies = useSelector(getUserFinancialManagerCompanies);

  const [isFinancialManager, setFinancialManager] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  useEffect(() => {
    // SELECTED CHOOSED TAB

    let currentUrl = urlParams["*"];
    let companyId = urlParams["companyid"];

    setFinancialManager(userFinancialManagerCompanies.some(x => x.companyId === Number(companyId)));
    setAdmin(userAdminCompanies.some(x => x.companyId === Number(companyId)));

    if (isAdmin === false && currentUrl === "users") {
      navigateToView("info");
    }

    switch (currentUrl) {
      case "info":
        setcurrentTab(0);
        break;
      case "users":
        setcurrentTab(1);
        break;
    }
  }, [urlParams, userFinancialManagerCompanies, userAdminCompanies]);

  const navigateToView = (_value: string) => {
    let linkUrl: string = "";
    //let tabIndex = 0;

    switch (_value) {
      case "info":
        linkUrl = "info";
        //tabIndex = 0;

        break;
      case "users":
        linkUrl = "users";
        //tabIndex = 1;

        break;
    }
    //setCurrentTab(tabIndex);
    navigateToLink(linkUrl);
  };

  return (
    <>
      {smallScreen ? (
        <>
          <Box
            sx={{
              flexGrow: "1",
              background: "var(--colorBackground)"
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={2} sx={{ alignSelf: "flex-start", marginLeft: "1.5%", marginTop: "1.5%" }}>
                <MobileMenu 
                  id="company-settings-mobile-menu"
                  icon="arrow" 
                  menuText={
                    currentTab === 0 ? t("company_settings.company_info")
                    : t("company_settings.company_users")
                    }>
                  <Stack spacing={0}>
                    <MenuItem>
                      <Button
                        variant={currentTab === 0 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        onClick={() => navigateToView("info")}
                      >
                        {t("company_settings.company_info")}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant={currentTab === 1 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        onClick={() => navigateToView("users")}
                        disabled={!isAdmin}
                      >
                        {t("company_settings.company_users")}
                      </Button>
                    </MenuItem>
                  </Stack>
                </MobileMenu>
                <Grid item xs={12}>
                  <div className={styles["company-settings-content"]}>
                    <Routes>
                      <Route path="/" element={<Navigate replace to="./info" />} />
                      <Route
                        path="/info"
                        element={
                          <CompanyViewInfo
                            isAdmin={isAdmin}
                            isFinancialManager={isFinancialManager}
                          />
                        }
                      />
                      <Route path="/users" element={<CompanyViewUsers />} />
                    </Routes>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <div className={styles["company-settings-container"]}>
          <div className={styles["company-settings-navigation"]}>
            <Button
              variant={currentTab === 0 ? "contained" : "outlined"}
              fullWidth
              size="small"
              onClick={() => navigateToView("info")}
            >
              {t("company_settings.company_info")}
            </Button>
            <Button
              variant={currentTab === 1 ? "contained" : "outlined"}
              fullWidth
              size="small"
              onClick={() => navigateToView("users")}
              disabled={!isAdmin}
            >
              {t("company_settings.company_users")}
            </Button>
          </div>
          <div className={styles["company-settings-content"]}>
            <Routes>
              <Route path="/" element={<Navigate replace to="./info" />} />
              <Route
                path="/info"
                element={
                  <CompanyViewInfo isAdmin={isAdmin} isFinancialManager={isFinancialManager} />
                }
              />
              <Route path="/users" element={<CompanyViewUsers />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}
