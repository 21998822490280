import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./DeviceManagement.module.css";
import Devices from "../features/project/projectview/components/devices/Devices";

const DeviceManagement = () => {
    const { t } = useTranslation();

    return (
      <div className={styles["device-management-container"]}>
        <div className={styles["device-management-header"]}>
          <Typography sx={{ fontSize: "var(--largeHeaderFontSize)" }} component="h1">
            {t("device_management.device_management")}
          </Typography>
        </div>
        <Devices />
      </div>
    );
};

export default DeviceManagement;