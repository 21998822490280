import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

// IMPORTS
import { Dialog, Button, TextField, MenuItem } from "@mui/material";

import { commonAttributes } from "../../common/commonAttributes";

// CSS
import styles from "./CompanyViewAddUserDialog.module.css";
import { GridSelectionModel } from "@mui/x-data-grid";

// INTERFACE
type CompanyUserDataRow = {
  id: number;

  userId: number;
  userName: string;

  startDateTime: string;
  endDateTime: string;
  status: string;

  companyId: number;
  companyName: string;

  companyRoleId: number;
  companyRoleName: string;
};

interface FormInterface {
  companyId: number;
  handleSave: any;
  handleClose: () => void;
  usersData: Array<CompanyUserDataRow>;
  add: boolean;
  edit: boolean;
  userToEdit?: CompanyUserDataRow[];
  gridSelectionModel: GridSelectionModel;
}

function validateEmail(email: string) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export default function CompanyViewAddUserDialog(props: FormInterface) {
  // COMMONS
  const { t } = useTranslation();

  useEffect(() => {
    if (props.companyId >= 0) getCompanyRoles();
    else
      setErrorMessage(
        t("company_settings.add_dialog.error_false_companyid") + ", " + props.companyId
      );
  }, [props.companyId]);

  // **********************************************************************************************************************
  // FORM DATA
  const [companyRoles, setCompanyRoles] = useState<Array<companyRoleType>>(
    Array<companyRoleType>()
  );

  const [userEmail, setuserEmail] = useState<string | undefined>(undefined);
  const [selectedRole, setselectedRole] = useState<companyRoleType | undefined>(undefined);

  const userToEdit = props.userToEdit !== undefined ? props.userToEdit[0] : null;

  //ERROR

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.edit === true && userToEdit !== (undefined || null)) {
      setselectedRole({id: userToEdit.companyRoleId.toString(), name: userToEdit.companyRoleName});
      setuserEmail(userToEdit.userName);
    }
    return () => {
      setselectedRole(undefined);
      setuserEmail(undefined);
    }
  }, [props.edit, userToEdit]);

  const getCompanyRoles = () => {
    const urlPath = commonAttributes.apiUrl + `/companies/roles`;

    axios
      .get(urlPath)
      .then(response => {
        const dataObj: Array<any> = response.data;

        let companyRoles: Array<companyRoleType> = Array<companyRoleType>();

        dataObj.map(role => companyRoles.push({ id: role.id, name: role.name }));

        setCompanyRoles(companyRoles);
      })
      .catch(error =>
        setErrorMessage(
          t("company_settings.add_dialog.error_failed_to_get_roles") + ", " + props.companyId
        )
      );
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedRole = companyRoles.find(x => x.id === event.target.value);
    setselectedRole(selectedRole);
  };

  const handleSave = () => {
    if (props.usersData.filter(row => row.userName === userEmail).length > 0) {
      setErrorMessage(t("company_settings.add_dialog.user_already_exists"));
      return;
    }
    if (userEmail === undefined || selectedRole === undefined) {
      setErrorMessage(t("company_settings.add_dialog.please_fill_required"));
      return;
    } else if (props.companyId < 0) {
      setErrorMessage(
        t("company_settings.add_dialog.error_false_companyid") + ", " + props.companyId
      );
      return;
    } else if (validateEmail(userEmail) === false) {
      setErrorMessage(t("company_settings.add_dialog.error_email_not_valid"));
      return;
    }

    const timeNow = moment.now();

    const timeUtc = moment.utc(timeNow, true);

    let newUserDTO = {
      userName: userEmail,
      companyId: props.companyId,
      companyRoleId: Number(selectedRole.id),
      startDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
    };

    const urlPath = commonAttributes.apiUrl + `/companies/${props.companyId}/Users/Add`;

    axios
      .post(urlPath, newUserDTO)
      .then(response => {
        props.handleSave();
      })
      .catch(error =>
        setErrorMessage(t("company_settings.add_dialog..error_failed_to_add_user") + ", " + error)
      );
  };
  const handleClose = () => {
    props.handleClose();
  };
  
  type companyRoleType = { id: string; name: string };

  const handleEditDialogSave = (email: string | undefined, role: companyRoleType | undefined) => {
    if (props.usersData.filter(row => row.userName === email && row.id !== props.gridSelectionModel[0]).length > 0) {
      setErrorMessage(t("company_settings.add_dialog.user_already_exists"));
      return;
    }
    if (email === undefined || role === undefined) {
      setErrorMessage(t("company_settings.add_dialog.please_fill_required"));
      return;
    } else if (props.companyId < 0) {
      setErrorMessage(
        t("company_settings.add_dialog.error_false_companyid") + ", " + props.companyId
      );
      return;
    } else if (validateEmail(email) === false) {
      setErrorMessage(t("company_settings.add_dialog.error_email_not_valid"));
      return;
    }

    let selectedUserId = props.gridSelectionModel[0];

    const oldRole = props.usersData.filter(user => user.id === selectedUserId);

    const timeNow = moment.now();

    const timeUtc = moment.utc(timeNow, true);
   
    const editUrlPath = commonAttributes.apiUrl + `/companies/Users/${selectedUserId}`;
    const addUrlPath = commonAttributes.apiUrl + `/companies/${props.companyId}/Users/Add`;

    let endTimeUserDTO = {
      id: selectedUserId,
      username: oldRole[0].userName,
      companyid: Number(props.companyId),
      companyRoleID: oldRole[0].companyRoleId,
      endDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
    }

    let updateUserDTO = {
        id: selectedUserId,
        username: email,
        companyId: Number(props.companyId),
        companyRoleID: role.id,
        startDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
      };
    axios
      .post(editUrlPath, endTimeUserDTO)
      .then(() => axios.post(addUrlPath, updateUserDTO))
      .then(() => props.handleSave())
      .catch(err => console.log("Error updating user information"))
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <div className={styles["adduser-form-container"]}>
        {/**************************************************************************** */}

        <div className={styles["adduser-form-header"]}>
          {t("company_settings.add_dialog.add_new_user")}
        </div>
        {/* *************************************************************************** */}

        <TextField
          label={t("company_settings.add_dialog.user_email")}
          variant="standard"
          fullWidth
          required
          value={userEmail !== undefined ? userEmail : ""}
          type="email"
          onChange={event => setuserEmail(event.target.value)}
        />
        <TextField
          label={t("company_settings.add_dialog.user_role")}
          variant="standard"
          fullWidth
          required
          select
          value={selectedRole !== undefined ? selectedRole.id : ""}
          onChange={handleRoleChange}
        >
          {companyRoles.map(role => {
            return (
              <MenuItem key={"companyroles_" + role.id} value={role.id}>
                {role.name}
              </MenuItem>
            );
          })}
        </TextField>
        {/* *************************************************************************** */}

        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" onClick={props.add ? () => handleSave() : () => handleEditDialogSave(userEmail, selectedRole)}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={() => handleClose()}>
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
  );
}
