import { Drawer } from "@mui/material";
import { ReactElement } from "react";

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  position: "top" | "bottom" | "left" | "right";
  content: ReactElement;
}

const DrawerComponent = (props: DrawerProps) => {
  const { open, onClose, position, content } = props;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={position}
      sx={{
        "& .MuiDrawer-root": {
          height: "100%"
        }
      }}
    >
      {content}
    </Drawer>
  );
};

export default DrawerComponent;
