import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ProjectViewInfoEdit.module.css";
import { FormControl, TextField, Grid, Button } from "@mui/material";
//import { useGetCompanyQuery } from "../../../../company/companyAPI";
import { useGetProjectCompanyQuery, useEditProjectMutation } from "../../../projectApi";
import { ProjectObject } from "../../../../../types";

import { RootState } from "../../../../../app/store";
import { useSelector } from "react-redux";

type PropTypes = {
  editMode: boolean;
  projectInfo: ProjectObject;
  handleEditModeToggle: () => void;
};

const ProjectViewInfoEdit = (props: PropTypes) => {
  const projectUserRole = useSelector((state: RootState) => state.selectedProject.userProjectRole);

  const { editMode, projectInfo, handleEditModeToggle } = props;
  // If we want to edit company through this view - might fit better to company admin UI
  //const { data, error, isLoading: isLoadingCompanies } = useGetUserAdminCompaniesQuery(1);

  const {
    data: company,
    error,
    isLoading: isLoadingCompany
  } = useGetProjectCompanyQuery(projectInfo.id);
  const [editProject, { isLoading: isLoadingPost }] = useEditProjectMutation();

  const { t } = useTranslation();

  const [name, setName] = React.useState(projectInfo.name);
  const [address1, setAddress1] = React.useState(projectInfo.address1);
  const [address2, setAddress2] = React.useState(projectInfo.address2 || "");
  const [postalCode, setPostalCode] = React.useState(projectInfo.postalCode);
  const [city, setCity] = React.useState(projectInfo.city);
  const [billingReference, setBillingReference] = React.useState(projectInfo.billingReference);

  useEffect(() => {
    setName(projectInfo.name);
    setAddress1(projectInfo.address1);
    setAddress2(projectInfo.address2 || "");
    setPostalCode(projectInfo.postalCode);
    setCity(projectInfo.city);
    setBillingReference(projectInfo.billingReference);
  }, [projectInfo]);

  // Validation
  const [nameTouched, setNameTouched] = React.useState(false);
  const [address1Touched, setAddress1Touched] = React.useState(false);
  const [postalCodeTouched, setPostalCodeTouched] = React.useState(false);
  const [cityTouched, setCityTouched] = React.useState(false);

  const nameValid = name?.trim() !== "";
  const nameInvalid = !nameValid && nameTouched;
  const address1Valid = address1?.trim() !== "";
  const address1Invalid = !address1Valid && address1Touched;
  const postalCodeValid = postalCode?.trim() !== "";
  const postalCodeInvalid = !postalCodeValid && postalCodeTouched;
  const cityValid = city?.trim() !== "";
  const cityInvalid = !cityValid && cityTouched;

  const formInvalid = !nameValid || !address1Valid || !postalCodeValid || !cityValid;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleAddress1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  };
  const handleAddress2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  };
  const handlePostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostalCode(event.target.value);
  };
  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };
  const handleBillingReferenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBillingReference(event.target.value);
  };

  const handleNameBlur = () => {
    setNameTouched(true);
  };
  const handleAddress1Blur = () => {
    setAddress1Touched(true);
  };
  const handlePostalCodeBlur = () => {
    setPostalCodeTouched(true);
  };
  const handleCityBlur = () => {
    setCityTouched(true);
  };
  
  const handleSave = () => {
    setNameTouched(true);
    setAddress1Touched(true);
    setPostalCodeTouched(true);
    setCityTouched(true);

    if (formInvalid) return;

    editProject({
      id: +projectInfo.id,
      ownerCompanyId: projectInfo.ownerCompanyId,
      name,
      address1,
      address2,
      postalCode,
      city,
      billingReference
    }).then(() => handleEditModeToggle());
  };

  return (
    <div className={styles["project-details-container"]}>
      {isLoadingCompany && <div>{t("notifications.loading_title")}</div>}
      {error && <div>{t("notifications.error_title")}</div>}
      {company && (
        <>
          <FormControl sx={{ m: 1, mb: 3, mr: 1 }}>
            <TextField
              disabled
              className={styles["project-details-text-field-view"]}
              id="project-company-text-field"
              label={t("projectview_page.edit_project_form.company_label")}
              value={company.name}
              error={nameInvalid}
              variant="standard"
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            ></TextField>
          </FormControl>
          <FormControl sx={{ m: 1, mb: 3, mr: 1 }}>
            <TextField
              className={!editMode ? styles["project-details-text-field-view"] : ""}
              disabled={!editMode}
              required={editMode}
              id="project-name-text-field"
              label={t("projectview_page.edit_project_form.project_name_label")}
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              error={nameInvalid}
              variant={editMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            ></TextField>
          </FormControl>
          <FormControl sx={{ m: 1, mb: 3, mr: 1 }}>
            <TextField
              className={!editMode ? styles["project-details-text-field-view"] : ""}
              disabled={!editMode}
              required={editMode}
              id="project-name-text-field"
              label={t("projectview_page.edit_project_form.address1_label")}
              value={address1}
              onChange={handleAddress1Change}
              sx={{ mb: 2 }}
              onBlur={handleAddress1Blur}
              error={address1Invalid}
              variant={editMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            ></TextField>
            <TextField
              className={!editMode ? styles["project-details-text-field-view"] : ""}
              disabled={!editMode}
              id="project-name-text-field"
              label={t("projectview_page.edit_project_form.address2_label")}
              value={address2 || ""}
              onChange={handleAddress2Change}
              sx={{ mb: 2 }}
              variant={editMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            ></TextField>
            <Grid container className={styles["postal-code-city-container"]}>
              <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                <TextField
                  className={!editMode ? styles["project-details-text-field-view"] : ""}
                  disabled={!editMode}
                  required={editMode}
                  id="project-name-text-field"
                  label={t("projectview_page.edit_project_form.postal_code_label")}
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  sx={{ mb: 2, mr: 2, flexGrow: 1 }}
                  onBlur={handlePostalCodeBlur}
                  error={postalCodeInvalid}
                  variant={editMode ? "outlined" : "standard"}
                  InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                  InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                ></TextField>
              </Grid>
              <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                <TextField
                  className={!editMode ? styles["project-details-text-field-view"] : ""}
                  disabled={!editMode}
                  required={editMode}
                  id="project-name-text-field"
                  label={t("projectview_page.edit_project_form.city_label")}
                  value={city}
                  onChange={handleCityChange}
                  sx={{ mb: 2, flexGrow: 1 }}
                  onBlur={handleCityBlur}
                  error={cityInvalid}
                  variant={editMode ? "outlined" : "standard"}
                  InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                  InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
                ></TextField>
              </Grid>
            </Grid>
            <TextField
              className={!editMode ? styles["project-details-text-field-view"] : ""}
              disabled={!editMode}
              required={editMode}
              id="project-name-text-field"
              label={t("projectview_page.edit_project_form.billing_reference_label")}
              value={billingReference || ""}
              onChange={handleBillingReferenceChange}
              sx={{ mb: 2, flexGrow: 1 }}
              variant={editMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            ></TextField>
            <Grid container className={styles["project-details-buttons-container"]}>
              {editMode ? (
                <>
                  <Button
                    className={styles["project-details-cancel-button"]}
                    variant="outlined"
                    sx={{ mr: 2 }}
                    disabled={isLoadingCompany || isLoadingPost}
                    onClick={handleEditModeToggle}
                  >
                    {t("projectview_page.edit_project_form.cancel_button")}
                  </Button>
                  <Button
                    className={styles["project-details-add-button"]}
                    variant="contained"
                    onClick={handleSave}
                  >
                    {t("projectview_page.edit_project_form.save_button")}
                  </Button>
                </>
              ) : (
                <Button
                  className={styles["project-details-cancel-button"]}
                  variant="contained"
                  sx={{ mr: 2 }}
                  disabled={
                    isLoadingCompany ||
                    isLoadingPost ||
                    (projectUserRole !== "Admin" && projectUserRole !== "Planner")
                  }
                  onClick={handleEditModeToggle}
                >
                  {t("projectview_page.edit_project_form.edit_button")}
                </Button>
              )}
            </Grid>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default ProjectViewInfoEdit;
