import React from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toggleAnyDialogOpen } from "../measurements/measurementsSlice";
import { useDispatch } from "react-redux";

interface DialogProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onConfirm: any;
	content: string;
	value: string | number | object | undefined;
}

const ConfirmDialog = ({ open, setOpen, onConfirm, content, value }: DialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

	const onClose = () => {
		onConfirm(value);
		setOpen(false);
	};

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText
					sx={{color: "var(--primaryFontColor)"}}
				> {content} 
				</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => {
          setOpen(false); 
          dispatch(toggleAnyDialogOpen(false));
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
					variant="contained"
          onClick={onClose}
        >
          {t("common.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;