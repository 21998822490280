import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";

export interface InitialState {
  loggedIn: boolean;
  user: User;
}

const initialState: InitialState = {
  user: {
    userName: undefined,
    firstName: undefined,
    lastName: undefined,
    idToken: undefined,
    accessToken: undefined,
    tokenExpiry: undefined,
    roles: undefined
  },
  loggedIn: false
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<User>) => {
      state.user.userName = action.payload.userName;
      state.user.firstName = action.payload.firstName;
      state.user.lastName = action.payload.lastName;
      state.user.idToken = action.payload.idToken;
      state.user.accessToken = action.payload.accessToken;
      state.user.tokenExpiry = action.payload.tokenExpiry;
      state.user.roles = action.payload.roles;
      state.loggedIn = true;

      window.sessionStorage.setItem("user", JSON.stringify(action.payload));
    },
    setLogoutUser: (state) => {
      state = initialState;
      window.sessionStorage.removeItem("user");
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLoggedInUser, setLogoutUser } = authSlice.actions;

export default authSlice.reducer;
