import { useState, useEffect } from "react";
import { RootState } from "../../../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchLicenceTypes } from "../../../projectSlice";

import { Button, Stack, TextField } from "@mui/material";

import { LicenceObject } from "../../../../../types";

import styles from "./ProjectViewLicences.module.css";

import { GetDateString } from "../../../../utils/commonFunctions";

import ChangeActiveLicenceDialog from "./ChangeActiveLicenceDialog";

// INTERFACE
interface ProjectUserInterface {
  projectId: number;
}

export default function ProjectViewLicences(props: ProjectUserInterface) {
  // COMMON
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);
  const usedLicenceDevicesState = useSelector(
    (state: RootState) => state.selectedProject.usedLicenceDevices
  );

  const [activeLicense, setactiveLicense] = useState<LicenceObject | undefined>(undefined);
  const [usedLicenceDevices, setusedLicenceDevices] = useState(0);
  const projectUserRole = useSelector((state: RootState) => state.selectedProject.userProjectRole);

  useEffect(() => {
    setactiveLicense(activeLicenceState);
  }, [activeLicenceState, dispatch]);

  useEffect(() => {
    dispatch(fetchLicenceTypes(props.projectId));
  }, [props.projectId, dispatch]);

  useEffect(() => {
    setusedLicenceDevices(usedLicenceDevicesState);
  }, [usedLicenceDevicesState]);

  const licenceName =
    activeLicense?.licenceTypeName !== undefined ? activeLicense?.licenceTypeName : "";

  const usedLicences =
    activeLicense?.maxLinkedDevices !== undefined
      ? usedLicenceDevices + " / " + activeLicense?.maxLinkedDevices
      : "";
  const startDate =
    activeLicense?.startDateTime !== undefined
      ? GetDateString(activeLicense?.startDateTime, "DD-MM-YYYY")
      : "";

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // DIALOG
  const [showAddDialog, setshowChangeDialog] = useState(false);

  const handleSave = () => {
    setshowChangeDialog(false);
  };

  return (
    <div className={styles["projectsettings-licences-container"]}>
      <div className={styles["projectsettings-content-container"]}>
        <Stack direction="column" spacing={3}>
          <TextField
            disabled
            className={styles["project-licences-text-field-view"]}
            variant="standard"
            label={t("projectview_page.settings_page.licence.type_name")}
            value={licenceName}
            contentEditable={false}
            sx={{ mt: 2 }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          />
          <TextField
            disabled
            className={styles["project-licences-text-field-view"]}
            variant="standard"
            label={t("projectview_page.settings_page.licence.device_count")}
            value={usedLicences}
            contentEditable={false}
            sx={{ mt: 2 }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          />
          <TextField
            disabled
            className={styles["project-licences-text-field-view"]}
            variant="standard"
            label={t("projectview_page.settings_page.licence.start_time")}
            value={startDate}
            contentEditable={false}
            sx={{ mt: 2 }}
            InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          />
        </Stack>
      </div>
      <Button
        variant="contained"
        size="small"
        onClick={() => setshowChangeDialog(true)}
        disabled={projectUserRole !== "Admin" || !activeLicense?.companyIsConfirmed}
        sx={{ marginTop: "10px" }}
      >
        {t("projectview_page.settings_page.licence.change_licence")}
      </Button>
      {showAddDialog && (
        <ChangeActiveLicenceDialog
          activeLicence={activeLicense}
          handleClose={() => setshowChangeDialog(false)}
          handleSave={() => {
            handleSave();
          }}
        />
      )}
    </div>
  );
}
