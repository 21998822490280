import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query";

import { commonAttributes } from "../features/common/commonAttributes";

import keycloak from "../KeyCloak";

export const baseQuery = fetchBaseQuery({
  baseUrl: commonAttributes.apiUrl,
  prepareHeaders: headers => {
    if (keycloak && keycloak.authenticated) {
      headers.set("authorization", `Bearer ${keycloak.token}`);
    }

    return headers;
  }
});

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    keycloak.logout();
  }
  return result;
};
