//STORE ROOT
import { RootState } from "../../app/store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  MeasurementPointObject,
  DeviceObject,
  SensorUnitObj,
  HistoryDataObject,
  MeasurementPointHistoryObject,
  SensorHistoryObject
} from "../../types";
import {
  InitialMeasurementPoint,
  InitialDevice,
  InitialSensorUnit
  //generateMeasurementPoints
} from "./measurementInitialData";

import axios from "axios";

//COMMON ATTRIBUTES
import { commonAttributes } from "./../common/commonAttributes";
import { mergeDeep, performTimestampDuplicate } from "../utils/commonFunctions";
export let controller: AbortController;
/***************************************************************************** */
export const fetchMeasurementPointHistoryData = createAsyncThunk(
  "MeasurementPoints/fetchHistoryData",
  async (measurementPointId: number) => {
    const data = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/History`, {
      params: { measurementPointId: measurementPointId }
    });

    return {
      data: data.data,
      id: measurementPointId
    };
  }
);

export const fetchPlanHistoryData = createAsyncThunk(
  "MeasurementPoints/fetchPlanHistoryData",
  async (planId: number, { dispatch }) => {
    controller = new AbortController();
    let data = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/PlanHistoryPage`, {
      params: { planId: planId },
      signal: controller.signal
    });
    let outData = {};
    mergeDeep(outData, data.data.measurementPoints);
    performTimestampDuplicate(outData);
    // Refresh the graph after first data loading
    //dispatch(updateHistoryData({ data: outData }));

    let refreshCycle = 8;
    // nextToken is returned when there is multiple pages available
    while (data.data.nextToken) {
      data = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/PlanHistoryPage`, {
        params: { planId: planId, nextToken: data.data.nextToken },
        signal: controller.signal
      });
      // merge the data to old data
      mergeDeep(outData, data.data.measurementPoints);
      performTimestampDuplicate(outData);
      // Refresh the graph during the loading
      if (refreshCycle++ > 8) {
        refreshCycle = 0;
        dispatch(updateHistoryData({ data: outData }));
      }
    }

    return {
      data: outData,
      id: planId
    };
  }
);

export const fetchPlanMeasurementsDevices = createAsyncThunk(
  "MeasurementPoints/fetchPlanMeasurementsDevices",
  async (planId: number | string) => {
    const data = await axios.get(
      `${commonAttributes.apiUrl}/Plans/${planId}/MeasurementPointsDevices`
    );

    return {
      data: data.data,
      planId: planId
    };
  }
);

export const fetchPlanMeasurementsLatestData = createAsyncThunk(
  "MeasurementPoints/fetchPlanMeasurementsLatestData",
  async (planId: number | string) => {
    const data = await axios.get(
      `${commonAttributes.apiUrl}/MeasurementData/Latest/Plan/${planId}`
    );

    return {
      data: data.data,
      planId: planId
    };
  }
);

export const addMeasurementPoint = createAsyncThunk(
  "MeasurementPoints/addMeasurementPoint",
  async (newObj: { measurementName: string; planid: string }) => {
    const newMeasurement = {
      name: newObj.measurementName,
      positionX: 0,
      positionY: 0,
      positionZ: 0,
      planId: Number(newObj.planid)
    };

    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/Add`,
      newMeasurement
    );

    return { data: data.data };
  }
);

export const updateMeasurementPoint = createAsyncThunk(
  "MeasurementPoints/updateMeasurementPoints",
  async (updatedObj: {
    measurementName: string;
    measurentPointId: string;
    planId: string;
    positionX: number;
    positionY: number;
    positionZ: number;
  }) => {
    const newMeasurement = {
      name: updatedObj.measurementName,
      id: Number(updatedObj.measurentPointId),
      planid: Number(updatedObj.planId),
      positionX: Number(updatedObj.positionX),
      positionY: Number(updatedObj.positionY),
      positionZ: Number(updatedObj.positionZ)
    };

    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${updatedObj.measurentPointId}`,
      newMeasurement
    );

    return { data: data.data };
  }
);

export const updateMeasurementPointLocation = createAsyncThunk(
  "MeasurementPoints/updateMeasurementPoints",
  async (updatedObj: { measurentPointId: string; positionX: number; positionY: number }) => {
    //api/MeasurementPoints/1/UpdateLocation?positionX=123&positionY=456
    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${updatedObj.measurentPointId}/UpdateLocation?positionX=${updatedObj.positionX}&positionY=${updatedObj.positionY}`
    );

    return { data: data.data };
  }
);

export const removeMeasurementPoint = createAsyncThunk(
  "MeasurementPoints/removeMeasurementPoint",
  async (measurementPointId: string) => {
    const data = await axios.delete(
      `${commonAttributes.apiUrl}/MeasurementPoints/${measurementPointId}/DeleteWithReferences`
    );

    return { measurementPointId: measurementPointId };
  }
);

export const addDeviceWithDetails = createAsyncThunk(
  "MeasurementPoints/addDeviceWithDetails",
  async (deviceObj: {
    measurementPointId: string;
    deviceTypeId: string;
    plannedDepth: string;
    depthDefinition: number;
    sensorDepths: Array<{ typeName: string; depth: string }>;
  }) => {
    let plannedDepths: { [key: string]: number } = {};

    //MAP DEPTHS
    deviceObj.sensorDepths.forEach(depth => {
      plannedDepths = { ...plannedDepths, [depth.typeName]: Number(depth.depth + ".0") };
    });

    let newDeviceObj;

    if (deviceObj.depthDefinition === 2) {
      newDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        devicetypeid: deviceObj.deviceTypeId,
        depths: plannedDepths
      };
    } else {
      newDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        devicetypeid: deviceObj.deviceTypeId,
        PlannedDepth: Number(deviceObj.plannedDepth + ".0")
      };
    }

    //MAP TO DICTIONARY
    // POST: api/MeasurementPoints/5/Devices/AddWithDetails
    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${deviceObj.measurementPointId}/Devices/AddWithDetails`,
      newDeviceObj
    );

    return { data: data.data, measurementPointId: deviceObj.measurementPointId };
  }
);

export const linkMeasurementPointDevice = createAsyncThunk(
  "MeasurementPoints/linkMeasurementPointDevice",
  async (deviceObj: {
    measurementPointId: string;
    deviceId: string;
    deviceSerial: string;
    depthDefinition: number;
    depth: string;
    depths: Array<{ typeName: string; depth: string }>;
  }) => {
    let newLinkDeviceObj;

    let newDepths: { [key: string]: number } = {};

    //MAP DEPTHS
    deviceObj.depths.forEach(depht => {
      newDepths = { ...newDepths, [depht.typeName]: Number(depht.depth + ".0") };
    });

    if (deviceObj.depthDefinition === 2) {
      newLinkDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        serial: deviceObj.deviceSerial,
        depths: newDepths
      };
    } else {
      newLinkDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        serial: deviceObj.deviceSerial,
        depth: Number(deviceObj.depth + ".0")
      };
    }

    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${deviceObj.measurementPointId}/Devices/${deviceObj.deviceId}/LinkDevice`,
      newLinkDeviceObj
    );

    return {
      data: data.data,
      measurementPointId: deviceObj.measurementPointId,
      deviceId: deviceObj.deviceId
    };
  }
);

export const unlinkMeasurementPointDevice = createAsyncThunk(
  "MeasurementPoints/unlinkMeasurementPointDevice",
  async (params: { measurementPointID: string; deviceId: string }) => {
    await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${params.measurementPointID}/Devices/${params.deviceId}/UnlinkDevice`
    );

    return { measurementPoint: params.measurementPointID, deviceid: params.deviceId, data: "" };
  }
);

export const removeSensorFromMeasurementPoint = createAsyncThunk(
  "MeasurementPoints/removeMeasurementPointDevice",
  async (params: { measurementPointID: string; deviceId: string }) => {
    // DELETE: api/MeasurementPoints/Devices/5/DeleteWithReferences
    const data = await axios.delete(
      `${commonAttributes.apiUrl}/MeasurementPoints/Devices/${params.deviceId}/DeleteWithReferences`
    );

    return { measurementPoint: params.measurementPointID, deviceid: params.deviceId };
  }
);

/***************************************************************************** */
export interface DeviceColorObject {
  deviceSerial: string;
  color: string;
}
export interface ShowSeriesDevicesObject {
  deviceSerial: string;
  show: boolean;
}
export interface MeasurementPointsState {
  projectPlanId: number; //SET TO GET WANTED DATA
  measurementPoints: Array<MeasurementPointObject>; //TO ARRAY OBJECT
  historyData: HistoryDataObject;
  selectedPoints: Array<string>;
  showSeriesDevices: Array<ShowSeriesDevicesObject>;
  loading: boolean;
  showRHorAH: boolean;
  showHumidityChart: boolean;
  showTemperatureChart: boolean;
  showMcChart: boolean;
  showDifferentialPressureChart: boolean;
  showPressureChart: boolean;
  showCo2Chart: boolean;
  showTvocChart: boolean;
  showDustChart: boolean;
  showDustPm10Chart: boolean;
  temperatureCompensation: boolean;
  deviceColors: Array<DeviceColorObject>;
  hoveredDevice: string;
  anyDialogOpen: boolean;
  uniqueColor: number;
  createdMeasurementPointId: number;
}

const initialState: MeasurementPointsState = {
  projectPlanId: 1,
  measurementPoints: Array<MeasurementPointObject>(),
  loading: false,
  historyData: {},
  selectedPoints: [],
  showSeriesDevices: [],
  showRHorAH: false,
  showHumidityChart: true,
  showTemperatureChart: true,
  showMcChart: true,
  showDifferentialPressureChart: false,
  showPressureChart: false,
  showCo2Chart: false,
  showTvocChart: false,
  showDustChart: false,
  showDustPm10Chart: false,
  temperatureCompensation: false,
  deviceColors: [],
  hoveredDevice: "",
  anyDialogOpen: false,
  uniqueColor: 0,
  createdMeasurementPointId: -1
};

/***************************************************************************** */
interface SensorUpdateProperties {
  measurementId: string;
  sensorDataArray: Array<DeviceObject>;
}

interface MeasurementPointPropertyUpdeteProperties {
  measurementId: string;
  property: string;
  value: string;
}

const colors = [
  '#e6194B', 
  '#3cb44b', 
  "#12CBC4",
  '#4363d8', 
  '#f58231', 
  '#911eb4',  
  '#9A6324',
  "#4F4557",   
  "#EE5A24",
  "#1B1464",
  "#F79F1F",
  "#245953",
  "#A3CB38",
  "#D980FA",
  "#EA2027",
  "#5758BB",
  "#FFA559",
  "#485460",
  "#FF6000",
  "#408E91",
  "#E49393",
  "#1289A7",
  "#B3C99C",
  "#006266",
  '#800000', 
  "#FFC312",
  "#ED4C67",
  '#ffe119', 
  '#42d4f4',
  '#f032e6', 
  "#009FBD",
  '#bfef45', 
  '#fabed4', 
  '#469990', 
  '#dcbeff',
  '#fffac8',
  '#aaffc3',
  '#808000', 
  '#ffd8b1', 
  '#000075', 
  '#a9a9a9',
  "#C4E538",
  "#D14D72",
  "#FDA7DF",
];

export const measurementsSlice = createSlice({
  name: "MeasurementPoints",
  initialState,
  reducers: {
    /* generateBulk: (state, action: PayloadAction<number>) => {
      state.measurementPoints = generateMeasurementPoints(action.payload);
    }, */
    updateDeviceColors: (state, action: PayloadAction<{ deviceSerial: string; }>) => {
      const { deviceSerial } = action.payload;
      const color = colors[state.uniqueColor];

      if (!state.deviceColors.some(device => device.deviceSerial === deviceSerial)) {
        state.deviceColors.push({ deviceSerial, color });
        state.uniqueColor = state.uniqueColor + 1;
      }
    },
    clearDeviceColors: state => {
      state.deviceColors = [];
      state.uniqueColor = 0;
    },
    toggleShowSeriesDevice: (state, action: PayloadAction<{deviceSerial: string}>) => {
      const { deviceSerial } = action.payload;

      const currentDeviceExists = state.showSeriesDevices.some(device => device.deviceSerial === deviceSerial);

      if (!currentDeviceExists) {
        state.showSeriesDevices.push({ deviceSerial, show: false });
      }else {
       const index = state.showSeriesDevices.findIndex(device => device.deviceSerial === deviceSerial);
       state.showSeriesDevices[index] = {deviceSerial, show: !state.showSeriesDevices[index].show};
      }
    },
    setHoveredDevice: (state, action: PayloadAction<string>) => {
      state.hoveredDevice = action.payload;
    },
    clearHoveredDevice: (state) => {
      state.hoveredDevice = "";
    },
    updateMeasurementPoints: (state, action: PayloadAction<Array<MeasurementPointObject>>) => {
      state.measurementPoints = action.payload;
    },
    // removeSensorFromMeasurementPoint: (
    //   state,
    //   action: PayloadAction<{ measurementId: string; sensorId: string }>
    // ) => {
    //   const measurementPointIndex = state.measurementPoints.findIndex(
    //     measurementPoint => measurementPoint.id === action.payload.measurementId
    //   );

    //   if (measurementPointIndex >= 0) {
    //     state.measurementPoints[measurementPointIndex].devices = state.measurementPoints[
    //       measurementPointIndex
    //     ].devices.filter(sensor => sensor.id !== action.payload.sensorId);
    //   }
    // },
    updateSensorData: (state, action: PayloadAction<{ data: SensorUpdateProperties }>) => {
      state.measurementPoints = state.measurementPoints.map(measurementPoint => {
        if (measurementPoint.id === action.payload.data.measurementId) {
          return { ...measurementPoint, sensors: action.payload.data.sensorDataArray };
        } else {
          return { ...measurementPoint };
        }
      });
    },
    toggleMeasurementPointSelectedState: (
      state,
      action: PayloadAction<{ measurementId: string; planId: string }>
    ) => {
      const id = action.payload.measurementId;
      const planId = action.payload.planId;

      const index = state.measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === id
      );

      if (index >= 0) {
        state.measurementPoints[index].selected = !state.measurementPoints[index].selected;
      }

      const selectedIndex = state.selectedPoints.findIndex(point => point === id.toString());

      if (selectedIndex >= 0 && state.measurementPoints[index].selected === false) {
        state.selectedPoints.splice(selectedIndex, 1);
      } else state.selectedPoints.push(id.toString());

      // STORE SELECTION TO LOCAL STORAGE
      localStorage.setItem(
        "PLANID_" + planId + "_SELECTED_MEASUREMENTS",
        JSON.stringify(state.selectedPoints)
      );
    },
    toggleShowRHorAHSelection: state => {
      state.showRHorAH = !state.showRHorAH;
    },
    toggleShowHumidityChartSelection: state => {
      state.showHumidityChart = !state.showHumidityChart;
    },
    toggleShowTemperatureChart: state => {
      state.showTemperatureChart = !state.showTemperatureChart;
    },
    toggleShowMcChart: state => {
      state.showMcChart = !state.showMcChart;
    },
    toggleShowDifferentialPressureChart: state => {
      state.showDifferentialPressureChart = !state.showDifferentialPressureChart;
    },
    toggleCo2Chart: state => {
      state.showCo2Chart = !state.showCo2Chart;
    },
    toggleShowTvocChart: state => {
      state.showTvocChart = !state.showTvocChart;
    },
    toggleShowPressureChart: state => {
      state.showPressureChart = !state.showPressureChart;
    },
    toggleShowDustChart: state => {
      state.showDustChart = !state.showDustChart;
    },
    toggleShowDustPm10Chart: state => {
      state.showDustPm10Chart = !state.showDustPm10Chart;
    },
    toggleTemperatureCompensation: state => {
      state.temperatureCompensation = !state.temperatureCompensation;
    },
    toggleAnyDialogOpen: (state, action) => {
      state.anyDialogOpen = action.payload;
    },
    updateMeasurementPointProperty: (
      state,
      action: PayloadAction<MeasurementPointPropertyUpdeteProperties>
    ) => {
      const { measurementId, property, value } = action.payload;
      const index = state.measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === measurementId
      );

      if (index >= 0) {
        let measurePoint = state.measurementPoints[index];

        state.measurementPoints[index] = { ...measurePoint, [property]: value };
      }
    },
    // Used to update graph during loading of the history data
    updateHistoryData: (state, action: PayloadAction<{ data: HistoryDataObject }>) => {
      // Object assign is used because the data could be readOnly from the loading cycle
      state.historyData = Object.assign({}, action.payload.data);
    }
  },
  extraReducers: {
    [fetchMeasurementPointHistoryData.pending.type]: (state, action) => {},
    [fetchMeasurementPointHistoryData.fulfilled.type]: (state, action) => {
      console.log("fulfilled", action.payload);
    },
    [fetchMeasurementPointHistoryData.rejected.type]: (state, action) => {},
    /*********************************************************************************** */
    [fetchPlanHistoryData.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchPlanHistoryData.fulfilled.type]: (state, action) => {
      state.historyData = action.payload.data;
      state.loading = false;
    },
    [fetchPlanHistoryData.rejected.type]: (state, action) => {
      state.loading = false;
    },
    /*********************************************************************************** */
    [fetchPlanMeasurementsDevices.pending.type]: (state, action) => {},
    [fetchPlanMeasurementsDevices.fulfilled.type]: (state, action) => {
      try {
        let newMeasurementPoints: Array<MeasurementPointObject> =
          new Array<MeasurementPointObject>();

        let dataObj: Array<any> = action.payload.data;

        let pointsWithNoCoords = 0;

        // GET SELECTED MEASUREMENTS FROM STORAGE
        const localPlanSelectedPoints = localStorage.getItem(
          "PLANID_" + action.payload.planId + "_SELECTED_MEASUREMENTS"
        );

        let selectedPoints: string[] = [];

        if (localPlanSelectedPoints !== null && localPlanSelectedPoints !== undefined) {
          const localStorageObject = JSON.parse(localPlanSelectedPoints);

          selectedPoints = localStorageObject;
        }

        state.selectedPoints = selectedPoints;

        //////////////////////////////////////////////////////////////////////
        dataObj.forEach(point => {
          let newMeasurementPoint = InitialMeasurementPoint();

          //POINT INFO
          newMeasurementPoint.id = point.id;
          newMeasurementPoint.name = point.name;

          if (point.x === 0 && point.y === 0) {
            newMeasurementPoint.x =
              commonAttributes.newPointX + pointsWithNoCoords * commonAttributes.newPointDivX;
            newMeasurementPoint.y =
              commonAttributes.newPointY + pointsWithNoCoords * commonAttributes.newPointDivY;
            newMeasurementPoint.isLocated = false;
            pointsWithNoCoords++;
          } else {
            newMeasurementPoint.x = point.x;
            newMeasurementPoint.y = point.y;
            newMeasurementPoint.isLocated = true;
          }

          newMeasurementPoint.selected = state.selectedPoints.includes(point.id.toString());

          //-----------------------------------------
          //DEVICES
          let deviceArray: Array<any> = point.devices;

          deviceArray.forEach(device => {
            let newDevice = InitialDevice();
            const serial = device.serial;
            newDevice.id = device.id;
            newDevice.serial = device.serial;
            newDevice.deviceType = device.deviceType;

            newDevice.linkStartDateTime = device.linkStartDateTime;
            newDevice.linkEndDateTime = device.linkEndDateTime;
            newDevice.depthDefinition = device.depthDefinition;
            newDevice.sensorSpecificDepths = device.sensorSpecificDepths;

            const deviceExist = state.showSeriesDevices.some(device => device.deviceSerial === serial);
            !deviceExist && state.showSeriesDevices.push({deviceSerial: serial, show: true});
            //-----------------------------------------
            //SENSORTYPES
            let sensorArray: Array<any> = device.sensorTypes;

            sensorArray.forEach(sensor => {
              let newSensor: SensorUnitObj = InitialSensorUnit();

              newSensor.typeName = sensor.typeName;
              newSensor.unit = sensor.dataUnit;
              newSensor.depth = sensor.depth;
              newSensor.ambient = sensor.ambient;

              newDevice.sensorUnits.push(newSensor);
            });

            //PUSH NEW DEVICE
            newMeasurementPoint.devices.push(newDevice);
          });

          //PUSH NEW MEASUREMENTPOINT
          newMeasurementPoints.push(newMeasurementPoint);
        });
        //////////////////////////////////////////////////////////////////////

        state.measurementPoints = newMeasurementPoints;
      } catch (_e: any) {
        let e: Error = _e;
        console.log("Initialize error...., " + e.toString());
      }
    },
    [fetchPlanMeasurementsDevices.rejected.type]: (state, action) => {
      console.log("fetchPlanMeasurementsDevices REJECTED");
    },
    /*********************************************************************************** */
    [fetchPlanMeasurementsLatestData.pending.type]: (state, action) => {},
    [fetchPlanMeasurementsLatestData.fulfilled.type]: (state, action) => {
      //FILL DATA
      let measurementPoints = state.measurementPoints;

      let dataObj = action.payload.data;

      measurementPoints.forEach(point => {
        let pointData = dataObj[point.id];

        //MAP DEVICES
        point.devices?.forEach(device => {
          if (pointData === undefined || pointData === null) {
            return;
          }
          let deviceData = pointData[device.id];

          if (deviceData !== undefined && deviceData !== null) {
            device.lastUpdated = deviceData.lastUpdated;
            device.latestData = deviceData.latestData;
          }
        });
      });

      state.measurementPoints = measurementPoints;
    },
    [fetchPlanMeasurementsLatestData.rejected.type]: (state, action) => {
      console.log("fetchPlanMeasurementsLatestData REJECTED");
    },
    /*********************************************************************************** */
    [addMeasurementPoint.pending.type]: (state, action) => {},
    [addMeasurementPoint.fulfilled.type]: (state, action) => {
      let dataObj = action.payload.data;

      //FILL DATA
      let measurementPoints = state.measurementPoints;

      let newMeasurement: MeasurementPointObject = InitialMeasurementPoint();

      newMeasurement.id = dataObj.id;
      newMeasurement.name = dataObj.name;
      newMeasurement.x = dataObj.positionX;
      newMeasurement.y = dataObj.positionY;
      newMeasurement.selected = false;
      newMeasurement.isLocated = false;

      measurementPoints.push(newMeasurement);

      // SORT ZERO MEASUREMENT POINTS TO STACK

      let pointsWithNoCoords = 0;

      measurementPoints.forEach(point => {
        if (point.isLocated === false) {
          point.x = commonAttributes.newPointX + pointsWithNoCoords * commonAttributes.newPointDivX;
          point.y = commonAttributes.newPointY + pointsWithNoCoords * commonAttributes.newPointDivY;
          pointsWithNoCoords++;
        }
      });

      state.measurementPoints = measurementPoints;
      state.createdMeasurementPointId = Number(newMeasurement.id);
    },
    [addMeasurementPoint.rejected.type]: (state, action) => {
      console.log("addMeasurementPoint rejected");
    },

    /*********************************************************************************** */
    [updateMeasurementPoint.pending.type]: (state, action) => {},
    [updateMeasurementPoint.fulfilled.type]: (state, action) => {
      let dataObj = action.payload.data;

      //FILL DATA
      let measurementPoints = state.measurementPoints;

      let measurementPointIndex = measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === dataObj.id
      );

      //let newMeasurement: MeasurementPointObject = InitialMeasurementPoint();

      measurementPoints[measurementPointIndex].name = dataObj.name;

      state.measurementPoints = measurementPoints;
    },
    [updateMeasurementPoint.rejected.type]: (state, action) => {
      console.log("updateMeasurementPoint rejected");
    },
    /*********************************************************************************** */
    [updateMeasurementPointLocation.pending.type]: (state, action) => {},
    [updateMeasurementPointLocation.fulfilled.type]: (state, action) => {
      let dataObj = action.payload.data;

      //FILL DATA
      let measurementPoints = state.measurementPoints;

      let measurementPointIndex = measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === dataObj.id
      );

      //let newMeasurement: MeasurementPointObject = InitialMeasurementPoint();

      measurementPoints[measurementPointIndex].name = dataObj.name;
      measurementPoints[measurementPointIndex].isLocated = true;
      measurementPoints[measurementPointIndex].x = dataObj.positionX;
      measurementPoints[measurementPointIndex].y = dataObj.positionY;

      state.measurementPoints = measurementPoints;
    },
    [updateMeasurementPointLocation.rejected.type]: (state, action) => {
      console.log("updateMeasurementPointLocation rejected");
    },
    /*********************************************************************************** */
    [removeMeasurementPoint.pending.type]: (state, action) => {},
    [removeMeasurementPoint.fulfilled.type]: (state, action) => {
      let measurementId = action.payload.measurementPointId;
      //FILL DATA

      const filteredPoints = [
        ...state.measurementPoints.filter(measurementPoint => measurementPoint.id !== measurementId)
      ];

      state.measurementPoints = filteredPoints;
    },
    [removeMeasurementPoint.rejected.type]: (state, action) => {
      console.log("removeMeasurementPoint rejected");
    },
    /*********************************************************************************** */

    [addDeviceWithDetails.pending.type]: (state, action) => {},
    [addDeviceWithDetails.fulfilled.type]: (state, action) => {
      let dataObj = action.payload.data;

      //FILL DATA
      let measurementPoints = state.measurementPoints;

      const findedIndex = measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === action.payload.measurementPointId
      );

      if (findedIndex === -1) {
        console.log(
          "ERROR!, didnt find returned index [" + action.payload.measurementPointId + "]"
        );
        return;
      }

      let newDevice = InitialDevice();

      newDevice.id = dataObj.id;
      newDevice.deviceType = dataObj.deviceType;

      newDevice.linkStartDateTime = dataObj.linkDate;
      newDevice.depthDefinition = dataObj.depthDefinition;

      //Sensors
      newDevice.sensorUnits = dataObj.sensorTypes.map((sensor: any) => {
        let newSensor = InitialSensorUnit();

        newSensor.typeName = sensor.typeName;
        newSensor.unit = sensor.unit;
        newSensor.depth = sensor.depth;
        newSensor.ambient = sensor.ambient;

        return newSensor;
      });

      measurementPoints[findedIndex].devices.push(newDevice);

      state.measurementPoints = measurementPoints;
    },
    [addDeviceWithDetails.rejected.type]: (state, action) => {
      console.log("addDeviceWithDetails rejected");
    },

    /***********************************************************************************/
    [linkMeasurementPointDevice.pending.type]: (state, action) => {},
    [linkMeasurementPointDevice.fulfilled.type]: (state, action) => {
      let dataObj = action.payload.data;

      /*  data: data.data,
          measurementPointId: deviceObj.measurementPointId,
          deviceId: deviceObj.deviceId
      */

      //FILL DATA
      let measurementPoints = state.measurementPoints;

      const measurementPointIndex = measurementPoints.findIndex(
        measurementPoint => measurementPoint.id === action.payload.measurementPointId
      );

      if (measurementPointIndex === -1) {
        console.log(
          "ERROR!, didnt find returned index [" + action.payload.measurementPointId + "]"
        );
        return;
      }

      let measurementPointDevices = measurementPoints[measurementPointIndex].devices;

      const deviceIndex = measurementPointDevices.findIndex(x => x.id === action.payload.deviceId);

      measurementPointDevices[deviceIndex].serial = dataObj.serial;
      measurementPointDevices[deviceIndex].linkStartDateTime = dataObj.linkStartDateTime;

      measurementPoints[measurementPointIndex].devices = measurementPointDevices;

      state.measurementPoints = measurementPoints;
    },
    [linkMeasurementPointDevice.rejected.type]: (state, action) => {
      console.log("linkMeasurementPointDevice rejected");
    },
    /***********************************************************************************/
    [unlinkMeasurementPointDevice.pending.type]: (state, action) => {},
    [unlinkMeasurementPointDevice.fulfilled.type]: (state, action) => {},
    [unlinkMeasurementPointDevice.rejected.type]: (state, action) => {
      console.log("unlinkMeasurementPointDevice rejected"); //removeSensorFromMeasurementPoint
    },
    /***********************************************************************************/
    [removeSensorFromMeasurementPoint.pending.type]: (state, action) => {},
    [removeSensorFromMeasurementPoint.fulfilled.type]: (state, action) => {
      const removedSensorId = action.payload.deviceid;
      const mpId = action.payload.measurementPoint;

      const selectedMeasurementPoint = state.measurementPoints.find((mp: MeasurementPointObject) => mp.id === mpId);
      
      const filteredPointDevices = selectedMeasurementPoint?.devices.filter(device => device.id !== removedSensorId);

      if (selectedMeasurementPoint !== undefined && filteredPointDevices !== undefined) {
        const point = state.measurementPoints.findIndex(mp => mp.id === selectedMeasurementPoint.id);

        state.measurementPoints[point].devices = filteredPointDevices;
      }
    },
    [removeSensorFromMeasurementPoint.rejected.type]: (state, action) => {
      console.log("removeSensorFromMeasurementPoint rejected"); //
    }
  }
  /***********************************************************************************/
});

/* ********************************************************************************************************************** */
export const selectedHistoryDataSelector = (state: RootState) => {
  const selectedData: HistoryDataObject = Object.keys(
    state.ProjectPlanMeasurementPoints.historyData
  )
    .filter(key => state.ProjectPlanMeasurementPoints.selectedPoints.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: state.ProjectPlanMeasurementPoints.historyData[key] as MeasurementPointHistoryObject
      };
    }, {});

  const selectedHistoryData = Object.values(selectedData).map(measurementPoint =>
    Object.values(measurementPoint.measurementPointDevices)
  );
  
  return selectedHistoryData.flat(1) as Array<SensorHistoryObject>;
};

/* export const selectedHistoryDataSelector = (state: RootState) => {
  const selectedData: HistoryDataObject = Object.keys(
    state.ProjectPlanMeasurementPoints.historyData
  )
    .filter(key => state.ProjectPlanMeasurementPoints.selectedPoints.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: state.ProjectPlanMeasurementPoints.historyData[key] as MeasurementPointHistoryObject
      };
    }, {});

  const selectedHistoryData = Object.entries(selectedData).flatMap(
    ([measurementPointKey, measurementPoint]) =>
      Object.entries(measurementPoint.measurementPointDevices).flatMap(([deviceId, device]) => {
        const sensorsWithUniqueIds = Object.entries(device.measurementHistory).flatMap(
          ([sensorName, sensorObject], index) => {
            const sensorId = `${deviceId}_${index + 1}`;

            const measurementPointName = device.measurementPointName;
            const measurementPointsDevices = state.ProjectPlanMeasurementPoints.measurementPoints.flatMap(point => point.devices);
            const currentDevice = measurementPointsDevices.filter(device => device.id.toString() === deviceId.toString());
            let depth = null;
           
            if (currentDevice[0].sensorSpecificDepths) {
              const sensorForDepth = currentDevice[0].sensorUnits.filter(sensor => sensor.typeName === sensorObject.name);
              depth = sensorForDepth[0].depth;
            }else {
              depth = device.deviceDepth !== null || undefined ? device.deviceDepth : device.plannedDepth !== null || undefined ? device.plannedDepth : null;
            };

            const measurementNameObject = {
              ...sensorObject,
              depth: depth,
              sensorId
            };

            const sensorHistoryObject: SensorHistoryObject = {
              measurementPointName, 
              deviceId: deviceId.toString(),
              deviceTypeName: device.deviceTypeName,
              measurementHistory: {
                [sensorName]: measurementNameObject
              }
            };

            return sensorHistoryObject;
          }
        );

        return sensorsWithUniqueIds;
      })
  );

  return selectedHistoryData.flat(1) as Array<SensorHistoryObject>;
}; */

/* ********************************************************************************************************************** */

export const {
  //addMeasurementPoint,
  //removeMeasurementPoint,
  //generateBulk,
  updateDeviceColors,
  toggleShowSeriesDevice,
  updateMeasurementPoints,
  clearDeviceColors,
  //addDeviceToMeasurementPoint,
  //removeSensorFromMeasurementPoint,
  updateHistoryData,
  updateSensorData,
  toggleMeasurementPointSelectedState,
  toggleShowRHorAHSelection,
  toggleShowHumidityChartSelection,
  toggleShowTemperatureChart,
  toggleShowMcChart,
  toggleTemperatureCompensation,
  toggleCo2Chart,
  toggleShowDifferentialPressureChart,
  toggleShowTvocChart,
  toggleShowPressureChart,
  toggleShowDustChart,
  toggleShowDustPm10Chart,
  toggleAnyDialogOpen,
  setHoveredDevice,
  clearHoveredDevice,
  updateMeasurementPointProperty
} = measurementsSlice.actions;

export default measurementsSlice.reducer;
