import { Button, Divider, IconButton, MenuItem, Stack, Tooltip, useMediaQuery, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

//STORE
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { projectInitialState, deleteProjectPlan } from "../../projectSlice";
//import { useDeleteProjectPlanMutation } from "../../projectApi";

import styles from "./ProjectPlans.module.css";

//COMPONENTS
import AddProjectPlanForm from "./AddProjectPlanForm";
import { PlanObject } from "../../../../types";
import MobileMenu from "../../../UI/MobileMenu";
import ConfirmDialog from "../../../UI/ConfirmDialog";

//PROPS
interface PlanHeader {
  projectID: number;
  activePlan: number;
  editContent: boolean;
}

export default function ProjectPlans(props: PlanHeader) {
  const { t } = useTranslation();

  let navigateToLink = useNavigate();

  let dispatch = useDispatch();

  const selectedProjectState = useSelector((state: RootState) => state.selectedProject);

  const [selectedProject, setselectedProject] = useState(projectInitialState);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const loading = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.loading);

  const [currentPlanName, setCurrentPlanName] = useState<string>("");

  const [deletePlanDialogOpen, setDeletePlanDialogOpen] = useState<boolean>(false);
  const [planToRemove, setPlanToRemove] = useState<PlanObject>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  ///const [deleteProjectPlan, { isLoading: isLoadingDelete }] = useDeleteProjectPlanMutation();

  useEffect(() => {
    if (
      selectedProjectState.plans === undefined ||
      selectedProjectState.plans.length === 0 ||
      selectedProjectState.plans.length !== selectedProject.plans.length
    ) {
      navigateToLink("/projects/" + props.projectID + "/load");
    }

    setselectedProject(selectedProjectState);
  }, [selectedProjectState, navigateToLink, props.projectID, selectedProject.plans.length]);

  const [editContent, setEditContent] = useState(false);

  useEffect(() => {
    setEditContent(props.editContent);
  }, [props.editContent]);

  const [editedPlan, seteditedPlan] = useState<PlanObject | undefined>(undefined);

  useEffect(() => {
   selectedProject.plans.map(plan => plan.id === props.activePlan ? setCurrentPlanName(plan.name) : null);
  }, [selectedProject, props.activePlan]);

  //***************************************************************************************************** */
  const AddPlanElements = () => {
    if (editContent) {
      return (
        <React.Fragment>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Button
              key="add_plan_key"
              variant="contained"
              color="success"
              size="small"
              sx={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap" }}
              fullWidth
              onClick={() => openEditDialog(undefined, false)}
              startIcon={
                <AddCircleOutlineIcon
                  sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
                />
              }
            >
              {t("project_plan_list.add_plan")}
            </Button>
          </Stack>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            flexItem
            light={false}
            sx={{ borderColor: "var(--colorDivider)", marginBottom: "5px", marginTop: "5px" }}
          />
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  const [addDialogOpen, setaddFormOpen] = useState(false);

  const handleFormSave = () => {
    setaddFormOpen(false);
    setIsEditing(false);
  };

  const FillStack = () => {
    let btnTexts: string[] = [];

    for (let index = 0; index < 150; index++) {
      btnTexts.push("button_" + index);
    }

    return (
      <React.Fragment>
        {btnTexts.map(text => (
          <Button variant="contained">{text}</Button>
        ))}
      </React.Fragment>
    );
  };

  //***************************************************************************************************** */
  // OPEN

  const openEditDialog = (planID: PlanObject | undefined, editing: boolean) => {
    setIsEditing(editing);
    seteditedPlan(planID);
    setaddFormOpen(true);
  };

  // DELETE
  const deletePlanDialog = (plan: PlanObject | undefined) => {
    if (plan === undefined) return;

      dispatch(deleteProjectPlan(plan.id));
    };
  //*****************************************************************************************************
  return (
    <>
    {/* Mobile view************************************************************************/}
    
    {smallScreen ?
     <>
      <MobileMenu 
        id="project-plans-mobile-menu"
        icon={"arrow"} 
        menuText={currentPlanName || t("project_plans.menutext")}>
        <AddPlanElements />
         <Stack spacing={0}>
          {selectedProject.plans.map(plan => 
            <MenuItem key={plan.id}>
            <Button
              key={plan.id}
              variant={plan.id === props.activePlan ? "contained" : "outlined"}
              size="small"
              sx={{ 
                width: "100%", 
                overflow: "hidden", 
                whiteSpace: "normal", 
                wordWrap: "break-word"
              }}
              fullWidth
              onClick={() => {
                setCurrentPlanName(plan.name);
                navigateToLink("/projects/" + props.projectID + "/plans/" + plan.id)
              }
                      }
              >
              {plan.name}
              </Button>
              {editContent ? (
                <React.Fragment>
                <IconButton
                  key={plan.id}
                  data-iseditingbutton={true}
                  onClick={() => {
                    openEditDialog(plan, true);
                  }}
                  style={{ background: "white", borderRadius: "20px", width: "25px", height: "25px", marginLeft: "10px", alignSelf: "center" }}
                  >
                  <EditRoundedIcon
                    sx={{ height: "var(--iconSize)", width: "var(--iconSize)", color: "black !important" }}
                  />
                  </IconButton>
                  <IconButton
                    data-iseditingbutton={true}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setPlanToRemove(plan);
                      setDeletePlanDialogOpen(true);
                    }}
                    style={{ background: "white", borderRadius: "20px", width: "25px", height: "25px", marginLeft: "10px", alignSelf: "center"}}
                  >
                  <DeleteOutlineRoundedIcon
                    sx={{ height: "var(--iconSize)", width: "var(--iconSize)", color: "red !important" }}
                  />
                  </IconButton>
                  </React.Fragment>
                    ) : (
                      <></>
                    )}
            </MenuItem>
          )}
       </Stack>
      </MobileMenu>
      <div>
        <ConfirmDialog 
        key="delete-plan-dialog"
        open={deletePlanDialogOpen}
        value={planToRemove}
        content={t("dialog.delete_plan")}
        setOpen={setDeletePlanDialogOpen}
        onConfirm={() => deletePlanDialog(planToRemove)}
        />
      {addDialogOpen && (
        <AddProjectPlanForm
          isEditing={isEditing}
          maxOrdinalNumber={5}
          currentOrdinalNro={-1}
          open={addDialogOpen}
          projectId={props.projectID}
          plan={editedPlan} 
          projectPlans={selectedProject.plans}
          handleClose={() => {
            setaddFormOpen(false);
            setIsEditing(false);
          }}
          handleSave={() => handleFormSave()}
          deviceSettings={false}
        />
      )}
      </div>
     </>
      :
      /**Mobile view end *********************************************************************************/

      <div className={styles["plans-container"]}>
      <AddPlanElements />

      <div className={styles["plans-stack"]}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          {selectedProject.plans.map(plan => {
            return (
              <div
                className={styles["plans-stack-button-row"]}
                key={"plan_button_" + plan.name + plan.id}
              >
                <Button
                  key={plan.id}
                  variant={plan.id === props.activePlan ? "contained" : "outlined"}
                  size="small"
                  sx={{ 
                    width: "100%", 
                    overflow: "hidden", 
                    whiteSpace: "normal", 
                    wordWrap: "break-word" 
                  }}
                  fullWidth
                  onClick={() =>
                    navigateToLink("/projects/" + props.projectID + "/plans/" + plan.id)
                  }
                >
                  {plan.name}
                {plan.id === props.activePlan && loading && 
                <CircularProgress size={30} sx={{ml: "20px", color: "var(--colorOrange)"}}/>
                }
                </Button>
                {editContent ? (
                  <React.Fragment>
                    <Tooltip title={t("tooltip.edit") || ""} placement={"left"}>
                    <IconButton
                      key={plan.id}
                      data-iseditingbutton={true}
                      onClick={() => {
                        openEditDialog(plan, true);
                      }}
                    >
                      <EditRoundedIcon
                        sx={{ height: "var(--iconSize)", width: "var(--iconSize)", color: "black !important" }}
                      />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title={t("tooltip.delete") || ""} placement={"right"}>
                    <IconButton
                      data-iseditingbutton={true}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setPlanToRemove(plan);
                        setDeletePlanDialogOpen(true);
                      }}
                    >
                      <DeleteOutlineRoundedIcon
                        sx={{ color: "red !important", height: "var(--iconSize)", width: "var(--iconSize)" }}
                      />
                    </IconButton>
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </Stack>
      </div>
      <ConfirmDialog 
        key="delete-plan-dialog"
        open={deletePlanDialogOpen}
        content={t("dialog.delete_plan")}
        value={planToRemove}
        setOpen={setDeletePlanDialogOpen}
        onConfirm={() => deletePlanDialog(planToRemove)}
        />
      {addDialogOpen && (
        <AddProjectPlanForm
          isEditing={isEditing}
          maxOrdinalNumber={5}
          currentOrdinalNro={-1}
          open={addDialogOpen}
          projectId={props.projectID}
          plan={editedPlan}
          projectPlans={selectedProject.plans}
          handleClose={() => {
            setaddFormOpen(false);
            setIsEditing(false);
          }}
          handleSave={() => handleFormSave()}
          deviceSettings={false}
        />
      )}
    </div>}
    </>
  );
}
