import { useState, useEffect } from "react";
//KONVA
import { Image } from "react-konva";
import useImage from "use-image";

import no_image from "./../../../../../Resources/No_image_available.png";

import { commonAttributes } from "../../../../common/commonAttributes";

import axios from "axios";
import { useMediaQuery } from "@mui/material";

interface ImageProperties {
  projectPlanId: string;
  prepareImageChange: (image: { heigth: number; width: number; x: number; y: number }) => void;
  handleImageChange: (image: { heigth: number; width: number; x: number; y: number }) => void;
}

export function PlanViewFloorImage(props: ImageProperties) {
  //HELLO
  const imagePropertiesObject = {
    Name: "Pohjakuva1",
    x: 0, //commonAttributes.floorPlanImageX,
    y: 0, //commonAttributes.floorPlanImageY,
    Path: ""
  };

  const [floorImageProps] = useState(imagePropertiesObject);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  // ¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤

  useEffect(() => {
    //CONSOLE NEW IMAGE
    let unmountedd = false;

    if (props.projectPlanId !== "-1") {
      // CHECKING THAT MEASUREMENT POINT NAME DONT EXISTS

      const urlPath = commonAttributes.apiUrl + `/Plans/${props.projectPlanId}/Image`;

      axios
        .get(urlPath, { responseType: "blob" })
        .then(response => {
          if (!unmountedd || smallScreen) {
          setFloorImage(window.URL.createObjectURL(new Blob([response.data])));
          }
        })
        .catch(() => {
          //console.log(error)          return;
        });
    }
    return () => { unmountedd = true };
  }, [props.projectPlanId, smallScreen]);

  const [floorImage, setFloorImage] = useState(no_image);

  const [usedImage] = useImage(floorImage, "anonymous");

  useEffect(() => {
    //console.log(usedImage);
    //console.log(usedImage?.naturalHeight + " -- " + usedImage?.height);
    //console.log(usedImage?.naturalWidth + " -- " + usedImage?.width);
    let unmounted = false;

    if ((usedImage === undefined) || (unmounted && !smallScreen)) return;

    props.prepareImageChange({
      heigth: usedImage === undefined ? 9999 : usedImage.height,
      width: usedImage === undefined ? 9999 : usedImage.width,
      x: floorImageProps.x,
      y: floorImageProps.y
    });
    props.handleImageChange({
      heigth: usedImage === undefined ? 9999 : usedImage.height,
      width: usedImage === undefined ? 9999 : usedImage.width,
      x: floorImageProps.x,
      y: floorImageProps.y
    });
    return () => {unmounted = true};
  }, [usedImage, smallScreen]);

  return (
    <Image
      image={usedImage}
      y={floorImageProps.y}
      x={floorImageProps.x}
      stroke={false ? "red" : ""}
    />
  );
}
