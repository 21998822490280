import { Routes, Route, Navigate } from "react-router-dom";

// Companies
import CompanySetting from "../features/company/CompanySettings";

// CSS
import styles from "./Companies.module.css";

export default function Companies() {
  return (
    <div className={styles["companies-container"]}>
      <Routes>
        <Route path="/" element={<Navigate replace to="./settings" />} />
        <Route path="/settings/*" element={<CompanySetting />} />
      </Routes>
    </div>
  );
}
