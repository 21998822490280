import React, { useState, useEffect } from "react";
import { Button, Popper, Paper } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ClickAwayListener } from "@mui/material";

import { useTranslation } from "react-i18next";

import styles from "./LanguageSelection.module.css";

interface LanguageInterface {
  saveLanguage: boolean;
  display: boolean;
}

export default function LanguageSelection(props: LanguageInterface) {
  const { i18n } = useTranslation();

  const localLang = localStorage.getItem("language");

  const [selectedLanguage, setlanguage] = useState<CountryType>(
    localLang !== null && localLang !== undefined ? JSON.parse(localLang) : languages[0]
  );

  useEffect(() => {
    if (props.saveLanguage) localStorage.setItem("language", JSON.stringify(selectedLanguage));
    changeLanguage(selectedLanguage.code);
  }, [selectedLanguage]);

  const changeLanguage = (lang: string) => {
    lang = lang.toLocaleLowerCase();
    if (lang === "us") lang = "en";
    setOpen(false);
    i18n.changeLanguage(lang);
  };

  const [popperOpen, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = popperOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div style={{ display: props.display ? "" : "none" }}>
      <Button
        size="small"
        variant="outlined"
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        startIcon={
          <LanguageOutlinedIcon
            sx={{
              height: "var(--iconSize)",
              width: "var(--iconSize)"
            }}
          />
        }
        sx={{
          border: "1px solid var(--colorDivider)",
          color: "var(--colorOrange)",
          "&:hover": { border: "1px solid var(--colorDivider)" },
          "@media (max-width: 800px)": {
            fontSize: "0.6rem",
            minWidth: "0.5rem"
          }
        }}
      >
        {selectedLanguage.code}
      </Button>
      <Popper id={id} open={popperOpen} anchorEl={anchorEl} placement="bottom-end">
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper
          elevation={3}
          sx={{
            minWidth: "150px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexFlow: "column",
            zIndex: 4,
            "@media (max-width: 1450px)": {
              fontSize: "var(--navButtonFontSize)"
            }
          }}
        >
          {languages.map(lang => {
            return (
              <Button
                key={"language_" + lang.code}
                onClick={() => setlanguage(lang)}
                fullWidth
                startIcon={
                  <img
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${lang.imageCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${lang.imageCode.toLowerCase()}.png 2x`}
                    alt=""
                    className={styles["flag-image"]}
                  />
                }
              >
                <p className={styles["language-text"]}>{lang.label}</p>
              </Button>
            );
          })}
        </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

interface CountryType {
  code: string;
  imageCode: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
const languages: readonly CountryType[] = [
  {
    code: "fi",
    imageCode: "fi",
    label: "Suomeksi",
    phone: "358",
    suggested: true
  },
  {
    code: "en",
    imageCode: "us",
    label: "In English",
    phone: "1"
  }
];
