import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate, useParams, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";

//COMPONENTS
import MainView from "./../features/project/projectview/ProjectViewMain";
//import InfoView from "./../features/project/projectview/ProjectViewInfo";
import ProjectSettings from "./../features/project/projectview/ProjectSettings";
import ReportView from "./../features/project/projectview/ProjectViewReport";
//import AlarmsView from "./../features/project/projectview/ProjectViewAlarms";

//STORE
import { RootState } from "../app/store";
import { fetchSelectedProject, projectInitialState, updateUserProjectRole } from "../features/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";

//STYLES
import "./ProjectPage.root.css";
import styles from "./ProjectPage.module.css";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { InsertChart, Topic, Settings, Mode } from '@mui/icons-material'
import { getCompanyUserRights, getUserAdminCompanies } from "../features/company/companySlice";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function ProjectView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let urlParams = useParams();

  const userAdminCompanies = useSelector(getUserAdminCompanies);

  let projectId = Number(urlParams["projectId"]);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  useEffect(() => {
    dispatch(getCompanyUserRights());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedProject(projectId.toString()));
  }, [projectId, dispatch]);

  const selectedProjectState = useSelector((state: RootState) => state.selectedProject);
  const projectUserRole = useSelector((state: RootState) => state.selectedProject.userProjectRole);
  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);

  const [selectedProject, setselectedProject] = useState(projectInitialState);

  useEffect(() => {
    if (userAdminCompanies && userAdminCompanies.map(company => company.companyId).includes(selectedProjectState.project.ownerCompanyId)) {
      dispatch(updateUserProjectRole("Admin"));
    } 
  }, [selectedProjectState, userAdminCompanies, dispatch]);

  useEffect(() => {
    setselectedProject(selectedProjectState);
  }, [selectedProjectState, projectUserRole]);

  let navigateToLink = useNavigate();

  const [currentTab, setCurrentTab] = useState(0);

  const [editContent, seteditContent] = useState(false);

  const [lastPlanPath, setlastPlanPath] = useState("");

  const navigateToView = function (_value: string) {
    let linkUrl: string = "";
    let tabIndex = 0;

    switch (_value) {
      case "plan":
        linkUrl = lastPlanPath;
        tabIndex = 0;
        seteditContent(false);
        break;
      case "edit":
        linkUrl = lastPlanPath;
        tabIndex = 0;
        seteditContent(true);
        break;
      case "projectsettings":
        linkUrl = "projectsettings";
        tabIndex = 1;
        break;
      case "report":
        linkUrl = "report";
        tabIndex = 2;
        break;
      case "alarms":
        linkUrl = "alarms";
        tabIndex = 3;
        break;
    }

    setCurrentTab(tabIndex);
    navigateToLink(linkUrl);
  };

  const setlastPlanPathF = (urlPath: string) => {
    setlastPlanPath(urlPath);

    localStorage.setItem("PROJECT_ID_LASTPATH", urlPath);
  };

  const setActiveItems = function (urlPath: string | undefined) {
    let tabIndex = 0;

    if (urlPath === undefined) {
      tabIndex = 0;
    } else if (urlPath.indexOf("plans") !== -1) {
      tabIndex = 0;
      /*******************************/
      setlastPlanPath(urlPath);
      localStorage.setItem("PROJECT_" + selectedProject.project.id + "_LASTPATH", urlPath);

      /*******************************/
    } else if (urlPath.indexOf("projectsettings") !== -1) {
      tabIndex = 1;
    } else if (urlPath.indexOf("projecreporttinfo") !== -1) {
      tabIndex = 2;
    } else if (urlPath.indexOf("alarms") !== -1) {
      tabIndex = 3;
    }

    setCurrentTab(tabIndex);
  };

  /********************************************************************************************** */
  //FOLLOW URL PARAMETERS LINK
  useEffect(() => {
    let urlPath = urlParams["*"]?.toString(); //FOLLOWING PARAMETER FOR SITE

    //WHEN LOAD GET FIRST PROJECT
    if (urlPath !== undefined) {
      if (urlPath.indexOf("load") !== -1) {
        //DO NOTHING
      } else if (urlPath.indexOf("error") !== -1) {
        return;
      }
    }
    setActiveItems(urlPath);
  }, [urlParams, navigateToLink]);

  useEffect(() => {
    let urlPath = urlParams["*"]?.toString(); //FOLLOWING PARAMETER FOR SITE

    if (selectedProject === undefined) {
      return;
    } else if (selectedProject !== undefined && selectedProject.project.id <= 0) {
      return;
    } else if (selectedProject.plans[0] === undefined) {
      return;
    } else if (selectedProject.plans[0] !== undefined && selectedProject.plans[0].id <= -1) {
      return;
    }

    if (urlPath?.indexOf("load") !== -1) {
      // GET LAST PLAN FROM LOCAL STORAGE
      const localLastPath = localStorage.getItem(
        "PROJECT_" + selectedProject.project.id + "_LASTPATH"
      );

      const comparePlanId = localLastPath?.split("/")[1];

      const foundPlan = selectedProject.plans.find(x => x.id.toString() === comparePlanId);

      if (localLastPath !== null && localLastPath !== undefined && foundPlan !== undefined) {
        navigateToLink(`/projects/${selectedProject.project.id}/${localLastPath}`);
      } else if (selectedProject.plans !== undefined && selectedProject.plans.length > 0) {
        navigateToLink(
          `/projects/${selectedProject.project.id}/plans/${selectedProject.plans[0].id}`
        );
      }
    } else if (urlPath?.indexOf("plans") !== -1) {
      //CHECK VALID PATH
      let validPath = selectedProject.plans.find(x => "plans/" + x.id === urlPath) !== undefined;

      if (validPath === false) navigateToLink("./error");
    }
  }, [selectedProject]);

  /********************************************************************************************** */

  return (
    <div className={styles["projectView-container"]}>
      {/* NAVIGATION ********************************************************/}
      <div className={styles["tabs-container"]}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={{xs: 0.6, sm: 1, md: 2}}
          ml={smallScreen ? "5px" : "10px"}
          sx={{ height: "inherit", lineHeight: "1" }}
        >
          {/* ---------------------------------------------------------------------- */}
          <Button
            sx={{
              color:
                editContent === false && currentTab === 0
                  ? "var(--colorOrange)"
                  : "var(--primaryFontColor)",
              fontSize: "var(--buttonFontSize)"
            }}
            onClick={() => {
              navigateToView("plan");
            }}
            size="small"
          >
            <InsertChart
              sx={
                editContent === false && currentTab === 0
                  ? { color: "var(--colorOrange)", fontSize: "var(--iconSize)", marginRight: "0.1rem" }
                  : { color: "var(--colorDeselectedText)", fontSize: "var(--iconSize)", marginRight: "0.1rem" }
              }
            />
            {t("projectview_page.snapshot")}
          </Button>
          {/* ---------------------------------------------------------------------- */}
          <Button
            sx={{
              color:
                editContent === true && currentTab === 0
                  ? "var(--colorOrange)"
                  : "var(--primaryFontColor)",
              fontSize: "var(--buttonFontSize)"
            }}
            onClick={() => {
              navigateToView("edit");
            }}
            size="small"
            disabled={projectUserRole !== "Admin" && projectUserRole !== "Planner"}
          >
            <Mode
              sx={
                editContent === true && currentTab === 0
                  ? { color: "var(--colorOrange)", fontSize: "var(--iconSize)", marginRight: "0.1rem" }
                  : { color: "var(--colorDeselectedText)", fontSize: "var(--iconSize)", marginRight: "0.1rem" }
              }
            />
            {t("projectview_page.planning")}
          </Button>
          {/* ---------------------------------------------------------------------- */}
          <Button
            sx={{
              color: currentTab === 1 ? "var(--colorOrange)" : "var(--primaryFontColor)",
              fontSize: "var(--buttonFontSize)"
            }}
            onClick={() => navigateToView("projectsettings")}
            size="small"
          >
            <Settings
              sx={
                currentTab === 1
                  ? { color: "var(--colorOrange)", fontSize: "var(--iconSize)", marginRight: "0.1rem"}
                  : { color: "var(--colorDeselectedText)", fontSize: "var(--iconSize)", marginRight: "0.1rem"}
              }
            />
            {t("projectview_page.settings")}
            {activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0 && <PriorityHighIcon sx={!smallScreen ? {fontSize: "20px", color: "red"} : {fontSize: "17px", color: "red"}}/>}
          </Button>
          {/* ---------------------------------------------------------------------- */}
          <Link
            style={{ textDecoration: "none" }}
            target={"_blank"}
            to={"/report/" + urlParams.projectId}
          >
            <Button
              sx={{ color: "var(--primaryFontColor)", fontSize: "var(--buttonFontSize)" }}
              // onClick={() => navigateToView("report")}
              size="small"
            >
              <Topic
                sx={{ color: "var(--colorDeselectedText)", fontSize: "var(--iconSize)", marginRight: "0.1rem"}}
              />
              {t("projectview_page.report")}
            </Button>
          </Link>
        </Stack>
      </div>
      {/* CONTENT ********************************************************/}
      <div className={styles["content-container"]}>
        <Routes>
          {/*  If route dont contains /plans/id, route to first  */}
          {/*  TODO: BY FLOOR PLAN ORDER NUMBER */}
          <Route path="/" element={<Navigate replace to="./load" />} />
          <Route
            path="/load"
            element={<MainView projectId={Number(urlParams.projectId)} editContent={editContent} />}
          />
          <Route
            path="/plans/:planid"
            element={<MainView projectId={Number(urlParams.projectId)} editContent={editContent} />}
          />
          <Route path="/projectsettings/*" element={<ProjectSettings />} />
          <Route path="/report" element={<ReportView />} />
          <Route path="/error" element={<div>ERROR IN PAGE</div>} />
          {/* <Route path="/alarms" element={<AlarmsView />} /> */}
        </Routes>
      </div>
    </div>
  );
}
