import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Stack, Box, useMediaQuery, Grid, Tooltip, IconButton } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useParams } from "react-router-dom";

//STORE
import { RootState } from "../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import { selectedProjectType } from "../projectSlice";

import { controller } from "../../measurements/measurementsSlice";

import styles from "./ProjectViewMain.module.css";

//Components
import ProjectPlans from "./components/ProjectPlans";
import PlanView from "./components/planview/PlanView";
import MeasurementPointList from "./components/measurementPointList/MeasurementPointList";
import { CustomSwitch } from "../../UI/CustomSwitch";

//import { generateBulk } from "../../measurements/measurementsSlice";
import ProjectViewHistory from "./components/history/ProjectViewHistory";
import {
  fetchPlanHistoryData,
  fetchPlanMeasurementsDevices,
  fetchPlanMeasurementsLatestData,
  toggleShowRHorAHSelection,
  toggleTemperatureCompensation
} from "../../measurements/measurementsSlice";

import { MeasurementPointObject } from "../../../types";

//PROPS
interface ViewHeader {
  projectId: number;
  editContent: boolean;
}

export default function ProjectViewMain(props: ViewHeader) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let urlParams = useParams();

  const [projectPlanId, setprojectPlanId] = useState("-1");

  const smallScreen = useMediaQuery("(max-width: 999px)");
  const [showMeasurementsOnSmallScreen, setShowMeasurementsOnSmallScreen] = useState<boolean>(true);

  // ************************************************************************************************************

  const storeSelectedProject = useSelector((state: RootState) => state.selectedProject);

  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH
  );

  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );

  const [transitions, setTransitions] = useState<{
    buttons: boolean;
    measurements: boolean;
    planview: boolean;
    history: boolean;
  }>({
    buttons: false,
    measurements: false,
    planview: false,
    history: false
  });

  useEffect(() => {
    if (
      projectPlanId !== undefined &&
      projectPlanId === "-1" &&
      isProjectPlan(projectPlanId, storeSelectedProject)
    ) {
      setprojectPlanId(projectPlanId);
    }
    //CHECK OPENED PLAN

    //LOAD STORED LAYOUT
    defineLayout("load_layout");
  }, [storeSelectedProject, props.editContent]);

  // ************************************************************************************************************
  //MeasurementPoints from Store
  const storeMeasurementPoints = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.measurementPoints
  );

  const [measurementPointArray, setMeasurementPointArray] = useState(
    Array<MeasurementPointObject>()
  );

  useEffect(() => {
    setMeasurementPointArray(storeMeasurementPoints);
  }, [storeMeasurementPoints]);

  useEffect(() => {
    const projectPlanId = urlParams["planid"];

    let newProjectId = "-1";
    if (projectPlanId !== undefined) {
      newProjectId = projectPlanId;
    }

    setprojectPlanId(newProjectId);
  }, [urlParams]);

  useEffect(() => {
    if (Number(projectPlanId) < 0) return;

    dispatch(fetchPlanMeasurementsDevices(Number(projectPlanId)));

    dispatch(fetchPlanMeasurementsLatestData(Number(projectPlanId)));

    dispatch(fetchPlanHistoryData(Number(projectPlanId)));
    return () => controller.abort();
  }, [projectPlanId, dispatch]);

  /**********************************************************************************************/
  const isProjectPlan = (planId: string, selProject: selectedProjectType): boolean => {
    if (selProject !== undefined && selProject.plans !== undefined && selProject.plans.length > 0) {
      if (selProject.plans.find(x => x.id === Number(planId)) !== undefined) {
        return true;
      }
    }

    return false;
  };

  /**********************************************************************************************/

  interface ViewInterface {
    value: boolean;
    old: boolean;
  }

  const [viewPlanList, setviewPlanList] = useState<ViewInterface>({ value: true, old: true });
  const [viewMeasurementPointList, setviewMeasurementPointList] = useState<ViewInterface>({
    value: true,
    old: true
  });
  const [viewPlan, setViewPlan] = useState<ViewInterface>({ value: true, old: true });
  const [viewHistory, setViewHistory] = useState<ViewInterface>({ value: false, old: false });
  const [planOrHistoryActive, setPlanOrHistoryActive] = useState<ViewInterface>({
    value: true,
    old: true
  });

  const [resizeCanvas, setresizeCanvase] = useState(0);

  //EDIT ALL
  const [editContent, seteditContent] = useState(false);

  useEffect(() => {
    seteditContent(props.editContent);

    if (props.editContent) {
      defineLayout("historyhide");
    }
  }, [props.editContent]);

  const defineLayout = function (_value: string) {
    let _measurement: boolean = viewMeasurementPointList.value;
    let _plan: boolean = viewPlan.value;
    let _history: boolean = viewHistory.value;
    let _planList: boolean = viewPlanList.value;

    if (_value === "load_layout") {
      const localValues = localStorage.getItem(
        "PROJECT_" + storeSelectedProject.project.id + "_MENUSELECTIONS"
      );

      if (localValues !== null && localValues !== undefined) {
        const localValuesObject = JSON.parse(localValues);

        _measurement = localValuesObject["measurement"];
        _plan = localValuesObject["plan"];
        _history = props.editContent ? false : localValuesObject["history"];
        _planList = localValuesObject["planlist"];
      }
    } else if (_value === "measurement") {
       if (smallScreen) {
        _history = false;
        _plan = false;
       }
      _measurement = !_measurement;
      setTransitions({
        buttons: transitions.buttons,
        measurements: true,
        planview: transitions.planview,
        history: transitions.history
      });
    } else if (_value === "plan") {
       if (smallScreen) {
        _history = false;
        _plan = true;
       }else {
      _plan = !_plan;
       }
      setTransitions({
        buttons: transitions.buttons,
        measurements: transitions.measurements,
        planview: true,
        history: transitions.history
      });
    } else if (_value === "history") {
        if (smallScreen) {
          _plan = false;
          _history = true;
        }else {
      _history = !_history;
        }
      setTransitions({
        buttons: transitions.buttons,
        measurements: transitions.measurements,
        planview: transitions.planview,
        history: true
      });
    } else if (_value === "historyhide") {
      _history = false;
      _measurement = true;
      _plan = viewPlan.value;
      setTransitions({
        buttons: transitions.buttons,
        measurements: transitions.measurements,
        planview: transitions.planview,
        history: true
      });
    } else if (_value === "planlist") {
      _planList = !_planList;
      setTransitions({
        buttons: true,
        measurements: transitions.measurements,
        planview: transitions.planview,
        history: transitions.history
      });
    }

    if (_measurement === false && _plan === false && _history === false) {
      //DONT HIDE ALL
    } else {
      setviewMeasurementPointList({ ...viewMeasurementPointList, value: _measurement });
      setViewPlan({ ...viewPlan, value: _plan });
      setViewHistory({ ...viewHistory, value: _history });
      setPlanOrHistoryActive({ ...planOrHistoryActive, value: _plan || _history }); //(_plan || _history);
    }

    setviewPlanList({ ...viewPlanList, value: _planList });

    // STORE STATE BY PROJECT

    if (_value !== "load_layout" && !props.editContent) {
      localStorage.setItem(
        "PROJECT_" + storeSelectedProject.project.id + "_MENUSELECTIONS",
        JSON.stringify({
          measurement: _measurement,
          plan: _plan,
          history: _history,
          planlist: _planList
        })
      );
		}
  };

  const handleTransitionEnd = (event: any, id: string) => {
    //event.cancelBubble = true;

    let bResize = false;

    if (id === "planList" && viewPlanList.old !== viewPlanList.value) {
      bResize = true;
      setviewPlanList({ ...viewPlanList, old: viewPlanList.value });
      setTransitions({
        buttons: false,
        measurements: transitions.measurements,
        planview: transitions.planview,
        history: transitions.history
      });
    } else if (
      id === "measurementPoints" &&
      viewMeasurementPointList.old !== viewMeasurementPointList.value
    ) {
      bResize = true;
      setviewMeasurementPointList({
        ...viewMeasurementPointList,
        old: viewMeasurementPointList.value
      });
      setTransitions({
        buttons: transitions.buttons,
        measurements: false,
        planview: transitions.planview,
        history: transitions.history
      });
    } else if (id === "history" && viewHistory.old !== viewHistory.value) {
      bResize = true;
      setViewHistory({
        ...viewHistory,
        old: viewHistory.value
      });
      setTransitions({
        buttons: transitions.buttons,
        measurements: transitions.measurements,
        planview: transitions.planview,
        history: false
      });
    } else if (id === "planView" && viewPlan.old !== viewPlan.value) {
      bResize = true;
      setViewPlan({
        ...viewPlan,
        old: viewPlan.value
      });
      setTransitions({
        buttons: transitions.buttons,
        measurements: transitions.measurements,
        planview: false,
        history: transitions.history
      });
    }

    if (bResize) {
      setresizeCanvase(resizeCanvas + 1);
    }
  };

  /**********************************************************************************************/
  
  return (
    <>
      {/*Mobile view ****************************************************************************** */}

      {smallScreen ? (
        <Box
          sx={{
            flexGrow: 1,
            borderTop: "1px solid var(--colorDivider)",
            background: "var(--colorBackground)"
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={4} sx={{ alignSelf: "flex-start", marginLeft: "2%", marginTop: "2%" }}>
              <ProjectPlans
                projectID={props.projectId}
                activePlan={Number(projectPlanId)}
                editContent={editContent}
              ></ProjectPlans>
            </Grid>
            <Grid item xs={6}>
            <CustomSwitch
                  checked1={!showRHorAH}
                  onChange1={() => {
                    dispatch(toggleShowRHorAHSelection());
                  }}
                  checked2={!temperatureCompensation}
                  onChange2={() => {
                    dispatch(toggleTemperatureCompensation());
                  }}
                />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant={showMeasurementsOnSmallScreen === true ? "contained" : "outlined"}
                size="small"
                onClick={() => {
                  setShowMeasurementsOnSmallScreen(true);
                  defineLayout("measurement");
                }}
                sx={{
                  width: "30%",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  marginRight: "2%"
                }}
              >
                {t("projectview_page.measurement_points")}
              </Button>
              <Button
                variant={
                  viewPlan.value === true && showMeasurementsOnSmallScreen === false
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={() => {
                  setShowMeasurementsOnSmallScreen(false);
                  defineLayout("plan");
                }}
                sx={{
                  width: "30%",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  marginRight: "2%"
                }}
              >
                {t("projectview_page.floor_plan")}
              </Button>
              <Button
                variant={
                  viewHistory.value === true && showMeasurementsOnSmallScreen === false
                    ? "contained"
                    : "outlined"
                }
                size="small"
                onClick={() => {
                  setShowMeasurementsOnSmallScreen(false);
                  defineLayout("history");
                }}
                disabled={editContent}
                sx={{
                  width: "30%",
                  overflow: "hidden",
                  whiteSpace: "normal",
                  wordWrap: "break-word"
                }}
              >
                {t("projectview_page.history_label")}
              </Button>
            </Grid>
            {showMeasurementsOnSmallScreen && (
              <Grid item xs={12} sx={{ width: "100% !important" }}>
                <MeasurementPointList
                  editContent={editContent}
                  fullView={!planOrHistoryActive.value}
                  projectId={props.projectId}
                  planId={projectPlanId}
                />
              </Grid>
            )}
            {!showMeasurementsOnSmallScreen && (
              <Grid
                className={styles["planhistory-div"]}
                data-show={planOrHistoryActive.value}
                data-edit={editContent}
                item
                xs={12}
                sx={{ display: "flex", height: "900px" }}
              >
                <div
                  className={styles["planhistory-container"]}
                  data-show={planOrHistoryActive.value}
                  data-edit={editContent}
                  data-grow-animation
                >
                  <div
                    className={styles["plan-container"]}
                    data-show={viewPlan.value}
                    data-grow-animation
                    onTransitionEnd={(event: any) => {
                      handleTransitionEnd(event, "planView");
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        overflow: "hidden"
                      }}
                    >
                      <PlanView
                        editContent={editContent}
                        zoomLevel={1}
                        stageHidded={!viewPlan.value}
                        projectPlanId={projectPlanId}
                        resizeCanvas={resizeCanvas}
                        contentEditable={true}
                        measurementPoints={measurementPointArray}
                      />
                    </div>
                  </div>
                  <div
                    className={styles["history-container"]}
                    data-transitions={false}
                    data-show={viewHistory.value}
                    data-grow-animation
                    onTransitionEnd={(event: any) => {
                      handleTransitionEnd(event, "history");
                    }}
                  >
                    <ProjectViewHistory planViewShown={viewPlan.value} transitions={transitions} />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Box>
      ) : (
        /*Mobile view end ****************************************************************** */

        <div className={styles["viewContent-container"]}>
          <div className={styles["content-container"]}>
            <div
              className={styles["plans-container"]}
              data-show={viewPlanList.value}
              data-grow-animation
              onTransitionEnd={(event: any) => {
                handleTransitionEnd(event, "planList");
              }}
            >
              <div className={styles["plans-header"]}>
                {/*t("projectview_page.floorplans")*/}
                <CustomSwitch
                  checked1={!showRHorAH}
                  onChange1={() => {
                    dispatch(toggleShowRHorAHSelection());
                  }}
                  checked2={!temperatureCompensation}
                  onChange2={() => {
                    dispatch(toggleTemperatureCompensation());
                  }}
                />
                <Tooltip title={t("tooltip.pin") || ""} placement="top">
                  <IconButton
                    onClick={() => {
                      defineLayout("planlist");
                    }}
                  >
                    <PushPinIcon
                      sx={{
                        height: "var(--iconSize)",
                        width: "var(--iconSize)",
                        color: viewPlanList.value ? "var(--colorOrange)" : ""
                      }}
                      color={viewPlanList.value ? "primary" : "action"}
                    ></PushPinIcon>
                  </IconButton>
                </Tooltip>
              </div>
              {/* ---------------------------------------------------------------- */}
              {/* NEW PLACE FOR BUTTONS */}
              <div
                data-show={viewPlanList.value}
                className={styles["plans-selection"]}
                data-grow-animation
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ width: "100%", mt: "15px" }}
                >
                  <Button
                    variant={viewMeasurementPointList.value === true ? "contained" : "outlined"}
                    size="small"
                    onClick={() => defineLayout("measurement")}
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}
                  >
                    {t("projectview_page.measurement_points")}
                  </Button>
                  <Button
                    variant={viewPlan.value === true ? "contained" : "outlined"}
                    size="small"
                    onClick={() => defineLayout("plan")}
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}
                  >
                    {t("projectview_page.floor_plan")}
                  </Button>
                  <Button
                    variant={viewHistory.value === true ? "contained" : "outlined"}
                    size="small"
                    onClick={() => defineLayout("history")}
                    disabled={editContent}
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      wordWrap: "break-word"
                    }}
                  >
                    {t("projectview_page.history_label")}
                  </Button>
                </Stack>
              </div>
              {/* ---------------------------------------------------------------- */}
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                flexItem
                light={false}
                sx={{ borderColor: "var(--colorDivider)", marginBottom: "10px", marginTop: "10px" }}
              />
              {/* ---------------------------------------------------------------- */}
              <div className={styles["plans-rows"]} data-grow-animation>
                <ProjectPlans
                  projectID={props.projectId}
                  activePlan={Number(projectPlanId)}
                  editContent={editContent}
                ></ProjectPlans>
              </div>
            </div>
            {/* *********************************************************************************************** */}
            <div
              className={styles["measurementpoints-container"]}
              data-show={viewMeasurementPointList.value}
              data-plan-or-history-active={planOrHistoryActive.value}
              data-grow-animation
              onTransitionEnd={(event: any) => {
                handleTransitionEnd(event, "measurementPoints");
              }}
            >
              <MeasurementPointList
                editContent={editContent}
                fullView={!planOrHistoryActive.value}
                projectId={props.projectId}
                planId={projectPlanId}
              />
            </div>
            <div
              className={styles["planhistory-container"]}
              data-show={planOrHistoryActive.value}
              data-grow-animation
            >
              <div
                className={styles["plan-container"]}
                data-show={viewPlan.value}
                data-grow-animation
                onTransitionEnd={(event: any) => {
                  handleTransitionEnd(event, "planView");
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden"
                  }}
                >
                  <PlanView
                    editContent={editContent}
                    zoomLevel={1}
                    stageHidded={!viewPlan.value}
                    projectPlanId={projectPlanId}
                    resizeCanvas={resizeCanvas}
                    contentEditable={true}
                    measurementPoints={measurementPointArray}
                  />
                </div>
              </div>
              <div
                className={styles["history-container"]}
                data-transitions={transitions.planview || transitions.measurements}
                data-show={viewHistory.value}
                data-grow-animation
                onTransitionEnd={(event: any) => {
                  handleTransitionEnd(event, "history");
                }}
              >
                <ProjectViewHistory planViewShown={viewPlan.value} transitions={transitions} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
