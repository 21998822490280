export function wheelZoomPlugin(opts: { factor: number; minX: number; maxX: number }) {
  const factor = opts.factor || 0.75;

  let xMin: number, xMax: number, yMin: number, yMax: number, xRange: number, yRange: number;

  // to switch drag zoom to scroll zoom change wheel drag pan zoom from (e.button == 1) to (e.button == 0) from below
  // uncomment wheelzoom from below
  // uncomment drag: { x: false, y: false, setScale: false } from HistoryChart.tsx file in options object
  function clamp(
    nRange: number,
    nMin: number,
    nMax: number,
    fRange: number,
    fMin: number,
    fMax: number
  ): number[] {
    if (nRange > fRange) {
      nMin = fMin;
      nMax = fMax;
    } else if (nMin < fMin) {
      nMin = fMin;
      nMax = fMin + nRange;
    } else if (nMax > fMax) {
      nMax = fMax;
      nMin = fMax - nRange;
    }

    return [nMin, nMax];
  }
  return {
    hooks: {
      ready: (u: any) => {
        xMin = opts.minX;
        xMax = opts.maxX;
        yMin = u.scales.y.min;
        yMax = u.scales.y.max;

        xRange = xMax - xMin;
        yRange = yMax - yMin;
        const plots = Object.values(document.getElementsByClassName("u-over"));
        // wheel drag pan
        plots.forEach(plot => {
          plot.addEventListener("mousedown", (e: any) => {
            // 0 is left mouse 1 is mouse scroll pushed down
            if (e.button == 1) {
              e.preventDefault();

              let left0 = e.clientX;

              let scXMin0 = u.scales.x.min;
              let scXMax0 = u.scales.x.max;
              let xUnitsPerPx = u.posToVal(1, "x") - u.posToVal(0, "x");

              const onmove = (e: any) => {
                e.preventDefault();
                let left1 = e.clientX;

                let dx = xUnitsPerPx * (left1 - left0);
                if (opts.minX < scXMin0 - dx && opts.maxX > scXMax0 - dx) {
                  //setting to localstorage everytime seems to cause performance issues
                  localStorage.setItem("chartZoomFrom", (scXMin0 - dx).toString());
                  localStorage.setItem("chartZoomTo", (scXMax0 - dx).toString());
                  u.setScale("x", {
                    min: scXMin0 - dx,
                    max: scXMax0 - dx
                  });
                }
              };

              const onup = () => {
                document.removeEventListener("mousemove", onmove);
                document.removeEventListener("mouseup", onup);
              };

              document.addEventListener("mousemove", onmove);
              document.addEventListener("mouseup", onup);
            }
          });

          // wheel scroll zoom
          /* plot.addEventListener("wheel", (e: any)  => {
            e.preventDefault();
            let rect = plot.getBoundingClientRect();
            const { left, top } = u.cursor;

            const leftPct = left / rect.width;
            const btmPct = 1 - top / rect.height;
            const xVal = u.posToVal(left, "x");
            const yVal = u.posToVal(top, "y");
            const oxRange = u.scales.x.max - u.scales.x.min;
            const oyRange = u.scales.y.max - u.scales.y.min;

            const nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
            let nxMin = xVal - leftPct * nxRange;
            let nxMax = nxMin + nxRange;
            [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, xRange, xMin, xMax);

            const nyRange = e.deltaY < 0 ? oyRange * factor : oyRange / factor;
            let nyMin = yVal - btmPct * nyRange;
            let nyMax = nyMin + nyRange;
            [nyMin, nyMax] = clamp(nyRange, nyMin, nyMax, yRange, yMin, yMax);

            u.batch(() => {
              u.setScale("x", {
                min: nxMin,
                max: nxMax
              });

              u.setScale("y", {
                min: nyMin,
                max: nyMax
              });
            });
          }); */
        });
      }
    }
  };
}
