import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Stack, Divider, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import styles from "./MeasurementPointList.module.css";

import { MeasurementPointObject } from "../../../../../types";

//STORE
import { RootState } from "../../../../../app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  addMeasurementPoint,
  updateMeasurementPoint,
  removeMeasurementPoint,
  toggleMeasurementPointSelectedState,
  addDeviceWithDetails,
} from "../../../../measurements/measurementsSlice";

import { DeviceTypeObject } from "./../../../../devices/deviceTypeAPI";

//COMPONENTS
import { MeasurementPointSensorsList } from "./MeasurementPointSensorList";
import ConfirmDialog from "../../../../UI/ConfirmDialog";

//DIALOGS
import AddOrEditMeasurementDialog from "./AddOrEditMeasurementDialog";
import AddDeviceToMeasurementDialog from "./AddDeviceToMeasurementDialog";
import { isActiveDevices } from "../../../../utils/commonFunctions";

interface PlanViewProperties {
  editContent: boolean;
  fullView: boolean;
  projectId: number;
  planId: string;
}

export default function MeasurementPointList(props: PlanViewProperties) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const urlParams = useParams();

  //FOR CONTENT EDITING
  const [editContent, seteditContent] = useState(props.editContent);
  const [fullView, setfullView] = useState(false);

  const [addOrEditMeasurementDialogOpen, setaddOrEditMeasurementFormOpen] = useState(false);

  const [addDeviceDialogOpen, setaddDeviceDialogOpen] = useState(false);
  const [editedMeasurementPoint, seteditedMeasurementPoint] = useState<
    MeasurementPointObject | undefined
  >(undefined);

	const [addDeviceError, setAddDeviceError] = useState<boolean>(false);

  const [pointToRemove, setPointToRemove] = useState<string>("");
  const [deleteMeasurementPointDialogOpen, setDeleteMeasurementPointDialogOpen] = useState<boolean>(false);

  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);

  const anyDialogOpen = useSelector((state: RootState) => state.ProjectPlanMeasurementPoints.anyDialogOpen);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  //console.log("rerender whole page!");

  useEffect(() => {
    const currentUrl = urlParams["*"];

    if (currentUrl?.includes("load")) {
      seteditContent(false);
    } else {
      seteditContent(props.editContent);
    }

    //console.log("editConent");
  }, [props.editContent, urlParams]);

  useEffect(() => {
    setfullView(props.fullView);
    //console.log("fullView");
  }, [props.fullView]);

  /**************************************************************************************** */

  //STORE STATE
  //Measurements from Store
  const storeMeasurementPoints = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.measurementPoints
  );

  const [measurementPointsArray, setMeasurementPointArray] = useState(
    Array<MeasurementPointObject>()
  );

  useEffect(() => {
    setMeasurementPointArray(storeMeasurementPoints);
  }, [storeMeasurementPoints]);

  /**************************************************************************************** */

  /* ELEMENTS  */
  const AddMeasurementPointElement = () => {
    if (editContent) {
      return (
        <React.Fragment>
          <div className={styles["measurementpointlist-measurements-addmeasurement"]}>
            <Button
              size="small"
              variant="contained"
              color="success"
              style={{ width: "95%" }}
              onClick={() => {
                addMeasurementPointClick();
              }}
              startIcon={
                <AddCircleOutlineIcon
                  sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }}
                />
              }
            >
              {t("measurement_list.add_measurement")}
            </Button>
          </div>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            flexItem
            light={false}
            sx={{ borderColor: "var(--colorDivider)", marginBottom: "5px", marginTop: "0px" }}
          />
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  interface addSensorProperties {
    measurementPoint: MeasurementPointObject;
  }

  const AddDevice = (props: addSensorProperties) => {
    if (editContent)
      return (
        <div className={styles["measurementpointlist-measurement-addsensors"]}>
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              variant="contained"
              style={{ height: "99%" }}
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                addDeviceClick(props.measurementPoint);
              }}
            >
              {t("measurement_list.add_device")}
            </Button>
          </Stack>
        </div>
      );
    else return <></>;
  };

  /**************************************************************************************** */
  const handleMeasurementPointSelectedToggle = (id: string) => {
    //console.log("handleMeasurementPointSelectedToggle(" + id + ")");
    dispatch(toggleMeasurementPointSelectedState({ measurementId: id, planId: props.planId }));
  };

  const addMeasurementPointClick = () => {
    seteditedMeasurementPoint(undefined);
    setaddOrEditMeasurementFormOpen(true);
  };

  const handleAddOrEditMeasurementPoint = (name: string, isEdit: boolean) => {
    if (isEdit && editedMeasurementPoint?.id !== undefined) {
      dispatch(
        updateMeasurementPoint({
            measurementName: name,
            measurentPointId: editedMeasurementPoint.id,
            planId: props.planId.toString(),
            positionX: editedMeasurementPoint.x,
            positionY: editedMeasurementPoint.y,
            positionZ: 0
        })
      );
    } else {
      dispatch(addMeasurementPoint({ measurementName: name, planid: props.planId }));
    }
  };

  const handleAddDevice = (
    deviceType: DeviceTypeObject,
    plannedDepth: string,
    deviceDepths: Array<{ typeName: string; depth: string }>
  ) => {
    
    if (editedMeasurementPoint === undefined) {
			alert(t("measurement_list.error_failed_to_add_device"));
      return;
    }
    if ((deviceType.depthDefinition !== 0) && (plannedDepth.includes(",") || plannedDepth.includes(".") || Number(plannedDepth) < 1)){
      setAddDeviceError(true);
			return;
    }

    dispatch(
      addDeviceWithDetails({
        measurementPointId: editedMeasurementPoint.id,
        deviceTypeId: deviceType.id,
        depthDefinition: deviceType.depthDefinition,
        plannedDepth: plannedDepth,
        sensorDepths: deviceDepths
      })
    );
		setaddDeviceDialogOpen(false);
  };

  const deleteMeasurementPointClick = (measurementPointId: string) => {
      dispatch(removeMeasurementPoint(measurementPointId));
  };

  const updateMeasurementPointClick = (measurementPoint: MeasurementPointObject) => {
    seteditedMeasurementPoint(measurementPoint);
    setaddOrEditMeasurementFormOpen(true);
  };

  const addDeviceClick = (measurementPoint: MeasurementPointObject) => {
    setaddDeviceDialogOpen(true);
    seteditedMeasurementPoint(measurementPoint);
    //dispatch(addSensorToMeasurementPoint(measurementPointId));
  };

  /* const FillStack = () => {
    let arr: string[] = [];

    for (let index = 0; index < 150; index++) {
      arr.push("mittaus_thing_" + index);
    }

    return (
      <React.Fragment>
        {arr.map((name, index) => (
          <div
            key={"measuremenList_XX" + index}
            className={styles["measurementpointlist-measurement-container"]}
          >
            {name}
          </div>
        ))}
      </React.Fragment>
    );
  }; */
  /**************************************************************************************** */
  return (
    <div className={styles["measurementpointlist-main-container"]}>
      {/* ************************************************************************************** */}

      <AddMeasurementPointElement />

      {/* ************************************************************************************** */}
      <div className={styles["measurementpointlist-measurementlist-container"]}>
        {/* ************************************************************************************** */}
        {activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0 &&
        <div className={styles["license-warning"]}>
         <PriorityHighIcon sx={!smallScreen ? {fontSize: "20px", color: "red"} : {fontSize: "17px", color: "red"}}/> 
          <p className={styles["warning-text"]}>
           {t("measurement_list.licence_warning")}
          </p>
        </div>
        }
        {props.planId !== "-1" &&
          measurementPointsArray.map((measurementPoint, index) => {
            const isActiveDevicesStyle = isActiveDevices(measurementPoint?.devices);
            return (
              <div
                key={"measuremenList_" + index}
                className={styles["measurementpointlist-measurement-container"]}
                data-measurement-highlighted={measurementPoint.selected}
                data-measurement-id={measurementPoint.id}
                active-devices={isActiveDevicesStyle.toString()}
                onClick={(event) => {
                  if (anyDialogOpen) {return;}
                  event.stopPropagation();
                  event.preventDefault();
                  handleMeasurementPointSelectedToggle(measurementPoint.id);
                }}
              >
                <div style={{ width: "100%" }}>
                  {/* ************************************************************************************** */}
                  <div className={styles["measurementpointlist-measurement-header"]}>
                    {editContent ? (
                      <React.Fragment>
                        <button
                          value={measurementPoint.selected.toString()}
                          onClick={(event) => {
                            if (anyDialogOpen) {return;}
                            event.stopPropagation();
                            event.preventDefault();
                            handleMeasurementPointSelectedToggle(measurementPoint.id)
                          }}
                          // onClick={(event: React.MouseEvent<HTMLElement>) =>
                          //   handleMeasurementPointSelectedToggle(measurementPoint.id)
                          // }
                          data-isselectingbutton
                        >
                          {measurementPoint.name}
                        </button>
                        {/* -------------------------------------------------- */}
                        <Tooltip title={t("tooltip.edit") || ""}>
                        <IconButton
                          onClick={(event: any) => {
                            event.stopPropagation();
                            updateMeasurementPointClick(measurementPoint);
                          }}
                          data-iseditingbutton
                        >
                          <EditRoundedIcon
                            sx={{ height: "var(--iconSize)", width: "var(--iconSize)", color: "black !important" }}
                          />
                        </IconButton>
                        </Tooltip>
                        {/* -------------------------------------------------- */}
                        <Tooltip title={t("tooltip.delete") || ""}>
                        <IconButton
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setPointToRemove(measurementPoint.id);
                            setDeleteMeasurementPointDialogOpen(true);
                          }}
                          data-isdeletingbutton
                        >
                          <DeleteOutlineRoundedIcon
                            sx={{
                              color: "red !important",
                              height: "var(--iconSize)",
                              width: "var(--iconSize)"
                            }}
                          />
                        </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : (
                      <button
                        value={measurementPoint.selected.toString()}
                        onClick={(event) => {
                          if (anyDialogOpen) {return;}
                          event.stopPropagation();
                          event.preventDefault();
                          handleMeasurementPointSelectedToggle(measurementPoint.id);
                        }}
                        // onClick={(event: React.MouseEvent<HTMLElement>) =>
                        //   handleMeasurementPointSelectedToggle(measurementPoint.id)
                        // }
                        data-isselectingbutton
                      >
                        {measurementPoint.name}
                      </button>
                    )}
                  </div>
                  {/* ************************************************************************************** */}
                  {/* LATER PROPERTIES */}

                  {/* ************************************************************************************** */}
                  <div className={styles["measurementpointlist-measurement-sensors"]}>
                    <div>
                      {/* //MAP SENSORS */}
                      <MeasurementPointSensorsList
                        measurementPointId={measurementPoint.id}
                        editContent={editContent}
                        fullView={fullView}
                        deviceData={measurementPoint.devices}
                        planId={Number(props.planId)}
                      />
                    </div>
                    {/* """"""""""""""""""""""""""""" */}
                    <AddDevice measurementPoint={measurementPoint} />
                  </div>
                  {/* ************************************************************************************** */}
                </div>
              </div>
            );
          })}
      </div>
      <ConfirmDialog 
        key="delete-measurementpoint-dialog"
        open={deleteMeasurementPointDialogOpen}
        content={t("dialog.delete_measurement_point")}
        value={pointToRemove}
        setOpen={setDeleteMeasurementPointDialogOpen}
        onConfirm={() => deleteMeasurementPointClick(pointToRemove)}
      />
      {addOrEditMeasurementDialogOpen && (
        <AddOrEditMeasurementDialog
          oldValue={editedMeasurementPoint?.name}
          projectId={props.projectId}
          handleSave={(name, isEdit) => {
            handleAddOrEditMeasurementPoint(name, isEdit);

            setaddOrEditMeasurementFormOpen(false);
          }}
          handleClose={() => setaddOrEditMeasurementFormOpen(false)}
        />
      )}
      {addDeviceDialogOpen && (
        <AddDeviceToMeasurementDialog
					addDeviceError={addDeviceError}
          handleClose={() => setaddDeviceDialogOpen(false)}
          handleSave={(deviceType, plannedDepth, deviceDepths) => {
            handleAddDevice(deviceType, plannedDepth, deviceDepths);
          }}
        />
      )}
    </div>
  );
}
