import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import { DeviceSettingsAvailablePointDevicesProjectObject } from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useEffect, useState } from "react";
import { getAvailableMeasurementPointDevices } from "./deviceSlice";
import DeviceSettingsLinkTableRow from "./DeviceSettingsLinkTableRow";
import { t } from "i18next";
import { useGetUserProjectsQuery } from "../../../projectApi";

interface TableProps {
  handleLinkDialogClose: any;
}

const DeviceSettingsLinkTable = (props: TableProps) => {
  const dispatch = useDispatch();
  const { handleLinkDialogClose } = props;

  const { data: userProjects } = useGetUserProjectsQuery();

  const device = useSelector((state: RootState) => state.device.device);
  const loading = useSelector((state: RootState) => state.device.loading);
  const availableProjects = useSelector(
    (state: RootState) => state.device.availableMeasurementPointDeviceProjects
  );

  const [projectList, setProjectList] = useState<Array<DeviceSettingsAvailablePointDevicesProjectObject>>([]);

  useEffect(() => {
    dispatch(getAvailableMeasurementPointDevices(device.deviceId));
  }, [dispatch, device.deviceId]);

  useEffect(() => {
    let finalProjects: Array<DeviceSettingsAvailablePointDevicesProjectObject> = [];
    if (userProjects !== undefined && availableProjects.length > 0) {
      const userAdminAndPlannerProjects = userProjects.filter(userProject => userProject.projectRoleName === "Admin" || userProject.projectRoleName === "Planner");
      availableProjects.forEach(availableProject => {
        if (userAdminAndPlannerProjects.some(project => project.projectId === availableProject.id)) {
          finalProjects.push(availableProject);
        }
      })
      setProjectList(finalProjects); 
    }
  }, [availableProjects, userProjects]);

  return (
    <TableContainer
     component={Paper}
      sx={{
        margin: "10px",
        width: "90%",
        overflowX: "hidden",
        borderRadius: "12px 12px 0px 0px",
        padding: "0px"
      }}
    >
      {loading ?
      <div>LOADING...</div>
      :
      <Table aria-label="collapsible table">
        <TableHead sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
          <TableRow>
            <TableCell>
              {projectList.length < 1 ? t("device_management.no_linkable_projects") : t("device_management.linkable_projects")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectList.map((project: DeviceSettingsAvailablePointDevicesProjectObject) => (
            <DeviceSettingsLinkTableRow
              key={project.id.toString()}
              project={project}
              handleLinkDialogClose={handleLinkDialogClose}
            />
          ))}
        </TableBody>
      </Table>
    }
    </TableContainer>
  );
};

export default DeviceSettingsLinkTable;
