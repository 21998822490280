import { Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme } from "./theme";

import styles from "./AppContent.module.css";

// ROUTED PAGES
import Companies from "./pages/Companies";
import About from "./pages/About";
import Projects from "./pages/Projects";
import ProjectPage from "./pages/ProjectPage";
import DeviceManagement from "./pages/DeviceManagement";
import DeviceSettings from "./features/project/projectview/components/devices/DeviceSettings";

// MAIN LEVEL OFF NAVIGATION
export default function AppContent(): JSX.Element {
  return (
    <div className={styles["appContent-Container"]} data-testing>
      <ThemeProvider theme={defaultTheme}>
        <Routes>
          {/* default route */}
          <Route path="/" element={<Navigate replace to="/projects" />}></Route>
          <Route path="*" element={<Navigate replace to="/" />}></Route>
          {/* OTHERS ROUTES HERE */}
          <Route path="/about" element={<About />}></Route>
          <Route path="/companies/:companyid/*" element={<Companies />}></Route>
          <Route path="/devicemanagement/company/:companyid" element={<DeviceManagement />}></Route>
          <Route path="/devicemanagement/devicesettings/:deviceserial" element={<DeviceSettings />}></Route>
          {/* WITH DEFINITION * YOU CAN HAVE SUB ROUTES ON COMPONENT */}
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/projects/:projectId/*" element={<ProjectPage />}></Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}
