import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface MenuItems {
    children?: React.ReactNode,
    icon: string,
    menuText: string |null,
    id: string
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'var(--colorOrange)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: "var(--colorOrange)",
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function MobileMenu(props: MenuItems) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={props.id}
        aria-label={props.id}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        color="inherit"
        onClick={handleClick}
        sx={{
          height: "30px",
          width: "100px",
          overflow: "hidden",
          whiteSpace: "break-spaces",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
          padding: "1px",
          marginRight: "4%",
          marginLeft: "1%", 
          backgroundColor:"var(--colorOrange)",
          lineHeight: "1 !important"
        }}
      >
        {props.icon === "menu" ?
        <MenuIcon />
        :
        <KeyboardArrowDownIcon/>
      }
      {props.menuText}
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={handleClose}
      >
        {props.children}
      </StyledMenu>
      </>
  );
}