import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link, Typography, useMediaQuery } from "@mui/material";
import styles from "./Devices.module.css";
import { clearCompanyDevices, clearCompanySliceError, getCompanyDevices } from "../../../../company/companySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useGetDeviceTypesQuery } from "../../../../devices/deviceTypeAPI";
import { GetDateString } from "../../../../utils/commonFunctions";
import { clearError } from "./deviceSlice";

const Devices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const smallScreen = useMediaQuery("(max-width: 999px)");

  const companyId = Number(params.companyid);

  const { data: deviceTypes } = useGetDeviceTypesQuery();

  const companyDevices = useSelector((state: RootState) => state.company.companyDevices);
  const error = useSelector((state: RootState) => state.company.error);
  const loading = useSelector((state: RootState) => state.company.loading);
  const [rowData, setRowData] = useState<any>([]);

  useEffect(() => {
    dispatch(clearError());
    dispatch(getCompanyDevices(companyId));
    return () => {
      dispatch(clearCompanySliceError());
      dispatch(clearCompanyDevices());
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if (companyDevices.length <= 0) {
      return;
    } else {
      const rowArray: any = [];
      companyDevices.map(device =>
        rowArray.push({
          id: device.deviceId,
          serial: device.serial,
          deviceType: deviceTypes?.find(x => Number(x.id) === device.deviceTypeId)?.name,
          tags: device.deviceTags.map(tag => tag.name),
          projects: device.projects.map(project => project.name),
          lastSeen:
            device.latestMeasurements.length !== 0
              ? device.latestMeasurements
                  .map(meas => GetDateString(meas.dateTime.toString(), "DD-MM-YYYY HH:mm"))
                  .reduce((a, b) => (a > b ? a : b))
              : "-",
          batteryStatus: device.latestMeasurements.find(x => x.sensorTypeName === "BatteryStatus")
            ?.value,
          networkStatus: device.latestMeasurements.find(x => x.sensorTypeName === "NetworkQuality")
            ?.value
        })
      );
      setRowData(rowArray);
    }
  }, [companyDevices, deviceTypes]);

  const columns: GridColDef[] = [
    {
      field: "serial",
      headerName: t("device_management.serial"),
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 0.2
    },
    {
      field: "deviceType",
      headerName: t("device_management.device_type"),
      flex: 0.2
    },
    {
      field: "tags",
      headerName: t("device_management.tags"),
      flex: !smallScreen ? 0.4 : 0.2
    },
    {
      field: "projects",
      headerName: t("device_management.project_links"),
      flex: 0.6,
      hide: !smallScreen ? false : true
    },
    {
      field: "lastSeen",
      headerName: t("device_management.last_seen"),
      flex: 0.2,
      hide: !smallScreen ? false : true
    },
    {
      field: "batteryStatus",
      type: "number",
      headerName: t("device_management.battery"),
      flex: 0.1,
      hide: !smallScreen ? false : true
    },
    {
      field: "networkStatus",
      type: "number",
      headerName: t("device_management.network"),
      flex: 0.1,
      hide: !smallScreen ? false : true
    }
  ];

  return (
    <>
      {error ? (
        <div>ERROR</div>
      ) : (
        <>
          <div className={styles["devices-table-header"]}>
            <Typography component={"h2"}>{t("device_management.company_devices")}</Typography>
            {smallScreen && 
             <Link component={RouterLink} to="/projects" sx={{ ml: "auto" }}>
               {t("device_management.return")}
             </Link>
            }
          </div>
          <div className={styles["devices-table"]}>
            <DataGrid
              autoHeight
              showCellRightBorder
              disableColumnSelector
              disableDensitySelector
              editMode="row"
              loading={loading}
              rows={rowData}
              columns={columns}
              density={"compact"}
              onRowClick={e => {
                navigate(`/devicemanagement/devicesettings/${e.row.serial}`);
                window.localStorage.setItem("clickedcompany", companyId.toString());
              }}
              rowsPerPageOptions={[5, 25, 100, 500]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "serial", sort: "asc"}]
                }
              }}
              sx={{
                boxShadow: 2,
                borderRadius: "12px",
                fontSize: "var(--basicFontSize)",
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                  color: "var(--colorOrange)"
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "1000"
                },
                "& .Mui-selected": {
                  border: "2px solid black"
                },
                "& .MuiDataGrid-columnHeader": {
                  background: "var(--colorGray)"
                },
                "& .MuiDataGrid-columnSeparator": {
                  color: "var(--colorOrange)"
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "var(--colorOrange)"
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none !important"
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none"
                },
                "& .MuiDataGrid-menuIconButton": {
                  color: "var(--colorOrange)"
                },
                "& .MuiDataGrid-filterIcon": {
                  color: "var(--colorOrange)"
                },
                "& .MuiDataGrid-filterForm": {
                  background: "orange"
                }
                /*  "& .MuiDataGrid-menuIcon": {
                visibility: "visible",
                width: "auto"
              } */
              }}
              localeText={{
                noRowsLabel: t("measurement_list.data_grid_toolbar.no_rows"),
                filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
                filterPanelOperators: t(
                  "measurement_list.data_grid_toolbar.filter_panel_operators"
                ),
                filterPanelInputLabel: t(
                  "measurement_list.data_grid_toolbar.filter_panel_Input_label"
                ),
                filterPanelInputPlaceholder: t(
                  "measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"
                ),
                filterOperatorContains: t(
                  "measurement_list.data_grid_toolbar.filter_operator_contains"
                ),
                filterOperatorEquals: t(
                  "measurement_list.data_grid_toolbar.filter_operator_equals"
                ),
                filterOperatorStartsWith: t(
                  "measurement_list.data_grid_toolbar.filter_operator_starts_with"
                ),
                filterOperatorEndsWith: t(
                  "measurement_list.data_grid_toolbar.filter_operator_ends_with"
                ),
                filterOperatorIsEmpty: t(
                  "measurement_list.data_grid_toolbar.filter_operator_is_empty"
                ),
                filterOperatorIsNotEmpty: t(
                  "measurement_list.data_grid_toolbar.filter_operator_is_not_empty"
                ),
                filterOperatorIsAnyOf: t(
                  "measurement_list.data_grid_toolbar.filter_operator_is_any_of"
                ),
                filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
                filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
                filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
                columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
                columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
                columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
                columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
                columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc"),
                columnHeaderFiltersLabel: t("measurement_list.data_grid_toolbar.show_filters"),
                columnHeaderSortIconLabel: t("measurement_list.data_grid_column_menu.sort"),
                footerRowSelected: count =>
                  count === 1 ? t("measurement_list.data_grid_toolbar.rows_selected") : "",
                MuiTablePagination: {
                  labelRowsPerPage: t("measurement_list.data_grid_toolbar.rows_per_page")
                }
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Devices;
