// REACT
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// IMPORTS
import { Routes, Route, useNavigate, useParams, Navigate } from "react-router-dom";
import { Box, Button, Grid, MenuItem, Stack, useMediaQuery } from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// COMPONENTS
import ProjectViewInfo from "./components/settings/ProjectViewInfo";
import ProjectViewAlarms from "./components/settings/ProjectViewAlarms";
import ProjectViewUsers from "./components/settings/ProjectViewUsers";
import ProjectViewLicences from "./components/settings/ProjectViewLicences";
import ProjectTransfer from "./components/settings/ProjectViewTransferProject";
import MobileMenu from "../../UI/MobileMenu";

import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";

// CSS
import styles from "./ProjectSettings.module.css";

export default function ProjectSettings() {
  // IMPORTED
  const navigateToLink = useNavigate();

  const { t } = useTranslation();

  let urlParams = useParams();

  const projectUserRole = useSelector((state: RootState) => state.selectedProject.userProjectRole);
  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);

  const smallScreen = useMediaQuery("(max-width: 999px)");

  useEffect(() => {
    // SELECTED CHOOSED TAB

    let currentUrl = urlParams["*"];
    let projectId = urlParams["projectId"];

    switch (currentUrl) {
      case "info":
        setcurrentTab(0);
        break;
      case "users":
        setcurrentTab(1);
        break;
      case "alarms":
        setcurrentTab(2);
        break;
      case "licences":
        setcurrentTab(3);
        break;
      case "transfer":
        setcurrentTab(4);
        break;
    }

    setprojectId(Number(projectId));
  }, [urlParams]);

  ///////////////////////////////////////////////////////////////////////////////////
  const [currentTab, setcurrentTab] = useState(0);
  const [projectId, setprojectId] = useState(-1);

  ///////////////////////////////////////////////////////////////////////////////////
  //NAVIGATION
  const navigateToView = function (_value: string) {
    let linkUrl: string = "";
    //let tabIndex = 0;

    switch (_value) {
      case "info":
        linkUrl = "info";
        //tabIndex = 0;

        break;
      case "users":
        linkUrl = "users";
        //tabIndex = 1;

        break;
      case "alarms":
        linkUrl = "alarms";
        //tabIndex = 2;

        break;
      case "licences":
        linkUrl = "licences";
        //tabIndex = 3;

        break;
      case "transfer":
        linkUrl = "transfer";
        break;
    }
    //setCurrentTab(tabIndex);
    navigateToLink(linkUrl);
  };
  ///////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/*Mobile view*****************************************************/}
      {smallScreen ? (
        <>
          <Box sx={{ flexGrow: "1", background: "var(--colorBackground)", borderTop: "1px solid var(--colorDivider)", height: "100%" }}>
            <Grid container spacing={0} sx={{height: "85%"}}>
              <Grid item xs={2} sx={{ alignSelf: "flex-start", marginLeft: "1.5%", marginTop: "1.5%" }}>
                <MobileMenu 
                  id="project-settings-mobile-menu"
                  icon="arrow" 
                  menuText={
                    currentTab === 0 ? t("projectview_page.settings_page.info")
                    : currentTab === 1 ? t("projectview_page.settings_page.users")
                    : currentTab === 2 ? t("projectview_page.settings_page.alarms")
                    : currentTab === 3 ? t("projectview_page.settings_page.licences")
                    : currentTab === 4 ? t("projectview_page.settings_page.transferProjectHeader")
                    : ""
                  }
                >
                  <Stack spacing={0}>
                    <MenuItem>
                      <Button
                        variant={currentTab === 0 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        onClick={() => navigateToView("info")}
                      >
                        {t("projectview_page.settings_page.info")}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant={currentTab === 1 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        disabled={projectUserRole !== "Admin"}
                        onClick={() => navigateToView("users")}
                      >
                        {t("projectview_page.settings_page.users")}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant={currentTab === 2 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        disabled={projectUserRole !== "Admin" && projectUserRole !== "Planner"}
                        onClick={() => navigateToView("alarms")}
                      >
                        {t("projectview_page.settings_page.alarms")}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant={currentTab === 3 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        disabled={projectUserRole !== "Admin"}
                        onClick={() => navigateToView("licences")}
                      >
                        {t("projectview_page.settings_page.licences")}
                        {activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0 && <PriorityHighIcon sx={{color: "red !important"}} fontSize="small" />}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        variant={currentTab === 4 ? "contained" : "outlined"}
                        fullWidth
                        size="small"
                        disabled={projectUserRole !== "Admin"}
                        onClick={() => navigateToView("transfer")}
                      >
                        {t("projectview_page.settings_page.transferProjectHeader")}
                      </Button>
                    </MenuItem>
                  </Stack>
                </MobileMenu>
              </Grid>
              <Grid item xs={12} sx={{height: "100%"}}>
                  <Routes>
                    {/*  If route dont contains /plans/id, route to first  */}
                    {/*  TODO: BY FLOOR PLAN ORDER NUMBER */}
                    <Route path={`/`} element={<Navigate replace to="info" />} />
                    <Route path={`/info`} element={<ProjectViewInfo />} />
                    <Route path={`/users`} element={<ProjectViewUsers projectId={projectId} />} />
                    <Route path={`/alarms`} element={<ProjectViewAlarms />} />
                    <Route
                      path={`/licences`}
                      element={<ProjectViewLicences projectId={projectId} />}
                    />
                    <Route path={`/transfer`} element={<ProjectTransfer projectId={projectId} />} />
                  </Routes>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : 
        /*Mobile view end *************************************************************/
        (
        <div className={styles["projectsettings-container"]}>
          <div className={styles["projectsettings-navigation-container"]}>
            {/* ************************************************************************ */}
            <Button
              variant={currentTab === 0 ? "contained" : "outlined"}
              fullWidth
              size="small"
              onClick={() => navigateToView("info")}
            >
              {t("projectview_page.settings_page.info")}
            </Button>
            <Button
              variant={currentTab === 1 ? "contained" : "outlined"}
              fullWidth
              size="small"
              disabled={projectUserRole !== "Admin"}
              onClick={() => navigateToView("users")}
            >
              {t("projectview_page.settings_page.users")}
            </Button>
            <Button
              variant={currentTab === 2 ? "contained" : "outlined"}
              fullWidth
              size="small"
              disabled={projectUserRole !== "Admin" && projectUserRole !== "Planner"}
              onClick={() => navigateToView("alarms")}
            >
              {t("projectview_page.settings_page.alarms")}
            </Button>
            <Button
              variant={currentTab === 3 ? "contained" : "outlined"}
              fullWidth
              size="small"
              disabled={projectUserRole !== "Admin"}
              onClick={() => navigateToView("licences")}
            >
              {t("projectview_page.settings_page.licences")}
              {activeLicenceState !== undefined && activeLicenceState.maxLinkedDevices === 0 && <PriorityHighIcon sx={{color: "red"}} fontSize="small"/>}
            </Button>

            <Button
              variant={currentTab === 4 ? "contained" : "outlined"}
              fullWidth
              size="small"
              disabled={projectUserRole !== "Admin"}
              onClick={() => navigateToView("transfer")}
            >
              {t("projectview_page.settings_page.transferProjectHeader")}
            </Button>
          </div>
          <div className={styles["projectsettings-content-container"]}>
            <Routes>
              {/*  If route dont contains /plans/id, route to first  */}
              {/*  TODO: BY FLOOR PLAN ORDER NUMBER */}
              <Route path={`/`} element={<Navigate replace to="info" />} />
              <Route path={`/info`} element={<ProjectViewInfo />} />
              <Route path={`/users`} element={<ProjectViewUsers projectId={projectId} />} />
              <Route path={`/alarms`} element={<ProjectViewAlarms />} />
              <Route path={`/licences`} element={<ProjectViewLicences projectId={projectId} />} />
              <Route path={`/transfer`} element={<ProjectTransfer projectId={projectId} />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}
