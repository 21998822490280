// MUI
import { Button, Stack, useMediaQuery, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { GetDateString } from "../../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import axios from "axios";
import moment from "moment";

import { commonAttributes } from "../../../../common/commonAttributes";

// CSS
import styles from "./ProjectViewUsers.module.css";

// COMPONENTS
import ProjectViewAddProjectUser from "./ProjectViewAddProjectUserDialog";
import ConfirmDialog from "../../../../UI/ConfirmDialog";

// INTERFACE
interface ProjectUserInterface {
  projectId: number;
}

type UserDataRow = {
  id: string;
  userid: string;
  projectRoleId: string;
  role: string;
  email: string;
  firstname: string;
  lastname: string;
  startdate: string;
  enddate: string;
  receiveAlarms: boolean;
};

export default function ProjectViewUsers(props: ProjectUserInterface) {
  const { t } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 500px)");

  // GET DATA WHEN PROJECT ID CHANGES
  const [refreshContent, setrefreshContent] = useState(false);

  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);

  const [showEditUserDialog, setShowEditUserDialog] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<UserDataRow[]>();

  useEffect(() => {
    if (refreshContent === true) getProjectUsers();

    setrefreshContent(false);
  }, [refreshContent]);

  useEffect(() => {
    if (props.projectId >= 0) {
      setrefreshContent(true);
    } else {
      setusersData(Array<UserDataRow>());
    }
  }, [props.projectId]);

  // GET DATA FROM BACKEND
  const getProjectUsers = () => {
    const urlPath = commonAttributes.apiUrl + `/projects/${props.projectId}/users`;

    axios
      .get(urlPath)
      .then(response => {
        let dataObj: Array<any> = response.data;

        let usersData: Array<UserDataRow> = Array<UserDataRow>();

        dataObj.forEach(user => {
          if (user.endDateTime !== null && user.endDateTime !== "") return;

          usersData.push({
            id: user.id,
            userid: user.userId,
            projectRoleId: user.projectRoleId,
            role: user.projectRoleName,
            email: user.userName,
            firstname: "",
            lastname: "",
            startdate: GetDateString(user.startDateTime, "DD-MM-YYYY"),
            enddate: GetDateString(user.endDateTime, "DD-MM-YYYY"),
            receiveAlarms: user.receiveAlarms
          });
        });

        setusersData(usersData);
      })
      .catch(error => alert("Failed TO Get, " + error));
  };

  // USERS COLUMS
  const columns: GridColDef[] = [
    // { ENABLE WHEN TESTING OUT
    //   field: "id",
    //   headerName: t("projectview_page.settings_page.users_page.id"),
    //   minWidth: 75
    // },
    // {
    //   field: "userid",
    //   headerName: t("projectview_page.settings_page.users_page.userid"),
    //   minWidth: 75
    // },
    {
      field: "email",
      headerName: t("projectview_page.settings_page.users_page.email"),
      minWidth: 150,
      flex: 0.7
    },
    {
      field: "role",
      headerName: t("projectview_page.settings_page.users_page.role"),
      minWidth: 55,
      flex: 0.3
    },
    // {
    //   field: "firstname",
    //   headerName: "First name",
    //   minWidth: 200
    // },
    // {
    //   field: "lastname",
    //   headerName: "Last name",
    //   minWidth: 200
    // },
    {
      field: "startdate",
      headerName: t("projectview_page.settings_page.users_page.startdate"),
      minWidth: 70,
      flex: 1,
      hide: smallScreen ? true : false
    },
    // {
    //   field: "enddate",
    //   headerName: t("projectview_page.settings_page.users_page.enddate"),
    //   minWidth: 125
    // }
    {
      field: "receiveAlarms",
      headerName: t("projectview_page.settings_page.users_page.send_alarms"),
      minWidth: 30,
      flex: 0.5,
      renderCell: (params: any) => {
        return params.value ? (
          <Tooltip title={t("tooltip.deactivate_alarms") || ""} placement="left">
          <NotificationsActiveIcon
            sx={{
              width: "var(--iconSize)",
              height: "var(--iconSize)",
              color: "black",
              cursor: "pointer",
              "&:hover": {
                color: "var(--colorOrange)"
              }
            }}
            onClick={() => setUserReceiveAlarm(false, params.id)}
          />
          </Tooltip>
        ) : (
          <Tooltip title={t("tooltip.activate_alarms") || ""} placement="left">
          <NotificationsNoneIcon
            sx={{
              width: "var(--iconSize)",
              height: "var(--iconSize)",
              color: "black",
              cursor: "pointer",
              "&:hover": {
                color: "var(--colorOrange)"
              }
            }}
            onClick={() => setUserReceiveAlarm(true, params.id)}
          />
          </Tooltip>
        );
      }
    }
  ];

  // DATA
  const [usersData, setusersData] = useState<Array<UserDataRow>>(Array<UserDataRow>());
  const [gridSelectionModel, setgridSelectionModel] = useState<GridSelectionModel>([]);

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // DIALOG
  const [showAddDialog, setshowAddDialog] = useState(false);

  const handleAddDialogSave = () => {
    setrefreshContent(true);
    setshowAddDialog(false);
  };
  const handleAddDialogClose = () => {
    setshowAddDialog(false);
  };
  const handleEditDialogClose = () => {
    setShowEditUserDialog(false);
  };

  const handleRemove = () => {

    let selectedUsers: Array<UserDataRow> = usersData.filter(x =>
      gridSelectionModel.includes(x.id)
    );

    selectedUsers.forEach(user => {
      const urlPath = commonAttributes.apiUrl + `/projects/Users/${user.id}`;

      const timeNow = moment.now();

      const timeUtc = moment.utc(timeNow, true);

      let updateUserDTO = {
        id: user.id,
        username: user.email,
        ProjectId: props.projectId,
        projectRoleID: user.projectRoleId,
        endDateTime: moment(timeUtc, true).format("YYYY-MM-DDThh:mm:ss")
      };

      axios
        .post(urlPath, updateUserDTO)
        .then(() => {
          setrefreshContent(true);
        })
        .catch(error => console.log("Errro 123123, " + error));
    });

    setgridSelectionModel([]);
  };
  const setUserReceiveAlarm = (state: boolean, id: number) => {
    const urlPath =
      commonAttributes.apiUrl +
      `/Alarms/ProjectUsers/${id}/SetReceiveAlarms?receiveAlarms=${state}`;

    axios
      .post(urlPath, { receiveAlarms: state })
      .then(() => {
        setrefreshContent(true);
      })
      .catch(error => console.log(error));
  };

  const handleEditDialogSave = () => {
    setgridSelectionModel([]);
    setrefreshContent(true);
    setShowEditUserDialog(false);
  };

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  return (
    <div className={styles["projectsettings-users-container"]}>
      <div className={styles["projectsettings-content-container"]}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "email", sort: "asc" }]
            }
          }}
          rows={usersData}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50]}
          autoPageSize
          checkboxSelection
          disableSelectionOnClick
          selectionModel={gridSelectionModel}
          onSelectionModelChange={newSelectionModel => {
            setgridSelectionModel(newSelectionModel);
          }}
          sx={{
            backgroundColor: "white",
            borderRadius: "12px",
            fontSize: "var(--basicFontSize)",
            "& .Mui-checked svg": {
              width: "var(--checkBoxSize)",
              height: "var(--checkBoxSize)",
              backgroundColor: "transparent",
              borderRadius: 2,
              color: "orange"
            },
            "& .MuiCheckbox-root svg": {
              width: "var(--checkBoxSize)",
              height: "var(--checkBoxSize)",
              backgroundColor: "transparent",
              borderRadius: 2
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main"
            },
            "& .Mui-selected": {
              background: "gray"
            }
          }}
          localeText = {{
            toolbarDensity: t("measurement_list.data_grid_toolbar.toolbar_density"),
            toolbarDensityLabel: t("measurement_list.data_grid_toolbar.toolbar_density_label"),
            toolbarDensityCompact: t("measurement_list.data_grid_toolbar.toolbar_density_compact"),
            toolbarDensityStandard: t("measurement_list.data_grid_toolbar.toolbar_density_standard"),
            toolbarDensityComfortable: t("measurement_list.data_grid_toolbar.toolbar_density_comfortable"),
            toolbarColumns: t("measurement_list.data_grid_toolbar.toolbar_columns"),
            toolbarFilters: t("measurement_list.data_grid_toolbar.toolbar_filters"),
            toolbarExport: t("measurement_list.data_grid_toolbar.toolbar_export"),
            toolbarExportLabel: t("measurement_list.data_grid_toolbar.toolbar_export_label"),
            toolbarExportCSV: t("measurement_list.data_grid_toolbar.toolbar_export_csv"),
            toolbarExportPrint: t("measurement_list.data_grid_toolbar.toolbar_export_print"),
            columnsPanelTextFieldLabel: t("measurement_list.data_grid_toolbar.columns_panel_text_field_label"),
            columnsPanelTextFieldPlaceholder: t("measurement_list.data_grid_toolbar.columns_panel_text_field_placeholder"),
            columnsPanelShowAllButton: t("measurement_list.data_grid_toolbar.columns_panel_show_all_button"),
            columnsPanelHideAllButton: t("measurement_list.data_grid_toolbar.columns_panel_hide_all_button"),
            filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
            filterPanelOperators: t("measurement_list.data_grid_toolbar.filter_panel_operators"),
            filterPanelInputLabel: t("measurement_list.data_grid_toolbar.filter_panel_Input_label"),
            filterPanelInputPlaceholder: t("measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"),
            filterOperatorContains: t("measurement_list.data_grid_toolbar.filter_operator_contains"),
            filterOperatorEquals: t("measurement_list.data_grid_toolbar.filter_operator_equals"),
            filterOperatorStartsWith: t("measurement_list.data_grid_toolbar.filter_operator_starts_with"),
            filterOperatorEndsWith: t("measurement_list.data_grid_toolbar.filter_operator_ends_with"),
            filterOperatorIsEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_empty"),
            filterOperatorIsNotEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_not_empty"),
            filterOperatorIsAnyOf: t("measurement_list.data_grid_toolbar.filter_operator_is_any_of"),
            filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
            filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
            filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
            columnMenuShowColumns: t("measurement_list.data_grid_column_menu.show_columns"),
            columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
            columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
            columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
            columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
            columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc")
          }}
        />
      </div>
      <div className={styles["projectsettings-controls-container"]}>
        <Stack direction="row" spacing={2}>
          <Button 
            size="small" 
            variant="contained" 
            onClick={() => setshowAddDialog(true)}
          >
           {t("projectview_page.settings_page.users_page.add")}
          </Button>
            <Button 
              size="small" 
              variant="contained" 
              disabled={gridSelectionModel.length <= 0}
              onClick={(e) => {
                e.preventDefault();
                setShowDeleteUserDialog(true);
              }}
            >
             {t("projectview_page.settings_page.users_page.remove")}
            </Button>
            <Button 
              size="small" 
              variant="contained" 
              disabled={gridSelectionModel.length <= 0 || gridSelectionModel.length > 1}
              onClick={() => {
                setUserToEdit(usersData.filter(user => user.id === gridSelectionModel[0]));
                setShowEditUserDialog(true)
              }}
            >
            {t("projectview_page.settings_page.users_page.edit")}
          </Button>
        </Stack>
      </div>
      <ConfirmDialog 
        key="delete-user-dialog"
        open={showDeleteUserDialog}
        content={t("dialog.delete_user")}
        setOpen={setShowDeleteUserDialog}
        value={undefined}
        onConfirm={handleRemove}
      />
      {showAddDialog && (
        <ProjectViewAddProjectUser
          key="projectview-add-user-dialog"
          add={true}
          edit={false}
          projectId={props.projectId}
          handleClose={() => handleAddDialogClose()}
          handleSave={() => handleAddDialogSave()}
          usersData={usersData}
          gridSelectionModel={gridSelectionModel}
        />
      )}
      {showEditUserDialog && (
        <ProjectViewAddProjectUser
          key="projectview-eidt-user-dialog"
          add={false}
          edit={true}
          projectId={props.projectId}
          handleClose={() => handleEditDialogClose()}
          handleSave={() => handleEditDialogSave()}
          usersData={usersData}
          userToEdit={userToEdit}
          gridSelectionModel={gridSelectionModel}
        />
      )}
    </div>
  );
}
