import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { MenuItem, Menu, IconButton, Tooltip, Badge, SvgIcon, Divider, useMediaQuery } from "@mui/material";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OpacityIcon from "@mui/icons-material/Opacity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AirIcon from "@mui/icons-material/Air";
import CompressIcon from '@mui/icons-material/Compress';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Co2Icon from '@mui/icons-material/Co2';
import GrainIcon from '@mui/icons-material/Grain';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import HistoryChart from "./HistoryChart";
import styles from "./ProjectViewHistory.module.css";
import { 
  selectedHistoryDataSelector, 
  toggleShowHumidityChartSelection, 
  toggleShowTemperatureChart,
  toggleCo2Chart,
  toggleShowDifferentialPressureChart,
  toggleShowTvocChart,
  toggleShowPressureChart,
  toggleShowDustChart,
  toggleShowMcChart, 
  toggleShowDustPm10Chart} from "../../../../measurements/measurementsSlice";

import { TimeRangeOptionsType } from "../../../../../types";

import { RootState } from "../../../../../app/store";
import AddchartIcon from '@mui/icons-material/Addchart';

export default function ProjectViewHistory(props: {
  planViewShown: boolean;
  transitions: {
    buttons: boolean;
    measurements: boolean;
    planview: boolean;
    history: boolean;
  };
  deviceSettings?: boolean;
}) {
  const { planViewShown, transitions } = props;
  const { t } = useTranslation();

  interface TimeRange {
    from: string,
    to: string
  }

  const now = moment();
  const [timeOption, setTimeOption] = useState<keyof TimeRangeOptionsType>("week");
  const [timeRange, setTimeRange] = useState<TimeRange>({
    from: window.localStorage.getItem("chartZoomFrom")?.toString() || moment(now).subtract(6, "months").toISOString(),
    to: window.localStorage.getItem("chartZoomTo")?.toString() || moment(now).toISOString()
  });
  const [timeRangeChanged, setTimeRangeChanged] = useState(false);
  const [showAmbient, setShowAmbient] = useState(false);
  const minDateRef = useRef(0);
  const [calendarSelectionsMenuAnchorEl, setCalendarSelectionsMenuAnchorEl] =
    useState<Element | null>(null);
  const [timeSelectionsMenuAnchorEl, setTimeSelectionsMenuAnchorEl] = useState<Element | null>(
    null
  );
  const data = useSelector(selectedHistoryDataSelector);

  const historyData = useSelector((state: RootState) => state.device.historyData);
  
  const smallScreen = useMediaQuery("(max-width: 999px)");
  const lowHeightScreen = useMediaQuery("(max-height: 730px");

  const showRHorAH = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showRHorAH)

  const showHumidityChart = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showHumidityChart
  );
  const showTemperatureChart = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.showTemperatureChart
  );
  const temperatureCompensation = useSelector(
    (state: RootState) => state.ProjectPlanMeasurementPoints.temperatureCompensation
  );
  const showMcChart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showMcChart
  );
  const showDifferentialPressureChart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showDifferentialPressureChart
  );
  const showCo2Chart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showCo2Chart
  );
  const showTvocChart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showTvocChart
  );
  const showPressureChart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showPressureChart
  );
  const showDustChart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showDustChart
  );
  const showDustPm10Chart = useSelector (
    (state: RootState) => state.ProjectPlanMeasurementPoints.showDustPm10Chart
  );
 
  const [chartsAmount, setChartsAmount] = useState(6);

  const [activeChart, setActiveChart] = useState(1);

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState<Element | null>(null);

  const dispatch = useDispatch();

  const timeRangeOptions: TimeRangeOptionsType = useMemo(() => {
    return {
    "24h": { unit: "hours", number: 24 },
    week: {
      unit: "days",
      number: 7
    },
    "2weeks" : {
      unit: "days",
      number: 14
    },
    month: {
      unit: "days",
      number: 30
    },
    "6months": {
      unit: "months",
      number: 6
    },
    year: {
      unit: "months",
      number: 12
    },
    alltime: {
      unit: "months",
      number: 36
    }
    }}, []);

  const timeOptionLabels = [
    { id: "24h", label: t("projectview_page.history.24h_option") },
    { id: "week", label: t("projectview_page.history.week_option") },
    { id: "2weeks", label: t("projectview_page.history.2weeks_option") },
    { id: "month", label: t("projectview_page.history.month_option") },
    { id: "6months", label: t("projectview_page.history.6months_option") },
    { id: "year", label: t("projectview_page.history.year_option") },
    { id: "alltime", label: t("projectview_page.history.alltime")}
  ];

  const onTimeOptionChange = useCallback( (label: string) => {
    setTimeOption(label);
    window.localStorage.setItem("chartZoomFrom", moment(now).subtract(timeRangeOptions[label].number, timeRangeOptions[label].unit).unix().toString());
    const timeRangeOption = timeRangeOptions[label];
    window.localStorage.setItem("timeoption", label);
    setTimeRangeOption(timeRangeOption);
    setTimeRangeChanged(false);
  }, [timeRangeOptions]);

  useEffect(() => {
    if (smallScreen) {
      return;
    }
    const savedTimeOption = window.localStorage.getItem("timeoption");
    const savedShowTemperature = window.localStorage.getItem("showtemp");
    const savedShowAmbient = window.localStorage.getItem("showambient");
    const savedShowMc = window.localStorage.getItem("showmc");

    if (savedShowTemperature === "false") {
      dispatch(toggleShowTemperatureChart());
    }
    if (savedShowMc === "false") {
      dispatch(toggleShowMcChart());
    }
    if (savedShowAmbient === "true") {
      setShowAmbient(true);
    }
    if (savedTimeOption) {
      onTimeOptionChange(savedTimeOption);
    }
  }, [onTimeOptionChange, dispatch, smallScreen]);

  useEffect(() => {
    if (smallScreen) {
      return;
    }
    setChartsAmount(
      Number(showCo2Chart) +
      Number(showHumidityChart) +
      Number(showPressureChart) + 
      Number(showTemperatureChart) +
      Number(showDifferentialPressureChart) + 
      Number(showMcChart) +
      Number(showTvocChart) +
      Number(showDustChart) +
      Number(showDustPm10Chart)
    );
  }, [showHumidityChart, showPressureChart, showTemperatureChart, showTvocChart, showMcChart, showCo2Chart, showDifferentialPressureChart, showDustChart, showDustPm10Chart, smallScreen])

  const setTimeRangeOption = (timeRangeOption: {
    number: number;
    unit: moment.unitOfTime.DurationConstructor;
  }) => {
    if (timeRangeOption && timeRangeOption.number && timeRangeOption.unit) {
      const now = moment();
      const from = moment(now).subtract(timeRangeOption.number, timeRangeOption.unit);

      setTimeRange({
        from: from.toISOString(),
        to: now.toISOString()
      });
      window.localStorage.setItem("chartZoomFrom", from.unix().toString());
      window.localStorage.setItem("chartZoomTo", now.unix().toString());
    }
  };

  const handleTimeRangeChange = (from: string, to: string) => {
    setTimeRangeChanged(true);
    setTimeRange({ from, to });
  };

  /*const resetTimeRange = () => {
    setTimeRangeOption(timeRangeOptions[timeOption]);
    setTimeRangeChanged(false);
  };*/

  const getDateInputValue = (date: string) => {
    const dateToReturn = `${new Date(date).getFullYear()}-${
      (new Date(date).getMonth() + 1).toString().length !== 1
        ? new Date(date).getMonth() + 1
        : `0${new Date(date).getMonth() + 1}`
    }-${
      new Date(date).getDate().toString().length !== 1
        ? new Date(date).getDate()
        : `0${new Date(date).getDate()}`
    }`;
    return dateToReturn;
  };

  const refChartArea = useRef<HTMLDivElement>(null);

  const activeIconStyles = { color: "#eb941c", fontSize: "8px", marginBottom: "1px", cursor: "pointer" };
  const inactiveIconStyles = { color: "#8c95a4", fontSize: "8px", marginBottom: "1px", cursor: "pointer" };

  const selectedIconButtonStyles = {color: "#eb941c", borderRadius: "1px", width: "48px"};
  const iconButtonStyles = {borderRadius: "1px", width: "48px"};

  return (
    <React.Fragment>
      <div className={styles["project-view-history-toolbar-container"]}>
        <Tooltip title={t("tooltip.time") || ""} placement="left">
          <IconButton
            sx={{ borderRadius: "1px", width: "48px" }}
            onClick={e => {
              setTimeSelectionsMenuAnchorEl(e.currentTarget);
            }}
          >
            <div
              style={
                timeSelectionsMenuAnchorEl
                  ? {
                      color: "#eb941c",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }
              }
            >
              <AccessTimeIcon
                sx={{ height: "var(--iconSize)", width: "var(--iconSize)", mt: "5px" }}
              />
            </div>
          </IconButton>
        </Tooltip>
        <Tooltip title={t("tooltip.calendar") || ""} placement="left">
          <IconButton
            sx={{ borderRadius: "1px", width: "48px" }}
            onClick={e => {
              setCalendarSelectionsMenuAnchorEl(e.currentTarget);
            }}
          >
            <div
              style={
                calendarSelectionsMenuAnchorEl
                  ? {
                      color: "#eb941c",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }
                  : {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }
              }
            >
              <CalendarMonthIcon
                sx={{ height: "var(--iconSize)", width: "var(--iconSize)", mt: "5px" }}
              />
            </div>
          </IconButton>
        </Tooltip>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          light={false}
          sx={{ borderColor: "var(--colorDivider)" }}
        />
        <Tooltip title={t("tooltip.ambient") || ""} placement="left">
          <IconButton
            style={showAmbient ? selectedIconButtonStyles : iconButtonStyles}
            onClick={() => {
              setShowAmbient(prev => !prev);
              if (!showAmbient) {
                window.localStorage.setItem("showambient", "true");
              } else {
                window.localStorage.setItem("showambient", "false");
              }
            }}
          >
            <div className={styles["project-view-history-icons"]}>
              <AirIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)", mt: "5px" }} />
              <label style={showAmbient ? activeIconStyles : inactiveIconStyles}>
                {t("projectview_page.history.ambient")}
              </label>
            </div>
          </IconButton>
        </Tooltip>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          light={false}
          sx={{ borderColor: "var(--colorDivider)" }}
        />

        {/********* MOBILE MENU    *************************************************************** */}

        {(smallScreen || lowHeightScreen ||planViewShown) && (
          <IconButton
            onClick={e => {
              setMobileMenuAnchorEl(e.currentTarget);
              setMobileMenuOpen(true);
            }}
          >
            <AddchartIcon />
          </IconButton>
        )}
        {(smallScreen || lowHeightScreen || planViewShown) && (
          <Menu
            anchorEl={mobileMenuAnchorEl}
            id="mobile-charts-units-menu"
            open={mobileMenuOpen}
            onClose={() => {
              setMobileMenuOpen(false);
              setMobileMenuAnchorEl(null);
            }}
            PaperProps={{
              sx: {
                width: "auto",
                height: "auto"
              }
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowHumidityChartSelection());
              } else {
                setActiveChart(1);
              }
            }}>
              <IconButton
                sx={{ mt: "5px", borderRadius: "1px" }}
                disabled={chartsAmount === 6 && !showHumidityChart}
                style={
                  (!smallScreen && showHumidityChart) || (smallScreen && activeChart === 1)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <Badge badgeContent={"RH"}>
                  <div className={styles["project-view-history-icons"]}>
                    <OpacityIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                    <label
                      style={
                        (!smallScreen && showHumidityChart) || (smallScreen && activeChart === 1)
                          ? activeIconStyles
                          : inactiveIconStyles
                      }
                    >
                      {t("projectview_page.history.humidity_chart_title")}
                    </label>
                  </div>
                </Badge>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowMcChart());
                if (showMcChart) {
                  window.localStorage.setItem("showmc", "false");
                } else {
                  window.localStorage.setItem("showmc", "true");
                }
              } else {
                setActiveChart(2);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showMcChart}
                style={
                  (!smallScreen && showMcChart) || (smallScreen && activeChart === 2)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <Badge badgeContent={"MC"}>
                  <div className={styles["project-view-history-icons"]}>
                    <OpacityIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                    <label
                      style={
                        (!smallScreen && showMcChart) || (smallScreen && activeChart === 2)
                          ? activeIconStyles
                          : inactiveIconStyles
                      }
                    >
                      {t("projectview_page.history.humidity_chart_title")}
                    </label>
                  </div>
                </Badge>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowTemperatureChart());
                if (showTemperatureChart) {
                  window.localStorage.setItem("showtemp", "false");
                } else {
                  window.localStorage.setItem("showtemp", "true");
                }
              } else {
                setActiveChart(3);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showTemperatureChart}
                style={
                  (!smallScreen && showTemperatureChart) || (smallScreen && activeChart === 3)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <ThermostatIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showTemperatureChart) || (smallScreen && activeChart === 3)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.temperature_chart_title")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowDifferentialPressureChart());
              } else {
                setActiveChart(4);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showDifferentialPressureChart}
                style={
                  (!smallScreen && showDifferentialPressureChart) ||
                  (smallScreen && activeChart === 4)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <CompareArrowsIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showDifferentialPressureChart) ||
                      (smallScreen && activeChart === 4)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.differential_pressure")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowPressureChart());
              } else {
                setActiveChart(5);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showPressureChart}
                style={
                  (!smallScreen && showPressureChart) || (smallScreen && activeChart === 5)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <CompressIcon sx={{ height: "var(--iconSize)", width: "(var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showPressureChart) || (smallScreen && activeChart === 5)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.pressure")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleCo2Chart());
              } else {
                setActiveChart(6);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showCo2Chart}
                style={
                  (!smallScreen && showCo2Chart) || (smallScreen && activeChart === 6)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <Co2Icon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showCo2Chart) || (smallScreen && activeChart === 6)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.co2")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowTvocChart());
              } else {
                setActiveChart(7);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showTvocChart}
                style={
                  (!smallScreen && showTvocChart) || activeChart === 7
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <SvgIcon sx={{ height: "var(--iconSize)" + 5, width: "var(--iconSize)" }}>
                    <path d="M7.27,10L9,7H14.42L15.58,5L15.5,4.5A1.5,1.5 0 0,1 17,3A1.5,1.5 0 0,1 18.5,4.5C18.5,5.21 18,5.81 17.33,5.96L16.37,7.63L17.73,10L18.59,8.5L18.5,8A1.5,1.5 0 0,1 20,6.5A1.5,1.5 0 0,1 21.5,8C21.5,8.71 21,9.3 20.35,9.46L18.89,12L20.62,15C21.39,15.07 22,15.71 22,16.5A1.5,1.5 0 0,1 20.5,18A1.5,1.5 0 0,1 19,16.5V16.24L17.73,14L16.37,16.37L17.33,18.04C18,18.19 18.5,18.79 18.5,19.5A1.5,1.5 0 0,1 17,21A1.5,1.5 0 0,1 15.5,19.5L15.58,19L14.42,17H10.58L9.42,19L9.5,19.5A1.5,1.5 0 0,1 8,21A1.5,1.5 0 0,1 6.5,19.5C6.5,18.79 7,18.19 7.67,18.04L8.63,16.37L4.38,9C3.61,8.93 3,8.29 3,7.5A1.5,1.5 0 0,1 4.5,6A1.5,1.5 0 0,1 6,7.5C6,7.59 6,7.68 6,7.76L7.27,10M10.15,9L8.42,12L10.15,15H14.85L16.58,12L14.85,9H10.15Z" />
                  </SvgIcon>
                  <label
                    style={
                      (!smallScreen && showTvocChart) || activeChart === 7
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.tvoc")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowDustChart());
              } else {
                setActiveChart(8);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showDustChart}
                style={
                  (!smallScreen && showDustChart) || (smallScreen && activeChart === 8)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <GrainIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showDustChart) || (smallScreen && activeChart === 8)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.dust")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
            <MenuItem onClick={() => {
              setMobileMenuOpen(false);
              if (!smallScreen) {
                dispatch(toggleShowDustPm10Chart());
              } else {
                setActiveChart(9);
              }
            }}>
              <IconButton
                disabled={chartsAmount === 6 && !showDustPm10Chart}
                style={
                  (!smallScreen && showDustPm10Chart) || (smallScreen && activeChart === 9)
                    ? selectedIconButtonStyles
                    : iconButtonStyles
                }
              >
                <div className={styles["project-view-history-icons"]}>
                  <GrainIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showDustPm10Chart) || (smallScreen && activeChart === 9)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.dust_pm10")}
                  </label>
                </div>
              </IconButton>
            </MenuItem>
          </Menu>
        )}

        {/************MOBILE MENU END*************************************************************** */}

        {!smallScreen && !lowHeightScreen && !planViewShown &&(
          <Tooltip title={t("tooltip.moisture") || ""} placement="left">
            <IconButton
              sx={{ mt: "5px", borderRadius: "1px" }}
              disabled={chartsAmount === 6 && !showHumidityChart}
              style={
                (!smallScreen && showHumidityChart) || (smallScreen && activeChart === 1)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowHumidityChartSelection());
                } else {
                  setActiveChart(1);
                }
              }}
            >
              <Badge badgeContent={"RH"}>
                <div className={styles["project-view-history-icons"]}>
                  <OpacityIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showHumidityChart) || (smallScreen && activeChart === 1)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.humidity_chart_title")}
                  </label>
                </div>
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.moisture_mc") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showMcChart}
              style={
                (!smallScreen && showMcChart) || (smallScreen && activeChart === 2)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowMcChart());
                  if (showMcChart) {
                    window.localStorage.setItem("showmc", "false");
                  } else {
                    window.localStorage.setItem("showmc", "true");
                  }
                } else {
                  setActiveChart(2);
                }
              }}
            >
              <Badge badgeContent={"MC"}>
                <div className={styles["project-view-history-icons"]}>
                  <OpacityIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                  <label
                    style={
                      (!smallScreen && showMcChart) || (smallScreen && activeChart === 2)
                        ? activeIconStyles
                        : inactiveIconStyles
                    }
                  >
                    {t("projectview_page.history.humidity_chart_title")}
                  </label>
                </div>
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.temp") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showTemperatureChart}
              style={
                (!smallScreen && showTemperatureChart) || (smallScreen && activeChart === 3)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowTemperatureChart());
                  if (showTemperatureChart) {
                    window.localStorage.setItem("showtemp", "false");
                  } else {
                    window.localStorage.setItem("showtemp", "true");
                  }
                } else {
                  setActiveChart(3);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <ThermostatIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showTemperatureChart) || (smallScreen && activeChart === 3)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.temperature_chart_title")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.differential_pressure") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showDifferentialPressureChart}
              style={
                (!smallScreen && showDifferentialPressureChart) ||
                (smallScreen && activeChart === 4)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowDifferentialPressureChart());
                } else {
                  setActiveChart(4);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <CompareArrowsIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showDifferentialPressureChart) ||
                    (smallScreen && activeChart === 4)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.differential_pressure")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.pressure") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showPressureChart}
              style={
                (!smallScreen && showPressureChart) || (smallScreen && activeChart === 5)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowPressureChart());
                } else {
                  setActiveChart(5);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <CompressIcon sx={{ height: "var(--iconSize)", width: "(var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showPressureChart) || (smallScreen && activeChart === 5)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.pressure")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.co2") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showCo2Chart}
              style={
                (!smallScreen && showCo2Chart) || (smallScreen && activeChart === 6)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleCo2Chart());
                } else {
                  setActiveChart(6);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <Co2Icon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showCo2Chart) || (smallScreen && activeChart === 6)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.co2")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.tvoc") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showTvocChart}
              style={
                (!smallScreen && showTvocChart) || activeChart === 7
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowTvocChart());
                } else {
                  setActiveChart(7);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <SvgIcon sx={{ height: "var(--iconSize)" + 5, width: "var(--iconSize)" }}>
                  <path d="M7.27,10L9,7H14.42L15.58,5L15.5,4.5A1.5,1.5 0 0,1 17,3A1.5,1.5 0 0,1 18.5,4.5C18.5,5.21 18,5.81 17.33,5.96L16.37,7.63L17.73,10L18.59,8.5L18.5,8A1.5,1.5 0 0,1 20,6.5A1.5,1.5 0 0,1 21.5,8C21.5,8.71 21,9.3 20.35,9.46L18.89,12L20.62,15C21.39,15.07 22,15.71 22,16.5A1.5,1.5 0 0,1 20.5,18A1.5,1.5 0 0,1 19,16.5V16.24L17.73,14L16.37,16.37L17.33,18.04C18,18.19 18.5,18.79 18.5,19.5A1.5,1.5 0 0,1 17,21A1.5,1.5 0 0,1 15.5,19.5L15.58,19L14.42,17H10.58L9.42,19L9.5,19.5A1.5,1.5 0 0,1 8,21A1.5,1.5 0 0,1 6.5,19.5C6.5,18.79 7,18.19 7.67,18.04L8.63,16.37L4.38,9C3.61,8.93 3,8.29 3,7.5A1.5,1.5 0 0,1 4.5,6A1.5,1.5 0 0,1 6,7.5C6,7.59 6,7.68 6,7.76L7.27,10M10.15,9L8.42,12L10.15,15H14.85L16.58,12L14.85,9H10.15Z" />
                </SvgIcon>
                <label
                  style={
                    (!smallScreen && showTvocChart) || activeChart === 7
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.tvoc")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.dust") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showDustChart}
              style={
                (!smallScreen && showDustChart) || (smallScreen && activeChart === 8)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowDustChart());
                } else {
                  setActiveChart(8);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <GrainIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showDustChart) || (smallScreen && activeChart === 8)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.dust")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}
        {!smallScreen && !lowHeightScreen && !planViewShown && (
          <Tooltip title={t("tooltip.dust_pm10") || ""} placement="left">
            <IconButton
              disabled={chartsAmount === 6 && !showDustPm10Chart}
              style={
                (!smallScreen && showDustPm10Chart) || (smallScreen && activeChart === 9)
                  ? selectedIconButtonStyles
                  : iconButtonStyles
              }
              onClick={() => {
                if (!smallScreen) {
                  dispatch(toggleShowDustPm10Chart());
                } else {
                  setActiveChart(9);
                }
              }}
            >
              <div className={styles["project-view-history-icons"]}>
                <GrainIcon sx={{ height: "var(--iconSize)", width: "var(--iconSize)" }} />
                <label
                  style={
                    (!smallScreen && showDustPm10Chart) || (smallScreen && activeChart === 9)
                      ? activeIconStyles
                      : inactiveIconStyles
                  }
                >
                  {t("projectview_page.history.dust_pm10")}
                </label>
              </div>
            </IconButton>
          </Tooltip>
        )}

        <Menu
          open={!!timeSelectionsMenuAnchorEl}
          anchorEl={timeSelectionsMenuAnchorEl}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
          onClose={() => {
            setTimeSelectionsMenuAnchorEl(null);
          }}
        >
          {timeOptionLabels.map(timeOption => {
            return (
              <MenuItem
                key={timeOption.id}
                value={timeOption.id}
                onClick={() => {
                  onTimeOptionChange(timeOption.id);
                  setTimeSelectionsMenuAnchorEl(null);
                }}
              >
                {timeOption.label}
              </MenuItem>
            );
          })}
          {/* <MenuItem>
            {timeRangeChanged && (
              <div
                className={styles["project-view-history-reset-time-range"]}
                onClick={() => {
                  resetTimeRange();
                  setTimeSelectionsMenuAnchorEl(null);
                }}
              >
                {t("projectview_page.history.time_range_reset")}
              </div>
            )}
          </MenuItem> */}
        </Menu>
        <Menu
          open={!!calendarSelectionsMenuAnchorEl}
          anchorEl={calendarSelectionsMenuAnchorEl}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
          onClose={() => {
            setCalendarSelectionsMenuAnchorEl(null);
          }}
        >
          <div style={{ padding: "10px" }}>
            <input
              className={`${styles["dateInput"]}`}
              type={"date"}
              value={getDateInputValue(timeRange.from)}
              onChange={event => {
                setTimeRange({
                  from: new Date(event.target.value).toISOString(),
                  to: timeRange.to
                });
                window.localStorage.setItem(
                  "chartZoomFrom",
                  moment(event.target.value).unix().toString()
                );
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <input
              type={"date"}
              value={getDateInputValue(timeRange.to)}
              onChange={event => {
                setTimeRange({
                  from: timeRange.from,
                  to: new Date(event.target.value).toISOString()
                });
                window.localStorage.setItem(
                  "chartZoomTo",
                  moment(event.target.value).unix().toString()
                );
              }}
            />
          </div>
        </Menu>
      </div>
      <div className={styles["project-view-history-charts-container"]} ref={refChartArea}>
        {((showHumidityChart && !smallScreen) || (smallScreen && activeChart === 1)) && (
          <div
            className={`${styles["project-view-history-container-main"]} ${
              showHumidityChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key={showRHorAH ? "project-view-history-main-rh" : "project-view-history-main-abs"}
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="RelativeHumidity"
              title={t("projectview_page.history.humidity_chart_title")}
              //altDataProps={["MoistureContent A", "MoistureContent B"]}
              showAmbient={showAmbient}
              tickSizeDivisor={10}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              extraSpaceRight={true}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showMcChart && !smallScreen) || (smallScreen && activeChart === 2)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showMcChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key={"project-view-history-main-mc"}
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="MoistureContent"
              title={"Moisture Content"}
              //altDataProps={["MoistureContent A", "MoistureContent B"]}
              showAmbient={showAmbient}
              tickSizeDivisor={10}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              extraSpaceRight={true}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showTemperatureChart && !smallScreen) || (smallScreen && activeChart === 3)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showTemperatureChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key={"project-view-history-alt-temp"}
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              extraSpaceRight={true}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="Temperature"
              title={"Temp"}
              //altDataProps={["DifferentialPressure"]}
              showAmbient={showAmbient}
              tickSizeDivisor={2}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showDifferentialPressureChart && !smallScreen) ||
          (smallScreen && activeChart === 4)) && (
          <div
            className={`${styles["project-view-history-container-main-right"]} ${
              showDifferentialPressureChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key={"project-view-history-main-differential-pressure"}
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="DifferentialPressure"
              title="PA"
              //altDataProps={["MoistureContent A", "MoistureContent B"]}
              showAmbient={showAmbient}
              tickSizeDivisor={10}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              extraSpaceRight={true}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showCo2Chart && !smallScreen) || (smallScreen && activeChart === 6)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showCo2Chart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key={"project-view-history-main-co2"}
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="CO2"
              title={"CO2"}
              //altDataProps={["MoistureContent A", "MoistureContent B"]}
              showAmbient={showAmbient}
              tickSizeDivisor={10}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              extraSpaceRight={true}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showPressureChart && !smallScreen) || (smallScreen && activeChart === 5)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showPressureChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key="project-view-history-alt-pressure"
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              extraSpaceRight={true}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="P"
              title={"P"}
              //altDataProps={["DifferentialPressure"]}
              showAmbient={showAmbient}
              tickSizeDivisor={2}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showTvocChart && !smallScreen) || (smallScreen && activeChart === 7)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showTvocChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key="project-view-history-alt-tvoc"
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              extraSpaceRight={true}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="TVOC"
              title={"tVOC"}
              //altDataProps={["DifferentialPressure"]}
              showAmbient={showAmbient}
              tickSizeDivisor={2}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showDustChart && !smallScreen) || (smallScreen && activeChart === 8)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showDustChart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key="project-view-history-alt-dust"
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              extraSpaceRight={true}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="PM 2_5"
              title={"PM2.5"}
              //altDataProps={["DifferentialPressure"]}
              showAmbient={showAmbient}
              tickSizeDivisor={2}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
        {((showDustPm10Chart && !smallScreen) || (smallScreen && activeChart === 9)) && (
          <div
            className={`${styles["project-view-history-container-alt"]} ${
              showDustPm10Chart ? "" : styles["fullHeight"]
            }`}
          >
            <HistoryChart
              key="project-view-history-alt-dust"
              signalDatas={props.deviceSettings ? historyData : data}
              tickFormat="D-M-Y"
              tooltipFormat="D-M-Y HH:mm"
              fromDate={timeRange.from}
              toDate={timeRange.to}
              extraSpaceRight={true}
              handleTimeRangeChange={handleTimeRangeChange}
              dataProp="PM 10"
              title={"PM10"}
              //altDataProps={["DifferentialPressure"]}
              showAmbient={showAmbient}
              tickSizeDivisor={2}
              editEnabled={true}
              chartsAmount={chartsAmount}
              planViewShown={planViewShown}
              transitions={transitions}
              minDateRef={minDateRef}
              refChartArea={refChartArea}
              deviceSettings={props.deviceSettings ? true : false}
            ></HistoryChart>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
