import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  FormControl,
  TextField,
  Button,
  Input,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";

import styles from "./AddProjectPlanForm.module.css";

import { PlanObject } from "../../../../types";

//STORE
import { addProjectPlan, updateProjectPlan } from "../../projectSlice";

import { useDispatch } from "react-redux";

interface FormInterface {
  isEditing: boolean;
  maxOrdinalNumber: number;
  currentOrdinalNro: number;
  open: boolean;
  projectId: number;
  plan: PlanObject | undefined;
  projectPlans: Array<PlanObject>;
  handleClose: () => void;
  handleSave: () => void;
  deviceSettings: boolean;
}
export default function AddProjectPlanForm(props: FormInterface) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // **********************************************************************************************************************

  useEffect(() => {
    if (props.plan === undefined) return;

    setisEdit(true);
    setPlanName(props.plan.name);
  }, [props.plan]);

  useEffect(() => {
    if (props.plan === undefined) {
      return;
    }
    if (props.isEditing) {
      setImageObj(props.plan.filePath);
    }
  }, [props.isEditing, props.plan]);

  // **********************************************************************************************************************
  const [planName, setPlanName] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageObj, setImageObj]: any = useState(undefined);
  const [currentOrdinalNro, setCurrordinalNro] = useState("-1");
  const [isEdit, setisEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  //**************************
  const MenuItems: Array<number> = [];

  for (let index = 0; index <= props.maxOrdinalNumber; index++) {
    MenuItems.push(index);
  }

  // **********************************************************************************************************************
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPlanName(event.target.value);
  };

  const handleOrdinalChange = (event: SelectChangeEvent) => {
    setCurrordinalNro(event.target.value);
  };

  const handlePictureChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event !== null && event !== undefined) {
      if (event.target.files !== null && event.target.files !== undefined) {
        setImageName(event.target.files[0].name);
        setImageObj(event.target.files[0]);
      }
    }
  };

  // **********************************************************************************************************************
  // CHECK ERRORS
  const planNameValid = planName !== "";
  const planNameIsUnique = props.projectPlans.filter(plan => plan.name === planName).length <= 0;
  const imageNameValid = imageName !== "";
  const ordinalNroValid = currentOrdinalNro !== "";
  const charsAllowed = new RegExp(/([^a-öA-Ö0-9._-\s])/g);

  const validFileType = (file: File) => {
    const fileTypes = ["image/jpg", "image/jpeg", "application/pdf", "image/png", "image/gif"];
    return fileTypes.includes(file.type);
  };

  // **********************************************************************************************************************
  const handleSave = () => {
    const fileNameCharactersAllowed = new RegExp(/([^a-öA-Ö0-9._-\s])/g);
    //CHECK IF PLAN NAME CONTAINS SPECIAL CHARACTERS
    if (fileNameCharactersAllowed.test(planName)) {
      setErrorMessage(t("project_plan_list.add_form.plan_name_special_characters"));
      return;
    }
    //CHECK IF IMAGE NAME CONTAINS SPECIAL CHARACTERS
    else if (!props.isEditing && fileNameCharactersAllowed.test(imageName)) {
      setErrorMessage(t("project_plan_list.add_form.image_name_special_characters"));
      return;
    }
    //CHECK IF PLAN NAME ALREADY EXISTS ON CURRENT PROJECT
    else if (!planNameIsUnique) {
      setErrorMessage(t("project_plan_list.add_form.plan_name_exists"));
      return;
    }
    //CHECK IF IMAGE HAS BEEN SELECTED
    else if (planName === "") {
      setErrorMessage(t("project_plan_list.add_form.fill_required"));
      return;
    }
    else if (!props.isEditing && !validFileType(imageObj)) {
      setErrorMessage(t("project_plan_list.add_form.invalid_file_type"));
      return;
    }
    else if (props.isEditing && props.plan !== undefined) {
      // UPDATE

      let imageForm1: FormData | undefined = undefined;
      if (imageObj !== undefined) {
        imageForm1 = new FormData();
        imageForm1.append("Image", imageObj);
      }

      dispatch(updateProjectPlan({ plan: props.plan, newName: planName, imageForm: imageForm1 }));
    } else {
      // ADD NEW

      if (planNameValid === false || imageNameValid === false || ordinalNroValid === false) {
        setErrorMessage(t("project_plan_list.add_form.fill_required"));
        return;
      }

      //IMAGE DATA
      const imageForm = new FormData();
      imageForm.append("Image", imageObj);
      const fileType = imageObj.name.substring(imageObj.name.lastIndexOf(".") + 1);
      //console.log("TESTING NEW ADDING NEED THEN");

      dispatch(
        addProjectPlan({ projectId: props.projectId, planName: planName, imageForm: imageForm, fileType: fileType })
      );
    }

    // CLOSE DIAL
    props.handleClose();
  };

  // **********************************************************************************************************************
  return !props.deviceSettings ? (
    <Dialog open={true} onClose={props.handleClose}>
      <div className={styles["floorplanform-form-container"]}>
        <div className={styles["floorplanform-form-header"]}>
          {isEdit
            ? t("project_plan_list.add_form.edit_header")
            : t("project_plan_list.add_form.add_new_header")}
        </div>

        <TextField
          required
          fullWidth
          label={t("project_plan_list.add_form.plan_name")}
          size="small"
          onChange={handleNameChange}
          value={planName}
          error={planNameValid === false}
          InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        />
        {!props.isEditing && (
          <>
            <InputLabel
              id="picture-label"
              margin="dense"
              disableAnimation={true}
              style={{
                fontSize: "var(--inputFontSize)",
                textAlign: "left",
                width: "90%",
                display: isEdit ? "none" : ""
              }}
            >
              {t("project_plan_list.add_form.image")}
            </InputLabel>
            <Input
              required
              fullWidth
              inputProps={{
                accept: ".png, .jpeg, .pdf, .jpg, .gif",
                style: { fontSize: "var(--inputFontSize)" }
              }}
              id="contained-button-file"
              type="file"
              onChange={handlePictureChange}
              error={imageNameValid === false || charsAllowed.test(imageName)}
              style={{ display: isEdit ? "none" : "" }}
            />
            <InputLabel
              id="accepted-formats-helper-text"
              margin="dense"
              disableAnimation={true}
              style={{
                fontSize: "var(--inputFontSize)",
                fontWeight: "bold",
                textAlign: "left",
                width: "90%",
                marginBottom: "10px"
              }}
            >
              {t("project_plan_list.add_form.accepted_formats")}
            </InputLabel>
          </>
        )}
        <FormControl variant="standard" sx={{ display: "none" }}>
          <InputLabel sx={{ fontSize: "var(--inputFontSize)" }} id="ordinal-label">
            {t("project_plan_list.add_form.ordinal_nro")}
          </InputLabel>
          <Select
            required
            fullWidth
            labelId="ordinal-label"
            value={currentOrdinalNro}
            error={ordinalNroValid === false}
            onChange={handleOrdinalChange}
            label={t("project_plan_list.add_form.ordinal_nro")}
            size="small"
            sx={{ fontSize: "var(--inputFontSize)" }}
          >
            <MenuItem sx={{ fontSize: "var(--inputFontSize)" }} value="-1">
              {t("project_plan_list.add_form.next_free")}
            </MenuItem>
            {MenuItems.map(number => {
              return (
                <MenuItem
                  sx={{ fontSize: "var(--inputFontSize)" }}
                  key={"project_form_number_" + number}
                  value={number}
                >
                  {number}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* *************************************************************************** */}
        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>

        <Button
          variant="contained"
          disabled={planName === "" || (!props.isEditing && imageName === "")}
          onClick={() => handleSave()}
        >
          {t("common.save")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.handleClose();
          }}
        >
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
  ) : (
    <>
    <div className={styles["floorplanform-form-container-device-settings"]}>
      <div className={styles["floorplanform-form-header"]}>
        {isEdit
          ? t("project_plan_list.add_form.edit_header")
          : t("project_plan_list.add_form.add_new_header")}
      </div>

      <TextField
        required
        fullWidth
        label={t("project_plan_list.add_form.plan_name")}
        size="small"
        onChange={handleNameChange}
        value={planName}
        error={planNameValid === false}
        InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
      />
      {!props.isEditing && (
        <>
          <InputLabel
            id="picture-label"
            margin="dense"
            disableAnimation={true}
            style={{
              fontSize: "var(--inputFontSize)",
              textAlign: "left",
              width: "90%",
              display: isEdit ? "none" : ""
            }}
          >
            {t("project_plan_list.add_form.image")}
          </InputLabel>
          <Input
            required
            fullWidth
            inputProps={{
              accept: ".png, .jpeg, .pdf, .jpg, .gif",
              style: { fontSize: "var(--inputFontSize)" }
            }}
            id="contained-button-file"
            type="file"
            onChange={handlePictureChange}
            error={imageNameValid === false || charsAllowed.test(imageName)}
            style={{ display: isEdit ? "none" : "" }}
          />
          <InputLabel
            id="accepted-formats-helper-text"
            margin="dense"
            disableAnimation={true}
            style={{
              fontSize: "var(--inputFontSize)",
              fontWeight: "bold",
              textAlign: "left",
              width: "90%",
              marginBottom: "10px"
            }}
          >
            {t("project_plan_list.add_form.accepted_formats")}
          </InputLabel>
        </>
      )}
      <FormControl variant="standard" sx={{ display: "none" }}>
        <InputLabel sx={{ fontSize: "var(--inputFontSize)" }} id="ordinal-label">
          {t("project_plan_list.add_form.ordinal_nro")}
        </InputLabel>
        <Select
          required
          fullWidth
          labelId="ordinal-label"
          value={currentOrdinalNro}
          error={ordinalNroValid === false}
          onChange={handleOrdinalChange}
          label={t("project_plan_list.add_form.ordinal_nro")}
          size="small"
          sx={{ fontSize: "var(--inputFontSize)" }}
        >
          <MenuItem sx={{ fontSize: "var(--inputFontSize)" }} value="-1">
            {t("project_plan_list.add_form.next_free")}
          </MenuItem>
          {MenuItems.map(number => {
            return (
              <MenuItem
                sx={{ fontSize: "var(--inputFontSize)" }}
                key={"project_form_number_" + number}
                value={number}
              >
                {number}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* *************************************************************************** */}
      <Button
        variant="contained"
        color="error"
        style={errorMessage !== "" ? { display: "" } : { display: "none" }}
      >
        {errorMessage}
      </Button>

      <Button
        variant="contained"
        disabled={planName === "" || (!props.isEditing && imageName === "")}
        onClick={() => handleSave()}
      >
        {t("common.save")}
      </Button>
      </div>
    </>
  );
}
