import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import axios from "axios";

//COMMON ATTRIBUTES
import { commonAttributes } from "./../common/commonAttributes";

import {
  PlanObject,
  ReportObject,
  MeasurementPointObject,
  DeviceObject,
  SensorUnitObj,
  latestDataInterface,
  HistoryDataObject
} from "../../types";

import { projectInitialState } from "../project/projectSlice";
import { mergeDeep, performTimestampDuplicate } from "../utils/commonFunctions";
///////////////////////////////////////////////////////////////////////////////////////////////
export interface ReportState {
  name: string;
  loading: boolean;
  pendingItems: number;
  reportObject: ReportObject;
}

const reportInitialState: ReportState = {
  name: "report",
  loading: true,
  pendingItems: 0,
  reportObject: {
    project: projectInitialState.project,
    plans: projectInitialState.plans,
    planData: {}
  }
};
///////////////////////////////////////////////////////////////////////////////////////////////
// API CALLS
export const fecthProjectBaseReport = createAsyncThunk(
  "Report/fecthProjectBaseReport",
  async (projectId: number) => {
    //api/Projects/1/Report
    const data = await axios.get(`${commonAttributes.apiUrl}/Projects/${projectId}/Report`);
    return { data: data.data, projectId: projectId };
  }
);

export const fetchPlanHistoryData = createAsyncThunk(
  "Report/fetchProjectPlanHistory",
  async (planId: number, {dispatch}) => {
    let data = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/PlanHistoryPage`, {
      params: { planId: planId },
    });
    let outData = {};
    mergeDeep(outData, data.data.measurementPoints);
    // Refresh the graph after first data loading
    performTimestampDuplicate(outData);

    dispatch(updateHistoryData({data: outData, planId: planId}));
    let refreshCycle = 0;
    // nextToken is returned when there is multiple pages available
    while(data.data.nextToken) {
      data = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/PlanHistoryPage`, {
        params: { planId: planId, nextToken: data.data.nextToken },
      });
      // merge the data to old data
      mergeDeep(outData, data.data.measurementPoints)
      performTimestampDuplicate(outData);
      
      // Refresh the graph during the loading
      if(refreshCycle++ > 8) {
        refreshCycle = 0;
        dispatch(updateHistoryData({data: outData, planId: planId}));
      }
    }
   
    return { data: outData, planId: planId };
  }
);

export const reportSlice = createSlice({
  name: "reporting",
  initialState: reportInitialState,
  reducers: {
    // Used to update graph during loading of the history data
    updateHistoryData: (state, action: PayloadAction<{ data: HistoryDataObject, planId: number}>) => {
      // Object assign is used because the data could be readOnly from the loading cycle
      state.reportObject.planData[action.payload.planId].historyData = Object.assign({}, action.payload.data);
    }
  },
  extraReducers: {
    [fecthProjectBaseReport.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fecthProjectBaseReport.fulfilled.type]: (state, action) => {
      let reportData = action.payload.data;

      // report state
      let reportState = state.reportObject;
      {
        // Project
        let projectState = reportState.project;

        projectState.id = reportData.project.id;
        projectState.createdAt = reportData.project.createdAt;
        projectState.name = reportData.project.name;
        projectState.address1 = reportData.project.address1;
        projectState.address2 = reportData.project.address2;
        projectState.city = reportData.project.city;
        projectState.postalCode = reportData.project.postalCode;
        projectState.ownerStatus = reportData.project.ownerStatus;
        projectState.status = reportData.project.status;
        projectState.ownerCompanyId = reportData.project.ownerCompanyId;
        projectState.ownerCompanyName = reportData.project.ownerCompanyName;
        projectState.newOwnerCompanyId = reportData.project.newOwnerCompanyId;
        projectState.newOwnerCompanyName = reportData.project.newOwnerCompanyName;

        reportState.project = projectState;

        // PLANS
        let plansState = reportState.plans;
        
        reportData.plans.forEach((plan: any) => {
          let newPlan: PlanObject = {
            id: plan.id,
            name: plan.name,
            filePath: plan.filePath,
            projectId: plan.projectid,
            projectName: plan.projectname
          };

          plansState.push(newPlan);
        });

        reportState.plans = plansState;

        //PLANS DATA / MEASUREMENT POINTS
        let planData = reportState.planData;

        reportState.plans.forEach(plan => {
          let planMeasurementPoints = Array<MeasurementPointObject>();

          reportData.plansData[plan.id].measurementPoints.forEach((measurementPoint: any) => {
            //LATEST DATA OBJ
            let latestDataObj = reportData.plansData[plan.id].latestData[measurementPoint.id];

            //GET MEASUREMENT DEVICES
            let newDevices: Array<DeviceObject> = Array<DeviceObject>();
            {
              let deviceData = measurementPoint.devices;

              deviceData.forEach((device: any) => {
                //////////////////////////////////////////////
                // SENSORS
                let newSensors: Array<SensorUnitObj> = Array<SensorUnitObj>();

                device.sensorTypes.forEach((sensorType: any) => {
                  let newSensor: SensorUnitObj = {
                    id: "-1",
                    typeName: sensorType.typeName,
                    unit: sensorType.dataUnit,
                    depth: sensorType.depth,
                    ambient: sensorType.ambient
                  };
                  newSensors.push(newSensor);
                });

                let deviceLatestData: latestDataInterface = {};
                if (latestDataObj !== undefined) {
                if (latestDataObj[device.id] !== null && latestDataObj[device.id] !== undefined) {
                  deviceLatestData = latestDataObj[device.id].latestData;
                }
                //////////////////////////////////////////////
                //NEW DEVICE
                let newDevice: DeviceObject = {
                  id: device.id,
                  serial: device.serial,
                  deviceType: device.deviceType,
                  lastUpdated: latestDataObj[device.id]?.lastUpdated,
                  sensorUnits: newSensors,
                  latestData: deviceLatestData,
                  linkStartDateTime: "dd:mm DD-MM-YYYY",
                  linkEndDateTime: "",
                  depthDefinition: device.depthDefinition,
                  sensorSpecificDepths: device.sensorSpecificDepths
                };

                newDevices.push(newDevice);
              }
              });
            }

            // NEW MEASUREMENT POINTS
            let newMeasurementPoint: MeasurementPointObject = {
              id: measurementPoint.id,
              name: measurementPoint.name,
              annotation: "",
              x: measurementPoint.x,
              y: measurementPoint.y,
              isLocated: false,
              selected: false,
              devices: newDevices
            };
            planMeasurementPoints.push(newMeasurementPoint);
          });

          planData[plan.id] = { measurementPoints: planMeasurementPoints, historyData: {} };
        });

        reportState.planData = planData;
      }
      state.reportObject = reportState;
    },
    [fecthProjectBaseReport.rejected.type]: (state, action) => {
      state.loading = false;
      //console.log("fecthBaseReport rejected", action.payload);
    },
    /*********************************************************************************** */
    [fetchPlanHistoryData.pending.type]: (state, action) => {
      state.pendingItems += 1;
    },
    [fetchPlanHistoryData.fulfilled.type]: (state, action) => {
      const planId = action.payload.planId;
      const data = action.payload.data;
      //console.log("fetchPlanHistoryData");
      //console.log(data);
      state.pendingItems -= 1;
      if (state.pendingItems === 0) {
        state.loading = false;
      }
      let planData = state.reportObject.planData[planId];

      if (planData === undefined) return;
      planData.historyData = data;
    },
    [fetchPlanHistoryData.rejected.type]: (state, action) => {
      state.loading = false;
      //console.log("fetchPlanHistoryData rejected");
    }
  }
});

export const {updateHistoryData} = reportSlice.actions;

export default reportSlice.reducer;
