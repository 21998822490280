import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

// IMPORTS
import { Dialog, Button, TextField, MenuItem } from "@mui/material";

import { commonAttributes } from "../../../../common/commonAttributes";

// CSS
import styles from "./ProjectViewAddProjectUserDialog.module.css";
import { GridSelectionModel } from "@mui/x-data-grid";

// INTERFACE

type UserDataRow = {
  id: string;
  userid: string;
  projectRoleId: string;
  role: string;
  email: string;
  firstname: string;
  lastname: string;
  startdate: string;
  enddate: string;
  receiveAlarms: boolean;
};
interface FormInterface {
  projectId: number;
  handleSave: any;
  handleClose: () => void;
  usersData: Array<UserDataRow>;
  add: boolean;
  edit: boolean;
  userToEdit?: UserDataRow[];
  gridSelectionModel: GridSelectionModel;
}

type projectRoleType = { id: string; name: string };

export default function ProjectViewAddProjectUserDialog(props: FormInterface) {
  // COMMONS
  const { t } = useTranslation();

  const usersData = props.usersData;

  const userToEdit = props.userToEdit !== undefined ? props.userToEdit[0] : null;

  useEffect(() => {
    if (props.edit === true && userToEdit !== (undefined || null)) {
    setselectRole({id: userToEdit.projectRoleId, name: userToEdit.role});
    setuserEmail(userToEdit.email);
    }
    return () => {
      setuserEmail(undefined);
      setselectRole(undefined);
    }
  }, [props.edit, userToEdit]);

  useEffect(() => {
    if (props.projectId >= 0) getProjectRoles();
    else
      setErrorMessage(
        t("projectview_page.settings_page.users_page.add_form.error_false_projectid") +
          ", " +
          props.projectId
      );
  }, [props.projectId]);

  // **********************************************************************************************************************
  // FORM DATA
  const [projectRoles, setProjectRoles] = useState<Array<projectRoleType>>(
    Array<projectRoleType>()
  );

  const [userEmail, setuserEmail] = useState<string | undefined>(undefined);
  const [selectRole, setselectRole] = useState<projectRoleType | undefined>(undefined);

  //ERROR

  const [errorMessage, setErrorMessage] = useState("");

  const getProjectRoles = () => {
    const urlPath = commonAttributes.apiUrl + `/projects/roles`;

    axios
      .get(urlPath)
      .then(response => {
        const dataObj: Array<any> = response.data;

        let projectRoles: Array<projectRoleType> = Array<projectRoleType>();

        dataObj.map(role => projectRoles.push({ id: role.id, name: role.name }));

        setProjectRoles(projectRoles);
      })
      .catch(error =>
        setErrorMessage(
          t("projectview_page.settings_page.users_page.add_form.error_failed_to_get_roles") +
            ", " +
            props.projectId
        )
      );
  };

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // **********************************************************************************************************************

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedRole = projectRoles.find(x => x.id === event.target.value);
    setselectRole(selectedRole);
  };

  const handleSave = () => {
    if (usersData.filter(row => row.email === userEmail).length > 0) {
      setErrorMessage(t("projectview_page.settings_page.users_page.add_form.user_already_exists"));
      return;
    }
    if (userEmail === undefined || selectRole === undefined) {
      setErrorMessage(t("projectview_page.settings_page.users_page.add_form.please_fill_required"));
      return;
    } else if (props.projectId < 0) {
      setErrorMessage(
        t("projectview_page.settings_page.users_page.add_form.error_false_projectid") +
          ", " +
          props.projectId
      );
      return;
    } else if (validateEmail(userEmail) === false) {
      setErrorMessage(
        t("projectview_page.settings_page.users_page.add_form.error_email_isnot_valid")
      );
      return;
    }

    const timeNow = moment.now();

    const timeUtc = moment.utc(timeNow, true);

    let newUserDTO = {
      UserName: userEmail,
      ProjectId: props.projectId,
      ProjectRoleId: Number(selectRole.id),
      startDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
    };

    const urlPath = commonAttributes.apiUrl + `/projects/${props.projectId}/Users/Add`;

    axios
      .post(urlPath, newUserDTO)
      .then(response => {
        props.handleSave();
      })
      .catch(error =>
        setErrorMessage(
          t("projectview_page.settings_page.users_page.add_form.error_failed_to_add_user") +
            ", " +
            error
        )
      );
  };

  type projectRoleType = { id: string; name: string };

  const handleEditDialogSave = (email: string | undefined, role: projectRoleType |undefined) => {  
    if (usersData.filter(row => row.email === email && row.id !== props.gridSelectionModel[0]).length > 0) {
      setErrorMessage(t("projectview_page.settings_page.users_page.add_form.user_already_exists"));
      return;
    }
    if (email === undefined || role === undefined) {
      setErrorMessage(t("projectview_page.settings_page.users_page.add_form.please_fill_required"));
      return;
    } else if (props.projectId < 0) {
      setErrorMessage(
        t("projectview_page.settings_page.users_page.add_form.error_false_projectid") +
          ", " +
          props.projectId
      );
      return;
    } else if (validateEmail(email) === false) {
      setErrorMessage(
        t("projectview_page.settings_page.users_page.add_form.error_email_isnot_valid")
      );
      return;
    }

    let selectedUserId = props.gridSelectionModel[0];
   
    const editUrlPath = commonAttributes.apiUrl + `/projects/Users/${selectedUserId}`;
    const addUrlPath = commonAttributes.apiUrl + `/projects/${props.projectId}/Users/Add`;

    const oldRole = props.usersData.filter(user => user.id === selectedUserId);

    const timeNow = moment.now();

    const timeUtc = moment.utc(timeNow, true);

    let endTimeUserDTO = {
        id: selectedUserId,
        username: oldRole[0].email,
        ProjectId: props.projectId,
        projectRoleID: oldRole[0].projectRoleId,
        endDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
      };

    let updateUserDTO = {
      id: selectedUserId,
      username: email,
      ProjectId: props.projectId,
      projectRoleID: role.id,
      startDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
    }

    axios
      .post(editUrlPath, endTimeUserDTO)
      .then(() => axios.post(addUrlPath, updateUserDTO))
      .then(() => props.handleSave())
      .catch(err => console.log("Error updating user information"))
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <div className={styles["adduser-form-container"]}>
        {/**************************************************************************** */}

        <div className={styles["adduser-form-header"]}>
          {t("projectview_page.settings_page.users_page.add_form.add_new_user")}
        </div>
        {/* *************************************************************************** */}

        <TextField
          label={t("projectview_page.settings_page.users_page.add_form.user_email")}
          variant="standard"
          fullWidth
          required
          value={userEmail !== undefined ? userEmail : ""}
          type="email"
          onChange={event => setuserEmail(event.target.value)}
          InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        />
        <TextField
          label={t("projectview_page.settings_page.users_page.add_form.user_role")}
          variant="standard"
          fullWidth
          required
          select
          value={selectRole !== undefined ? selectRole.id : {id: 1, name: "Admin"}}
          onChange={handleRoleChange}
          InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
          InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
        >
          {projectRoles.map(role => {
            return (
              <MenuItem
                sx={{ fontSize: "var(--inputFontSize)" }}
                key={"projectroles_" + role.id}
                value={role.id}
              >
                {role.name}
              </MenuItem>
            );
          })}
        </TextField>
        {/* *************************************************************************** */}

        <Button
          variant="contained"
          color="error"
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" onClick={props.add ? () => handleSave() : () => handleEditDialogSave(userEmail, selectRole)}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={() => handleClose()}>
          {t("common.cancel")}
        </Button>
      </div>
    </Dialog>
  );
}
