import { useState, useEffect } from "react";

//KONVA
import { Group } from "react-konva";

//TYPES
import { MeasurementPointObject } from "./../../../../../types";

//COMPONENTS
import PlanViewMeasurementPointArrow from "./PlanViewMeasurementPointArrow";
import PlanViewSensorTexts from "./PlanViewSensorTexts";

interface MeasurementPointComponentProperties {
  editContent: boolean;
  movePoints: boolean;
  measurementArray: Array<MeasurementPointObject>;
  measurementDragEnd: (event: any, id: string) => void;
  measurementSetSelected: (event: any, id: string) => void;
  viewDeviceSensorTexts: boolean;
  showRHorAH: boolean;
  temperatureCompensation: boolean;
  textScale: number;
}

export function PlanViewMeasurementPoints(props: MeasurementPointComponentProperties) {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //STATES
  const [editContent, setEditContent] = useState(false);

  const [lastClickedId, setLastClickedId] = useState("");

  //FOLLOWS EDIT STATE
  useEffect(() => {
    setEditContent(props.editContent);
  }, [props.editContent]);

  //STATES
  const [movePoints, setmovePoints] = useState(false);

  //FOLLOWS EDIT STATE
  useEffect(() => {
    setmovePoints(props.movePoints);
  }, [props.movePoints]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //STATES
  const [measurementPointsArray, setMeasurmentArray] = useState(Array<MeasurementPointObject>());

  //FOLLOWS EDIT STATE
  useEffect(() => {
    setEditContent(props.editContent);
  }, [props.editContent]);

  //FOLLOWS MEASUREMENT ARRAY STATE
  useEffect(() => {
    setMeasurmentArray(props.measurementArray);
  }, [props.measurementArray]);

  //***************************************************************************************************/
  // SET MEASUREMENT POINTS
  const measurementDragEnd = (e: any, id: string) => {
    props.measurementDragEnd(e, id);
  };

  const setSelectedMeasurementPoint = (e: any, id: string) => {
    props.measurementSetSelected(e, id);
  };

  //***************************************************************************************************/

  return (
    <>
      {measurementPointsArray.map((measurementPoint, index) => {
        return (
          <Group
            key={"textgroup_" + index}
            x={measurementPoint.x}
            y={measurementPoint.y}
            draggable={movePoints && editContent}
            onDragEnd={(event: any) => measurementDragEnd(event, measurementPoint.id)}
            scaleX={props.textScale}
            scaleY={props.textScale}
            zIndex={measurementPoint.id === lastClickedId ? 1 : 0}
          >
            <PlanViewSensorTexts
              devices={measurementPoint.devices}
              editContent={movePoints}
              selected={measurementPoint.selected}
              headertext={measurementPoint.name}
              onDataClick={(event: any) => {
                setSelectedMeasurementPoint(event, measurementPoint.id);
                measurementPoint.selected ?
                setLastClickedId(prev => prev)
                :
                setLastClickedId(measurementPoint.id)
              }}
              viewSensorTexts={props.viewDeviceSensorTexts}
              showRHorAH={props.showRHorAH}
              temperatureCompensation={props.temperatureCompensation}
              onTap={(e) => { 
                setSelectedMeasurementPoint(e, measurementPoint.id)
                measurementPoint.selected ?
                setLastClickedId(prev => prev)
                :
                setLastClickedId(measurementPoint.id)
              }}
            />
            {/* ############################################### */}
            <PlanViewMeasurementPointArrow
              onTap={(e) => setSelectedMeasurementPoint(e, measurementPoint.id)}
              devices={measurementPoint.devices}
              editContent={movePoints}
              selected={measurementPoint.selected}
              arrowClick={(event: any) => setSelectedMeasurementPoint(event, measurementPoint.id)}
            />
          </Group>
        );
      })}
    </>
  );
}
