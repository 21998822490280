import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { companyApi } from "../features/company/companyAPI";
import { projectsApi } from "../features/project/projectApi";
import projectSliceReducer from "../features/project/projectSlice";
import { deviceTypeApi } from "../features/devices/deviceTypeAPI";
import measurementReducer from "../features/measurements/measurementsSlice";
import authenticationReducer from "../features/authentication/authSlice";
import deviceTypeReduces from "../features/devices/deviceTypesSlice";
import reportReducer from "../features/report/reportSlice";
import companyReducer from "../features/company/companySlice";
import deviceReducer from "../features/project/projectview/components/devices/deviceSlice";

export const store = configureStore({
  reducer: {
    [companyApi.reducerPath]: companyApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [deviceTypeApi.reducerPath]: deviceTypeApi.reducer,
    ProjectPlanMeasurementPoints: measurementReducer,
    authentication: authenticationReducer,
    deviceTypes: deviceTypeReduces,
    selectedProject: projectSliceReducer,
    report: reportReducer,
    company: companyReducer,
    device: deviceReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(companyApi.middleware)
      .concat(projectsApi.middleware)
      .concat(deviceTypeApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<any>>;
