import { baseQueryWithAuth } from "../../app/baseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { UserProject, ProjectObject, CompanyObject, PlanObject, AlarmProfile, SensorType } from "../../types";
import { groupBy } from "../utils/commonFunctions";

import { UserCompanyObject } from "../company/companySlice";

type ProjectUser = {
  id: number;
  startDateTime: string;
  endDateTime: string;
  status: number;
  userId: string;
  userName: string;
  projectId: number;
  projectName: string;
  projectRoleId: number;
  projectRoleName: string;
  receiveAlarms: boolean;
  companyId: number;
  companyName: string;
};

type ImageObject = {
  planId: number;
  formData: FormData;
};

type GroupedUserProjects = {
  [key: string]: UserProject[];
};

type ProjectUserResponse = {
  data: ProjectUser;
};

export type ProjectPlanResponse = {
  data: PlanObject;
};

type MeasurementPointSensorAlarmSendObject = {
  MeasurementPointDeviceId: number;
  ProjectId: number;
  AlarmProfileId: number;
  SensorTypeName: string;
};

type AlarmProfileSendObject = {
  Name: string;
  SensorTypeName: string;
  GreaterThanValue?: number;
  LessThanValue?: number;
  ProjectId: number;
};

type AlarmProfileEditObject = {
  alarmProfile: AlarmProfileSendObject;
  id: number;
};

type ProjectTransfer = {
  projectId: number;
  userName: string;
  transferDevices: boolean;
};

export type ProjectTransferReturn = {
  projectId: number;
  projectName: string;
  userName: string;
  transferDevices: boolean;
  createdDateTime: string;
  confirmedDateTime: string | undefined | null;
  status: number;
};

export type ProjectTransferObject = {
  projectTransfers: ProjectTransferReturn[];
  companyUsers: UserCompanyObject[];
};

export const projectsApi = createApi({
  reducerPath: "projectApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Projects", "Plans", "Alarms", "SensorTypes"],
  endpoints: builder => ({
    //**************************************************************/
    getProjects: builder.query<ProjectObject[], void>({
      query: () => "/Projects",
      providesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    getTransferedProjects: builder.query<ProjectTransferObject, void>({
      query: () => "/Users/Projects/Transfers",
      providesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    //**************************************************************/
    getProjectsPage: builder.query<ProjectObject[], number>({
      query: id => `/Projects/Page`,
      providesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    //**************************************************************/
    getProject: builder.query<ProjectObject, string>({
      query: id => `/Projects/${id}`,
      providesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    getProjectCompany: builder.query<CompanyObject, number>({
      query: id => `/Projects/${id}/Company`,
      providesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    //**************************************************************/
    addProject: builder.mutation<
      ProjectObject,
      Omit<
        ProjectObject,
        | "id"
        | "ownerCompanyName"
        | "newOwnerCompanyId"
        | "newOwnerCompanyName"
        | "ownerStatus"
        | "status"
        | "createdAt"
        | "planList"
      >
    >({
      query: project => {
        return {
          url: "/Projects/Add",
          method: "POST",
          body: project
        };
      },
      invalidatesTags: [{ type: "Projects", id: "USER_PROJECTS_LIST" }]
    }),
    //**************************************************************/
    editProject: builder.mutation<
      ProjectObject,
      Omit<
        ProjectObject,
        | "ownerCompanyName"
        | "newOwnerCompanyId"
        | "newOwnerCompanyName"
        | "ownerStatus"
        | "status"
        | "createdAt"
        | "planList"
      >
    >({
      query: project => {
        return {
          url: `/Projects/${project.id}`,
          method: "POST",
          body: project
        };
      },
      invalidatesTags: [{ type: "Projects", id: "PROJECTS_LIST" }]
    }),
    //**************************************************************/
    addProjectUser: builder.mutation<
      ProjectUserResponse,
      Omit<
        ProjectUser,
        | "id"
        | "status"
        | "startDateTime"
        | "endDateTime"
        | "companyName"
        | "userName"
        | "projectName"
        | "planList"
      >
    >({
      query: projectUser => {
        return {
          url: `Projects/${projectUser.projectId}/Users/Add`,
          method: "POST",
          body: projectUser
        };
      },
      invalidatesTags: [{ type: "Projects", id: "USER_PROJECTS_LIST" }]
    }),

    //**************************************************************/
    getUserProjects: builder.query<UserProject[], void>({
      query: () => `/Users/Projects`,
      providesTags: [{ type: "Projects", id: "USER_PROJECTS_LIST" }]
    }),
    //**************************************************************/
    getProjectPlans: builder.query<PlanObject[], number>({
      query: id => `/Projects/${id}/Plans`,
      providesTags: [{ type: "Plans", id: "PLANS_LIST" }]
    }),
    //**************************************************************/
    addProjectPlan: builder.mutation<
      PlanObject,
      Omit<PlanObject, "id" | "projectName" | "planList">
    >({
      query: projectPlan => {
        return {
          url: `/Plans/Add`,
          method: "POST",
          body: projectPlan
        };
      },
      invalidatesTags: [{ type: "Plans", id: "PLANS_LIST" }]
    }),
    //**************************************************************/
    deleteProjectPlan: builder.mutation<any, number>({
      query: id => {
        return {
          url: `/Plans/${id}/DeleteWithReferences`,
          method: "DELETE"
        };
      },
      invalidatesTags: [{ type: "Plans", id: "PLANS_LIST" }]
    }),
    //**************************************************************/
    addProjectPlanImage: builder.mutation<any, ImageObject>({
      query: imageObj => {
        return {
          url: `/Plans/${imageObj.planId}/Image/Add`,
          method: "POST",
          body: imageObj.formData
        };
      },
      invalidatesTags: [{ type: "Plans", id: "PLANS_LIST" }]
    }),
    //**************************************************************/
    getProjectAlarmProfiles: builder.query<Array<AlarmProfile>, string>({
      query: id => `/Alarms/Projects/${id}`,
      providesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    addProjectAlarmProfile: builder.mutation<any, AlarmProfileSendObject>({
      query: alarmProfileSendObject => {
        return {
          url: "/Alarms/AlarmProfiles/Add",
          method: "POST",
          body: alarmProfileSendObject
        };
      },
      invalidatesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    editProjectAlarmProfile: builder.mutation<any, AlarmProfileEditObject>({
      query: alarmProfileEditObject => {
        return {
          url: `/Alarms/AlarmProfiles/${alarmProfileEditObject.id}`,
          method: "POST",
          body: alarmProfileEditObject.alarmProfile
        };
      },
      invalidatesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    deleteProjectAlarmProfile: builder.mutation<any, number>({
      query: id => {
        return {
          url: `/Alarms/AlarmProfiles/${id}/Delete`,
          method: "DELETE"
        };
      },
      invalidatesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    getProjectMeasurementPointDevices: builder.query<any, string>({
      query: id => `/Projects/${id}/MeasurementPointDevices`
    }),
    //**************************************************************/
    addProjectMeasurementPointSensorAlarm: builder.mutation<
      any,
      MeasurementPointSensorAlarmSendObject
    >({
      query: measurementPointSensorAlarm => {
        return {
          url: `/Alarms/MeasurementPointSensorAlarms/Add`,
          method: "POST",
          body: measurementPointSensorAlarm
        };
      },
      invalidatesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    deleteProjectMeasurementPointSensorAlarm: builder.mutation<any, number>({
      query: id => {
        return {
          url: `/Alarms/MeasurementPointSensorAlarms/${id}/Delete`,
          method: "DELETE"
        };
      },
      invalidatesTags: [{ type: "Alarms", id: "ALARMS_LIST" }]
    }),
    //**************************************************************/
    getSensorTypes: builder.query<Array<SensorType>, void>({
      query: () => `/Sensors/Types`,
      transformResponse(response: Array<SensorType>) {
        const sorted = response.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        });
        return sorted;
      },
      providesTags: [{ type: "SensorTypes", id: "SENSOR_TYPES" }]
    }),
    //**************************************************************/
    sendTransferRequest: builder.mutation<any, ProjectTransfer>({
      query: projectTransfer => {
        return {
          url: `/Projects/${projectTransfer.projectId}/Transfers/Add`,
          method: "POST",
          body: projectTransfer
        };
      },
      invalidatesTags: [{ type: "Projects", id: "TRANSFER_PROJECT" }]
    }),
    //**************************************************************/
    cancelTransferRequest: builder.mutation<any, ProjectTransfer>({
      query: projectTransfer => {
        return {
          url: `/Projects/${projectTransfer.projectId}/Transfers/Cancel`,
          method: "DELETE",
          body: projectTransfer
        };
      },
      invalidatesTags: [{ type: "Projects", id: "TRANSFER_PROJECT" }]
    }),
    //**************************************************************/
    getTransferRequest: builder.query<ProjectTransferReturn, number>({
      query: id => `/Projects/${id}/Transfers/Waiting`,
      providesTags: [{ type: "Projects", id: "TRANSFER_PROJECT" }]
    }),

  })
});

export const {
  useGetProjectsQuery,
  useGetTransferedProjectsQuery,
  useGetProjectsPageQuery,
  useGetProjectQuery,
  useGetProjectCompanyQuery,
  useAddProjectMutation,
  useEditProjectMutation,
  useGetUserProjectsQuery,
  useGetProjectPlansQuery,
  useDeleteProjectPlanMutation,
  useAddProjectUserMutation,
  useAddProjectPlanMutation,
  useAddProjectPlanImageMutation,
  useGetProjectAlarmProfilesQuery,
  useAddProjectAlarmProfileMutation,
  useEditProjectAlarmProfileMutation,
  useDeleteProjectAlarmProfileMutation,
  useGetProjectMeasurementPointDevicesQuery,
  useAddProjectMeasurementPointSensorAlarmMutation,
  useDeleteProjectMeasurementPointSensorAlarmMutation,
  useGetSensorTypesQuery,
  useSendTransferRequestMutation,
  useCancelTransferRequestMutation,
  useGetTransferRequestQuery
} = projectsApi;
