import { nanoid } from "@reduxjs/toolkit";

import { MeasurementPointObject, DeviceObject, SensorUnitObj, SensorTypeObject } from "../../types";

export const InitialMeasurementPoint = (): MeasurementPointObject => {
  return {
    id: nanoid(),
    name: "New Point",
    annotation: "Added" + Date(),
    x: 0,
    y: 0,
    isLocated: false,
    selected: false, //NO BACKEND
    devices: Array<DeviceObject>()
  };
};
export const InitialDevice = (): DeviceObject => {
  return {
    id: nanoid(),
    serial: "-",
    deviceType: "-",
    lastUpdated: "Never",
    sensorUnits: Array<SensorUnitObj>(),
    latestData: {},
    linkStartDateTime: "HH:mm DD-MM-YYYY",
    linkEndDateTime: "HH:mm DD-MM-YYYY",
    depthDefinition: 0,
    sensorSpecificDepths: false
  };
};

export const InitialSensorUnit = (): SensorUnitObj => {
  return {
    id: nanoid(),
    typeName: "none",
    unit: "",
    depth: "-",
    ambient: false
  };
};

export const initialHistoryData = {};

/***************************************************************************** */
export const InitialSensorTypes = (): Array<SensorTypeObject> => {
  let sensorTypes: Array<SensorTypeObject> = [
    {
      name: "",
      SensorUnits: [{ id: "1", typeName: "1", unit: "°C", depth: "100 mm", ambient: false }]
    }
  ];

  return sensorTypes;
};
