import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Fellix-Regular", "Roboto"].join(",")
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: { 
          "& label": {
            fontFamily: "Fellix-Regular"
          },
          "& label.Mui-focused": {
            color: "#eb941c"
          },
          "& .MuiInput-underline: after": {
            borderBottomColor: "#eb941c"
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#eb941c"
         },
        },
      }
    }
  },
    MuiGrid: {
      styleOverrides: {
        root: { fontFamily: "Fellix-Regular", border: "0", fontSize: "var(--basicFontSize)" }
      }
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: "#8c95a4",
          height: "var(--iconSize)",
          width: "5px"
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        root: {
        "& .MuiBadge-badge": {
          margin: "2px 3px",
          fontWeight: "bold",
          fontSize: "0.6rem"
        }
      }
    }
    },
    MuiButton: {
      defaultProps: {
        // The default props to change
        disableRipple: true
      },

      styleOverrides: {
        root: {
          fontSize: "var(--buttonFontSize)",
          borderRadius: "12px",
          color: "#000",
          fontWeight: "bold",
          boxShadow: "0px 0px 0px 0px",
          padding: "4px 10px 4px 10px",
          textTransform: "none",
          "&:hover": {
            background: "#eb941c",
            boxShadow: "0px 0px 0px 0px"
          }
        }
      },

      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: "#fff",
            border: "2px solid #eb941c"
          }
        },
        {
          props: { variant: "contained", color: "error" },
          style: {
            cursor: "not-allowed",
            background: "#fff",
            border: "2px solid red",
            color: "red",
            fontWeight: "bold",
            "&:hover": {
              background: "#fff",
              border: "2px solid red",
              color: "black"
            }
          }
        },
        {
          props: { variant: "contained", color: "success" },
          style: {
            background: "#eb941c",
            border: "2px solid eb941c",
            color: "black",
            fontWeight: "bold",
            "&:hover": {
              background: "#8c95a4",
              border: "2px solid #eb941c",
              color: "black"
            }
          }
        },
        {
          props: { variant: "outlined" },
          style: {
            background: "#fff",
            border: "2px solid #8c95a4",
            "&:hover": {
              background: "#8c95a4",
              border: "2px solid #8c95a4"
            }
          }
        },
        {
          props: {variant: "contained", color: "info"},
          style: {
            background: "#fff",
            border: "2px solid red",
            "&:hover": {
              cursor: "pointer",
              background: "red"
            }
          }
        }
      ]
    }
  }
});
