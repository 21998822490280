import { Button, Chip, Dialog, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./DeviceSettingsTagDialog.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { addCompanyTagToDevice, addTag, getCompanyTags, removeTagFromDevice } from "./deviceSlice";
import { Tag } from "../../../../../types";

interface TagsDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrRemove: "add" | "remove";
}

const DeviceSettingsTagsDialog = (props: TagsDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, handleClose, addOrRemove } = props;

  const device = useSelector((state: RootState) => state.device.device); 
  const companyTags = useSelector((state: RootState) => state.device.companyTags);
  const [filteredCompanyTags, setFilteredCompanyTags] = useState<Array<Tag> | undefined>();
  const [newTag, setNewTag] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (device.companyId !== -1) {
    dispatch(getCompanyTags(device.companyId));
    }
  }, [device.companyId, dispatch]);

  useEffect(() => {
    if (companyTags.length > 0) {
      const deviceTags: Array<number> = [];
      device.deviceTags.map(tag => deviceTags.push(tag.id));
      const filteredCompanyTags = companyTags.filter(tag => !deviceTags.includes(tag.id));
      setFilteredCompanyTags(filteredCompanyTags);
    }
  }, [companyTags, device.deviceTags]);

  const handleTagDelete = (tagId: number) => {
    dispatch(removeTagFromDevice({tagId: tagId, companyDeviceId: device.companyDeviceId}));
    dispatch(getCompanyTags(device.companyId));
  };

  const handleTagAdd = (tagId: number) => {
    const existingText = companyTags.find(tag => tag.id === tagId)?.name;
    if (existingText !== undefined) {
    dispatch(addCompanyTagToDevice({tagId: tagId, companyDeviceId: device.companyDeviceId}));
    }
  };

  const handleNewTag = () => {
    if (companyTags.filter(companyTag => companyTag.name.toLowerCase() === newTag.toLowerCase()).length > 0) {
      setErrorMessage(t("device_management.tag_exists"));
      return;
    }
    dispatch(addTag({companyDeviceId: device.companyDeviceId, tagText: newTag}));
    setNewTag("");
  };

  return (
    <Dialog open={open}>
      <div className={styles["tag-dialog-main-container"]}>
        {addOrRemove === "add"
          ? <Typography component={"h5"} sx={{fontWeight: "bold"}}>{t("device_management.add_tags")}</Typography>
          : <Typography component={"h5"} sx={{fontWeight: "bold"}}>{t("device_management.remove_tags")}</Typography>
        }
        <Grid container spacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{ mt: "10px" }}>
          {addOrRemove === "remove" &&
            device.deviceTags.map(tag => (
             <Grid key={"device-tag-item" + tag.id} item xs={6} md={4}> 
              <Chip
                key={tag.id}
                label={tag.name}
                deleteIcon={<DeleteIcon sx={{ color: "var(--colorRed) !important" }} />}
                sx={{
                  fontWeight: "bold",
                  bgcolor: "var(--colorWhite)",
                  border: "2px solid var(--colorOrange)"
                }}
                onDelete={() => handleTagDelete(tag.id)}
              />
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{ mt: "10px" }}>
          {addOrRemove === "add" && filteredCompanyTags !== undefined &&
            filteredCompanyTags.map(tag => (
            <Grid key={"company-tag-item" + tag.id} item xs={6} md={4}>
              <Chip
                key={tag.id}
                label={tag.name}
                icon={<AddIcon sx={{ color: "green !important" }} />}
                sx={{
                  fontWeight: "bold",
                  bgcolor: "var(--colorWhite)",
                  border: "2px solid var(--colorOrange)"
                }}
                onClick={() => handleTagAdd(tag.id)}
              />
              </Grid>
            ))}
        </Grid>
        {addOrRemove === "add" && (
          <FormControl>
            <TextField
              sx={{ mt: "10px", mb: "10px" }}
              variant="outlined"
              autoComplete="off"
              value={newTag}
              inputProps={{ maxLength: 10 }}
              onChange={e => {
                setNewTag(e.target.value);
                setErrorMessage("");
              }}
              label={t("device_management.new_tag")}
            />
            <Button
              variant="contained"
              color="error"
              style={errorMessage !== "" ? { display: "" } : { display: "none" }}
            >
              {errorMessage}
            </Button>
            <Button 
              variant="contained" 
              sx={{mt: "5px"}}
              disabled={newTag === ""} 
              onClick={() => handleNewTag()}>
                {t("device_management.add_tag")}
            </Button>
          </FormControl>
        )}
        <div className={styles["tag-dialog-cancel-button"]}>
          <Stack direction={"column"} spacing={1}>
            <Button
              sx={{ mt: "20px", width: "10px", alignSelf: "center" }}
              variant="contained"
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
                setErrorMessage("");
                handleClose();
              }}
            >
              {t("common.close")}
            </Button>
          </Stack>
          {addOrRemove === "add" && (
            <div className={styles["tag-dialog-current-tags"]}>
              {t("device_management.current_tags")}:
              {device.deviceTags.map(tag => <li key={tag.id}>{tag.name}</li>)}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DeviceSettingsTagsDialog;
