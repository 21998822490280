import React, { ChangeEvent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControl, TextField, Grid, Button } from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// TYPES
import { CompanyBillingObject, CompanyObject } from "../../../types";

import {
  useGetCompanyQuery,
  useGetCompanyBillingQuery,
  useEditCompanyMutation,
  useEditCompanyBillingMutation
} from "../companyAPI";

import styles from "./CompanyViewInfo.module.css";

interface CompanyInfoInterface {
  isAdmin: boolean;
  isFinancialManager: boolean;
}

export default function CompanyViewInfo(props: CompanyInfoInterface) {
  useEffect(() => {
    if (props.isAdmin) {
      setisAdmin(true);
      setIsFinancial(true);
    }

    if (props.isFinancialManager) {
      setIsFinancial(true);
    }
  }, [props]);

  const [isAdmin, setisAdmin] = useState(false);
  const [isFinancial, setIsFinancial] = useState(false);

  const { companyid } = useParams();

  //////////////////////////////////////////////////////////////////////////////////////
  // COMPANY
  const { data, error, isLoading } = useGetCompanyQuery(Number(companyid));
  const [editCompany, { isLoading: companyEditloading }] = useEditCompanyMutation();
  //////////////////////////////////////////////////////////////////////////////////////
  // BILLING
  const {
    data: billingData,
    error: billingError,
    isLoading: billingLoading
  } = useGetCompanyBillingQuery(Number(companyid));
  const [editBilling, { isLoading: billingEditLoading }] = useEditCompanyBillingMutation();

  //////////////////////////////////////////////////////////////////////////////////////
  const [editAdminMode, setEditAdminMode] = useState(false);
  const [editFinancialMode, setEditFinancialMode] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setcompanyid(data.id);
      setcompanyName(data.name);

      setcompanyAddress1(data.address1);
      setcompanyAddress2(data.address2);
      setcompanyPostalCode(data.postalCode);
      setcompanyCity(data.city);
      setcompanyCountry(data.country);

      setbusinessid(data.businessId);
      setcontactnumber(data.contactNumber);
      setcompanystatus(data.status);
    }
  }, [data]);

  useEffect(() => {
    //let companyId = companyInfo?.id !== undefined ? companyInfo?.id : -1;

    if (billingData !== undefined && billingData !== null) {
      setbillingId(billingData.id);
      setnetvisorId(billingData?.netvisorId);
      setemailinvoiceaddress(billingData.emailInvoicingAddress);
      setinvoicingaddress(billingData.finvoiceAddress);
      setinvoiceroutercode(billingData.finvoiceRoutercode);
    }
  }, [billingData]);

  const [companyId, setcompanyid] = useState(-1);
  const [companyName, setcompanyName] = useState("");
  const [companyAddress1, setcompanyAddress1] = useState("");
  const [companyAddress2, setcompanyAddress2] = useState("");
  const [companyPostalCode, setcompanyPostalCode] = useState("");
  const [companyCity, setcompanyCity] = useState("");
  const [companyCountry, setcompanyCountry] = useState("");

  const [businessid, setbusinessid] = useState("");
  const [contactnumber, setcontactnumber] = useState("");
  const [companystatus, setcompanystatus] = useState(-1);

  const handleCompanyChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    target: string
  ) => {
    switch (target.toLowerCase()) {
      case "name":
        setcompanyName(event.target.value);
        break;

      case "address1":
        setcompanyAddress1(event.target.value);
        break;
      case "address2":
        setcompanyAddress2(event.target.value);
        break;
      case "postalcode":
        setcompanyPostalCode(event.target.value);
        break;
      case "city":
        setcompanyCity(event.target.value);
        break;
      case "country":
        setcompanyCountry(event.target.value);
        break;

      case "businessid":
        setbusinessid(event.target.value);
        break;
      case "contactnumber":
        setcontactnumber(event.target.value);
        break;
      case "status":
        setcompanystatus(Number(event.target.value));
        break;
    }
  };

  const [billingId, setbillingId] = useState(-1);
  const [netvisorId, setnetvisorId] = useState("");
  const [emailinvoiceaddress, setemailinvoiceaddress] = useState("");
  const [finvoicingaddress, setinvoicingaddress] = useState("");
  const [finvoiceroutercode, setinvoiceroutercode] = useState("");

  const handleBillingChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    target: string
  ) => {
    switch (target.toLowerCase()) {
      case "netvisorid":
        setnetvisorId(event.target.value);
        break;
      case "emailinvoicingaddress":
        setemailinvoiceaddress(event.target.value);
        break;
      case "finvoicingaddress":
        setinvoicingaddress(event.target.value);
        break;
      case "finvoiceroutercode":
        setinvoiceroutercode(event.target.value);
        break;
    }
  };

  const handleSave = () => {
    // HANDLE COMPANY SAVE
    let changeCompany: CompanyObject = {
      id: companyId,
      name: companyName,
      address1: companyAddress1,
      address2: companyAddress2,
      postalCode: companyPostalCode,
      city: companyCity,
      country: companyCountry,
      businessId: businessid,
      contactNumber: contactnumber,
      status: companystatus
    };
    // HANDLE BILLING SAVE
    editCompany(changeCompany).then(() => {
      setEditAdminMode(false);
    });
  };

  const handleFinancialSave = () => {
    let changeBilling: CompanyBillingObject = {
      id: billingId,
      companyId: companyId,
      netvisorId: netvisorId,
      emailInvoicingAddress: emailinvoiceaddress,
      finvoiceAddress: finvoicingaddress,
      finvoiceRoutercode: finvoiceroutercode
    };

    editBilling(changeBilling).then(() => setEditFinancialMode(false));
  };

  return (
    <div className={styles["companyview-info-container"]}>
      {isLoading && <div>{t("notifications.loading_title")}</div>}
      {error && <div>{t("notifications.error_title")}</div>}

      {data && (
        <React.Fragment>
          <FormControl sx={{ m: 1, mb: 2 }}>
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_name")}
              value={companyName ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "name")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />

            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_address1")}
              value={companyAddress1 ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "address1")}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
            />
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_address2")}
              value={companyAddress2 ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "address2")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_postalcode")}
              value={companyPostalCode ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "postalcode")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_city")}
              value={companyCity ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "city")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.company_country")}
              value={companyCountry ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "country")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />

            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.businessid")}
              value={businessid ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "businessid")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editAdminMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editAdminMode}
              label={t("company_settings.company_infoview.contact_number")}
              value={contactnumber ?? ""}
              onChange={(event: ChangeEvent<any>) => handleCompanyChange(event, "contactnumber")}
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editAdminMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
          </FormControl>
        </React.Fragment>
      )}
      {/* ***************************************************************************************************** */}
      {/* SAVE ADMIN BUTTONS */}
      <FormControl sx={{ m: 1, mb: 3 }}>
        <Grid container className={styles["company-details-buttons-container"]}>
          {editAdminMode ? (
            <>
              <Button
                className={styles["company-details-cancel-button"]}
                variant="outlined"
                sx={{ mr: 2 }}
                disabled={isLoading}
                onClick={() => setEditAdminMode(!editAdminMode)}
              >
                {t("projectview_page.edit_project_form.cancel_button")}
              </Button>
              <Button
                className={styles["company-details-add-button"]}
                variant="contained"
                onClick={handleSave}
              >
                {t("projectview_page.edit_project_form.save_button")}
              </Button>
            </>
          ) : (
            <Button
              className={styles["company-details-cancel-button"]}
              variant="contained"
              sx={{ mr: 2 }}
              disabled={isLoading || isAdmin === false}
              onClick={() => setEditAdminMode(!editAdminMode)}
            >
              {t("projectview_page.edit_project_form.edit_button")}
            </Button>
          )}
        </Grid>
      </FormControl>
      {/* ***************************************************************************************************** */}
      {billingData?.emailInvoicingAddress === null && billingData.finvoiceAddress === null && <PriorityHighIcon sx={{color: "red"}} />}
      {billingLoading && <div>{t("notifications.loading_title")}</div>}
      {billingError && <div>{t("notifications.error_title")}</div>}
      {billingData && (
        <React.Fragment>
          <FormControl sx={{ m: 1, mb: 3, mr: 1 }}>
            <TextField
              className={!editFinancialMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editFinancialMode}
              label={t("company_settings.company_infoview.billing_emailinvoicingaddress")}
              value={emailinvoiceaddress ?? ""}
              onChange={(event: ChangeEvent<any>) =>
                handleBillingChange(event, "emailinvoicingaddress")
              }
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editFinancialMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editFinancialMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editFinancialMode}
              label={t("company_settings.company_infoview.billing_finvoiceaddress")}
              value={finvoicingaddress ?? ""}
              onChange={(event: ChangeEvent<any>) =>
                handleBillingChange(event, "finvoicingaddress")
              }
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editFinancialMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <TextField
              className={!editFinancialMode ? styles["company-details-text-field-view"] : ""}
              disabled={!editFinancialMode}
              label={t("company_settings.company_infoview.billing_invoiceroutercode")}
              value={finvoiceroutercode ?? ""}
              onChange={(event: ChangeEvent<any>) =>
                handleBillingChange(event, "finvoiceroutercode")
              }
              // onBlur={handleNameBlur}
              // error={nameInvalid}
              variant={editFinancialMode ? "outlined" : "standard"}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
          </FormControl>
        </React.Fragment>
      )}
      {/* ***************************************************************************************************** */}
      {/* SAVE Financial BUTTONS */}
      <FormControl sx={{ m: 1, mb: 3 }}>
        <Grid container className={styles["company-details-buttons-container"]}>
          {editFinancialMode ? (
            <>
              <Button
                className={styles["company-details-cancel-button"]}
                variant="outlined"
                sx={{ mr: 2 }}
                disabled={billingLoading}
                onClick={() => setEditFinancialMode(!editFinancialMode)}
              >
                {t("projectview_page.edit_project_form.cancel_button")}
              </Button>
              <Button
                className={styles["company-details-add-button"]}
                variant="contained"
                onClick={handleFinancialSave}
              >
                {t("projectview_page.edit_project_form.save_button")}
              </Button>
            </>
          ) : (
            <Button
              className={styles["company-details-cancel-button"]}
              variant="contained"
              sx={{ mr: 2 }}
              disabled={billingLoading || isFinancial === false}
              onClick={() => setEditFinancialMode(!editFinancialMode)}
            >
              {t("projectview_page.edit_project_form.edit_button")}
            </Button>
          )}
        </Grid>
      </FormControl>
    </div>
  );
}
