import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../../app/baseQuery";

export type DeviceTypeObject = {
  id: string;
  name: string;
  description: string;

  sensorTypes: Array<sensorTypeObject>;
  depthDefinition: number;
};

export type sensorTypeObject = {
  id: string;
  name: string;
  description: string;
  dataUnit: string;
  ambient: boolean;
};

export const deviceTypeApi = createApi({
  reducerPath: "deviceTypeApi",
  baseQuery: baseQuery, //fetchBaseQuery({ baseUrl: "http://localhost:5000/api" }),
  tagTypes: ["DeviceTypes"],
  endpoints: builder => ({
    getDeviceTypes: builder.query<DeviceTypeObject[], void>({
      query: () => "/Devices/Types",
      providesTags: [{ type: "DeviceTypes", id: "DEVICETYPES_LIST" }]
    })
  })
});

export const { useGetDeviceTypesQuery } = deviceTypeApi;
