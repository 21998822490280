import { Alert } from "@mui/material";
import { AlertType } from "../../types";

const AlertComponent = ({msg, severity}: AlertType) => {

    return (
        <Alert sx={{width: "300px", margin: "auto"}} severity={severity}> {msg} </Alert>
    )
};

export default AlertComponent;