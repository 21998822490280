import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { RootState } from "../../../../../app/store";
import { fetchPlanMeasurementsDevices, fetchPlanMeasurementsLatestData, linkMeasurementPointDevice } from "../../../../measurements/measurementsSlice";
import { fetchLinkedDevicesCount } from "../../../projectSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { commonAttributes } from "./../../../../common/commonAttributes";
import { GetDateString } from "../../../../utils/commonFunctions";

import { Dialog, Button, TextField, MenuItem, Tabs, Tab, AppBar, useMediaQuery, Box } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

import styles from "./LinkDeviceToDeviceDialog.module.css";

import { DeviceObject, LicenceObject } from "../../../../../types";

//REDUX
import { useDispatch } from "react-redux";
import { clearCompanySliceError, getCompanyDevices } from "../../../../company/companySlice";
import { useGetDeviceTypesQuery } from "../../../../devices/deviceTypeAPI";

interface FormInterface {
  handleSave: () => void;
  handleClose: () => void;
  measurementPointId: string;
  editedDevice: DeviceObject | undefined;
  planId?: number;
}

type userCompanyType = {
  name: string;
  id: number;
};

type dataRowType = {
  id: string | number;
  typename: string | undefined;
  serial: string;
  tags?: Array<string>;
  isLinked: string;
  lastMeasurementDate?: string;
};

/*Data Grid toolbar**************************/
function CustomToolbar() {
  const smallScreen = useMediaQuery("(max-width: 999px)");
  
  return (
    <GridToolbarContainer>
      {!smallScreen && 
      <>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      </>
      }
      {! smallScreen && <GridToolbarDensitySelector/>}
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}
/*********************************************** */

export default function LinkDeviceToDeviceDialog(props: FormInterface) {
  // **********************************************************************************************************************
  //BASE FUNCTIONS

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const lowScreenHeight = useMediaQuery("(max-height: 600px)");

  const selectedProject = useSelector((state: RootState) => state.selectedProject);
  const activeLicenceState = useSelector((state: RootState) => state.selectedProject.activeLicence);
  const usedLicenceDevicesState = useSelector(
    (state: RootState) => state.selectedProject.usedLicenceDevices
  );
  const { data: deviceTypes } = useGetDeviceTypesQuery();
  const companyDevices = useSelector((state: RootState) => state.company.companyDevices);
  const loading = useSelector((state: RootState) => state.company.loading);

  const navigate = useNavigate();

  // **********************************************************************************************************************
  // FORM DATA

  const [activeLicence, setactiveLicence] = useState<LicenceObject | undefined>(undefined);
  const [usedLicenceDevices, setusedLicenceDevices] = useState<number>(-1);

  //ERROR

  const [errorMessage, setErrorMessage] = useState("");

  //TABS SELECTIION

  const [selectedTab, setselectedTab] = useState<number>(0);

  //DEVICE SELECTION

  const [editedDevice, seteditedDevice] = useState<DeviceObject | undefined>(undefined);

  const [userCompanies, setuserCompanies] = useState<Array<userCompanyType>>(
    new Array<userCompanyType>()
  );

  const [selectedCompany, setselectedCompany] = useState<userCompanyType | undefined>(undefined);

  const selectCompanyValid = selectedCompany !== undefined;

  const [wsyncId, setwsyncId] = useState<string>("");

  const [readerId, setReaderId] = useState<string | undefined>("-");

  const wsyncInputValid = wsyncId !== undefined && wsyncId !== "";

  const [selectedDeviceSerial, setselectedDeviceSerial] = useState<string | undefined>(undefined);

  const selectedDeviceSerialValid =
    selectedDeviceSerial !== undefined && selectedDeviceSerial !== "";

  // **********************************************************************************************************************
  //useEffect

  useEffect(() => {
    setactiveLicence(activeLicenceState);
    setusedLicenceDevices(usedLicenceDevicesState);
  }, [activeLicenceState, usedLicenceDevicesState]);

  useEffect(() => {
    const sessionWsyncId = window.sessionStorage.getItem("wsyncid");
    if (sessionWsyncId !== null) {
      setwsyncId(sessionWsyncId);
    }
    const sessionSelectedTab = Number(window.sessionStorage.getItem("selectedtab"));
    if (sessionSelectedTab !== null) {
      setselectedTab(sessionSelectedTab);
    }
    if (props.editedDevice !== undefined) {
      seteditedDevice(props.editedDevice);
      getUserCompanies();
    } else {
      setErrorMessage(t("measurement_list.link_device_dialog.error_device_missing"));
    }
  }, [props.editedDevice, t]);

  useEffect(() => {
    if (selectedTab === 0) {
      if (selectedCompany !== undefined) {
        dispatch(getCompanyDevices(selectedCompany.id));
      }
    }

    if (selectedProject !== null && selectedProject !== undefined) {
      dispatch(fetchLinkedDevicesCount(selectedProject.project.id));
    }
    return () => {
      dispatch(clearCompanySliceError());
    };
  }, [selectedProject, selectedCompany, selectedTab, dispatch]);

  useEffect(() => {
    if (deviceTypes !== undefined) {
    const editedDeviceType = deviceTypes.find(type => type.name === editedDevice?.deviceType)
    let arrDevices: Array<dataRowType> = [];

    companyDevices.filter(companyDevice => companyDevice.deviceTypeId === Number(editedDeviceType?.id)).forEach(device => {
      arrDevices.push({
        id: device.deviceId,
        serial: device.serial,
        tags: device.deviceTags.map(tag => tag.name),
        typename: deviceTypes?.find(x => Number(x.id) === device.deviceTypeId)?.name,
        isLinked: device.projects.length > 0 ? t("measurement_list.link_device_dialog.yes") : t("measurement_list.link_device_dialog.no")
      });
    });
    setDeviceData(arrDevices);
  };
  }, [companyDevices, deviceTypes, t, editedDevice]);

  // **********************************************************************************************************************
  // FORM FUNCTIONS
  const getUserCompanies = () => {
    let projectCompany: userCompanyType = {
      name:
        t("measurement_list.link_device_dialog.project_company") +
        ": " +
        selectedProject.project.ownerCompanyName,
      id: selectedProject.project.ownerCompanyId
    };
    // let userCompany: userCompanyType = {
    //   name: t("measurement_list.link_device_dialog.user_company") + ": Sami Y2",
    //   id: 2
    // };

    let newCompanies: Array<userCompanyType> = [projectCompany];

    setuserCompanies(newCompanies);
    setselectedCompany(newCompanies[0]);
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedCompany = userCompanies.find(x => x.id === Number(event.target.value));
    setselectedCompany(selectedCompany);
  };

  const handleWsyncIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setwsyncId(event.target.value);
    window.sessionStorage.setItem("wsyncid", event.target.value);
  };

  const handleSave = async () => {
    if (selectedTab === 0 && selectCompanyValid === false) {
      setErrorMessage(t("measurement_list.link_device_dialog.select_company"));
      return;
    } else if (selectedTab === 1 && wsyncInputValid === false) {
      setErrorMessage(t("measurement_list.link_device_dialog.input_wsyncid"));
      return;
    } else if (selectedDeviceSerialValid === false) {
      setErrorMessage(t("measurement_list.link_device_dialog.select_device"));
      return;
    } else if (activeLicence === undefined) {
      setErrorMessage(t("measurement_list.link_device_dialog.error_cannot_validate_licence"));
      return;
    } else if (
      activeLicence !== undefined &&
      usedLicenceDevices >= activeLicence.maxLinkedDevices
    ) {
      setErrorMessage(
        t("measurement_list.link_device_dialog.error_cannot_add_more_devices_to_project")
      );
      return;
    }

    if (editedDevice === undefined) {
      setErrorMessage(t("measurement_list.link_device_dialog.error_device_missing2"));
      return;
    }

    //DO ACTUALY SAVE TO
    let oldDepths: Array<{ typeName: string; depth: string }> = new Array<{
      typeName: string;
      depth: string;
    }>();

    editedDevice.sensorUnits.forEach(sensor => {
      oldDepths.push({ typeName: sensor.typeName, depth: sensor.depth });
    });

    let newSerial = selectedDeviceSerial !== undefined ? selectedDeviceSerial : "";

    try {
      await dispatch(
        linkMeasurementPointDevice({
          measurementPointId: props.measurementPointId,
          deviceId: editedDevice.id,
          deviceSerial: newSerial,
          depthDefinition: editedDevice.depthDefinition,
          depth: oldDepths[0].depth,
          depths: oldDepths
        })
      );
    } 
    catch (err) {
      console.log("Error linking device");
    } 
    finally {
      if (props.planId !== undefined) {
        dispatch(fetchPlanMeasurementsLatestData(props.planId));
        dispatch(fetchPlanMeasurementsDevices(props.planId));
      }
    }

    // alert("Selected ID was (" + selectedDeviceSerial + ")");

    props.handleSave();
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setselectedTab(newValue);
    window.sessionStorage.setItem("selectedtab", newValue.toString());
  };

  // **********************************************************************************************************************
  //DataGrid
  const columnsCompany: GridColDef[] = [
    {
      field: "typename",
      headerName: t("measurement_list.link_device_dialog.typename"),
      flex: 0.5
    },
    {
      field: "serial",
      headerName: t("measurement_list.link_device_dialog.serialid"),
      flex: 0.5
    },
    {
      field: "tags",
      headerName: t("device_management.tags"),
      type: "string",
      flex: 0.5
    },
    {
      field: "isLinked",
      headerName: t("measurement_list.link_device_dialog.is_linked"),
      flex: 0.5
    }
  ];

  const columnsWSYNC: GridColDef[] = [
    {
      field: "typename",
      headerName: t("measurement_list.link_device_dialog.typename"),
      flex: 0.5
    },
    {
      field: "serial",
      headerName: t("measurement_list.link_device_dialog.serialid"),
      flex: 0.5
    },
    {
      field: "isLinked",
      headerName: t("measurement_list.link_device_dialog.is_linked"),
      flex: 0.5
    },
    {
      field: "lastMeasurementDate",
      headerName: t("measurement_list.link_device_dialog.last_measurement_date"),
      flex: 0.5
    }
  ];

  const [deviceData, setDeviceData] = useState<Array<dataRowType>>(new Array<dataRowType>());

  const [gridSelectionModel, setgridSelectionModel] = React.useState<GridSelectionModel>([]);

  useEffect(() => {
    if (gridSelectionModel.length === 0) {
      setselectedDeviceSerial(undefined);
      return;
    }

    //FIND SERIAL ID FROM DATA
    let selectedDevice = deviceData.find(x => x.id === gridSelectionModel[0]);

    if (selectedDevice !== null && selectedDevice !== undefined) {
      setselectedDeviceSerial(selectedDevice.serial);
    }
  }, [gridSelectionModel]);

  // **********************************************************************************************************************
  //ELEMENTS

  // **********************************************************************************************************************
  // webAPI QUERY
  // **********************************************************************************************************************

  const getWSyncDevices = () => {
    let arrDevices: Array<dataRowType> = new Array<dataRowType>();

    if (wsyncId === undefined || wsyncId === "") {
      setErrorMessage(t("measurement_list.link_device_dialog.wsync_id_is_empty"));
      return;
    }

    // /api/Companies/1/LinkableDevices?deviceTypeName=SH4
    const urlPath =
      commonAttributes.apiUrl +
      `/Wsync/LinkableDevices?devicetypename=${editedDevice?.deviceType}&projectId=${selectedProject.project.id}&connectionCode=${wsyncId}`;

    axios
      .get(urlPath)
      .then(response => {
        let dataObj: Array<any> = response.data.devices;

        setReaderId(response.data.readerId);

        dataObj.forEach(device => {
          arrDevices.push({
            id: device.id,
            serial: device.serial,
            typename: device.deviceType,
            isLinked: device.isLinked
              ? t("measurement_list.link_device_dialog.yes")
              : t("measurement_list.link_device_dialog.no"),
            lastMeasurementDate: GetDateString(device.lastMeasurementDateTime, "HH:mm DD.MM.YYYY")
          });
        });

        setDeviceData(arrDevices);

        setErrorMessage("");
      })
      .catch(error => {
        setErrorMessage(t("measurement_list.link_device_dialog.failed_get_wsync_data"));
      });
  };

  // **********************************************************************************************************************
  return (
    <Dialog 
     open={true} 
     onClose={props.handleClose}
     sx={{
      minHeight: "500px",
      "& .MuiDialog-paper": {
        width: "95vw"
      }
     }}
    >
      <Box className={styles["linkdevice-form-container"]}>
        {!lowScreenHeight &&
        <div className={styles["linkdevice-form-header"]}>
          {t("measurement_list.link_device_dialog.link_selected_device")}
        </div>
        }
        {/**************************************************************************** */}
        <AppBar position="static" sx={{ width: "90%", backgroundColor: "#eb941c" }}>
          <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="inherit"
            value={selectedTab}
            onChange={handleTabChange}
          >
            <Tab
              sx={{ fontSize: "var(--inputFontSize)" }}
              label={t("measurement_list.link_device_dialog.company_devices")}
              value={0}
            />
            <Tab
              sx={{ fontSize: "var(--inputFontSize)" }}
              label={t("measurement_list.link_device_dialog.wsync_linking")}
              value={1}
            />
          </Tabs>
        </AppBar>
        <div className={styles["lindevice-tabs-content-container"]}>
          <div style={{ display: selectedTab === 0 ? "" : "none" }}>
            {/**************************************************************************** */}
            <TextField
              key={"company_selection"}
              select
              label={t("measurement_list.link_device_dialog.company")}
              helperText={t("measurement_list.link_device_dialog.please_select_company")}
              variant="standard"
              onChange={handleCompanyChange}
              required
              error={!selectCompanyValid}
              value={selectedCompany !== undefined ? selectedCompany.id : ""}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            >
              {userCompanies.map(company => (
                <MenuItem
                  sx={{ fontSize: "var(--inputFontSize)" }}
                  key={"companyselection_" + company.id}
                  value={company.id}
                >
                  {company.name}
                </MenuItem>
              ))}
            </TextField>

            {/**************************************************************************** */}
          </div>
          <div
            style={{
              display: selectedTab === 1 ? "flex" : "none",
              flexFlow: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <TextField
              key={"wsync_selection"}
              label={t("measurement_list.link_device_dialog.wsync_id")}
              helperText={t("measurement_list.link_device_dialog.please_fill_wsync")}
              variant="standard"
              required
              style={{ width: "50%" }}
              value={wsyncId}
              onChange={handleWsyncIDChange}
              error={!wsyncInputValid}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            {/* TODO */}
            <TextField
              key={"deviceid"}
              label={t("measurement_list.link_device_dialog.reader_id")}
              helperText={" "}
              variant="standard"
              style={{ width: "30%", marginLeft: "20px" }}
              disabled={true}
              value={readerId}
              InputProps={{ style: { fontSize: "var(--inputFontSize)" } }}
              InputLabelProps={{ style: { fontSize: "var(--inputFontSize)" } }}
            />
            <Button
              variant="contained"
              onClick={() => getWSyncDevices()}
              style={{ width: "20%", marginLeft: "20px" }}
            >
              {t("measurement_list.link_device_dialog.fecth")}
            </Button>
          </div>
        </div>
        {/**************************************************************************** */}
        {/* LICENCE  */}
        <div style={{ fontSize: "var(--basicFontSize)" }}>
          {t("measurement_list.link_device_dialog.project_licence") +
            ": " +
            usedLicenceDevices +
            " / " +
            activeLicence?.maxLinkedDevices}
        </div>
        {/**************************************************************************** */}
        <div style={{ flexGrow: "1", width: "90%", height: "100%"}}>
          <DataGrid
            rows={deviceData}
            loading={loading}
            columns={selectedTab === 0 ? columnsCompany : columnsWSYNC}
            density={"compact"}
            components={{ Toolbar: CustomToolbar }}
            // onRowClick={handleDeviceSelection}
            onSelectionModelChange={newSelectionModel => {
              setgridSelectionModel(newSelectionModel);
            }}
            selectionModel={gridSelectionModel}
            rowsPerPageOptions={[5, 25, 100, 500]}
            initialState={{
              sorting: {
                sortModel: [{ field: "serial", sort: "asc"}]
              }
            }}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "#eb941c",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main"
              },
              "& .Mui-selected": {
                border: "2px solid black"
              },
              fontSize: "var(--inputFontSize)"
            }}
            localeText = {{
              noRowsLabel: t("measurement_list.data_grid_toolbar.no_rows"),
              toolbarDensity: t("measurement_list.data_grid_toolbar.toolbar_density"),
              toolbarDensityLabel: t("measurement_list.data_grid_toolbar.toolbar_density_label"),
              toolbarDensityCompact: t("measurement_list.data_grid_toolbar.toolbar_density_compact"),
              toolbarDensityStandard: t("measurement_list.data_grid_toolbar.toolbar_density_standard"),
              toolbarDensityComfortable: t("measurement_list.data_grid_toolbar.toolbar_density_comfortable"),
              toolbarColumns: t("measurement_list.data_grid_toolbar.toolbar_columns"),
              toolbarFilters: t("measurement_list.data_grid_toolbar.toolbar_filters"),
              toolbarExport: t("measurement_list.data_grid_toolbar.toolbar_export"),
              toolbarExportLabel: t("measurement_list.data_grid_toolbar.toolbar_export_label"),
              toolbarExportCSV: t("measurement_list.data_grid_toolbar.toolbar_export_csv"),
              toolbarExportPrint: t("measurement_list.data_grid_toolbar.toolbar_export_print"),
              columnsPanelTextFieldLabel: t("measurement_list.data_grid_toolbar.columns_panel_text_field_label"),
              columnsPanelTextFieldPlaceholder: t("measurement_list.data_grid_toolbar.columns_panel_text_field_placeholder"),
              columnsPanelShowAllButton: t("measurement_list.data_grid_toolbar.columns_panel_show_all_button"),
              columnsPanelHideAllButton: t("measurement_list.data_grid_toolbar.columns_panel_hide_all_button"),
              filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
              filterPanelOperators: t("measurement_list.data_grid_toolbar.filter_panel_operators"),
              filterPanelInputLabel: t("measurement_list.data_grid_toolbar.filter_panel_Input_label"),
              filterPanelInputPlaceholder: t("measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"),
              filterOperatorContains: t("measurement_list.data_grid_toolbar.filter_operator_contains"),
              filterOperatorEquals: t("measurement_list.data_grid_toolbar.filter_operator_equals"),
              filterOperatorStartsWith: t("measurement_list.data_grid_toolbar.filter_operator_starts_with"),
              filterOperatorEndsWith: t("measurement_list.data_grid_toolbar.filter_operator_ends_with"),
              filterOperatorIsEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_empty"),
              filterOperatorIsNotEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_not_empty"),
              filterOperatorIsAnyOf: t("measurement_list.data_grid_toolbar.filter_operator_is_any_of"),
              filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
              filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
              filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
              columnMenuShowColumns: t("measurement_list.data_grid_column_menu.show_columns"),
              columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
              columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
              columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
              columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
              columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc"),
              columnHeaderFiltersLabel: t("measurement_list.data_grid_toolbar.show_filters"),
              toolbarFiltersTooltipShow: t("measurement_list.data_grid_toolbar.show_filters"),
              columnHeaderSortIconLabel: t("measurement_list.data_grid_column_menu.sort"),
              footerRowSelected: (count) => count === 1 ? t("measurement_list.data_grid_toolbar.rows_selected") : "",
              MuiTablePagination: {labelRowsPerPage: t("measurement_list.data_grid_toolbar.rows_per_page")},
            }}
          />
        </div>
        {/**************************************************************************** */}

        <Button
          onClick={() => {
            if (activeLicence && usedLicenceDevices < activeLicence?.maxLinkedDevices) {return;}
            navigate(`/projects/${selectedProject.project.id}/projectsettings/licences`)
            }}
          variant="contained"
          sx={{
            borderColor: "red",
            "&: hover": {
              background: "red"
            }
          }}
          style={errorMessage !== "" ? { display: "" } : { display: "none" }}
        >
          {errorMessage}
        </Button>
        <Button variant="contained" onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleSave();
          }}>
          {t("common.save")}
        </Button>
        <Button variant="contained" onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleClose();
          }}>
          {t("common.cancel")}
        </Button>
      </Box>
    </Dialog>
  );
}
