import * as React from "react";
import { Link as BrowserLink, useNavigate } from "react-router-dom";
import { UserProject } from "../../types";
import styles from "./ProjectListRow.module.css";
import { useTranslation } from "react-i18next";

//MaterialUI
import {
  Link,
  TableBody,
  TableCell,
  Typography,
  TableRow,
  Tooltip,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";

const ProjectListRow = (props: { row: UserProject }) => {
  const navigateToLink = useNavigate();
  const { row } = props;

  const { t } = useTranslation();

  const openMap = (project: UserProject) => {
    const apiUrl = "https://www.google.com/maps/search/?api=1&query="; //centurylink+field";
    let query = project.projectAddress1 + " ";
    query +=
      project.projectAddress2 !== undefined && project.projectAddress2 !== ""
        ? project.projectAddress2
        : "";
    query += " " + project.projectPostalCode;
    query += " " + project.projectCity;

    query = query.replaceAll(" ", "+");

    window.open(apiUrl + query, "_blank");
  };

  const openProjectSettings = (project: UserProject) => {
    ///projects/4/projectsettings/info
    navigateToLink(`${project.projectId}/projectsettings/info`);
  };

  return (
    <TableBody>
      <TableRow hover key={"projectrow_" + row.projectName + row.projectId}>
        <TableCell component="th" scope="row" className={styles["projects-table-project-cell"]}>
          <BrowserLink
            to={`/projects/${row.projectId}/load`}
            className={styles["projects-table-project-link"]}
          >
            <Link
              component="button"
              variant="body1"
              underline="always"
              sx={{ fontSize: "var(--navButtonFontSize)", textAlign: "left !important" }}
            >
              {row.projectName}
            </Link>
          </BrowserLink>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="right"
          className={styles["projects-table-address-cell"]}
        >
          <Typography
            sx={{ fontSize: "var(--navButtonFontSize)" }}
            variant="body1"
            component="span"
          >{`${row.projectAddress1}${row.projectAddress2 ? `, ${row.projectAddress2}` : ""}, ${
            row.projectPostalCode
          } ${row.projectCity}`}</Typography>
        </TableCell>
        <TableCell className={styles["projects-table-map-cell"]} size="small" align="right">
          <Tooltip title={t("tooltip.show_on_map") || ""}>
            <IconButton size="small" onClick={() => openMap(row)} aria-label="map">
              <MapIcon
                sx={{
                  color: "var(--colorOrange)",
                  height: "var(--iconSize)",
                  width: "var(--iconSize)"
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell className={styles["projects-table-edit-cell"]} size="small" align="right">
          <Tooltip title={t("tooltip.settings") || ""}>
            <IconButton size="small" onClick={() => openProjectSettings(row)} aria-label="settings">
              <SettingsIcon
                sx={{
                  color: "var(--colorOrange)",
                  height: "var(--iconSize)",
                  width: "var(--iconSize)"
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default ProjectListRow;
