import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { commonAttributes } from "../../../../common/commonAttributes";
import { DeviceSettingsAvailablePointDevicesProjectObject, DeviceSettingsDeviceObject, DeviceSettingsMeasurementPointObject, HistoryDataObject, MeasurementPointObject, PlanObject, SensorHistoryObject, Tag } from "../../../../../types";

export type DeviceState = {
  device: DeviceSettingsDeviceObject;
  plans: Array<PlanObject>;
  measurementPoints: Array<DeviceSettingsMeasurementPointObject>;
  availableMeasurementPointDeviceProjects: Array<DeviceSettingsAvailablePointDevicesProjectObject>;
  historyData: Array<SensorHistoryObject>;
  companyTags: Array<Tag>;
  createdProjectId: number;
  createdPlanId: number;
  planMeasurementPoints: Array<MeasurementPointObject>;
  error?: boolean;
  loading?: boolean;
  historyLoading: boolean;
};

const initialState: DeviceState = {
  device: {
    deviceId: -1,
    serial: "-1",
    deviceTypeId: -1,
    companyDeviceId: -1,
    companyId: -1,
    deviceTags: [],
    projects: [],
    latestMeasurements: []
  },
  plans: [],
  measurementPoints: [],
  availableMeasurementPointDeviceProjects: [],
  historyData: [],
  companyTags: [],
  createdProjectId: -1,
  createdPlanId: -1,
  planMeasurementPoints: [],
  error: false,
  loading: true,
  historyLoading: false
};

export const getDevice = createAsyncThunk(
  "selectedDevice/getDevice",
  async (deviceSerial: string) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/Devices/${deviceSerial}/ProjectsAndMeasurements`
    );

    return { data: response.data };
  }
);

export const getDevicePlans = createAsyncThunk(
  "selectedDevice/getDevicePlans",
  async (planId: number) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/Plans/${planId}`
    );

    return { data: response.data };
  }
);

export const getDeviceMeasurementPoints = createAsyncThunk(
  "selectedDevice/getDeviceMeasurementPoints",
  async (measurementPointId: number) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/MeasurementPoints/${measurementPointId}`
    );

    return { data: response.data };
  }
);

export const getAvailableMeasurementPointDevices = createAsyncThunk(
  "selectedDevice/getAvailableMeasurementPointDevices",
  async (deviceId: number) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/Devices/${deviceId}/AvailableMeasurementPointDevices`
    );

    return { data: response.data };
  }
);

export const getDeviceTags = createAsyncThunk(
  "selectedDevice/getDeviceTags",
  async (companyDeviceId: number) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/Tags/CompanyDevices/${companyDeviceId}`
    );

    return { data: response.data };
  }
);

export const getCompanyTags = createAsyncThunk(
  "selectedDevice/getCompanyTags",
  async (companyId: number) => {
    const response = await axios.get(
      `${commonAttributes.apiUrl}/Tags/Companies/${companyId}`
    );

    return { data: response.data };
  }
);

export const addTag = createAsyncThunk(
  "selectedDevice/addTag",
  async (params: {companyDeviceId: number, tagText: string}) => {
    const response = await axios.post(
      `${commonAttributes.apiUrl}/Tags/CompanyDevices/${params.companyDeviceId}?tagText=${params.tagText}`
    );

    return { data: response.data };
  }
);

export const addCompanyTagToDevice = createAsyncThunk(
  "selectedDevice/addCompanyTag",
  async (params: { tagId: number, companyDeviceId: number }) => {
    const response = await axios.put(
      `${commonAttributes.apiUrl}/Tags/${params.tagId}/CompanyDevices/${params.companyDeviceId}/AddTagToCompanyDevice`
    );

    return { data: response.data };
  }
);

export const removeTagFromDevice = createAsyncThunk(
  "selectedDevice/removeTag",
  async (params: { tagId: number, companyDeviceId: number }) => {
    const response = await axios.post(
      `${commonAttributes.apiUrl}/Tags/${params.tagId}/CompanyDevices/${params.companyDeviceId}/RemoveTagFromCompanyDevice`
    );

    return { data: params.tagId };
  }
);

export const linkMeasurementPointDeviceFromDeviceSettings =  createAsyncThunk(
  "DeviceSettings/linkMeasurementPointDevice",
  async (deviceObj: {
    measurementPointId: string;
    deviceId: string;
    deviceSerial: string;
    depthDefinition: number;
    depth: string;
    depths: Array<{ typeName: string; depth: string }>;
  }) => {
    let newLinkDeviceObj;

    let newDepths: { [key: string]: number } = {};

    //MAP DEPTHS
    deviceObj.depths.forEach(depht => {
      newDepths = { ...newDepths, [depht.typeName]: Number(depht.depth + ".0") };
    });

    if (deviceObj.depthDefinition === 2) {
      newLinkDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        serial: deviceObj.deviceSerial,
        depths: newDepths
      };
    } else {
      newLinkDeviceObj = {
        measurementpointid: Number(deviceObj.measurementPointId),
        serial: deviceObj.deviceSerial,
        depth: Number(deviceObj.depth + ".0")
      };
    }

    const data = await axios.post(
      `${commonAttributes.apiUrl}/MeasurementPoints/${deviceObj.measurementPointId}/Devices/${deviceObj.deviceId}/LinkDevice`,
      newLinkDeviceObj
    );

    return {
      data: data.data,
      measurementPointId: deviceObj.measurementPointId,
      deviceId: deviceObj.deviceId
    };
  }
);

export const getPlanMeasurementPoints = createAsyncThunk(
  "DeviceSettings/getPlanMeasurementPoints",
  async (planId: number) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/Plans/${planId}/MeasurementPoints`);

    return { data: response.data};
  }
);

export const getDeviceHistoryData = createAsyncThunk(
  "DeviceSettings/getDeviceHistoryData",
  async (deviceId: number) => {
    const response = await axios.get(`${commonAttributes.apiUrl}/MeasurementData/DeviceHistory?deviceId=${deviceId}`);

    return { data: response.data};
  }
);

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    clearDevice: state => {
      state = initialState;
    },
    clearError: state => {
      state.error = false;
    },
    clearMeasurementPointsAndPlans: state => {
      state.measurementPoints = [];
      state.plans = [];
    },
    updateProjects: (state, action) => {
      const projectId = action.payload;
      state.device.projects = state.device.projects.filter(project => project.id !== projectId);
    },
    setCreatedProjectId: (state, action) => {
      const projectId = action.payload;
      state.createdProjectId = projectId;
    },
    setCreatedPlanId: (state, action) => {
      const planId = action.payload;
      state.createdPlanId = planId;
    },
    clearAvailableMeasurementPoints: (state) => {
      state.availableMeasurementPointDeviceProjects = [];
    },
    clearHistoryData: (state) => {
      state.historyData = [];
    }
  },
  extraReducers: {
    [getDevice.pending.type]: (state, action) => {
      state.error = false;
      state.loading = true;
    },
    [getDevice.fulfilled.type]: (state, action) => {
      const response = action.payload.data;
      state.device = response;
      state.loading = false;
      state.error = false;
    },
    [getDevice.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to get device");
    },
    [getDevicePlans.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getDevicePlans.fulfilled.type]: (state, action) => {
      state.plans.push(action.payload.data);
      state.loading = false;
      state.error = false;
    },
    [getDevicePlans.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to get device plans");
    },
    [getDeviceMeasurementPoints.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getDeviceMeasurementPoints.fulfilled.type]: (state, action) => {
      state.measurementPoints.push(action.payload.data);
      state.loading = false;
      state.error = false;
    },
    [getDeviceMeasurementPoints.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to get device measurementpoints");
    },
    [getAvailableMeasurementPointDevices.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getAvailableMeasurementPointDevices.fulfilled.type]: (state, action) => {
      state.availableMeasurementPointDeviceProjects = action.payload.data;
      state.loading = false;
      state.error = false;
    },
    [getAvailableMeasurementPointDevices.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to get available measurementpointdevices");
    },
    [linkMeasurementPointDeviceFromDeviceSettings.pending.type]: (state, action) => {
      state.loading = true;
    },
    [linkMeasurementPointDeviceFromDeviceSettings.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to link measurementPointDevice");
    },
    [linkMeasurementPointDeviceFromDeviceSettings.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = false;
    },
    [addTag.pending.type]: (state, action) => {
      state.loading = true;
    },
    [addTag.fulfilled.type]: (state, action) => {
      const { id, name } = action.payload.data;
      state.loading = false;
      state.error = false;
      state.device.deviceTags.push({id, name});
      state.companyTags.push({id, name});
    },
    [addTag.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = true;
      console.log("Failed to add tag");
    },
    [removeTagFromDevice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [removeTagFromDevice.fulfilled.type]: (state, action) => {
      const updatedTags = state.device.deviceTags.filter(tag => tag.id !== action.payload.data);
      state.device.deviceTags = updatedTags;
      state.loading = false;
      state.error = false;
    },
    [removeTagFromDevice.rejected.type]: (state, action) => {
      state.loading = false;
      console.log("Failed to remove tag from device");
    },
    [getCompanyTags.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getCompanyTags.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.companyTags = action.payload.data;
    },
    [getCompanyTags.rejected.type]: (state, action) => {
      state.error = true;
      state.loading = false;
      console.log("Failed to get company tags");
    },
    [addCompanyTagToDevice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [addCompanyTagToDevice.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.error = false;
      const {id, name} = action.payload.data;
      const newTags = state.device.deviceTags.concat({id: id, name: name});
      state.device.deviceTags = newTags;
    },
    [addCompanyTagToDevice.rejected.type]: (state, action) => {
      state.error = true;
      state.loading = false;
      console.log("Failed to add company tag to device");
    },
    [getPlanMeasurementPoints.pending.type]: (state, action) => {
      state.loading = true;
    },
    [getPlanMeasurementPoints.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.planMeasurementPoints = action.payload.data;
    },
    [getPlanMeasurementPoints.rejected.type]: (state, action) => {
      state.loading = false;
      console.log("Failed to get plan measurement points");
    },
    [getDeviceHistoryData.pending.type]: (state) => {
      state.historyLoading = true;
    },
    [getDeviceHistoryData.fulfilled.type]: (state, action) => {
      const newData = [];
      newData.push(action.payload.data);
      state.historyData = newData;
      state.historyLoading = false;
    },
    [getDeviceHistoryData.rejected.type]: (state) => {
      state.historyLoading = false;
      console.log("Failed to get device history data");
    }
  }
});

export const { 
  clearDevice, 
  clearError, 
  clearMeasurementPointsAndPlans, 
  updateProjects,
  setCreatedProjectId,
  setCreatedPlanId,
  clearAvailableMeasurementPoints,
  clearHistoryData
} = deviceSlice.actions;

export default deviceSlice.reducer;
