// MUI
import { Button, Stack, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { GetDateString } from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import axios from "axios";
import moment from "moment";

import { commonAttributes } from "../../common/commonAttributes";

// CSS
import styles from "./CompanyViewUsers.module.css";

// DIALOG
import AddCompanyUserDialog from "./CompanyViewAddUserDialog";
import ConfirmDialog from "../../UI/ConfirmDialog";

type CompanyUserDataRow = {
  id: number;

  userId: number;
  userName: string;

  startDateTime: string;
  endDateTime: string;
  status: string;

  companyId: number;
  companyName: string;

  companyRoleId: number;
  companyRoleName: string;
};

export default function CompanyViewUsers() {
  const { t } = useTranslation();

  const { companyid } = useParams();

  const smallScreen = useMediaQuery("(max-width: 500px)");

  // GET DATA WHEN PROJECT ID CHANGES
  const [refershContent, setrefershContent] = useState(false);

  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);

  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [userToEdit, setUserToEdit] = useState<CompanyUserDataRow[]>();

  useEffect(() => {
    if (refershContent === true) getCompanyUsers();

    setrefershContent(false);
  }, [refershContent]);

  useEffect(() => {
    if (Number(companyid) >= 0) {
      setrefershContent(true);
    }
  }, [companyid]);

  const clearUserArray = () => {
    let usersData: Array<CompanyUserDataRow> = Array<CompanyUserDataRow>();
    setusersData(usersData);
  };

  const getCompanyUsers = () => {
    const urlPath = commonAttributes.apiUrl + `/companies/${companyid}/users`;

    axios
      .get(urlPath)
      .then(response => {
        let dataObj: Array<any> = response.data;

        let usersData: Array<CompanyUserDataRow> = Array<CompanyUserDataRow>();

        dataObj.forEach(user => {
          //console.log(user);

          if (user.endDateTime !== null) return;

          usersData.push({
            id: user.id,
            status: user.status,
            userId: user.userId,
            userName: user.userName,
            companyId: user.companyId,
            companyName: user.companyName,
            companyRoleId: user.companyRoleId,
            companyRoleName: user.companyRoleName,

            startDateTime: GetDateString(user.startDateTime, "DD-MM-YYYY"),
            endDateTime: GetDateString(user.endDateTime, "DD-MM-YYYY")
          });
        });

        setusersData(usersData);
      })
      .catch(error => alert(error));
  };

  ////////////////////////////////////////////////////////////////////////////////////////
  // USERS COLUMS
  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 150
    // },
    // {
    //   field: "userId",
    //   headerName: "UserId",
    //   width: 150
    // },
    {
      field: "userName",
      headerName: t("company_settings.company_usersview.email"),
      minWidth: 100,
      flex: 0.7
    },
    {
      field: "companyRoleName",
      headerName: t("company_settings.company_usersview.company_role"),
      minWidth: 100,
      flex: 0.3
    },
    {
      field: "startDateTime",
      headerName: t("company_settings.company_usersview.start_date"),
      minWidth: 100,
      flex: 0.3,
      hide: smallScreen ? true : false
    }
    // {
    //   field: "endDateTime",
    //   headerName: t("company_settings.company_usersview.end_date"),
    //   width: 150
    // }
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150
    // }
  ];

  // DATA
  const [usersData, setusersData] = useState<Array<CompanyUserDataRow>>(
    Array<CompanyUserDataRow>()
  );
  const [gridSelectionModel, setgridSelectionModel] = useState<GridSelectionModel>([]);

  const handleRemove = () => {
    let selectedUsers: Array<CompanyUserDataRow> = usersData.filter(x =>
      gridSelectionModel.includes(x.id)
    );

    selectedUsers.forEach(user => {
      const urlPath = commonAttributes.apiUrl + `/companies/Users/${user.id}`;

      const timeNow = moment.now();

      const timeUtc = moment.utc(timeNow, true);

      let updateUserDTO = {
        id: user.id,
        username: user.userName,
        companyId: Number(companyid),
        companyRoleID: user.companyRoleId,
        endDateTime: moment(timeUtc, true).format("YYYY-MM-DDTHH:mm:ss")
      };

      axios
        .post(urlPath, updateUserDTO)
        .then(() => {
          setrefershContent(true);
        })
        .catch(error => console.log("Errro 123123, " + error));
    });

    setgridSelectionModel([]);
  };

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // DIALOG
  const [showAddDialog, setshowAddDialog] = useState(false);
  const handleAddDialogSave = () => {
    setrefershContent(true);
    setshowAddDialog(false);
  };
  const handleAddDialogClose = () => {
    setshowAddDialog(false);
  };
  const handleEditDialogClose = () => {
    setShowEditUserDialog(false);
  };

  const handleEditDialogSave = () => {
    setgridSelectionModel([]);
    setrefershContent(true);
    setShowEditUserDialog(false);
  };
  
  return (
    <div className={styles["companysettings-users-container"]}>
      <div className={styles["companysettings-controls-container"]}>
        <Stack direction="row" spacing={2}>
          <Button 
            size="small" 
            variant="contained" 
            onClick={() => setshowAddDialog(true)}
          >
            {t("company_settings.company_usersview.add")}
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={gridSelectionModel.length <= 0}
            onClick={(e) => {
              e.preventDefault();
              setShowDeleteUserDialog(true);
            }}
          >
            {t("company_settings.company_usersview.remove")}
          </Button>
          <Button 
            size="small" 
            variant="contained" 
            disabled={gridSelectionModel.length <= 0 ||gridSelectionModel.length > 1}
            onClick={() => {
              setUserToEdit(usersData.filter(user => user.id === gridSelectionModel[0]));
              setShowEditUserDialog(true);
            }}
          >
            {t("company_settings.company_usersview.edit")}
          </Button>
        </Stack>
      </div>
      <div className={styles["companysettings-content-container"]}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "userName", sort: "asc" }],
            },
          }}
          rows={usersData}
          columns={columns}
          autoPageSize
          checkboxSelection
          selectionModel={gridSelectionModel}
          onSelectionModelChange={newSelectionModel => {
            setgridSelectionModel(newSelectionModel);
          }}
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main"
            },
            "& .Mui-selected": {
              background: "gray"
            },
            fontSize: "var(--basicFontSize)",
             "& .Mui-checked svg": {
              width: "var(--checkBoxSize)",
              height: "var(--checkBoxSize)",
              backgroundColor: "transparent",
              borderRadius: 2,
              color: "orange"
            },
            "& .MuiCheckbox-root svg": {
              width: "var(--checkBoxSize)",
              height: "var(--checkBoxSize)",
              backgroundColor: "transparent",
              borderRadius: 2
            }
          }}
          localeText = {{
            toolbarDensity: t("measurement_list.data_grid_toolbar.toolbar_density"),
            toolbarDensityLabel: t("measurement_list.data_grid_toolbar.toolbar_density_label"),
            toolbarDensityCompact: t("measurement_list.data_grid_toolbar.toolbar_density_compact"),
            toolbarDensityStandard: t("measurement_list.data_grid_toolbar.toolbar_density_standard"),
            toolbarDensityComfortable: t("measurement_list.data_grid_toolbar.toolbar_density_comfortable"),
            toolbarColumns: t("measurement_list.data_grid_toolbar.toolbar_columns"),
            toolbarFilters: t("measurement_list.data_grid_toolbar.toolbar_filters"),
            toolbarExport: t("measurement_list.data_grid_toolbar.toolbar_export"),
            toolbarExportLabel: t("measurement_list.data_grid_toolbar.toolbar_export_label"),
            toolbarExportCSV: t("measurement_list.data_grid_toolbar.toolbar_export_csv"),
            toolbarExportPrint: t("measurement_list.data_grid_toolbar.toolbar_export_print"),
            columnsPanelTextFieldLabel: t("measurement_list.data_grid_toolbar.columns_panel_text_field_label"),
            columnsPanelTextFieldPlaceholder: t("measurement_list.data_grid_toolbar.columns_panel_text_field_placeholder"),
            columnsPanelShowAllButton: t("measurement_list.data_grid_toolbar.columns_panel_show_all_button"),
            columnsPanelHideAllButton: t("measurement_list.data_grid_toolbar.columns_panel_hide_all_button"),
            filterPanelColumns: t("measurement_list.data_grid_toolbar.filter_panel_columns"),
            filterPanelOperators: t("measurement_list.data_grid_toolbar.filter_panel_operators"),
            filterPanelInputLabel: t("measurement_list.data_grid_toolbar.filter_panel_Input_label"),
            filterPanelInputPlaceholder: t("measurement_list.data_grid_toolbar.filter_panel_Input_placeholder"),
            filterOperatorContains: t("measurement_list.data_grid_toolbar.filter_operator_contains"),
            filterOperatorEquals: t("measurement_list.data_grid_toolbar.filter_operator_equals"),
            filterOperatorStartsWith: t("measurement_list.data_grid_toolbar.filter_operator_starts_with"),
            filterOperatorEndsWith: t("measurement_list.data_grid_toolbar.filter_operator_ends_with"),
            filterOperatorIsEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_empty"),
            filterOperatorIsNotEmpty: t("measurement_list.data_grid_toolbar.filter_operator_is_not_empty"),
            filterOperatorIsAnyOf: t("measurement_list.data_grid_toolbar.filter_operator_is_any_of"),
            filterValueAny: t("measurement_list.data_grid_toolbar.filter_value_any"),
            filterValueTrue: t("measurement_list.data_grid_toolbar.filter_value_true"),
            filterValueFalse: t("measurement_list.data_grid_toolbar.filter_value_false"),
            columnMenuShowColumns: t("measurement_list.data_grid_column_menu.show_columns"),
            columnMenuFilter: t("measurement_list.data_grid_column_menu.filter"),
            columnMenuHideColumn: t("measurement_list.data_grid_column_menu.hide_column"),
            columnMenuUnsort: t("measurement_list.data_grid_column_menu.unsort"),
            columnMenuSortAsc: t("measurement_list.data_grid_column_menu.sort_asc"),
            columnMenuSortDesc: t("measurement_list.data_grid_column_menu.sort_desc")
          }}
        />
      </div>
      <ConfirmDialog 
        key="delete-company-user-dialog"
        open={showDeleteUserDialog}
        content={t("dialog.delete_user")}
        setOpen={setShowDeleteUserDialog}
        value={undefined}
        onConfirm={handleRemove}
      />
      {showAddDialog && (
        <AddCompanyUserDialog
          key="company-user-add-dialog"
          add={true}
          edit={false}
          companyId={Number(companyid)}
          handleSave={() => handleAddDialogSave()}
          handleClose={() => handleAddDialogClose()}
          usersData={usersData}
          gridSelectionModel={gridSelectionModel}
        />
      )}
      {showEditUserDialog && (
        <AddCompanyUserDialog
          key="projectview-eidt-user-dialog"
          add={false}
          edit={true}
          companyId={Number(companyid)}
          handleClose={() => handleEditDialogClose()}
          handleSave={() => handleEditDialogSave()}
          usersData={usersData}
          userToEdit={userToEdit}
          gridSelectionModel={gridSelectionModel}
        />
      )}
    </div>
  );
}
