import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  FormControl,
  TextField,
  MenuItem,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { useGetUserAdminCompaniesQuery } from "../company/companyAPI";
import { useAddProjectMutation, useAddProjectUserMutation } from "./projectApi";
import { getUserProjectManagerAndAdminCompanies } from "../company/companySlice";

import styles from "./AddProjectForm.module.css";
import { setCreatedProjectId } from "./projectview/components/devices/deviceSlice";
import { fetchActiveLicence, fetchLicenceTypes } from "./projectSlice";

const AddProjectForm = (props: { open: boolean; handleClose: () => void, deviceSettings?: boolean; }) => {

  const dispatch = useDispatch();

  const { data, error, isLoading: isLoadingCompanies } = useGetUserAdminCompaniesQuery(1);
  const [addProject, { isLoading: isLoadingPost }] = useAddProjectMutation();
  const [addProjectUser, { isLoading: isLoadingUserPost }] = useAddProjectUserMutation();
  const userCompanies = useSelector(getUserProjectManagerAndAdminCompanies);

  //const userCompanies = useSelector((state: RootState) => state.company.userCompanyRoles);

  const { t } = useTranslation();

  const [ownerCompanyId, setOwnerCompanyId] = React.useState("");
  const [name, setName] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [billingReference, setBillingReference] = React.useState("");

  // Validation
  const [ownerCompanyIdTouched, setOwnerCompanyIdTouched] = React.useState(false);
  const [nameTouched, setNameTouched] = React.useState(false);
  const [address1Touched, setAddress1Touched] = React.useState(false);
  const [postalCodeTouched, setPostalCodeTouched] = React.useState(false);
  const [cityTouched, setCityTouched] = React.useState(false);

  const ownerCompanyIdValid = ownerCompanyId !== "";
  const ownerCompanyIdInvalid = !ownerCompanyIdValid && ownerCompanyIdTouched;
  const nameValid = name?.trim() !== "";
  const nameInvalid = !nameValid && nameTouched;
  const address1Valid = address1?.trim() !== "";
  const address1Invalid = !address1Valid && address1Touched;
  const postalCodeValid = postalCode?.trim() !== "";
  const postalCodeInvalid = !postalCodeValid && postalCodeTouched;
  const cityValid = city?.trim() !== "";
  const cityInvalid = !cityValid && cityTouched;

  const formInvalid =
    !ownerCompanyIdValid || !nameValid || !address1Valid || !postalCodeValid || !cityValid;

  const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOwnerCompanyId(event.target.value);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleAddress1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  };
  const handleAddress2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  };
  const handlePostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostalCode(event.target.value);
  };
  const handleCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };
  const handleBillingReferenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBillingReference(event.target.value);
  };

  const handleCompanyBlur = () => {
    setOwnerCompanyIdTouched(true);
  };
  const handleNameBlur = () => {
    setNameTouched(true);
  };
  const handleAddress1Blur = () => {
    setAddress1Touched(true);
  };
  const handlePostalCodeBlur = () => {
    setPostalCodeTouched(true);
  };
  const handleCityBlur = () => {
    setCityTouched(true);
  };

  const handleSend = () => {
    setOwnerCompanyIdTouched(true);
    setNameTouched(true);
    setAddress1Touched(true);
    setPostalCodeTouched(true);
    setCityTouched(true);

    if (formInvalid) return;

    addProject({
      ownerCompanyId: +ownerCompanyId,
      name,
      address1,
      address2,
      postalCode,
      city,
      billingReference
    })
      .then(res => {
        if ("data" in res) {
          if (props.deviceSettings === true) {
            dispatch(setCreatedProjectId(res.data.id));
            dispatch(fetchActiveLicence(res.data.id));
            dispatch(fetchLicenceTypes(res.data.id));
          }
        }
        props.handleClose();
      })
      .catch(error => console.log(error));
  };

  return (
    <>
    {!props.deviceSettings && <Dialog open={props.open} onClose={props.handleClose}>
      <div className={styles["add-project-form-container"]}>
        <Typography
          sx={{ fontSize: "var(--headerFontSize)", fontWeight: "bold" }}
          mt={2}
          mb={2}
          ml={2}
        >
          {t("projects_page.add_project_form.header")}
        </Typography>
        {isLoadingCompanies && <div>{t("notifications.loading_title")}</div>}
        {error && <div>{t("notifications.error_title")}</div>}
        {data && (
          <>
            <FormControl variant="standard" sx={{ m: 1, mb: 3 }}>
              <TextField
                required
                select
                fullWidth
                size="small"
                label={t("projects_page.add_project_form.company_label")}
                id="add-project-form-company"
                value={ownerCompanyId}
                onChange={handleCompanyChange}
                onBlur={handleCompanyBlur}
                error={ownerCompanyIdInvalid}
                SelectProps={{
                  native: true
                }}
                sx={{ fontSize: "var(--basicFontSize)" }}
              >
                <option key="empty_company_value" value="" />
                {userCompanies.map(company => {
                  return (
                    <option
                      style={{ fontSize: "var(--basicFontSize)" }}
                      key={company.companyId}
                      value={company.companyId}
                    >
                      {company.companyName}
                    </option>
                  );
                })}
                {!userCompanies && <MenuItem />}
              </TextField>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, mb: 1 }}>
              <TextField
                required
                id="project-name-text-field"
                label={t("projects_page.add_project_form.project_name_label")}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                error={nameInvalid}
                InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              ></TextField>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, mb: 3 }}>
              <TextField
                required
                id="project-name-text-field"
                label={t("projects_page.add_project_form.address1_label")}
                value={address1}
                onChange={handleAddress1Change}
                sx={{ mb: 2 }}
                onBlur={handleAddress1Blur}
                error={address1Invalid}
                InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              ></TextField>
              <TextField
                id="project-name-text-field"
                label={t("projects_page.add_project_form.address2_label")}
                value={address2}
                onChange={handleAddress2Change}
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              ></TextField>
              <Grid container className={styles["postal-code-city-container"]}>
                <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                  <TextField
                    required
                    id="project-name-text-field"
                    label={t("projects_page.add_project_form.postal_code_label")}
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                    sx={{ mb: 2, mr: 2, flexGrow: 1 }}
                    onBlur={handlePostalCodeBlur}
                    error={postalCodeInvalid}
                    InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                    InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                  ></TextField>
                </Grid>
                <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                  <TextField
                    required
                    id="project-name-text-field"
                    label={t("projects_page.add_project_form.city_label")}
                    value={city}
                    onChange={handleCityChange}
                    sx={{ mb: 2, flexGrow: 1 }}
                    onBlur={handleCityBlur}
                    error={cityInvalid}
                    InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                    InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                  ></TextField>
                </Grid>
              </Grid>
              <TextField
                id="project-name-text-field"
                label={t("projects_page.add_project_form.billing_reference_label")}
                value={billingReference}
                onChange={handleBillingReferenceChange}
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              ></TextField>
              <Grid container className={styles["add-project-form-buttons-container"]}>
                <Button
                  className={styles["add-project-form-cancel-button"]}
                  variant="outlined"
                  onClick={() => props.handleClose()}
                  sx={{ mr: 2 }}
                  disabled={isLoadingCompanies || isLoadingPost || isLoadingUserPost}
                >
                  {t("projects_page.add_project_form.cancel_button")}
                </Button>
                <Button
                  className={styles["add-project-form-add-button"]}
                  variant="contained"
                  onClick={handleSend}
                >
                  {t("projects_page.add_project_form.save_button")}
                </Button>
              </Grid>
            </FormControl>
          </>
        )}
      </div>
    </Dialog>
    }
    {props.deviceSettings &&
      <div className={styles["add-project-form-container"]}>
      {isLoadingCompanies && <div>{t("notifications.loading_title")}</div>}
      {error && <div>{t("notifications.error_title")}</div>}
      {data && (
        <>
          <FormControl variant="standard" sx={{ m: 1, mb: "3px" }}>
            <TextField
              required
              select
              fullWidth
              size="small"
              label={t("projects_page.add_project_form.company_label")}
              id="add-project-form-company"
              value={ownerCompanyId}
              onChange={handleCompanyChange}
              onBlur={handleCompanyBlur}
              error={ownerCompanyIdInvalid}
              SelectProps={{
                native: true
              }}
              sx={{ fontSize: "var(--basicFontSize)" }}
            >
              <option key="empty_company_value" value=""/>
              {userCompanies.map(company => {
                return (
                  <option
                    style={{ fontSize: "var(--basicFontSize)" }}
                    key={company.companyId}
                    value={company.companyId}
                  >
                    {company.companyName}
                  </option>
                );
              })}
              {!userCompanies && <MenuItem />}
            </TextField>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, mb: "3px" }}>
            <TextField
              required
              id="project-name-text-field"
              label={t("projects_page.add_project_form.project_name_label")}
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              error={nameInvalid}
              InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
            ></TextField>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, mb: "5px" }}>
            <TextField
              required
              id="project-name-text-field"
              label={t("projects_page.add_project_form.address1_label")}
              value={address1}
              onChange={handleAddress1Change}
              sx={{ mb: "8px" }}
              onBlur={handleAddress1Blur}
              error={address1Invalid}
              InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
            ></TextField>
            <TextField
              id="project-name-text-field"
              label={t("projects_page.add_project_form.address2_label")}
              value={address2}
              onChange={handleAddress2Change}
              sx={{ mb: "8px" }}
              InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
            ></TextField>
            <Grid container className={styles["postal-code-city-container"]}>
              <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                <TextField
                  required
                  id="project-name-text-field"
                  label={t("projects_page.add_project_form.postal_code_label")}
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                  sx={{ mb: "8px", mr: 2, flexGrow: 1 }}
                  onBlur={handlePostalCodeBlur}
                  error={postalCodeInvalid}
                  InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                  InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                ></TextField>
              </Grid>
              <Grid item sx={{ flexGrow: 1, display: "flex" }}>
                <TextField
                  required
                  id="project-name-text-field"
                  label={t("projects_page.add_project_form.city_label")}
                  value={city}
                  onChange={handleCityChange}
                  sx={{ mb: "8px", flexGrow: 1 }}
                  onBlur={handleCityBlur}
                  error={cityInvalid}
                  InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                  InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                ></TextField>
              </Grid>
              <TextField
                required
                id="project-name-text-field"
                label={t("projects_page.add_project_form.billing_reference_label")}
                value={billingReference}
                onChange={handleBillingReferenceChange}
                sx={{ mb: "6px" }}
                InputLabelProps={{ style: { fontSize: "var(--basicFontSize)" } }}
                InputProps={{ style: { fontSize: "var(--basicFontSize)" } }}
              />
                <Button
                  className={styles["add-project-form-add-button"]}
                  variant="contained"
                  onClick={handleSend}
                >
                  {t("projects_page.add_project_form.save_button")}
                </Button>
            </Grid>
          </FormControl>
        </>
      )}
    </div>
    }
    </>
  );
};

export default AddProjectForm;
