/* import axios from "axios"; */

import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithAuth } from "../../app/baseQuery";

import {
  CompanyObject,
  CompanyBillingObject,
  UserCompanyObject,
  LinkableDevice
} from "../../types";

export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Companies", "Billing"],
  endpoints: builder => ({
    getCompanies: builder.query<CompanyObject[], void>({
      query: () => "/Companies",
      providesTags: [{ type: "Companies", id: "COMPANY_LIST" }]
    }),

    getCompaniesPage: builder.query<CompanyObject[], number>({
      query: id => `/Companies/Page`,
      providesTags: [{ type: "Companies", id: "COMPANY_LIST" }]
    }),
    /***************************************************************************************************************/
    getCompany: builder.query<CompanyObject, number>({
      query: id => `/Companies/${id}`,
      providesTags: [{ type: "Companies", id: "COMPANY_LIST" }]
    }),
    addCompany: builder.mutation<CompanyObject, Omit<CompanyObject, "id">>({
      query: company => {
        return {
          url: "/Companies/Add",
          method: "POST",
          body: company
        };
      },
      invalidatesTags: [{ type: "Companies", id: "COMPANY_LIST" }]
    }),
    editCompany: builder.mutation<CompanyObject, Omit<CompanyObject, "status">>({
      query: company => {
        return {
          url: `/Companies/${company.id}`,
          method: "POST",
          body: company
        };
      },
      invalidatesTags: [{ type: "Companies", id: "COMPANY_LIST" }]
    }),
    /***************************************************************************************************************/
    getCompanyBilling: builder.query<CompanyBillingObject, number>({
      query: id => `/Companies/${id}/Billing`,
      providesTags: [{ type: "Billing", id: "COMPANY_LIST" }]
    }),
    editCompanyBilling: builder.mutation<CompanyBillingObject, Omit<CompanyBillingObject, "">>({
      query: billing => {
        return {
          url: `/Companies/${billing.companyId}/Billing`,
          method: "POST",
          body: billing
        };
      },
      invalidatesTags: [{ type: "Billing", id: "COMPANY_LIST" }]
    }),
    /***************************************************************************************************************/
    getUserAdminCompanies: builder.query<UserCompanyObject[], number>({
      query: id => `/Users/${id}/Companies/`,
      transformResponse(response: UserCompanyObject[]) {
        let adminCompanies = response.filter(company => company.companyRoleName === "Admin");
        adminCompanies = adminCompanies.sort((a, b) => a.companyName.localeCompare(b.companyName));
        return adminCompanies;
      }
    }),
    getUserCompanies: builder.query<UserCompanyObject[], void>({
      query: () => `/Users/Companies`,
      providesTags: [{ type: "Companies", id: "USER_COMPANIES"}]
    }),
    getUserCompanyRole: builder.query<CompanyObject, number>({
      query: id => `/Companies/roles/${id}/`,
      providesTags: [{ type: "Companies", id: "COMPANY_ROLE" }]
    }),
    /***************************************************************************************************************/
    getLinkableDevices: builder.query<LinkableDevice[], number>({
      query: id => `/Companies/${id}/LinkableDevices`,
      transformResponse(response: LinkableDevice[]) {
        return response;
      },
      providesTags: [{ type: "Companies", id: "DEVICE_LIST" }]
    })
  })
});

export const {
  useGetCompaniesQuery,
  useEditCompanyMutation,
  useGetCompaniesPageQuery,
  useGetCompanyQuery,
  useAddCompanyMutation,
  useGetCompanyBillingQuery,
  useEditCompanyBillingMutation,
  useGetUserAdminCompaniesQuery,
  useGetUserCompaniesQuery,
  useGetUserCompanyRoleQuery,
  useGetLinkableDevicesQuery
} = companyApi;
