import { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import styles from "./ProjectListRow.module.css";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useTranslation } from "react-i18next";

import { ProjectTransferObject } from "./projectApi";

import ProjectListTransferProjectRow from "./ProjectListTransferProjectRow";

interface ITransferProject {
  transferObject: ProjectTransferObject;
}

const ProjectListTransferProjectTable = (props: ITransferProject) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const RowArrow = () => {
    if (open) {
      return (
        <KeyboardArrowUpIcon
          sx={{
            height: "var(--iconSize)",
            width: "var(--iconSize)"
          }}
        />
      );
    } else {
      return (
        <KeyboardArrowDownIcon
          sx={{
            height: "var(--iconSize)",
            width: "var(--iconSize)"
          }}
        />
      );
    }
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          className={styles["projects-table-company-cell"]}
          onClick={() => setOpen(prev => !prev)}
        >
          <IconButton aria-label="expand row" size="small">
            <RowArrow />
          </IconButton>
          <Typography
            sx={{ fontSize: "var(--navButtonFontSize)" }}
            variant="body1"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            {t("projects_page.transferproject_header")}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="medium" aria-label="transferedProjects">
                <TableBody>
                  {props.transferObject.projectTransfers.map(project => {
                    return (
                      <ProjectListTransferProjectRow
                        key={"projecttransferRow_" + project.projectId}
                        transferProject={project}
                        companyUsers={props.transferObject.companyUsers}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ProjectListTransferProjectTable;
