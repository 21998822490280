import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Tooltip, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
interface switchProps {
  onChange1: () => void;
  onChange2: () => void;
  checked1: boolean;
  checked2: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--colorOrange)"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box"
  }
}));

export const CustomSwitch = (props: switchProps) => {

  const {t} = useTranslation();

  return (
    <FormGroup>
      <Stack
        ml={"1px"}
        mt={"5px"}
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"center"}
      >
        <Typography variant="caption" fontWeight={"bold"}>
          Abs.
        </Typography>
        <Tooltip title={t("tooltip.switch_rh") || ""} placement="top">
        <span>
        <AntSwitch
          checked={props.checked1}
          onChange={props.onChange1}
          inputProps={{ "aria-label": "ant design" }}
        />
        </span>
        </Tooltip>
        <Typography variant="caption" fontWeight={"bold"}>
          %RH
        </Typography>
      </Stack>
      <Stack mt={"8px"} direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
        <Typography variant="caption" sx={{fontWeight: "bold", textDecoration: "line-through"}}>
          MC(T)
        </Typography>
        <Tooltip title={t("tooltip.switch_temp") || ""}>
        <span>
        <AntSwitch
          checked={props.checked2}
          onChange={props.onChange2}
          inputProps={{ "aria-label": "ant design" }}
        />
        </span>
        </Tooltip>
        <Typography variant="caption" fontWeight={"bold"}>
          MC(T)
        </Typography>
      </Stack>
    </FormGroup>
  );
};

export const SingleSwitch = (props: SwitchProps) => {

  const { t } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <FormGroup sx={{alignItems: "flex-end"}}>
      <FormControlLabel
        checked={props.checked}
        control={
          <AntSwitch 
          sx={{marginRight: "5px"}}
          onChange={props.onChange}
          />
        }
      label={!smallScreen ? t("projects_page.show_all_projects_switch") || "": t("projects_page.show_all_projects_switch_mobile") || ""}
      />
    </FormGroup>
  );
};
